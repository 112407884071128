<script>
import { Pie } from "vue-chartjs";

export default {
  // props: {
  //   abp_data: {
  //     type: Array,
  //     default: []
  //   },
  // },
  data() {
    return {
      abp_data_list: [],
      abp_names_list: []
    }
  },
  // watch: {
  //   currentPage() {
  //     this.abp_data_list = this.abp_data;
  //   }
  // },
  created() {
    this.getABPStatistics();
  },
  extends: Pie,
  methods: {
    getABPStatistics() {
      this.$store.dispatch("getABPActStatistics").then((response) => {
        console.log(response.data);
        this.abp_data_list = response.data.abp_data;
        this.abp_names_list = response.data.abp_names_list;
        this.gradient = this.$refs.canvas
            .getContext("2d")
            .createLinearGradient(0, 0, 0, 450);
        this.gradient2 = this.$refs.canvas
            .getContext("2d")
            .createLinearGradient(0, 0, 0, 450);
        this.gradient3 = this.$refs.canvas
            .getContext("2d")
            .createLinearGradient(0, 0, 0, 450);

        this.gradient.addColorStop(0, "rgba(122, 211, 141, 0.5)");
        this.gradient.addColorStop(0.5, "rgba(122, 211, 141, 0.25)");
        this.gradient.addColorStop(1, "rgba(122, 211, 141, 1)");


        this.gradient2.addColorStop(0, "rgba(255, 187, 86, 0.9)");
        this.gradient2.addColorStop(0.5, "rgba(255, 187, 86, 0.25)");
        this.gradient2.addColorStop(1, "rgba(255, 187, 86, 1)");

        this.gradient3.addColorStop(0, "rgba(232, 99, 99, 1)");
        this.gradient3.addColorStop(0.5, "rgba(232, 99, 99, 0.25)");
        this.gradient3.addColorStop(1, "rgba(232, 99, 99, 1)");

        this.renderChart(
            {
              labels: this.abp_names_list,
              datasets: [
                {
                  backgroundColor: ["#ffb3c3", "#ffd0a9", "#ffe2a7", "#abe0df", "#a2d1f6",
                    "#bdb4dc", "#c9cbcf", "#4bc0c1",
                    "#abe0af", "#f6a2ef"],
                  data: this.abp_data_list
                }
              ]
            },
            {
              title: {
                display: true,
                text: 'Топ 10 АБП по количеству согласованных актов',
                fontSize: "20",
              },
              legend: {
                position: 'bottom',
              },
              responsive: true,
              maintainAspectRatio: false
            }
        );
      }).catch((error) => {
        this.$store.dispatch('addNotification', {
          type: 'error',
          message: error.response.data.detail,
        })
      });
    }
  },
  mounted() {
    // this.abp_data_list = this.abp_data;

  }
};
</script>
