<template>
  <div class="tutorial container">
    <div class="tutorial-inner">
      <vueper-slides bullets-outside :dragging-distance="50">
        <vueper-slide
          v-for="(slide, i) in slides"
          :key="i"
          :image="slide.image"
          :video="slide.video"
          :title="slide.title"
          :content="slide.content" />
      </vueper-slides>
      <div class="flex items-center justify-center mb-8 gap-5">
        <a :href="downloadInstructionABP" target="_blank" download>
          <button
            class="transition-all py-4 ease-in-out duration-150 transform hover:scale-110 bg-blue-500 hover:bg-blue-600 px-10 rounded-lg text-white"
          >
            Скачать инструкцию (АБП)
          </button>
        </a>
        <a :href="downloadInstructionIMPL" target="_blank" download>
          <button
              class="transition-all py-4 ease-in-out duration-150 transform hover:scale-110 bg-blue-500 hover:bg-blue-600 px-10 rounded-lg text-white"
          >
            Скачать инструкцию (Подрядчик)
          </button>
        </a>
        <a :href="downloadInstructionAPPR" target="_blank" download>
          <button
              class="transition-all py-4 ease-in-out duration-150 transform hover:scale-110 bg-blue-500 hover:bg-blue-600 px-10 rounded-lg text-white"
          >
            Скачать инструкцию (Согласующие)
          </button>
        </a>
        <button
          class="transition-all py-4 ease-in-out duration-150 transform hover:scale-110 bg-blue-500 hover:bg-blue-600 px-10 rounded-lg text-white"
          @click="showContactModal"
        >
          Связаться с нами
        </button>
      </div>
      <div class="learning-module">
			<modal class="contact-modal" name="contact-modal">
				<div class="modal-inner">
						<div>
							<p>По техническим вопросам</p>
              <span><a href="tel:87074927144" class="custom-link">8 707 492 71 44</a></span>
              <span><a href="mailto:support-avr@prodev.kz" class="custom-link">support-avr@prodev.kz</a></span>
						</div>
				</div>
			</modal>
		</div>
    </div>
  </div>
</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
  export default {
    components: { VueperSlides, VueperSlide },
    data() {
      return {
        downloadInstructionABP: `https://avr.emg.kmgep.kz/media/tutorial_abp.pdf`,
        downloadInstructionIMPL: `https://avr.emg.kmgep.kz/media/tutorial_impl.pdf`,
        downloadInstructionAPPR: `https://avr.emg.kmgep.kz/media/tutorial_appr.pdf`,
        slides: [
          {
            title: 'Подрядная организация',
            content: '',
            image: '',
            video: {
              mp4: `impls.mp4`,
              // props: {
              //   allow: 'accelerometer; encrypted-media; gyroscope; picture-in-picture'
              // },
            }
          },
          {
            title: 'АБП - Модуль договоры',
            content: '',
            image: '',
            video: {
              mp4: `abp_contracts_module.mp4`,
              props: {
                allow: 'autoplay'
              },
              // pointerEvents: false
            }
          },
          {
            title: 'АБП - Модуль акты',
            content: '',
            image: '',
            video: {
              mp4: `abp_acts_module.mp4`,
              props: {
                allow: 'autoplay'
              },
              // pointerEvents: false
            }
          },
          {
            title: 'Согласующий',
            content: '',
            image: '',
            video: {
              mp4: `apprs.mp4`,
              props: {
                allow: 'autoplay'
              },
              // pointerEvents: false
            }
          },
          {
            title: 'Утверждающий',
            content: '',
            image: '',
            video: {
              mp4: `ratificator.mp4`,
              props: {
                allow: 'autoplay'
              },
              // pointerEvents: false
            }
          },
          {
            title: 'Подписание акта с помощью ЭЦП',
            content: '',
            image: '',
            video: {
              mp4: `act_sign.mp4`,
              // url: 'https://www.youtube.com/embed/XuzBq37loRQ',
              props: {
                allow: 'autoplay'
              },
              // pointerEvents: false
            }
          },
          {
            title: 'Групповое подписание акта',
            content: '',
            image: '',
            video: {
              mp4: `group_sign.mp4`,
              // mp4: `${process.env.BASE_URL}images/flower.mp4`,
              props: {
                allow: 'autoplay'
              },
              // pointerEvents: false
            }
          }
        ]
      }
    },
    mounted() {
      console.log('---------');
      console.log(`${process.env.BASE_URL}`);
      console.log(`${process.env.BASE_URL}group_sign.mp4`)
    },
    methods: {
      showContactModal() {
        this.$modal.show('contact-modal');
      },
      hideContactModal() {
        this.$modal.hide('contact-modal');
      },
    }
  }
</script>

<style scoped>
.tutorial {
  width: 75%;
  margin-top: 3rem;
}

.modal-inner {
	height: 100%;
	padding: 1rem;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;
	gap: 1rem;
}

.instructions-block {

}
</style>