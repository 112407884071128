<template>
  <div class="login-wrap">
    <div class="auth-top login-fixed-width login-logo-wrap login-logo-wrap-fixed">
        <img
            class="auth-form-logo login-fixed-width login-logo-wrap-fixed"
            src="../assets/logo-header.png"
            alt=""
        />
      </div>
    <form action="" class="auth-section-wrap" @submit.prevent="registerForm">
    <h1 class="text-center" style="padding: 1rem; padding-top: 2rem">Регистрация в системе</h1>
    <div class="auth-section">
      <div class="auth-block-left">
        <div class="auth-form-container auth-form">
            <!-- <div class="auth-top login-fixed-width login-logo-wrap login-logo-wrap-fixed">
              <img
                  class="auth-form-logo login-fixed-width login-logo-wrap-fixed"
                  src="../assets/logo-header.png"
                  alt=""
              />
            </div> -->
            <div class="input-container login-fixed-width">
              <input
                class="input-field text-center"
                type="text"
                placeholder="Введите ИИН"
                v-model="iin"
                name="iin"
              />
            </div>
            <div class="input-container login-fixed-width">
              <input
                class="input-field text-center"
                type="text"
                placeholder="Введите фамилию"
                v-model="last_name"
                name="last_name"
              />
            </div>
            <div class="input-container login-fixed-width">
              <input
                class="input-field text-center"
                type="text"
                placeholder="Введите имя"
                v-model="first_name"
                name="first_name"
              />
            </div>
            <div class="input-container login-fixed-width">
              <input
                class="input-field text-center"
                type="text"
                placeholder="Введите отчество"
                v-model="middle_name"
                name="middle_name"
              />
            </div>
            <div class="input-container login-fixed-width">
              <input
                class="input-field text-center"
                type="email"
                placeholder="Введите почту"
                v-model="email"
                name="email"
              />
            </div>
            <div class="input-container login-fixed-width">
              <input
                class="input-field text-center"
                type="text"
                placeholder="Введите телефон"
                v-model="phone"
                name="phone"
              />
            </div>
            <div class="input-container login-fixed-width">
              <input
                class="input-field text-center"
                type="text"
                placeholder="Введите должность"
                v-model="position"
                name="position"
              />
            </div>

        </div>
      </div>
      <div class="auth-block-left">
        <div class="auth-form-container auth-form">
            <div class="input-container login-fixed-width form_radio_btn">
              <input
                  class="input-field text-center"
                  type="radio"
                  v-model="from_dzo"
                  id="from_dzo"
                  value="from_dzo"
                  name="from_dzo"
              />
              <label class="input-field" for="from_dzo">Я сотрудник АО Эмбамунайгаз</label>
            </div>
            <div class="input-container login-fixed-width form_radio_btn">
              <input
                  class="input-field text-center"
                  type="radio"
                  v-model="from_dzo"
                  id="not_from_dzo"
                  value="not_from_dzo"
                  name="from_dzo"
              />
              <label class="input-field" for="not_from_dzo">Я сотрудник подрядной организации</label>
            </div>
            <div class="input-container login-fixed-width" v-if="from_dzo === 'not_from_dzo'">
              <input
                  class="input-field text-center"
                  type="text"
                  placeholder="Введите БИН | ИИН организации"
                  v-model="bin"
                  name="bin"
              />
            </div>
            <div class="input-container login-fixed-width" v-if="from_dzo === 'not_from_dzo'">
              <input
                  class="input-field text-center"
                  type="text"
                  placeholder="Введите наименование организации"
                  v-model="company_name"
                  name="company_name"
              />
            </div>
            <div class="input-container login-fixed-width" v-if="from_dzo === 'not_from_dzo'">
              <input
                class="input-field text-center"
                type="text"
                placeholder="Введите адресс организации"
                v-model="company_address"
                name="company_address"
              />
            </div>
            <div class="login-fixed-width" style="position: relative">
              <div v-if="from_dzo === 'from_dzo'"
                  style="position: absolute; bottom: 0; left: 0; width: 335px; height: 50px; padding-top: 10px; border-bottom: 1px solid gray; transform: rotateZ(-90deg) translateY(-145px) translateX(158px)">
                <h3 class="text-center">
                  Выберите роли в системе
                </h3>
              </div>
              <div style="width: 80% !important; float: right; margin-right: 6px">
                <div class="input-container form_checkbox_btn" style="width: 100% !important;" v-if="from_dzo === 'from_dzo'">
                  <input
                      class="input-field text-center"
                      type="checkbox"
                      v-model="cb_abp"
                      id="cb_abp"
                      name="cb_abp"
                  />
                  <label class="input-field" for="cb_abp">Администратор бюджетной программы</label>
                </div>
                <div class="input-container form_checkbox_btn" v-if="from_dzo === 'from_dzo'">
                  <input
                      class="input-field text-center"
                      type="checkbox"
                      v-model="cb_appr"
                      id="cb_appr"
                      name="cb_appr"
                  />
                  <label class="input-field" for="cb_appr">Согласующий</label>
                </div>
                <div class="input-container form_checkbox_btn" v-if="from_dzo === 'from_dzo'">
                  <input
                      class="input-field text-center"
                      type="checkbox"
                      v-model="cb_rat"
                      id="cb_rat"
                      name="cb_rat"
                  />
                  <label class="input-field" for="cb_rat">Утверждающий</label>
                </div>
                <div class="input-container form_checkbox_btn" v-if="from_dzo === 'from_dzo'">
                  <input
                      class="input-field text-center"
                      type="checkbox"
                      v-model="cb_book"
                      id="cb_book"
                      name="cb_book"
                  />
                  <label class="input-field" for="cb_book">Бухгалтер</label>
                </div>
                <div class="input-container form_checkbox_btn" v-if="from_dzo === 'from_dzo'">
                  <input
                      class="input-field text-center"
                      type="checkbox"
                      v-model="cb_econ"
                      id="cb_econ"
                      name="cb_econ"
                  />
                  <label class="input-field" for="cb_econ">Экономист</label>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
    <div class="buttons_login">
      <button class="auth-btn login-fixed-width" id="submit-btn-reg" type="submit">
        Зарегистрироваться
      </button>
      <a href="/login">
        <button class="auth-btn edc login-fixed-width" type="button">
          Перейти на страницу авторизаций
        </button>
      </a>
    </div>
    </form>

    <div id="infoModal" class="modal">
      <!-- Modal content -->
      <div class="modal-content">
        <p>После прохождения регистрации пользователями, запрос рассматривают сотрудники АО "ЭмбаМунайГаз".</p>
        <p>По завершении рассмотрения заявки, пользователь получит ответ на указанный им адрес электронной почты. Этот ответ может содержать одобрение или отказ в регистрации. 
          Если заявка одобрена, пользователю будет предоставлен логин и пароль для входа в систему. 
          В случае отказа, будут предоставлены соответствующие объяснения или рекомендации для уточнения информации или повторной подачи заявки.
        </p>
        <p>Важно отметить, что время, необходимое для рассмотрения заявки, может занять до трех рабочих дней.</p>
        <button @click="closeInfoModal" class="close">Хорошо</button>
      </div>
    </div>

  <!-- <div class="registration-text">
    <p>После прохождения регистрации пользователями, запрос рассматривают сотрудники АО "ЭмбаМунайГаз".</p>
    <p>По завершении рассмотрения заявки, пользователь получит ответ на указанный им адрес электронной почты. Этот ответ может содержать одобрение или отказ в регистрации. 
      Если заявка одобрена, пользователю будет предоставлен логин и пароль для входа в систему. 
      В случае отказа, будут предоставлены соответствующие объяснения или рекомендации для уточнения информации или повторной подачи заявки.
    </p>
    <p>Важно отметить, что время, необходимое для рассмотрения заявки, может занять до трех рабочих дней.</p>
  </div> -->

  </div>
</template>

<script>
import router from "../router";

export default {
  data() {
    return {
      iin: "",
      last_name: "",
      first_name: "",
      middle_name: "",
      email: "",
      phone: "",
      position: "",
      bin: "",
      from_dzo: "from_dzo",
      company_name: "",
      company_address: "",
      cb_abp: false,
      cb_appr: false,
      cb_rat: false,
      cb_book: false,
      cb_econ: false,
    };
  },
  mounted() {
    var modal = document.getElementById("infoModal");
    var btn = document.getElementById("myBtn");
    var closeBtn = document.getElementsByClassName("close")[0];


    modal.style.display = "block";

    closeBtn.onclick = function () {
        modal.style.display = "none";
    }

    // When the user clicks anywhere outside of the modal, close it
    window.onclick = function (event) {
        if (event.target == modal) {
            modal.style.display = "none";
        }
    }
  },
  methods: {
    registerForm() {
      if (this.iin.toString().replace(' ', '.').length !== 12) {
        alert("Введите корректный ИИН");
        return;
      } else if (this.last_name === "") {
        alert("Введите фамилию");
        return;
      } else if (this.first_name === "") {
        alert("Введите имя");
        return;
      } else if (this.email === "") {
        alert("Введите почту");
        return;
      } else if (this.email.toString().split('@').length !== 2 || this.email.toString().split('@')[1].split('.').length < 2) {
        alert("Введите корректную почту");
        return;
      } else if (this.phone === "") {
        alert("Введите телефон");
        return;
      } else if (this.position === "") {
        alert("Введите должность");
        return;
      } else if (this.from_dzo === "not_from_dzo") {
        if (this.bin === "") {
          alert("Введите БИН|ИИН организации");
          return;
        }
      } else {
        if (this.cb_abp !== true && this.cb_appr !== true && this.cb_rat !== true && this.cb_book !== true && this.cb_econ !== true) {
          alert("Выберите хотя бы одну роль!");
          return;
        }
      }

      let formData = {
        iin: this.iin,
        last_name: this.last_name,
        first_name: this.first_name,
        middle_name: this.middle_name,
        email: this.email,
        phone: this.phone,
        position: this.position,
        bin: this.bin,
        from_dzo: this.from_dzo,
        company_name: this.company_name,
        company_address: this.company_address,
        cb_abp: this.cb_abp,
        cb_appr: this.cb_appr,
        cb_rat: this.cb_rat,
        cb_book: this.cb_book,
        cb_econ: this.cb_econ,
      };
      console.log('registerForm');
      let aaa = document.getElementById('submit-btn-reg');
      aaa.setAttribute('disabled', 'disabled');
      this.$store.dispatch("registerForm", formData).then((response) => {
          console.log(response);
          // this.$router.push({ name: "landing" });
          this.$store.dispatch('addNotification', {
            type: 'success',
            message: response.data.detail,
          });
          router.push({name: 'login'});
          // alert('Ваш запрос на регистрацию успешно отправлен!')
          // console.log('--------------SUCCESS--------------');
        }).catch((error) => {
            this.$store.dispatch('addNotification', {
              type: 'error',
              message: error.response.data.detail,
            });
            aaa.removeAttribute('disabled');
          });
    },
    closeInfoModal () {
      var modal = document.getElementById("infoModal");
      modal.style.display = "none";
    }
  },
};
</script>

<style scoped>
.registration-text {
  position: absolute;
  left: 50%;
  top: 20px;
}
.login-wrap {
  background-color: #e4e9f7;
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
}

.auth-section-wrap {
  /*width: auto;*/
  background-color: white;
  border-radius: 1rem;
}

.auth-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  /*padding-top: 100px;*/
}

.auth-block-left {
  /*background-color: white;*/
  padding: 2rem;
  padding-top: 0 !important;
  padding-bottom: 1rem !important;
  /*border-radius: 1rem;*/
  /*-webkit-box-shadow: 0 0 36px 5px rgba(34, 60, 80, 0.45);*/
  /*-moz-box-shadow: 0 0 36px 5px rgba(34, 60, 80, 0.45);*/
  /*box-shadow: 0 0 36px 5px rgba(34, 60, 80, 0.45);*/
}

.auth-form-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttons_login {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /*gap: 2rem;*/
}

.auth-form {
  display: flex;
  flex-direction: column;
  max-width: 26.25rem;
}

.auth-form-logo {
  display: inline;
}

.auth-top {
  display: grid;
  grid-template-columns: 0.3fr 1fr;
  margin-bottom: 30px;
}

.input-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.login-fixed-width {
  min-width: 400px;
  max-width: 400px;
}

.form_radio_btn {
  display: flex;
  justify-content: center;
  margin-right: 10px;
}
.form_radio_btn input[type=radio] {
  display: none;
}
.form_radio_btn label {
  display: inline-block;
  cursor: pointer;
  width: 100% !important;
  text-align: center;
  /*padding: 0px 15px;*/
  /*line-height: 34px;*/
  /*border: 1px solid #999;*/
  /*border-radius: 6px;*/
  user-select: none;
}

/* Checked */
.form_radio_btn input[type=radio] + label {
  text-decoration: line-through;
}
/* Checked */
.form_radio_btn input[type=radio]:checked + label {
  background: #ffe0a6;
  text-decoration: none;
}

/* Hover */
.form_radio_btn label:hover {
  color: #666;
}

.form_checkbox_btn {
  display: flex;
  justify-content: center;
  margin-right: 10px;
}
.form_checkbox_btn input[type=checkbox] {
  display: none;
}
.form_checkbox_btn label {
  display: inline-block;
  cursor: pointer;
  width: 100% !important;
  text-align: center;
  /*padding: 0px 15px;*/
  /*line-height: 34px;*/
  /*border: 1px solid #999;*/
  /*border-radius: 6px;*/
  user-select: none;
}

/* Checked */
.form_checkbox_btn input[type=checkbox] + label {
  text-decoration: line-through;
}
/* Checked */
.form_checkbox_btn input[type=checkbox]:checked + label {
  background: #ffe0a6;
  text-decoration: none;
}

/* Hover */
.form_checkbox_btn label:hover {
  color: #666;
}

/* .login-logo-wrap {
  position: absolute;
  top: 5vh;
  left: calc(50% - 100px);
} */

.login-logo-wrap-fixed {
  max-width: 200px !important;
  min-width: 200px !important;
}

.input-field {
  width: 100%;
  padding: 10px !important;
  outline: none;
  border: 1px solid #e8f0fe;
  margin: 0.5rem 0 1rem;
  border-radius: 10px;
}

.auth-btn {
  background: #36429e;
  padding: 1rem;
  border: none;
  outline: none;
  color: white;
  border-radius: 10px;
  margin-bottom: 0.8rem;
  cursor: pointer;
}

.auth-btn.edc {
  background: #454757;
}

.contact-item-phone ul {
  list-style-type: none;
  color: #fff;
  font-size: 1.2rem;
}

.modal {
  display: none;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}
.modal2 {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}


.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  border-radius: 10px;
  width: 50%;
  text-align: center;
}

.modal-content p {
  margin-bottom: 1rem;
}

/* The Close Button */
.close {
  padding: .5rem 1rem;
  text-align: center;
  font-size: 1rem;
  border: solid gray 1px;
  border-radius: 5px;
  margin: 0 auto;
  min-width: 300px;
}

.close:hover,
.close:focus {
  cursor: pointer;
}
</style>