<template>
  <div>
    <div id="myModal" class="modal">
      <!-- Modal content -->
      <div class="modal-content">
        <p>Убедитесь что программа NCALayer запущена и повторите попытку.</p>
        <button class="close">Хорошо</button>
      </div>
    </div>
    <div id="myModal2" class="modal2" v-if="opened">
      <!-- Modal content -->
      <div class="modal-content2">
        <p>
          <b>Уважаемые пользователи системы АВР!</b><br>



          В связи с техническим сбоем работы сервера ЭМГ (07/09/2022), функционирование системы АВР+ было остановлено, на сегодняшний день, система АВР+ была мигрирована и функционирует на новом сервере Общества, в связи с чем просим всех пользователей системы проверить и убедиться в:<br>

          1)       доступность ваших учетных данных, включая привязку ЭЦП;<br>

          * В случае если Вы не можете авторизоваться, нажмите на кнопку “Забыли пароль?”.<br>

          ** В  случае если Вы регистрировались после 06.06.2022, необходимо пройти повторную регистрацию.<br>

          2)       наличие ваших ранее зарегистрированных договоров и актов<br>

          * При обнаружение отсутствия ранее зарегистрированных договоров и/или актов, а также при возникновение вопросов просим незамедлительно обратиться  к Вашему администратору (АБП) по договорам.<br>
        </p>
          <button class="close2" @click="closeMyModal">Хорошо</button>
      </div>
    </div>
  </div>


</template>

<script>
    export default {
      data() {
        return {
          opened: false
        }
      },
      methods: {
        closeMyModal() {
          this.opened = false;
        },
      },

    }
</script>

<style scoped>
.modal {
  display: none;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}
.modal2 {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}


.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  border-radius: 10px;
  width: 30%;
  text-align: center;
}

/* The Close Button */
.close {
  padding: .5rem 1rem;
  margin: 1rem 0 0;
  text-align: center;
  font-size: 1rem;
  border: solid gray 1px;
  border-radius: 5px;
}

.close:hover,
.close:focus {
  cursor: pointer;
}

.modal-content2 {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  border-radius: 10px;
  width: 30%;
  text-align: center;
}

/* The Close Button */
.close2 {
  padding: .5rem 1rem;
  margin: 1rem 0 0;
  text-align: center;
  font-size: 1rem;
  border: solid gray 1px;
  border-radius: 5px;
}

.close2:hover,
.close2:focus {
    cursor: pointer;
}
</style>
