import axios from 'axios';
export default {
    state: {
        users: [],
        messages: [],
        allUsers: []
    },
    getters: {
        usersList(state) {
            return state.users;
        },
        getMessages(state) {
            return state.messages;
        },
        allUsers (state) {
            return state.allUsers
        }
    },
    mutations: {
        getMessages(state, messages) {
            state.messages = messages;
        },
        getChatRoomUsers(state, users) {
            state.users = users;
        },
        setAllChatRoomUsers(state, users) {
            state.allUsers = users;
        },
    },
    actions: {
        openSocket({commit}, roomName) {
            axios.get(`chat/messages/${roomName}/`).then((response) => {
                let data = response.data;
                commit('getMessages', data);
            });
        },
        getChatRoomUsers(context) {
            return axios
                .get('chat/users/')
                .then((response) => {
                    this.users = response.data;
                    context.commit('getChatRoomUsers', response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        getAllChatUsers (context) {
            return axios
                .get('chat/all_users/')
                .then((response) => {
                    context.commit('setAllChatRoomUsers', response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    },
};
