<template>
    <div>
        <paginate
          v-model="activePage"
          :class="{ hidden: displayPagination }"
          :page-count="pageCount"
          :page-range="3"
          :margin-pages="2"
          :prev-text="'Назад'"
          :next-text="'Вперед'"
          :container-class="'pagination-ex'"
          :page-class="'page-item-ex'"
          :active-class="'page-item-ex-active'"
          :click-handler="clickCallback">
        </paginate>
    </div>
</template>

<script>
import Paginate from 'vuejs-paginate'
    export default {
        props: {
            currentPage: {
                type: [Number, String],
                default: 1
            },
            pageCount: {
              type: [Number, String],
            }
        },
        data() {
          return {
            activePage: 1
          }
        },
        watch: {
          currentPage() {
            this.activePage = this.currentPage
          }
        },
        mounted() {
          this.activePage = this.currentPage
        },
        components: {
            Paginate
        },
        methods: {
          clickCallback() {
            this.$emit('pageClicked', this.activePage)
          }
        },
        computed: {
          displayPagination() {
            if (this.pageCount == 1) {
              return true
            } else {
              return false
            }
          }
        }
    }
</script>

<style>
.pagination-ex {
  display: flex;
  justify-content: center;
  gap: .5rem;
}

.page-item-ex {
  padding: 0 10px;
  border-radius: 12px;
}

.hidden {
  visibility: hidden;
}

.page-item-ex-active {
  background: #23468c;
  color: white;
}
</style>