<template>
	<div class="">
		<FAQ />
		<div class="flex items-center justify-center mb-8 gap-5">
			<a :href="downloadInstruction" target="_blank" download>
				<button
					class="transition-all py-4 ease-in-out duration-150 transform hover:scale-110 bg-blue-500 hover:bg-blue-600 px-10 rounded-lg text-white"
				>
					Скачать инструкцию
				</button>
			</a>
			<button
				class="transition-all py-4 ease-in-out duration-150 transform hover:scale-110 bg-blue-500 hover:bg-blue-600 px-10 rounded-lg text-white"
				@click="showContactModal"
			>
				Связаться с нами
			</button>
		</div>
		<div class="learning-module">
			<modal class="contact-modal" name="contact-modal">
				<div class="modal-inner">
						<div>
							<p>По техническим вопросам</p>
            <span><a href="tel:87074927144" class="custom-link">8 707 492 71 44</a></span>
            <span><a href="mailto:support-avr@prodev.kz" class="custom-link">support-avr@prodev.kz</a></span>
						</div>
				</div>
			</modal>
		</div>
	</div>
</template>

<script>
import FAQ from '@/components/FAQ';
export default {
	data() {
		return {
			downloadInstruction: `${process.env.VUE_APP_MEDIA_URL}/media/Инструкция_AVR.pdf`,
		};
	},
	components: {
		FAQ,
	},
	methods: {
		showContactModal() {
			this.$modal.show('contact-modal');
		},
		hideContactModal() {
			this.$modal.hide('contact-modal');
		},
	},
};
</script>

<style scoped>
.learning-module {
	display: flex;
	justify-content: center;
	align-items: center;
	/* min-height: 100vh; */
}

.learning {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 1rem;
	padding: 2rem;
}

.learning-left {
	background-image: url('../assets/learning-img.jpg');
	background-position: center;
	border-radius: 30px;
	/* overflow: hidden; */
	/* width: 300px; */
	/* border: 1px solid black; */
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: flex-end;
	padding: 10px;
}

.learning-left img {
	background-size: cover;
	width: 400px;
	border-radius: 30px;
}

.learning-right {
	/* border: 1px solid black; */
	position: relative;
}

.learning-right img {
	width: 400px;
	border-radius: 30px;
}

.downloadBtn {
	margin-top: 1rem;
	padding: 1rem 2rem;
	border: none;
	outline: none;
	border-radius: 10px;
	margin-left: 1rem;
	cursor: pointer;
	font-size: 1.1rem;
	background: #7ad38d;
	color: white;
	/* position: absolute;
	bottom: 20px;
	right: 30px; */
}

.downloadBtnTemplates {
	margin-top: 1rem;
	padding: 1rem 2rem;
	border: none;
	outline: none;
	border-radius: 10px;
	margin-left: 1rem;
	cursor: pointer;
	font-size: 1.1rem;
	background: #7ad38d;
	color: white;
	/* position: absolute;
	bottom: 100px;
	right: 30px; */
	max-width: 200px;
}

.contract-btn {
	margin-top: 1rem;
	padding: 1rem 2rem;
	border: none;
	outline: none;
	border-radius: 10px;
	margin-left: 1rem;
	cursor: pointer;
	font-size: 1.1rem;
	background: #7ad38d;
	color: white;
	position: absolute;
	bottom: 20px;
	left: 30px;
}

.modal-inner {
	height: 100%;
	padding: 1rem;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;
	gap: 1rem;
}

.contact-user {
	text-align: center;
	user-select: text;
}

.mail-link {
	text-decoration: none;
	color: black;
	display: block;
}
</style>
