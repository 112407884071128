<template>
    <div>
        <slot />
        <NotificationList />
    </div>
</template>

<script>
import NotificationList from "@/components/NotificationList.vue"
    export default {
        components: {
            NotificationList
        }
    }
</script>

<style lang="scss" scoped>

</style>