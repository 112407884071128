// import axios from 'axios'

export default {
	state: {
		allActs: [],
	},
	getters: {
		allReports(state) {
      return state.allActs
    }
	},
	mutations: {
    setReports(state, payload) {
      state.allActs = payload
    }
  },
	actions: {
    
  },
}
