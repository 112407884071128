// import AuthService from '@/service/auth.service'
// import router from '../../router.js'
import axios from 'axios'

export default {
    getters: {
        // isActiveAccount(state) {
        //     return state.user.ouser.is_active;
        // },
        // USER(state) {
        //     return state.user
        // }
    },
    mutations: {
        test(state) {
            state.isTest = true;
        }
    },

    state: {
        isTest: false,
    },

    actions: {
        getAllSContracts({commit}, formData) {
            console.log(commit);
            return axios.get('/adtl/scontracts/?page=' + formData.page, {
                params: {
                    status: formData.status,
                    search_contract: formData.search_scontract
                }
            });
        },
        getAllNgduList({commit}) {
            console.log(commit);
            return axios.get('/adtl/ngdus/');
        },
        createSContract({commit}, formData) {
            console.log(commit);

            return axios.post('/adtl/scontracts/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Accept': 'application/json',
                }
            });
        },
        getSContractDetail({commit}, id) {
            console.log(commit);
            return axios.get(`/adtl/scontracts/${id}/`);
        },
        downloadSContractPDF({commit}, id) {
            console.log(commit);
            return axios.get(`/adtl/scontracts_download/${id}/`,{
                responseType: 'blob'
            });
        },
        downloadSContractPDFNew({commit}, id) {
            console.log(commit);
            return axios.get(`/adtl/scontracts_download_new/${id}/`,{
                responseType: 'blob'
            });
        },
        acceptRejectSContract({commit}, formData) {
            console.log(commit);
            let contract_id = formData.get('contract_id');
            return axios.post(`/adtl/scontracts/${contract_id}/accept_reject/`, formData,{
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'multipart/form-data'
                }
            });
        },
        setImplApproversSContract({commit}, formData) {
            console.log(commit);
            return axios.post(`/adtl/scontracts/${formData.contract_id}/set_approvers/`, {
                selected_approve_mode: formData.selected_approve_mode,
                approvers: formData.impl_approvers,
                ratificator: formData.impl_ratificator,
                req_dop_kaz: formData.req_dop_kaz,
                req_dop: formData.req_dop,
                req_position_kaz: formData.req_position_kaz,
                req_position: formData.req_position,
            });
        },
        getHasSContracts({commit}) {
            console.log(commit);
            return axios.get(`/adtl/has_scontracts/`);
        },
        updateSContract({commit}, formData) {
            console.log(commit);
            let id = formData.get('scontract_id');
            return axios.put(`/adtl/scontracts/${id}/`, formData,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'multipart/form-data'
                    }
                }
            );
        },
        setSContractNumber({commit}, formData) {
            console.log(commit);
            return axios.post(`/adtl/scontracts/${formData.contract_id}/set_number/`, {
                new_number: formData.new_number
            });
        },
        sendSignXmlSContract({commit}, formData) {
            console.log(commit);

            return axios.post(`/adtl/scontracts/${formData.contract_id}/sign_ratificator/`, {
                xmlToSign: formData.xmlToSign,
                signedXml: formData.signedXml,
            });
        },
        setDZOApproversSContract({commit}, formData) {
            console.log(commit);
            return axios.post(`/adtl/scontracts/${formData.scontract_id}/set_approvers/`, {
                selected_approve_mode: formData.selected_dzo_approve_mode,
                approvers: formData.dzo_approvers,
                ratificator: formData.dzo_ratificator,
                req_dop_kaz: formData.req_dop_kaz,
                req_dop_impl_kaz: formData.req_dop_impl_kaz,
                req_dop: formData.req_dop,
                req_dop_impl: formData.req_dop_impl,
                req_position_kaz: formData.req_position_kaz,
                req_position_impl_kaz: formData.req_position_impl_kaz,
                req_position: formData.req_position,
                req_position_impl: formData.req_position_impl,
            });
        },
    },
}