<template>
  <transition name="bounce" appear>
    <div :class="classType" class="notification-item">
      <p>{{ notification.message }}</p>
      <button
        type="button"
        class="close"
        data-dismiss="alert"
        aria-label="Close"
        @click="closeToast"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </transition>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["notification"],
  data() {
    return {
      timeout: null,
    };
  },
  computed: {
    classType() {
      return `alert-${this.notification.type}`;
    },
  },
  /* eslint-disable */
  created() {
    if (this.notification.typeAlert == "persistent") {
    } else {
      this.timeout = setTimeout(() => {
        this.removeNotification(this.notification);
      }, 3000);
    }
  },
  beforeDestroy() {
    clearTimeout(this.timeout);
  },
  methods: {
    ...mapActions(["removeNotification"]),
    closeToast() {
      this.removeNotification(this.notification);
    }
  },
};
</script>

<style scoped>
.notification-item {
  display: flex;
  justify-content: space-between;
  padding: 1rem 2rem;
  margin-bottom: 0.8rem;
  border-radius: 10px;
}

.close {
  margin-left: 1rem;
  background: none;
  border: none;
  cursor: pointer;
  color: #fff;
}

.close span {
  font-size: 1.2rem;
}

.alert-success {
  background: limegreen;
  color: #fff;
}

.alert-warning {
  background: #ffbb56;
  color: #fff;
}

.alert-error {
  background: #900;
  color: white;
}
/* fade transition */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
/* bounce */
.bounce-enter-active {
  animation: bounce-in 0.5s !important;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
.alert-warning {
  background: #ffbb56;
  color: #fff;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
</style>