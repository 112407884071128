import Vue from 'vue'
// import App from './App.vue'
import router from "./router.js";
import store from './store'

// layouts
import Master from '@/layouts/Master.vue'
import LandingLayout from '@/layouts/LandingLayout.vue'
import MainLayout from '@/layouts/MainLayout.vue'

import VModal from 'vue-js-modal'
import axios from "axios";
import './index.css';
import 'material-icons/iconfont/material-icons.css';



// Registering Layouts
Vue.component('landing-layout', LandingLayout);
Vue.component('default-layout', MainLayout)

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
	if (to.matched.some(record => record.meta.requiresAuth)) {
		// this route requires auth, check if logged in
		// if not, redirect to login page.
		if (!store.getters.loggedIn) {
			next({
				name: 'login',
			});
		} else {
			next();
		}
	} else if (to.matched.some(record => record.meta.requiresGuest)) {
		// this route requires auth, check if logged in
		// if yes, redirect to home page.
		if (store.getters.loggedIn) {
			next({
				name: 'landing',
			})
		} else {
			next()
		}
	} else {
		next() // make sure to always call next()!
	}
})

Vue.filter('TitleCase', value => {
	return value.toLowerCase().replace(/(?:^|\s|-)\S/g, x => x.toUpperCase());
});

Vue.use(VModal)

new Vue({
    router,
    store,
    axios,
    render: h => h(Master)
}).$mount('#app')
