<template>
  <section class="home-section" id="home-section">
    <div class="p-10">
          <div class="card">
            <div class="card-header" style="display: flex; justify-content: space-between">
              <h4>Уведомления</h4>
  <!--            <button class="btn-danger" style="border-radius: 5px">Удалить все уведомления</button>-->
            </div>
            <div class="table-responsive">
              <table class="table table-hover table-nowrap">
                <thead class="thead-light">
                <tr>
                  <th scope="col" class="">№ п/п</th>
                  <th scope="col" class="">Наименование</th>
                  <th scope="col" class="text-center">Создано</th>
                  <!-- <th scope="col" class="text-center">Прочтено</th> -->
                  <th scope="col" class="">Содержание</th>
                  <th scope="col" class="text-center">Статус</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(notification, index) in this.notifications" :key="notification.id" :id="'req-tr-' + notification.id" class="tr-main">
                  <td data-label="" class="">
                    {{ index + 1 }}
                  </td>
                  <td data-label="" class="">
                    <button class="custom-link" @click="notification_clicked(notification)">
                      {{ notification.name }}
                    </button>
                  </td>
                  <td data-label="" class="text-center">
                    {{ formatDate(notification.created_datetime) }}
                  </td>
                  <!-- <td data-label="" class="text-center">
                    <span v-if="notification.opened_datetime">Да</span>
                    <span v-else>Нет</span>
                  </td> -->
                  <td data-label="" class="">
                    {{ notification.content }}
                  </td>
                  <td data-label="" class="text-center">
                    <span class="notification-status unread" :class="{ read: statusClass(notification) }" >{{ notification.status }}</span>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
  </section>
</template>
<script async>
import moment from 'moment'
export default {
  components: {},
  data() {
    return {
      notifications: []
    };
  },
  created() {
    this.getAllNotifications();
  },
  mounted() {
  },
  methods: {
    getAllNotifications() {
      this.$store.dispatch("getAllNotifications").then((response) => {
        this.notifications = response.data;
      }).catch((error) => {
        this.$store.dispatch('addNotification', {
          type: 'error',
          message: error.response.data.detail,
        })
      });
    },
    notification_clicked(notification) {
      this.$store.dispatch('notification_clicked', notification.id).then((response) => {
        console.log(response);
        const link = document.createElement('a')
        link.href = notification.redirect_link;
        document.body.appendChild(link)
        link.click();
      }).catch((error) => {
        this.$store.dispatch('addNotification', {
          type: 'error',
          message: error.response.data.detail,
        })
      });
    },
    formatDate(date){
        if (date) {
          return moment(String(date)).format('DD-MM-YYYY')
        }
    },
    statusClass(notification) {
      if (notification.status_name === 'read') {
        return true
      } else {
        return false
      }
    },
  },
};
</script>

<style scoped>
@import "../assets/css/webpixels.css";

.tr-main {
  cursor: pointer
}


.home-section {
  position: relative;
  background: #e4e9f7;
  min-height: 100vh;
  top: 0;
  left: 78px;
  width: calc(100% - 78px);
  transition: all 0.5s ease;
}

.sidebar.open ~ .home-section {
  left: 250px;
  width: calc(100% - 250px);
}

.home-section .text {
  display: inline-block;
  color: #11101d;
  font-size: 25px;
  font-weight: 500;
  margin: 18px;
}

.notification-status {
  padding: .5rem 1rem;
  border-radius: 12px;
}

.unread {
  background: #ebf8ee;
  border: 1px solid #c1e4c9;
}

.read {
  background: #e5effa;
  border: 1px solid #afcdef;
}
</style>