<template>
  <div class="home-section-wrap">
    <section class="home-section" id="home-section" style="left: 0 !important; width: 100% !important">
      <div class="p-10" style="padding-bottom: 0 !important;">
        <h3>Акт №
          <a :href="'/acts/' + this.act.id">
            {{ this.act.number }} {{ this.act.name }}
          </a> от {{ this.act.act_date }}
        </h3>
      </div>
      <div class="innernavbar-wrap p-10" style="padding-bottom: 0 !important;">
        <div class="item-wrap">
          <div class="item left-item">
            <ul class="mobile-flex">
              <li>
                <button
                    class="filter-btn transition-all ease-in-out duration-150 transform hover:scale-110 bg-indigo-500 hover:bg-indigo-600 px-5 py-1 rounded-lg max-h-12 whitespace-nowrap"
                    :class="fast_active('1')"
                    @click="change_fast_filter('1')">
                  Табличные данные
                </button>
              </li>
              <li v-if="act.type === 'ДКС'">
                <button
                    class="filter-btn transition-all ease-in-out duration-150 transform hover:scale-110 bg-indigo-500 hover:bg-indigo-600 px-5 py-1 rounded-lg max-h-12 whitespace-nowrap"
                    :class="fast_active('2')"
                    @click="change_fast_filter('2')">
                  Дополнительные формы
                </button>
              </li>
              <li>
                <button
                    class="filter-btn transition-all ease-in-out duration-150 transform hover:scale-110 bg-indigo-500 hover:bg-indigo-600 px-5 py-1 rounded-lg max-h-12 whitespace-nowrap"
                    :class="fast_active('2')"
                    @click="change_fast_filter('2')">
                  История
                </button>
              </li>
              <li>
                <button
                    class="filter-btn transition-all ease-in-out duration-150 transform hover:scale-110 bg-indigo-500 hover:bg-indigo-600 px-5 py-1 rounded-lg max-h-12 whitespace-nowrap"
                    :class="fast_active('3')"
                    @click="change_fast_filter('3')">
                  Согласующие
                </button>
              </li>
              <li>
                <button
                    class="filter-btn transition-all ease-in-out duration-150 transform hover:scale-110 bg-indigo-500 hover:bg-indigo-600 px-5 py-1 rounded-lg max-h-12 whitespace-nowrap"
                    :class="fast_active('4')"
                    @click="change_fast_filter('4')">
                  Файлы
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="p-10" style="padding-top: 1rem !important; padding-bottom: 0 !important;">
        <div class="card">
          <div class="table-responsive">
            <table class="table table-hover table-nowrap">
              <thead class="thead-light">
              <tr>
                <th scope="col" class="" style="border-top-left-radius: 0.75rem !important;">ID</th>
                <th scope="col" class="mainless">Номер акта</th>
                <th scope="col" class="">Дата создания</th>
                <th scope="col" class="mainless">Дата акта</th>
                <th scope="col" class="" v-if="act.contract">Договор</th>
                <th scope="col" class="mainless" v-if="act.contract && !act.contract.is_mirror">НГДУ</th>
                <th scope="col" class="mainless" v-else>Поставщик</th>
                <th scope="col" class="mainless">
                  Время с начала
                </th>
                <th scope="col" class="mainless">Всего</th>
                <th scope="col" class="mainless">Тип</th>
                <th scope="col" class="" v-if="act.contract && !act.contract.is_mirror">Поставщик</th>
                <th scope="col" class="" v-else>Заказчик</th>
                <th scope="col" class="" style="border-top-right-radius: 0.75rem !important;">Статус</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td data-label="" class="">
                  <a class="custom-link" :href="'/acts/' + act.id">
                    {{ act.id }}
                  </a>
                </td>
                <td data-label="" class="mainless">
                  {{ act.number }}
                </td>
                <td data-label="" class="">
                  {{ act.created_datetime_short }}
                </td>
                <td data-label="" class="mainless">
                  {{ act.act_date }}
                </td>
                <td data-label="" class="" v-if="act.contract">
                  <a class="custom-link" :href="'/contracts/' + act.contract.id" :title="act.contract.name">
                    {{ act.contract.number }}
                  </a>
                </td>
                <td data-label="" class="mainless" v-if="act.contract && !act.contract.is_mirror">
                  {{ act.ngdu_name }}
                </td>
                <td data-label="" class="mainless" v-else>
                  <span v-if="act.contract">{{ act.contract.abp.ouser.company.name }}</span>
                </td>
                <td data-label="" class="mainless">
                  {{ act.time_to_end }}
                </td>
                <td data-label="" class="mainless">
                  {{ prettyPrice(act.total_with_nds) }} тг
                </td>
                <td data-label="" class="mainless">
                  {{ act.type }}
                </td>
                <td data-label="" class="">
                  <span v-if="act.contract">
                    {{ act.contract.impl_company.name }}
                  </span>
                </td>
                <td data-label="" class="">
                  <span :class="act.color + ' act-status'">{{ act.status }}</span>
                </td>

              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="p-10" v-if="this.fast_filter === '1'">
        <div class="section-header">
          <h2>Табличные данные</h2>
          <hr>
        </div>
        <div class="card p-10">
          <!--          <div style="display: flex; justify-content: space-between;">-->
          <!--            <h4 style="margin-bottom: 10px">Табличные данные</h4>-->
          <!--          </div>-->
          <div class="table-responsive">
            <table class="m-table-act" v-if="act.type === 'Стандартный (458-562)'">
              <thead class="">
              <tr>
                <td colspan="12" style="height: 40px; text-align: center"><b>АКТ ВЫПОЛНЕНИЯ РАБОТ (ОКАЗАННЫХ УСЛУГ)*</b>
                </td>
              </tr>
              <tr>
                <th rowspan="2" style="width: 100px">Номер по порядку</th>
                <th rowspan="2" style="width: 100px" v-if="!act.contract.is_mirror">Код затрат</th>
                <th rowspan="2" style="width: 100px" v-if="!act.contract.is_mirror">Код МВЗ</th>
                <th rowspan="2" style="width: 100px" v-if="!act.contract.is_mirror">Заказ ТОРО</th>
                <th rowspan="2" style="width: 300px;" v-if="!act.contract.is_mirror">Наименование МВЗ</th>
                <th rowspan="2" style="width: 300px">Наименование работ (услуг) (в разрезе их подвидов в соответствии с
                  технической спецификацией, заданием, графиком выполнения работ (услуг) при их наличии)
                </th>
                <th rowspan="2" style="width: 300px">Дата выполнения работ (оказания услуг)**</th>
                <th rowspan="2" style="width: 250px;">Сведения об отчете о научных исследованиях, маркетинговых,
                  консультационных и прочих услугах (дата, номер, количество страниц) (при их наличии)***
                </th>
                <th rowspan="2">Единица измерения</th>
                <th colspan="3" rowspan="1">Выполнено работ (оказано услуг)</th>
              </tr>
              <tr>
                <th>Количество</th>
                <th>Цена за единицу</th>
                <th>Стоимость (в тенге)</th>
              </tr>
              <tr v-if="!act.contract.is_mirror">
                <td v-for="i in [1,2,3,4,5,6,7,8,9,10,11,12]" :key="i">{{ i }}</td>
              </tr>
              <tr v-else>
                <td v-for="i in [1,2,3,4,5,6,7,8]" :key="i">{{ i }}</td>
              </tr>
              <tr style="height: 40px; background: white"
                  v-if="act.global_work + act.global_mvz !== '' && act.global_work + act.global_mvz !== '--' && !act.contract.is_mirror">
                <td v-for="i in [1,2,3,4,5,6,7,8,9,10,11,12]" :key="i">
                  <span v-if="i === 5 && act.global_mvz !== 'Отсутствует'">{{ act.global_mvz }}</span>
                  <span v-else-if="i === 5 && act.global_mvz === 'Отсутствует'">—</span>
                  <span v-else-if="i === 6 && act.global_work !== 'Отсутствует'">{{ act.global_work }}</span>
                  <span v-else-if="i === 6 && act.global_work === 'Отсутствует'">—</span>
                </td>
              </tr>
              </thead>
              <tbody>
              <tr style="height: 40px; background: white" v-for="row in this.act.rows" :key="row.id">
                <td data-label="">{{ row.serial_number }}</td>
                <td data-label="" v-if="!act.contract.is_mirror"><span v-if="row.cost_code !== 'Отсутствует'">{{ row.cost_code }}</span><span
                    v-else>—</span></td>
                <td data-label="" v-if="!act.contract.is_mirror"><span v-if="row.mvz_code !== 'Отсутствует'">{{ row.mvz_code }}</span><span
                    v-else>—</span></td>
                <td data-label="" v-if="!act.contract.is_mirror"><span v-if="row.new_tora_zakaz !== 'Отсутствует'">{{ row.new_tora_zakaz }}</span><span
                    v-else>—</span></td>
                <td data-label="" v-if="!act.contract.is_mirror">{{ row.mvz_name }}</td>
                <td data-label="">{{ row.work_name }}</td>
                <td data-label="" v-if="row.double_date">{{ row.start_date }} : {{ row.end_date }}</td>
                <td data-label="" v-else>{{ row.row_date }}</td>
                <td data-label="">
                  <span v-if="row.research_report !== 'Отсутствует'">{{ row.research_report }}</span>
                  <span v-else>—</span></td>
                <td data-label="">{{ row.measure_unit }}</td>
                <td data-label="">{{ prettyPrice(row.count) }}</td>
                <td data-label="">{{ prettyPrice(row.unit_price) }}</td>
                <td data-label="">{{ prettyPrice(row.total) }}</td>
              </tr>
              <tr style="height: 40px; background: white" v-if="!act.contract.is_mirror">
                <td v-for="i in [1,2,3,4,5,6,7,8,9,10,11,12]" :key="i">
                  <span v-if="i === 6">Итого:</span>
                  <span v-else-if="i === 12">{{ prettyPrice(act.total) }}</span>
                </td>
              </tr>
              <tr style="height: 40px; background: white" v-else>
                <td v-for="i in [1,2,3,4,5,6,7,8]" :key="i">
                  <span v-if="i === 5">Итого:</span>
                  <span v-else-if="i === 8">{{ prettyPrice(act.total) }}</span>
                </td>
              </tr>
              <tr style="height: 40px; background: white" v-if="!act.contract.is_mirror">
                <td v-for="i in [1,2,3,4,5,6,7,8,9,10,11,12]" :key="i">
                  <span v-if="i === 6">Сумма НДС:</span>
                  <span v-else-if="i === 12">{{ prettyPrice(act.nds) }}</span>
                </td>
              </tr>
              <tr style="height: 40px; background: white" v-else>
                <td v-for="i in [1,2,3,4,5,6,7,8]" :key="i">
                  <span v-if="i === 5">Сумма НДС:</span>
                  <span v-else-if="i === 8">{{ prettyPrice(act.nds) }}</span>
                </td>
              </tr>
              <tr style="height: 40px; background: white" v-if="!act.contract.is_mirror">
                <td v-for="i in [1,2,3,4,5,6,7,8,9,10,11,12]" :key="i">
                  <span v-if="i === 6">Всего:</span>
                  <span v-else-if="i === 12">{{ prettyPrice(act.total_with_nds) }}</span>
                </td>
              </tr>
              <tr style="height: 40px; background: white" v-else>
                <td v-for="i in [1,2,3,4,5,6,7,8]" :key="i">
                  <span v-if="i === 5">Всего:</span>
                  <span v-else-if="i === 8">{{ prettyPrice(act.total_with_nds) }}</span>
                </td>
              </tr>
              </tbody>
            </table>
            <table class="m-table-act" v-else-if="act.type === 'ДБиКРС'">
              <thead class="">
              <tr>
                <td colspan="14" style="height: 40px; text-align: center"><b>АКТ ВЫПОЛНЕНИЯ РАБОТ (ОКАЗАННЫХ УСЛУГ)*</b>
                </td>
              </tr>
              <tr>
                <th rowspan="3" style="width: 100px">Номер по порядку</th>
                <th rowspan="3" style="width: 100px">Код затрат</th>
                <th rowspan="3" style="width: 100px">Код МВЗ</th>
                <th rowspan="3" style="width: 100px">Заказ ТОРО</th>
                <th rowspan="3" style="width: 300px;">Наименование МВЗ</th>
                <th rowspan="3" style="width: 300px">Наименование работ (услуг) (в разрезе их подвидов в соответствии с
                  технической спецификацией, заданием, графиком выполнения работ (услуг) при их наличии)
                </th>
                <th rowspan="3" style="width: 300px">Дата выполнения работ (оказания услуг)**</th>
                <th rowspan="3" style="width: 250px">Сведения об отчете о научных исследованиях, маркетинговых,
                  консультационных и прочих услугах (дата, номер, количество страниц) (при их наличии)***
                </th>
                <th rowspan="3">Единица измерения</th>
                <th colspan="5" rowspan="1">Выполнено работ (оказано услуг)</th>
              </tr>
              <tr>
                <th rowspan="2">количество</th>
                <th rowspan="2">цена за единицу</th>
                <th rowspan="1" colspan="2">снижение стоимости</th>
                <th rowspan="2">стоимость</th>
              </tr>
              <tr>
                <th>основание*</th>
                <th>сумма снижения, тенге</th>
              </tr>
              <tr>
                <td v-for="i in [1,2,3,4,5,6,7,8,9,10,11,12,13,14]" :key="i">{{ i }}</td>
              </tr>
              <tr style="height: 40px; background: white"
                  v-if="act.global_work + act.global_mvz !== '' && act.global_work + act.global_mvz !== '--'">
                <td v-for="i in [1,2,3,4,5,6,7,8,9,10,11,12,13]" :key="i">
                  <span v-if="i === 4 && act.global_mvz !== 'Отсутствует'">{{ act.global_mvz }}</span>
                  <span v-else-if="i === 4 && act.global_mvz === 'Отсутствует'">—</span>
                  <span v-else-if="i === 5 && act.global_work !== 'Отсутствует'">{{ act.global_work }}</span>
                  <span v-else-if="i === 5 && act.global_work === 'Отсутствует'">—</span>
                </td>
              </tr>
              </thead>
              <tbody>
              <tr style="height: 40px; background: white" v-for="row in this.act.rows" :key="row.id">
                <td data-label="">{{ row.serial_number }}</td>
                <td data-label=""><span v-if="row.cost_code !== 'Отсутствует'">{{ row.cost_code }}</span><span
                    v-else>—</span></td>
                <td data-label=""><span v-if="row.mvz_code !== 'Отсутствует'">{{ row.mvz_code }}</span><span
                    v-else>—</span></td>
                <td data-label=""><span v-if="row.new_tora_zakaz !== 'Отсутствует'">{{ row.new_tora_zakaz }}</span><span
                    v-else>—</span></td>
                <td data-label="">{{ row.mvz_name }}</td>
                <td data-label="">{{ row.work_name }}</td>
                <td data-label="" v-if="row.double_date">{{ row.start_date }} : {{ row.end_date }}</td>
                <td data-label="" v-else>{{ row.row_date }}</td>
                <td data-label="">
                  <span v-if="row.research_report !== 'Отсутствует'">{{ row.research_report }}</span>
                  <span v-else>—</span></td>
                <td data-label="">{{ row.measure_unit }}</td>
                <td data-label="">{{ prettyPrice(row.count) }}</td>
                <td data-label="">{{ prettyPrice(row.unit_price) }}</td>
                <td data-label="">{{ row.reason }}</td>
                <td data-label="">{{ prettyPrice(row.reason_cost) }}</td>
                <td data-label="">{{ prettyPrice(row.total) }}</td>
              </tr>
              <tr style="height: 40px; background: white">
                <td v-for="i in [1,2,3,4,5,6,7,8,9,10,11,12,13]" :key="i">
                  <span v-if="i === 5">Итого:</span>
                  <span v-else-if="i === 13">{{ prettyPrice(act.total) }}</span>
                </td>
              </tr>
              <tr style="height: 40px; background: white">
                <td v-for="i in [1,2,3,4,5,6,7,8,9,10,11,12,13]" :key="i">
                  <span v-if="i === 5">Сумма НДС:</span>
                  <span v-else-if="i === 13">{{ prettyPrice(act.nds) }}</span>
                </td>
              </tr>
              <tr style="height: 40px; background: white">
                <td v-for="i in [1,2,3,4,5,6,7,8,9,10,11,12,13]" :key="i">
                  <span v-if="i === 5">Всего:</span>
                  <span v-else-if="i === 13">{{ prettyPrice(act.total_with_nds) }}</span>
                </td>
              </tr>
              </tbody>
            </table>
            <table class="m-table-act" v-else-if="act.type === 'ДКС'">
              <thead>
              <tr>
                <td colspan="11" style="height: 40px; text-align: center"><b>АКТ ВЫПОЛНЕНИЯ РАБОТ (ОКАЗАННЫХ УСЛУГ)*</b>
                </td>
              </tr>
              <tr style="height: 40px">
                <th style="width: 3%">№п/п</th>
                <th style="width: 8%">Код затрат</th>
                <th style="width: 11%">Месторождение № скважин</th>
                <th style="width: 9%">Код ресурса</th>
                <th style="width: 9%">СПП, ВЗ, МВЗ</th>
                <th style="width: 19%">Наименование работ</th>
                <th style="width: 5%">Ед.изм.</th>
                <th style="width: 6.5%">Кол-во</th>
                <th style="width: 8%">Цена, тенге</th>
                <th style="width: 10%">Сумма, тенге</th>
                <th style="width: 11.5%">Примечание</th>
              </tr>
              <tr>
                <td v-for="i in [1,2,3,4,5,6,7,8,9,10,11]" :key="i">{{ i }}</td>
              </tr>
              </thead>
              <tbody>
              <tr style="height: 40px; background: white" v-for="row in this.act.rows" :key="row.id">
                <td data-label="">{{ row.serial_number }}</td>
                <td data-label=""><span v-if="row.cost_code !== 'Отсутствует'">{{ row.cost_code }}</span><span
                    v-else>—</span></td>
                <td data-label="">{{ row.mvz_name }}</td>
                <td data-label=""><span v-if="row.source_code !== 'Отсутствует'">{{ row.source_code }}</span><span
                    v-else>—</span></td>
                <td data-label=""><span v-if="row.mvz_code !== 'Отсутствует'">{{ row.mvz_code }}</span><span
                    v-else>—</span></td>
                <td data-label="">{{ row.work_name }}</td>
                <td data-label="">{{ row.measure_unit }}</td>
                <td data-label="">{{ prettyPrice(row.count) }}</td>
                <td data-label="">{{ prettyPrice(row.unit_price) }}</td>
                <td data-label="">{{ prettyPrice(row.total) }}</td>
                <td data-label="">{{ row.research_report }}</td>
              </tr>
              <tr style="height: 40px; background: white">
                <td style="font-weight: bold" colspan="2">
                  Итого
                </td>
                <td v-for="i in [3,4,5,6,7,8,9,10,11]" :key="i" style="font-weight: bold">
                  <span v-if="i === 10">{{ prettyPrice(act.total) }}</span>
                </td>
              </tr>
              <tr style="height: 40px; background: white">
                <td style="font-weight: bold" colspan="2">
                  Сумма НДС
                </td>
                <td v-for="i in [3,4,5,6,7,8,9,10,11]" :key="i" style="font-weight: bold">
                  <span v-if="i === 10">{{ prettyPrice(act.nds) }}</span>
                </td>
              </tr>
              <tr style="height: 40px; background: white">
                <td style="font-weight: bold" colspan="2">
                  Итого с НДС
                </td>
                <td v-for="i in [3,4,5,6,7,8,9,10,11]" :key="i" style="font-weight: bold">
                  <span v-if="i === 10">{{ prettyPrice(act.total_with_nds) }}</span>
                </td>
              </tr>
              </tbody>
            </table>
            <h4 class="hp" style="padding: 1rem; text-align: right;">
              {{ act.total_numwords }}
            </h4>
          </div>
        </div>
      </div>
      <div class="p-10" v-if="this.fast_filter === '2'">
        <div class="bg-gradient_solid">
          <div class="container">
            <div class="section-header">
              <h2>История / действия</h2>
              <hr>
            </div>
            <div class="steps">
              <div :class="'steps-container ' + slideClass(index)" v-for="(action, index) in this.act.actions"
                   :key="action.id" :id="'action-tr-' + action.id">
                <div class="content" v-if="true" style="min-width: 85%">
                  <h2>{{ action.user.first_name }} {{ action.user.last_name }} / {{ action.datetime_point_parsed }}</h2>
                  <p v-if="action.name !== 'Содержание акта редактировано'">{{ action.name }}. {{ action.comment }}</p>
                  <p v-if="action.name === 'Содержание акта редактировано'">{{ action.name }}.</p>
                  <div v-if="action.name === 'Содержание акта редактировано'">
                    <p v-for="(action_row, index) in action.comment.split(';')" :key="index">
                      <span v-if="index === action.comment.split(';').length - 1">{{ action_row }}.</span>
                      <span v-else>{{ action_row }}, </span>
                    </p>
                  </div>
                </div>
                <div class="content" v-else style="min-width: 85%">
                  <h2>Информация скрыта настройками приватности</h2>
                  <p>Информация скрыта настройками приватности, для подробной информации обратитесь к администратору
                    системы</p>
                </div>
                <i class="step-line"></i>
                <div class="date">{{ action.short_date }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="p-10" v-if="this.fast_filter === '3'">
        <div class="section-header">
          <h2>Согласующие</h2>
          <hr>
          <p>Текущий статус подсвечивается <b
              style="background-color: #23468c; color: whitesmoke">cиним</b></p>

        </div>
        <div class="card">
          <div class="table-responsive">
            <table class="table table-hover table-nowrap">
              <thead class="thead-light">
              <tr>
                <th scope="col" class="" style="border-top-left-radius: 0.75rem !important;">№</th>
                <th scope="col" class="">ФИО</th>
                <th scope="col" class="">Должность</th>
                <th scope="col" class="">Организация</th>
                <th scope="col" class="mainless">Контакты</th>
                <th scope="col" class="" style="border-top-right-radius: 0.75rem !important;">Решение</th>
              </tr>
              </thead>
              <tbody v-for="approver in this.act.approvers" :key="approver.id">
              <tr v-if="approver.decision === 'Назначен'" style="background: #23468c; color: whitesmoke">
                <td data-label="" class="-x" style="padding: 1.5rem; height: 40px">
                  {{ approver.serial_number }}
                </td>
                <td data-label="" class="" style="padding: 1.5rem; height: 40px">
                  {{ approver.const_last_name }} {{ approver.const_first_name }}
                  <span v-if="approver.const_middle_name && approver.const_middle_name !== 'None'">{{ approver.const_middle_name }}</span>
                </td>
                <td data-label="" class="" style="padding: 1.5rem; height: 40px">
                  {{ approver.const_position }}
                </td>
                <td data-label="" class="" style="padding: 1.5rem; height: 40px">
                  {{ approver.const_company_name }}
                </td>
                <td data-label="" class="mainless" style="padding: 1.5rem; height: 40px">
                  {{ approver.user.ouser.phone }} {{ approver.user.email }}
                </td>
                <td data-label="" class="" style="padding: 1.5rem; height: 40px">
                  {{ approver.decision }}
                </td>
              </tr>
              <tr v-else>
                <td data-label="" class="-x" style="padding: 1.5rem; height: 40px">
                  {{ approver.serial_number }}
                </td>
                <td data-label="" class="" style="padding: 1.5rem; height: 40px">
                  {{ approver.const_last_name }} {{ approver.const_first_name }} {{ approver.const_middle_name }}
                </td>
                <td data-label="" class="" style="padding: 1.5rem; height: 40px">
                  {{ approver.const_position }}
                </td>
                <td data-label="" class="" style="padding: 1.5rem; height: 40px">
                  {{ approver.const_company_name }}
                </td>
                <td data-label="" class="mainless" style="padding: 1.5rem; height: 40px">
                  {{ approver.user.ouser.phone }} {{ approver.user.email }}
                </td>
                <td data-label="" class="" style="padding: 1.5rem; height: 40px">
                  {{ approver.decision }}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="p-10" v-if="this.fast_filter === '4'">
        <div class="section-header">
          <h2>Файлы</h2>
          <hr>
        </div>
        <div class="card">
          <div class="table-responsive">
            <table class="table table-hover table-nowrap">
              <thead class="thead-light">
              <tr>
                <th scope="col" class="">№ п/п</th>
                <th scope="col" class="mainless">Наименование</th>
                <th scope="col" class="">Файл(-ы)</th>
                <th scope="col" class="mainless">Тип файла</th>
                <th scope="col" class="mainless">Расширения</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(add_file, index) in this.act.additional_files" :key="add_file.id"
                  :id="'req-tr-' + add_file.id" class="tr-main">
                <td data-label="" class="">
                  {{ index + 1 }}
                </td>
                <td data-label="" class="mainless">
                  {{ add_file.name }}
                </td>
                <td data-label="" class="">
                  <a class="custom-link" target="_blank" :href="add_file.file">
                    {{ add_file.file }}
                  </a>
                </td>
                <td data-label="" class="mainless">
                  Доп. файл
                </td>
                <td data-label="" class="mainless">
                  {{ add_file.extension }}
                </td>
              </tr>
              <tr v-for="(double_im, index) in this.act.before_after_images" :key="double_im.id"
                  :id="'req-tr-d-' + double_im.id" class="tr-main">
                <td data-label="" class="">
                  {{ index + 1 + act.additional_files.length }}
                </td>
                <td data-label="" class="mainless">
                  Изображения до-после №{{ index + 1 }}
                </td>
                <td data-label="" class="">
                  <a class="custom-link" @click="showDoubleImageModal(double_im.id)">
                    Просмотр изображений
                  </a>
                  <modal :name="'double-modal-' + double_im.id" :height="900" :width="1000">
                    <div class="modal-inner" style="height: inherit; width: inherit; text-align: left; overflow: auto">
                      <h3 class="modal-title" style="padding-bottom: 0">Изображение до-после №{{ index + 1 }}</h3>
                      <div class="modal-info"
                           style="width: auto; height: 100%; display: flex; justify-content: center; align-items: center; flex-direction: column">
                        <img-comparison-slider>
                          <img slot="before" :src="double_im.file_fst"
                               style="width: auto; height: auto; object-fit: contain !important;"/>
                          <img slot="after" :src="double_im.file_snd"
                               style="width: 100%; height: auto; object-fit: contain !important;"/>
                        </img-comparison-slider>
                      </div>
                    </div>
                  </modal>
                </td>
                <td data-label="" class="mainless">
                  Двойное изображение
                </td>
                <td data-label="" class="mainless">
                  {{ double_im.file_fst.split('.')[double_im.file_fst.split('.').length - 1].toUpperCase() }},
                  {{ double_im.file_snd.split('.')[double_im.file_snd.split('.').length - 1].toUpperCase() }}
                </td>
              </tr>
              </tbody>
            </table>
          </div>

        </div>
      </div>
      <div class="p-10" v-if="this.fast_filter === '5'">
        <div class="section-header">
          <h2>Дополнительные формы</h2>
          <hr>
          <div class="innernavbar-wrap p-10"
               style="padding-top: 0 !important; display: flex; justify-content: center; gap: 1rem">
            <li :class="'ul-li ' + fast_active_form_3(true)" @click="change_fast_form_3(true)">Акт приемки</li>
            <li :class="'ul-li ' + fast_active_form_3(false)" @click="change_fast_form_3(false)">Справка</li>
          </div>
        </div>
        <div class="card p-10" v-if="this.show_form_3">
          <div style="display: flex; justify-content: center; align-items: center; flex-direction: column;">
            <h4>Акт приемки выполненных работ №{{ act.number }}</h4>
            <h4>за {{ act.act_month }} месяц {{ act.act_date.split('-')[0] }} г.</h4>
            <h4>{{ act.dks_name }}</h4>
          </div>
          <div
              style="display: flex; justify-content: space-between; gap: 1rem; margin-top: .5rem; height: 2rem !important;">
            <span>{{ act.price_comment }}</span>
            <span>Название объекта: {{ act.object_name_short }}</span>
          </div>
          <div class="table-responsive">
            <table class="m-table-act" id="price42" style="border: none !important;">
              <tr>
                <th rowspan="3" style="">№ п/п</th>
                <th rowspan="3" style="">Шифр норм, код ресурса</th>
                <th rowspan="3" style="">Наименование работ</th>
                <th rowspan="3" style="">Единица измерения</th>
                <th rowspan="3" style="">Количество</th>
                <th colspan="2" style="">Стоимость единицы, тенге</th>
                <th colspan="3" style="">Общая стоимость, тенге</th>
                <th rowspan="2" style="">Накладные расходы, тенге</th>
                <th rowspan="3" style="">Всего стоимость с НР и СП, тенге</th>
              </tr>
              <tr>
                <th>Всего</th>
                <th>эксплуатация машин</th>
                <th>Всего</th>
                <th>эксплуатация машин</th>
                <th>материалы</th>
              </tr>
              <tr>
                <th>зарплата рабочих- стоителей</th>
                <th>в т.ч. зарплата машинистов</th>
                <th>зарплата рабочих- стоителей</th>
                <th>в т.ч. зарплата машинистов</th>
                <th>оборудование, мебель, инвентарь</th>
                <th>Сметная прибыль, тенге</th>
              </tr>
              <tr>
                <th v-for="i in [1,2,3,4,5,6,7,8,9,10,11,12]" :key="i">{{ i }}</th>
              </tr>
              <tbody v-for="(row, index) in act.rows_accept" :key="index">
              <tr v-if="row.type === 'Пустая строка'">
                <td colspan="12" style="height: 35px"></td>
              </tr>
              <tr v-else-if="row.type === 'Отдел/раздел'">
                <td colspan="12" style="font-weight: bold; font-size: 16px">{{ row.work_name }}</td>
              </tr>
              <tr v-else-if="row.type === 'Одна строка'">
                <td></td>
                <td></td>
                <td>{{ row.work_name }}</td>
                <td>{{ row.measure_unit }}</td>
                <td><span v-if="row.count > 0">{{ row.count }}</span></td>
                <td><span v-if="row.unit_all > 0">{{ row.unit_all }}</span></td>
                <td><span v-if="row.unit_car > 0">{{ row.unit_car }}</span></td>
                <td><span v-if="row.total_all > 0">{{ row.total_all }}</span></td>
                <td><span v-if="row.total_car > 0">{{ row.total_car }}</span></td>
                <td><span v-if="row.total_material > 0">{{ row.total_material }}</span></td>
                <td><span v-if="row.overheads > 0">{{ row.overheads }}</span></td>
                <td><span v-if="row.cost_total > 0">{{ row.cost_total }}</span></td>
              </tr>
              <tr v-else-if="row.type === 'Одна строка - объединенная'">
                <td></td>
                <td colspan="2">{{ row.work_name }}</td>
                <td>{{ row.measure_unit }}</td>
                <td><span v-if="row.count > 0">{{ row.count }}</span></td>
                <td><span v-if="row.unit_all > 0">{{ row.unit_all }}</span></td>
                <td><span v-if="row.unit_car > 0">{{ row.unit_car }}</span></td>
                <td><span v-if="row.total_all > 0">{{ row.total_all }}</span></td>
                <td><span v-if="row.total_car > 0">{{ row.total_car }}</span></td>
                <td><span v-if="row.total_material > 0">{{ row.total_material }}</span></td>
                <td><span v-if="row.overheads > 0">{{ row.overheads }}</span></td>
                <td><span v-if="row.cost_total > 0">{{ row.cost_total }}</span></td>
              </tr>
              <tr v-if="row.type === 'Двойная строка'">
                <td rowspan="2">{{ row.serial_number }}</td>
                <td rowspan="2">{{ row.source_code }}</td>
                <td rowspan="2">{{ row.work_name }}</td>
                <td rowspan="2">{{ row.measure_unit }}</td>
                <td rowspan="2"><span v-if="row.count > 0">{{ row.count }}</span></td>
                <td><span v-if="row.unit_all > 0">{{ row.unit_all }}</span><span v-else>---</span></td>
                <td><span v-if="row.unit_car > 0">{{ row.unit_car }}</span><span v-else>---</span></td>
                <td><span v-if="row.total_all > 0">{{ row.total_all }}</span><span v-else>---</span></td>
                <td><span v-if="row.total_car > 0">{{ row.total_car }}</span><span v-else>---</span></td>
                <td><span v-if="row.total_material > 0">{{ row.total_material }}</span><span v-else>---</span></td>
                <td><span v-if="row.overheads > 0">{{ row.overheads }}</span><span v-else>---</span></td>
                <td rowspan="2"><span v-if="row.cost_total > 0">{{ row.cost_total }}</span><span v-else>---</span></td>
              </tr>
              <tr v-if="row.type === 'Двойная строка'">
                <td><span v-if="row.unit_salary > 0">{{ row.unit_salary }}</span><span v-else>---</span></td>
                <td><span v-if="row.unit_salary_car > 0">{{ row.unit_salary_car }}</span><span v-else>---</span></td>
                <td><span v-if="row.total_salary > 0">{{ row.total_salary }}</span><span v-else>---</span></td>
                <td><span v-if="row.total_salary_car > 0">{{ row.total_salary_car }}</span><span v-else>---</span></td>
                <td><span v-if="row.total_furniture > 0">{{ row.total_furniture }}</span><span v-else>---</span></td>
                <td><span v-if="row.estimated_profit > 0">{{ row.estimated_profit }}</span><span v-else>---</span></td>
              </tr>
              <tr v-if="row.type === 'Двойная строка - итого'">
                <td rowspan="2"></td>
                <td rowspan="2" colspan="2">{{ row.work_name }}</td>
                <td rowspan="2">{{ row.measure_unit }}</td>
                <td rowspan="2"><span v-if="row.count > 0">{{ row.count }}</span></td>
                <td><span v-if="row.unit_all > 0">{{ row.unit_all }}</span><span v-else>---</span></td>
                <td><span v-if="row.unit_car > 0">{{ row.unit_car }}</span><span v-else>---</span></td>
                <td><span v-if="row.total_all > 0">{{ row.total_all }}</span><span v-else>---</span></td>
                <td><span v-if="row.total_car > 0">{{ row.total_car }}</span><span v-else>---</span></td>
                <td><span v-if="row.total_material > 0">{{ row.total_material }}</span><span v-else>---</span></td>
                <td><span v-if="row.overheads > 0">{{ row.overheads }}</span><span v-else>---</span></td>
                <td rowspan="2"><span v-if="row.cost_total > 0">{{ row.cost_total }}</span><span v-else>---</span></td>
              </tr>
              <tr v-if="row.type === 'Двойная строка - итого'">
                <td><span v-if="row.unit_salary > 0">{{ row.unit_salary }}</span><span v-else>---</span></td>
                <td><span v-if="row.unit_salary_car > 0">{{ row.unit_salary_car }}</span><span v-else>---</span></td>
                <td><span v-if="row.total_salary > 0">{{ row.total_salary }}</span><span v-else>---</span></td>
                <td><span v-if="row.total_salary_car > 0">{{ row.total_salary_car }}</span><span v-else>---</span></td>
                <td><span v-if="row.total_furniture > 0">{{ row.total_furniture }}</span><span v-else>---</span></td>
                <td><span v-if="row.estimated_profit > 0">{{ row.estimated_profit }}</span><span v-else>---</span></td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="card p-10" v-else>
          <div style="display: flex; justify-content: center; align-items: center; flex-direction: column;">
            <h4>Справка</h4>
            <h4>о стоимости выполненных строительных работ и затрат</h4>
            <h4>(по ресурсному методу)</h4>
            <h4>{{ act.dks_cert_name }}</h4>
          </div>
          <div
              style="display: flex; justify-content: space-between; gap: 1rem; margin-top: .5rem; height: 2rem !important;">
            <span>Адрес объекта: {{ act.object_address }}</span>
          </div>
          <div class="table-responsive">
            <table class="m-table-act" id="price52" style="">
              <tr>
                <th colspan="4">Код заказчика</th>
                <th colspan="2">Код подрядчика</th>
                <th colspan="2">Договор</th>
                <th colspan="3">Источник финансирования</th>
                <th colspan="2">Организационно-правовая форма</th>
              </tr>
              <tr>
                <th>код учереждения банка</th>
                <th colspan="3">№ счета</th>
                <th>код учереждения банка</th>
                <th>№ счета</th>
                <th>номер</th>
                <th>дата</th>
                <th>наименование</th>
                <th colspan="2">код</th>
                <th>наименование</th>
                <th>код</th>
              </tr>
              <tr>
                <td>{{ act.customer_bank_code }}</td>
                <td colspan="3">{{ act.customer_account_number }}</td>
                <td>{{ act.impl_bank_code }}</td>
                <td>{{ act.impl_account_number }}</td>
                <td>{{ act.contract_number }}</td>
                <td>{{ act.contract_date }}</td>
                <td>{{ act.finance_source }}</td>
                <td colspan="2">{{ act.finance_code }}</td>
                <td>{{ act.org_form_name }}</td>
                <td>{{ act.org_form_code }}</td>
              </tr>
              <tr>
                <th rowspan="4">Наименование пусковых, комплексов, объектов</th>
                <th colspan="10">Стоимость выполненных работ в тыс.тенге</th>
                <th rowspan="4">НДС</th>
                <th rowspan="4">всего к оплате с НДС</th>
              </tr>
              <tr>
                <th rowspan="3">с начала года по отчетный месяц включительно с НДС</th>
                <th rowspan="3">с начала строительства по отчетный месяц включительно без НДС</th>
                <th rowspan="3">Общая стоимость договора на без НДС</th>
                <th rowspan="3">в отчетном месяце (за {{ act.act_month }}) без НДС</th>
                <th colspan="6">из графы 1</th>
              </tr>
              <tr>
                <th rowspan="2">Строительно монтажные работы по смете</th>
                <th colspan="2">из них</th>
                <th rowspan="2">стоимость прочих затрат, не входящих в состав СМР по смете 2021г.</th>
                <th colspan="2">дополнительные затраты</th>
              </tr>
              <tr>
                <th>в отчетном месяце</th>
                <th>по монтажу оборудования</th>
                <th>на приобретение материалов</th>
                <th>другие дополнительные расходы</th>
              </tr>
              <tr>
                <th>A</th>
                <th v-for="i in [1,2,3,4,5,6,7,8,9,10,11,12]" :key="i">{{ i }}</th>
              </tr>
              <tr v-for="(row, index) in act.rows_cert" :key="index">
                <td>{{ row.work_name }}</td>
                <td>{{ prettyPrice(row.from_beg_year) }}</td>
                <td>{{ prettyPrice(row.from_beg_construction) }}</td>
                <td>{{ prettyPrice(row.contract_total) }}</td>
                <td>{{ prettyPrice(row.current_month) }}</td>
                <td><span v-if="row.constr_install > 0">{{ row.constr_install }}</span></td>
                <td><span v-if="row.constr_install > 0">{{ row.constr_install_month }}</span></td>
                <td><span v-if="row.constr_install > 0">{{ row.install_furniture }}</span></td>
                <td><span v-if="row.constr_install > 0">{{ row.other_expenses }}</span></td>
                <td><span v-if="row.constr_install > 0">{{ row.materials_purchase }}</span></td>
                <td><span v-if="row.constr_install > 0">{{ row.additional_costs }}</span></td>
                <td>{{ prettyPrice(row.nds) }}</td>
                <td>{{ prettyPrice(row.total_with_nds) }}</td>
              </tr>
              <tr>
                <td>Итого</td>
                <td>{{ prettyPrice(act.total_from_beg_year) }}</td>
                <td>{{ prettyPrice(act.total_from_beg_construction) }}</td>
                <td>{{ prettyPrice(act.total_contract_total) }}</td>
                <td>{{ prettyPrice(act.total_current_month) }}</td>
                <td v-for="i in [5,6,7,8,9,10]" :key="i"></td>
                <td>{{ prettyPrice(act.total_nds) }}</td>
                <td>{{ prettyPrice(act.total_twn) }}</td>
              </tr>
              <tr>
                <td>НДС 12%</td>
                <td></td>
                <td>{{ prettyPrice(act.nds_from_beg_construction) }}</td>
                <td>{{ prettyPrice(act.nds_contract_total) }}</td>
                <td>{{ prettyPrice(act.nds_current_month) }}</td>
                <td v-for="i in [5,6,7,8,9,10,11,12]" :key="i"></td>
              </tr>
              <tr>
                <td>Итого с НДС</td>
                <td></td>
                <td>{{ prettyPrice(act.twn_from_beg_construction) }}</td>
                <td>{{ prettyPrice(act.twn_contract_total) }}</td>
                <td>{{ prettyPrice(act.twn_current_month) }}</td>
                <td v-for="i in [5,6,7,8,9,10,11,12]" :key="i"></td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script async>
export default {
  components: {},
  data() {
    return {
      fast_filter: "1",
      show_form_3: true,
      uploading: false,
      act: {},
      reject_comment: '',
    };
  },
  created() {
    console.log('blaaaaaaaaaaaa');
    this.getActDetail();
  },
  computed: {
  },
  methods: {
    downloadActPDF() {
      let act_id = this.act.id;
      this.$store.dispatch("downloadActPDF", act_id).then((response) => {
        const url = URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'AVR_Акт_ID0_' + act_id + '.pdf');
        document.body.appendChild(link);
        link.click();
      }).catch((error) => {
        console.log(error);
      });
    },
    downloadHistoryPDF() {
      let act_id = this.act.id;
      this.$store.dispatch("downloadHistoryPDF", act_id).then((response) => {
        const url = URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'AVR_История_акта_ID0_' + act_id + '.pdf');
        document.body.appendChild(link);
        link.click();
      }).catch((error) => {
        console.log(error);
      });
    },
    prettyPrice(price) {
      if (typeof price == "number") {
        return price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
      } else {
        return price;
      }
    },
    showDoubleImageModal(index) {
      this.$modal.show("double-modal-" + index);
    },
    slideClass(index) {
      if (index % 2 !== 0) {
        return 'slide-right'
      }
      return 'slide-left'
    },
    fast_active(to_filter) {
      if (to_filter.toString() === this.fast_filter.toString()) {
        return 'active';
      }
      return '';
    },
    fast_active_form_3(to_filter) {
      if (to_filter === this.show_form_3) {
        return 'active';
      }
      return '';
    },
    change_fast_filter(curr_filter) {
      this.fast_filter = curr_filter;
    },
    change_fast_form_3(curr_filter) {
      this.show_form_3 = curr_filter;
    },
    getActDetail() {
      this.$store.dispatch('getActDetailVerify', this.$route.params.id).then((response) => {
        this.act = response.data;
      }).catch(e => {
        console.log(e)
      })
    },
  },
  mounted() {
    let recaptchaScript1 = document.createElement('script');
    recaptchaScript1.setAttribute('src', 'https://unpkg.com/img-comparison-slider@3/dist/component/component.esm.js');
    recaptchaScript1.setAttribute('type', 'module');
    document.head.appendChild(recaptchaScript1);

    let recaptchaScript2 = document.createElement('script');
    recaptchaScript2.setAttribute('src', 'https://unpkg.com/img-comparison-slider@3/dist/component/component.js');
    recaptchaScript2.setAttribute('nomodule', '');
    document.head.appendChild(recaptchaScript2);

    let recaptchaScript3 = document.createElement('link');
    recaptchaScript3.setAttribute('href', 'https://unpkg.com/img-comparison-slider@3/dist/collection/styles/initial.css');
    recaptchaScript3.setAttribute('rel', 'stylesheet');
    document.head.appendChild(recaptchaScript3);

  },
};
</script>

<style scoped>
@import "../assets/css/webpixels.css";

@keyframes example {
  from {
    flex: 1
  }
  to {
    flex: none
  }
}

.in_sap_card {

}

.sap-success {
  height: 100px;
  position: fixed;
  top: 35%;
  left: 450px;
  font-size: 100px;
  border: 5px solid #0c8;
  opacity: 0.3;
  text-align: center;
  /*transform: rotateZ(-30deg);*/
  padding: 4rem;
  border-radius: 1.5rem;
}

.sap-warning {
  height: 100px;
  position: fixed;
  top: 35%;
  left: 450px;
  font-size: 100px;
  border: 5px solid #ff8c00;
  opacity: 0.3;
  text-align: center;
  /*transform: rotateZ(-30deg);*/
  padding: 4rem;
  border-radius: 1.5rem;
}

.archived-warning {
  height: 100px;
  width: 350px;
  position: fixed;
  top: 4%;
  right: 150px;
  font-size: 40px;
  background: rgb(35, 70, 140);
  color: white;
  /*border: 5px solid rgb(35, 70, 140);*/
  opacity: 0.3;
  text-align: center;
  /*transform: rotateZ(-30deg);*/
  padding: 2rem;
  padding-top: 2.5rem;
  border-radius: 1.5rem;
}

.dropzone {
  /*margin-top: 3rem;*/
  height: 250px;
  min-height: 250px;
  padding: 10px;
  position: relative;
  cursor: pointer;
  border: 2px dashed gray;
  outline-offset: 10px;
  background: #d9e3f7;
  color: dimgray;
}

.input-field {
  opacity: 0;
  width: 100%;
  height: 100%;
  left: 0;
  position: absolute;
  cursor: pointer;
}

.dropzone:hover {
  background: lightblue;
  transition: all 300ms ease;
}

.dropzone .call-to-action {
  font-size: 1.5rem;
  text-align: center;
  padding: 35px 0;
}

.call-to-action > div {
  font-size: 1rem;
  padding: 1rem 2rem;
  background: #00aacc;
  max-width: 300px;
  margin: 1rem auto;
  border-radius: 20px;
  color: #fff;
}

.placeholder-disabled::placeholder {
  text-decoration: line-through;
  color: lightgray;
}

.uploaded-msg {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

img-comparison-slider {
  --divider-width: 2px;
  --divider-color: #c0c0c0;
  --handle-opacity: 0.3;
  height: auto;
  width: auto;
  /*background-color: black;*/
}

.flex-column-animated {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 2rem;
  padding-bottom: 2rem;
}

.section-btn {
  background: inherit;
  animation: example 1000ms ease;

  position: relative;
}

.section-btn-bx:hover {
  transform: scale(1.2) translateX(-0.6rem);
}

.section-btn-bx {
  color: white;
  font-size: 40px;
  border-radius: 50%;
  padding: 0.5rem;
  transition-duration: .5s;
}

.section-btn-bx-static {
  color: white;
  width: 70px;
  font-size: 30px;
  animation: example 3000ms ease;
  border-radius: .5rem;
  padding: 0.2rem;
  transition-duration: .5s;
  overflow: hidden;
}

.section-btn-bx-static:hover {
  width: 150px;
  text-align: center;
}

.section-btn-bx-static-change:hover::before {
  content: 'Заменить';
  font-size: 27px;
  font-family: 'Quicksand', 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
}

.section-btn-bx-static-remove:hover::before {
  content: 'Исключить';
  font-size: 27px;
  font-family: 'Quicksand', 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;

}

.bx-disabled {
  opacity: 0.2;
  /*background: lightgray !important;*/
}

.hp::first-letter {
  text-transform: capitalize;
}

.m-table-act {
  width: 100%;

  text-align: center;
  font-size: x-small;
  border: 1px solid black;
  border-collapse: collapse;
}

.m-table-act td {
  border: 1px solid black;
  border-collapse: collapse;
}

.m-table-act th {
  -moz-transition: all 1s ease-out;
  -o-transition: all 1s ease-out;
  -webkit-transition: all 1s ease-out;
  border: 1px solid black;
  border-collapse: collapse;
  background: #f5f9fc;
}


.m-table-act th:hover {
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -o-transform: scale(1.2);
  /*background-color: white;*/
}

.table-abp thead tr th {
  border-top-width: 0 !important;
}

.section-header {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
}

.section-header h2 {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 16px;
  color: black;
}

.section-header hr {
  border: 1px solid gray;
  width: 120px;
}

.bg-gradient_solid {
  color: #fff;
}

.steps {
  position: relative;
  margin-top: 32px;
}

.steps::after {
  content: "";
  position: absolute;
  width: 1px;
  background-color: white;
  opacity: 1;
  top: 0;
  bottom: 0;
  left: 50%;
}

.steps .content p {
  color: #676767;
  font-size: 16px;
}

.steps .content h2 {
  font-weight: 600;
  font-size: 16px;
  color: #676767;
}

.steps-container {
  position: relative;
  background-color: inherit;
  width: calc(50% + 32px);
}

.steps-container .content {
  padding: 32px;
  background-color: white;
  position: relative;
  border-radius: 0px 0px 80px 0px;
  transition: all 1.3s linear;
  box-shadow: 0px 16px 27px rgb(0 11 30 / 10%);
}

.steps .steps-container:nth-child(even) {
  left: calc(50% - 32px);
  flex-direction: row-reverse;
}

.steps-container {
  display: flex;
}

.steps .steps-container .date {
  font-weight: 900;
  font-size: 16px;
  color: #ffffff;
  margin-bottom: 10px;
  width: 62px;
  height: 62px;
  background-color: var(--primary);
  border-radius: 50%;
  flex-shrink: 0;
  align-items: center;
  display: flex;
  justify-content: center;
  z-index: 777;
}

.step-line {
  width: 40px;
  background-color: white;
  height: 1px;
  margin-top: 31px;
  opacity: 1;
  flex-shrink: 0;
}

@media (max-width: 767px) {
  .steps::after {
    left: 32px;
  }

  .steps-container {
    left: 0;
    flex-direction: row-reverse;
    width: auto;
    margin-bottom: 16px;
  }

  .steps .steps-container:nth-child(even) {
    left: 0;
  }
}

.table-bordered2 thead tr th {
  border-left: 1px #e7eaf0 solid;
  border-right: 1px #e7eaf0 solid
}

.table-bordered2 tbody tr td {
  border-left: 1px #e7eaf0 solid;
  border-right: 1px #e7eaf0 solid
}

.item-wrap {
  display: flex;
  justify-content: space-between;
}

.left-item ul {
  display: flex;
  justify-content: left;
  gap: 1rem;
}

@media screen and (max-width: 1100px) {
  .left-item {
    flex-direction: column;
    gap: 0.2rem;
    height: 100px !important;
  }

  .ul-li {
    height: 30px !important;
  }
}

.item {
  height: 50px;
  width: 50%;
}

.innernavbar-wrap {
  width: 100%;
}

.ul-li {
  list-style-type: none;
  width: 250px !important;
  min-width: 250px !important;
  height: 30px;
  border-radius: 10px;
  background: #d9e3f7 !important;
  text-align: center;
  cursor: pointer;
  color: #666 !important;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  border: 1px solid #d9e3f7;
}

.ul-li:hover {
  background: #2b6de8 !important;
  color: white !important;
}

.active {
  color: white !important;
  background: #23468c !important;
}

@keyframes progress {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

th {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

td {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.tr-main {
  cursor: pointer
}

@keyframes wave {
  50% {
    transform: scale(0.9);
  }
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.select_button {
  /*background: rgb(29, 161, 242);*/
  background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) no-repeat right #00aacc;
  -webkit-appearance: none;
  background-position-x: 97%;
  color: white;
  width: 98%;
  margin: .5rem;
  padding: .5rem 1.5rem;
  border-radius: 1rem;
  text-align: left;
}

.default_option {
  background: white !important;
  color: black !important;
}

.m-input-cell {
  width: 100%;
  /*border-width: 1px;*/
  height: 35px;
  text-align: center;
}

@media (max-width: 1000px) {
  .mainless {
    display: none;
  }
  .mobile-flex {
    display: flex;
    flex-direction: column;
  }
  .mobile-flex li {
    width: 100%;
  }
  .mobile-flex li button{
    width: 100%;
  }
  .innernavbar-wrap {
    height: 200px;
  }
  .item-wrap {
    width: 100% !important;
  }
  .item {
    width: 100%;
  }
  .p-10 {
    padding: 10px !important;
    margin: 0 !important;
  }
}

</style>