// import AuthService from '@/service/auth.service'
// import router from '../../router.js'
import axios from 'axios'

export default {
    getters: {
        // isActiveAccount(state) {
        //     return state.user.ouser.is_active;
        // },
        // USER(state) {
        //     return state.user
        // }
    },
    mutations: {
        test(state) {
            state.isTest = true;
        }
    },

    state: {
        isTest: false,
    },

    actions: {
        getMVZList({commit}) {
            console.log(commit);
            return axios.get('/core/mvz_list/');
        },
        getCostList({commit}) {
            console.log(commit);
            return axios.get('/core/cost_list/');
        },
        getAllFAQ({commit}) {
            console.log(commit);
            return axios.get('/core/faqs/');
        },
        getAllNotifications({commit}) {
            console.log(commit);
            return axios.get('/core/notifications/');
        },
        notification_clicked({commit}, id) {
            console.log(commit);
            return axios.get('/core/notification_clicked/',{
                params: {
                    notification_id: id,
                }
            });
        },
    },
}