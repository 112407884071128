<template>
  <div class="justify-center">
    <img
        src="https://i.ibb.co/DQ4FZhL/pattern-bg.png"
        alt="blue pattern background"
        class="absolute w-full h-64 md:h-96 object-center object-fit z-0"
    />
    <div
        class="relative flex flex-col items-center justify-center sm:px-0 px-6 z-20 pb-32"
    >
      <div class="md:py-36 py-20">
        <h1
            role="heading"
            class="xl:text-6xl md:text-5xl text-xl font-bold leading-10 text-white"
        >
          Обучение
        </h1>
      </div>
      <div class="lg:w-1/2 md:w-8/12 sm:w-9/12">
        <div v-for="faq in faqs"
             :key="faq.id"
            class="bg-white shadow rounded p-8 cursor-pointer mb-3"
        >
          <div class="flex items-center justify-between" @click="faq.open = !faq.open">
            <div>
              <h2
                  class="text-base font-semibold leading-none text-gray-800" style="line-height: 2em;"
              >
                {{ faq.role_for }}: {{ faq.q_name }}
              </h2>
            </div>
            <button
                data-menu
                class="focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 ring-offset-white cursor-pointer"
            >
              <svg
                  role="button"
                  aria-label="close dropdown"
                  class=""
                  v-if="faq.open"
                  @click="faq.open = false"
                  width="10"
                  height="6"
                  viewBox="0 0 10 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <path
                    d="M1 5L5 1L9 5"
                    stroke="#4B5563"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
              </svg>
              <svg
                  width="10"
                  role="button"
                  aria-label="open dropdown"
                  v-if="!faq.open"
                  @click="faq.open = true"
                  height="6"
                  viewBox="0 0 10 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <path
                    d="M1 1L5 5L9 1"
                    stroke="#4B5563"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
              </svg>
            </button>
          </div>
          <ul v-if="faq.open">
            <li>
              <p
                  class="text-base leading-normal text-gray-600 mt-4 w-full" style="line-height: 2em;"
                  v-for="faq_c in faq.content.split('\n')"
              >
                {{ faq_c }}
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FAQ',
  data() {
    return {
      faqs: [],
    };
  },
  created() {
    this.getAllFAQ();
  },
  methods: {
    getAllFAQ() {
      this.$store.dispatch("getAllFAQ").then((response) => {
        this.faqs = response.data;
      }).catch((error) => {
        this.$store.dispatch('addNotification', {
          type: 'error',
          message: error.response.data.detail,
        })
      });
    }
  }

};
</script>
