<template>
  <div class="home-section-wrap">
    <section class="home-section" id="home-section">
      <div class="p-10" style="padding-bottom: 0 !important;">
        <h3>Договор №
          <a :href="'/scontracts/' + this.scontract.id">
            {{ this.scontract.number }} {{ this.scontract.name }}
          </a>
          от
          <span v-if="this.scontract.date_from">{{ this.scontract.date_from_short }} г/ж.</span>
          <span v-else>__.__.____ г/ж.</span>
        </h3>
      </div>
      <div class="innernavbar-wrap p-10" style="padding-bottom: 0 !important;">
        <div class="item-wrap">
          <div class="item left-item">
            <ul>
              <li>
                <button
                    class="filter-btn transition-all ease-in-out duration-150 transform hover:scale-110 bg-indigo-500 hover:bg-indigo-600 px-5 py-1 rounded-lg max-h-12 whitespace-nowrap"
                    :class="fast_active('0')"
                    @click="change_fast_filter('0')">
                  Основные параметры
                </button>
              </li>
              <li>
                <button
                    class="filter-btn transition-all ease-in-out duration-150 transform hover:scale-110 bg-indigo-500 hover:bg-indigo-600 px-5 py-1 rounded-lg max-h-12 whitespace-nowrap"
                    :class="fast_active('1')"
                    @click="change_fast_filter('1')">
                  Приложения
                </button>
              </li>
              <li>
                <button
                    class="filter-btn transition-all ease-in-out duration-150 transform hover:scale-110 bg-indigo-500 hover:bg-indigo-600 px-5 py-1 rounded-lg max-h-12 whitespace-nowrap"
                    :class="fast_active('2')"
                    @click="change_fast_filter('2')">
                  История
                </button>
              </li>
              <li>
                <button
                    class="filter-btn transition-all ease-in-out duration-150 transform hover:scale-110 bg-indigo-500 hover:bg-indigo-600 px-5 py-1 rounded-lg max-h-12 whitespace-nowrap"
                    :class="fast_active('3')"
                    @click="change_fast_filter('3')">
                  Согласующие
                </button>
              </li>
              <li>
                <button
                    class="filter-btn transition-all ease-in-out duration-150 transform hover:scale-110 bg-indigo-500 hover:bg-indigo-600 px-5 py-1 rounded-lg max-h-12 whitespace-nowrap"
                    :class="fast_active('4')"
                    @click="change_fast_filter('4')">
                  Дополнительные файлы
                </button>
              </li>
              <li>
                <button
                    class="filter-btn transition-all ease-in-out duration-150 transform hover:scale-110 bg-indigo-500 hover:bg-indigo-600 px-5 py-1 rounded-lg max-h-12 whitespace-nowrap"
                    :class="fast_active('5')"
                    @click="change_fast_filter('5')">
                  Реквизиты
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="p-10" v-if="this.fast_filter === '0'">
        <div class="section-header">
          <h2>Основные параметры</h2>
          <hr>
        </div>
        <div class="card p-10">
          <div style="width: 100%; display: flex; justify-content: space-between; gap: 2rem">
            <div class="event-left-part">
<!--              <h4 style="margin-bottom: 10px">Заказчик</h4>-->
              <div class="div-item">
                <div class="descripcion">ID</div>
                <div class="precio"><a class="custom-link" :href="'/scontracts/' + scontract.id">ID0{{ scontract.created_date.year }}.C.0000{{ scontract.id }}</a></div>
              </div>
              <div class="div-item">
                <div class="descripcion">Номер</div>
                <div class="precio">{{ scontract.number }}</div>
              </div>
              <div class="div-item">
                <div class="descripcion">Предмет</div>
                <div class="precio">{{ scontract.name }}</div>
              </div>
              <div class="div-item">
                <div class="descripcion">Дата создания договора в системе</div>
                <div class="precio">{{ scontract.created_date }}</div>
              </div>
              <div class="div-item">
                <div class="descripcion">Исполнитель</div>
                <div class="precio">АО "Эмбамунайгаз"</div>
              </div>
              <div class="div-item" v-if="scontract.zak_abp">
                <div class="descripcion">Ответственный (исполнитель)</div>
                <div class="precio">{{ scontract.zak_abp.ouser.full_name }} ({{ scontract.zak_abp.ouser.position }})</div>
              </div>
            </div>
            <div class="event-left-part">
<!--              <h4 style="margin-bottom: 10px">Исполнитель</h4>-->
              <div class="div-item">
                <div class="descripcion">Статус</div>
                <div class="precio"><span :class="scontract.color + ' act-status'">{{ scontract.status }}</span></div>
              </div>
              <div class="div-item">
                <div class="descripcion">Тип договора</div>
                <div class="precio">{{ scontract.type_scontr }}</div>
              </div>
              <div class="div-item">
                <div class="descripcion">Дата начала оказания услуг</div>
                <div class="precio">{{ scontract.date_start }}</div>
              </div>
              <div class="div-item">
                <div class="descripcion">Дата завершения оказания услуг</div>
                <div class="precio">{{ scontract.date_to }}</div>
              </div>
<!--              <div class="div-item">-->
<!--                <div class="descripcion">Сумма без НДС</div>-->
<!--                <div class="precio">{{ prettyPrice(scontract.total) }} тг</div>-->
<!--              </div>-->
<!--              <div class="div-item">-->
<!--                <div class="descripcion">НДС</div>-->
<!--                <div class="precio">{{ prettyPrice(scontract.total * scontract.nds / 100.0) }} тг ({{ scontract.nds }}%)</div>-->
<!--              </div>-->
<!--              <div class="div-item">-->
<!--                <div class="descripcion">Сумма с НДС</div>-->
<!--                <div class="precio">{{ prettyPrice(scontract.total_with_nds) }} тг</div>-->
<!--              </div>-->
              <div class="div-item" v-if="scontract.impl_company">
                <div class="descripcion">Заказчик</div>
                <div class="precio">{{ scontract.impl_company.name }}</div>
              </div>
              <div class="div-item" v-if="scontract.impl_abp">
                <div class="descripcion">Ответственный (заказчик)</div>
                <div class="precio">{{ scontract.impl_abp.ouser.full_name }} ({{ scontract.impl_abp.ouser.position }})</div>
              </div>
            </div>
          </div>
<!--          <div class="table-responsive">-->
<!--            {{ scontract.total_numwords }}-->
<!--          </div>-->
        </div>
      </div>
      <div class="p-10" v-if="this.fast_filter === '1'">
        <div class="section-header">
          <h2>Приложения</h2>
          <hr>
          <div class="innernavbar-wrap p-10"
               style="padding-top: 0 !important; display: flex; justify-content: center; gap: 1rem">
            <li :class="'ul-li ' + fast_active_form_3(true)" @click="change_fast_form_3(true)">Приложение 1 (каз)</li>
            <li :class="'ul-li ' + fast_active_form_3(false)" @click="change_fast_form_3(false)">Приложение 1 (рус)</li>
          </div>
        </div>
        <div class="card p-10">
<!--          Содержание приложения 1 (каз)-->
          <table class="table table-hover table-nowrap">
            <thead class="thead-light">
              <tr>
                <th class="text-center"></th>
                <th>
                  <span v-if="show_form_3">Басқарма атауы</span>
                  <span v-else>Наименование</span>
                </th>
                <th>
                  <span v-if="show_form_3">Өлшем бірлігі</span>
                  <span v-else>Един.измер</span>
                </th>
                <th scope="col" class="text-center" style="width: 10% !important;">
                  <span v-if="show_form_3">Тариф ҚҚС құнысыз 2022-2023 жж.</span>
                  <span v-else>Тариф без учета НДС на 2022-2023 гг.</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="attachment in this.scontract.attachments" :key="attachment.id">
                <td class="text-center" style="font-weight: bold"><span v-if="attachment.is_ngdu">{{ attachment.ngdu_serial }}</span></td>
                <td>
                  <span v-if="!attachment.is_ngdu && show_form_3">{{ attachment.place_name_kaz }}</span>
                  <span v-else-if="attachment.is_ngdu && show_form_3" style="font-weight: bold">{{ attachment.place_name_kaz }}</span>
                  <span v-else-if="!attachment.is_ngdu && !show_form_3">{{ attachment.place_name_rus }}</span>
                  <span v-else-if="attachment.is_ngdu && !show_form_3" style="font-weight: bold">{{ attachment.place_name_rus }}</span>
                </td>
                <td>
                  <span v-if="!attachment.is_ngdu && show_form_3">{{ attachment.measure_unit_kaz }}</span>
                  <span v-else-if="!attachment.is_ngdu">{{ attachment.measure_unit_rus }}</span>
                </td>
                <td class="text-center" style="width: 10% !important;"><span v-if="!attachment.is_ngdu">{{ prettyPrice(attachment.cost) }}</span></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="p-10" v-if="this.fast_filter === '2'">
        <div class="bg-gradient_solid">
          <div class="container">
            <div class="section-header">
              <h2>История / действия</h2>
              <hr>
            </div>
            <div class="steps">
              <div :class="'steps-container ' + slideClass(index)" v-for="(action, index) in this.scontract.actions"
                   :key="action.id" :id="'action-tr-' + action.id">
                <div class="content" v-if="true" style="min-width: 85%">
                  <!--                  is_IMPL && !action.private || !is_IMPL-->
                  <h2>{{ action.user.first_name }} {{ action.user.last_name }} / {{ action.datetime_point_parsed }}</h2>
                  <p v-if="action.name !== 'Содержание договора редактировано'">{{ action.name }}. {{ action.comment }}</p>
                  <p v-if="action.name === 'Содержание договора редактировано'">{{ action.name }}.</p>
                  <div v-if="action.name === 'Содержание договора редактировано'">
                    <p v-for="(action_row, index) in action.comment.split(';')" :key="index">
                      <span v-if="index === action.comment.split(';').length - 1">{{ action_row }}.</span>
                      <span v-else>{{ action_row }}, </span>
                    </p>
                  </div>
                </div>
                <div class="content" v-else style="min-width: 85%">
                  <h2>Информация скрыта настройками приватности</h2>
                  <p>Информация скрыта настройками приватности, для подробной информации обратитесь к администратору
                    системы</p>
                </div>
                <i class="step-line"></i>
                <div class="date">{{ action.short_date }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="p-10" v-if="this.fast_filter === '3'">
        <div class="section-header">
          <h2>Согласующие</h2>
          <hr>
          <p>Текущий статус подсвечивается <b
              style="background-color: #23468c; color: whitesmoke">cиним</b></p>

        </div>
        <div class="card">
          <div class="table-responsive">
            <table class="table table-hover table-nowrap">
              <thead class="thead-light">
              <tr>
                <th scope="col" class="" style="border-top-left-radius: 0.75rem !important;">№</th>
                <th scope="col" class="">ФИО</th>
                <th scope="col" class="">Должность</th>
                <th scope="col" class="">Организация</th>
                <th scope="col" class="">Контакты</th>
                <th scope="col" class="" style="border-top-right-radius: 0.75rem !important;">Решение</th>
<!--                <th scope="col" class="text-center"-->
<!--                    style="border-top-right-radius: 0.75rem !important; width: 250px !important; "-->
<!--                    v-if="is_ABP && scontract.status !== 'На подписании у подрядчика' && scontract.status !== 'На подрядчике'">-->
<!--                  Действия-->
<!--                </th>-->
              </tr>
              </thead>
              <tbody v-for="approver in this.scontract.approvers" :key="approver.id">
              <tr v-if="approver.decision === 'Назначен' && approver.user" style="background: #23468c; color: whitesmoke">
                <td data-label="" class="-x" style="padding: 1.5rem; height: 40px">
                  {{ approver.serial_number }}
                </td>
                <td data-label="" class="" style="padding: 1.5rem; height: 40px">
                  {{ approver.const_last_name }} {{ approver.const_first_name }} {{ approver.const_middle_name }}
                </td>
                <td data-label="" class="" style="padding: 1.5rem; height: 40px">
                  {{ approver.const_position }}
                </td>
                <td data-label="" class="" style="padding: 1.5rem; height: 40px">
                  {{ approver.const_company_name }}
                </td>
                <td data-label="" class="" style="padding: 1.5rem; height: 40px">
                  {{ approver.user.ouser.phone }} {{ approver.user.email }}
                </td>
                <td data-label="" class="" style="padding: 1.5rem; height: 40px">
                  {{ approver.decision }}
                </td>
<!--                <td data-label="" class="" style="width: 250px !important; height: 40px;"-->
<!--                    v-if="is_ABP && scontract.status !== 'На подписании у подрядчика' && scontract.status !== 'На подрядчике'">-->
<!--                  <div v-if="is_ABP && approver.user.id !== scontract.zak_abp.id && !approver.is_impl &&-->
<!--                             scontract.status !== 'На подписании у подрядчика' && scontract.status !== 'На подрядчике' &&-->
<!--                            'Назначен, Отклонен, Проект'.includes(approver.decision)"-->
<!--                       style="display: flex; justify-content: center; gap: 1rem">-->
<!--                    <i class="bx bx-edit section-btn-bx-static section-btn-bx-static-change text-center"-->
<!--                       style="background: #1da1f2;" @click="showChangeApproverModalABP(approver.user.id)"></i>-->
<!--                    <i v-if="!approver.ratifier" @click="removeDZOCurrentApprovers(approver.user.id)"-->
<!--                       class="bx bx-trash section-btn-bx-static section-btn-bx-static-remove text-center"-->
<!--                       style="background: #f36;"></i>-->
<!--                    <modal :name="'change-approver-modal-abp-' + approver.user.id" :height="300" :width="950">-->
<!--                      <div class="modal-inner"-->
<!--                           style="height: inherit; width: inherit; text-align: left; overflow: auto">-->
<!--                        <div style="display: flex; justify-content: space-between; padding: .5rem; height: 4rem;">-->
<!--                          <div>-->
<!--                            <h3 style="padding: 1rem">Замена подписанта {{ approver.user.ouser.full_name }}</h3>-->
<!--                          </div>-->
<!--                          <button v-if="dzo_current_approver !== ''" class="select_button text-center"-->
<!--                                  style="width: 200px; border-radius: .5rem; padding-right: 1rem; height: 100%; background: #23468c !important"-->
<!--                                  @click="changeDZOCurrentApprovers(approver.user.id)">-->
<!--                            Заменить-->
<!--                          </button>-->
<!--                        </div>-->
<!--                        <div class="modal-info"-->
<!--                             style="width: 100%; height: auto; display: flex; justify-content: flex-start; align-items: flex-start; flex-direction: column">-->
<!--                          <select class="select_button" :id="'select_approver_' + approver.user.id"-->
<!--                                  v-on:change="change_dzo_current_approver"-->
<!--                                  v-model="selected_dzo_current_approver">-->
<!--                            <option class="default_option" value="">Выберите нового согласующего</option>-->
<!--                            <option class="default_option" v-for="ouser in all_collegues" :key="ouser.id" :value="ouser"-->
<!--                                    :title="ouser.full_name.toUpperCase() + ' / ' + ouser.position.toUpperCase() + ' / ' + ouser.company.name.toUpperCase()">-->
<!--                              {{ ouser.full_name.toUpperCase() }} /-->
<!--                              {{ ouser.position_short.toUpperCase() }}-->
<!--                            </option>-->
<!--                          </select>-->
<!--                          <table class="table table-hover table-nowrap">-->
<!--                            <thead class="thead-light">-->
<!--                            <tr>-->
<!--                              <th>№</th>-->
<!--                              <th>ФИО</th>-->
<!--                              <th>Должность</th>-->
<!--                              <th>Тип согласования</th>-->
<!--                              <th>Ваш комментарий</th>-->
<!--                            </tr>-->
<!--                            </thead>-->
<!--                            <tbody>-->
<!--                            <tr v-if="dzo_current_approver !== ''" style="font-weight: bold">-->
<!--                              <td>{{ approver.serial_number }}</td>-->
<!--                              <td>-->
<!--                                {{ dzo_current_approver.fio }}-->
<!--                              </td>-->
<!--                              <td :title="dzo_current_approver.position">-->
<!--                                {{ dzo_current_approver.position_short }}-->
<!--                              </td>-->
<!--                              <td>-->
<!--                                <span v-if="approver.ratifier">Утверждающий</span>-->
<!--                                <span v-else>Согласующий</span>-->
<!--                              </td>-->
<!--                              <td style="padding: 0; height: 30px;">-->
<!--                                <input class="" style="width: 100% !important; height: 100% !important; padding: .5rem"-->
<!--                                       v-model="dzo_current_approver.comment">-->
<!--                              </td>-->
<!--                            </tr>-->
<!--                            </tbody>-->
<!--                          </table>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </modal>-->
<!--                  </div>-->
<!--                </td>-->
              </tr>
              <tr v-else-if="approver.user">
                <td data-label="" class="-x" style="padding: 1.5rem; height: 40px">
                  {{ approver.serial_number }}
                </td>
                <td data-label="" class="" style="padding: 1.5rem; height: 40px">
                  {{ approver.const_last_name }} {{ approver.const_first_name }} {{ approver.const_middle_name }}
                </td>
                <td data-label="" class="" style="padding: 1.5rem; height: 40px">
                  {{ approver.const_position }}
                </td>
                <td data-label="" class="" style="padding: 1.5rem; height: 40px">
                  {{ approver.const_company_name }}
                </td>
                <td data-label="" class="" style="padding: 1.5rem; height: 40px">
                  {{ approver.user.ouser.phone }} {{ approver.user.email }}
                </td>
                <td data-label="" class="" style="padding: 1.5rem; height: 40px">
                  {{ approver.decision }}
                </td>
<!--                <td data-label="" class="" style="width: 250px !important; height: 40px;"-->
<!--                    v-if="is_ABP && act.status !== 'На подписании у подрядчика' && act.status !== 'На подрядчике'">-->
<!--                  <div v-if="is_ABP && approver.user.id !== act.contract.abp.id && !approver.is_impl &&-->
<!--                             act.status !== 'На подписании у подрядчика' && act.status !== 'На подрядчике' &&-->
<!--                            'Назначен, Отклонен, Проект'.includes(approver.decision)"-->
<!--                       style="display: flex; justify-content: center; gap: 1rem">-->
<!--                    <i class="bx bx-edit section-btn-bx-static section-btn-bx-static-change text-center"-->
<!--                       style="background: #1da1f2;" @click="showChangeApproverModalABP(approver.user.id)"></i>-->
<!--                    <i v-if="!approver.ratifier" @click="removeDZOCurrentApprovers(approver.user.id)"-->
<!--                       class="bx bx-trash section-btn-bx-static section-btn-bx-static-remove text-center"-->
<!--                       style="background: #f36;"></i>-->
<!--                    <modal :name="'change-approver-modal-abp-' + approver.user.id" :height="300" :width="950">-->
<!--                      <div class="modal-inner"-->
<!--                           style="height: inherit; width: inherit; text-align: left; overflow: auto">-->
<!--                        <div style="display: flex; justify-content: space-between; padding: .5rem; height: 4rem;">-->
<!--                          <div>-->
<!--                            <h3 style="padding: 1rem">Замена подписанта {{ approver.user.ouser.full_name }}</h3>-->
<!--                          </div>-->
<!--                          <button v-if="dzo_current_approver !== ''" class="select_button text-center"-->
<!--                                  style="width: 200px; border-radius: .5rem; padding-right: 1rem; height: 100%; background: #23468c !important"-->
<!--                                  @click="changeDZOCurrentApprovers(approver.user.id)">-->
<!--                            Заменить-->
<!--                          </button>-->
<!--                        </div>-->
<!--                        <div class="modal-info"-->
<!--                             style="width: 100%; height: auto; display: flex; justify-content: flex-start; align-items: flex-start; flex-direction: column">-->
<!--                          <select class="select_button" :id="'select_approver_' + approver.user.id"-->
<!--                                  v-on:change="change_dzo_current_approver"-->
<!--                                  v-model="selected_dzo_current_approver">-->
<!--                            <option class="default_option" value="">Выберите нового согласующего</option>-->
<!--                            <option class="default_option" v-for="ouser in all_collegues" :key="ouser.id" :value="ouser"-->
<!--                                    :title="ouser.full_name.toUpperCase() + ' / ' + ouser.position.toUpperCase() + ' / ' + ouser.company.name.toUpperCase()">-->
<!--                              {{ ouser.full_name.toUpperCase() }} /-->
<!--                              {{ ouser.position_short.toUpperCase() }}-->
<!--                            </option>-->
<!--                          </select>-->
<!--                          <table class="table table-hover table-nowrap">-->
<!--                            <thead class="thead-light">-->
<!--                            <tr>-->
<!--                              <th>№</th>-->
<!--                              <th>ФИО</th>-->
<!--                              <th>Должность</th>-->
<!--                              <th>Тип согласования</th>-->
<!--                              <th>Ваш комментарий</th>-->
<!--                            </tr>-->
<!--                            </thead>-->
<!--                            <tbody>-->
<!--                            <tr v-if="dzo_current_approver !== ''" style="font-weight: bold">-->
<!--                              <td>{{ approver.serial_number }}</td>-->
<!--                              <td>-->
<!--                                {{ dzo_current_approver.fio }}-->
<!--                              </td>-->
<!--                              <td :title="dzo_current_approver.position">-->
<!--                                {{ dzo_current_approver.position_short }}-->
<!--                              </td>-->
<!--                              <td>-->
<!--                                <span v-if="approver.ratifier">Утверждающий</span>-->
<!--                                <span v-else>Согласующий</span>-->
<!--                              </td>-->
<!--                              <td style="padding: 0; height: 30px;">-->
<!--                                <input class="" style="width: 100% !important; height: 100% !important; padding: .5rem"-->
<!--                                       v-model="dzo_current_approver.comment">-->
<!--                              </td>-->
<!--                            </tr>-->
<!--                            </tbody>-->
<!--                          </table>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </modal>-->
<!--                  </div>-->
<!--                </td>-->
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="p-10" v-if="this.fast_filter === '4'">
        <div class="section-header">
          <h2>Дополнительные файлы</h2>
          <hr>
        </div>
        <div class="card">
          <div class="table-responsive">
            <table class="table table-hover table-nowrap">
              <thead class="thead-light">
              <tr>
                <th scope="col" class="">№ п/п</th>
                <th scope="col" class="">Наименование</th>
                <th scope="col" class="">Файл(-ы)</th>
                <th scope="col" class="">Тип файла</th>
                <th scope="col" class="">Расширения</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(add_file, index) in this.scontract.additional_files" :key="add_file.id"
                  :id="'req-tr-' + add_file.id" class="tr-main">
                <td data-label="" class="">
                  {{ index + 1 }}
                </td>
                <td data-label="" class="">
                  {{ add_file.name }}
                </td>
                <td data-label="" class="">
                  <a class="custom-link" target="_blank" :href="add_file.file">
                    {{ add_file.file }}
                  </a>
                </td>
                <td data-label="" class="">
                  Доп. файл
                </td>
                <td data-label="" class="">
                  {{ add_file.extension }}
                </td>
              </tr>
              </tbody>
            </table>
          </div>

        </div>
      </div>
      <div class="p-10" v-if="this.fast_filter === '5'">
        <div class="section-header">
          <h2>Реквизиты</h2>
          <hr>
          <div class="innernavbar-wrap p-10"
               style="padding-top: 0 !important; display: flex; justify-content: center; gap: 1rem">
            <li :class="'ul-li ' + fast_active_form_4(true)" @click="change_fast_form_4(true)">Реквизиты (каз)</li>
            <li :class="'ul-li ' + fast_active_form_4(false)" @click="change_fast_form_4(false)">Реквизиты (рус)</li>
          </div>
        </div>
        <div class="card p-10">
          <div style="width: 100%; display: flex; justify-content: space-between; gap: 2rem">
            <div class="event-left-part">
              <h4 style="margin-bottom: 10px" v-if="show_form_4">Орындаушы</h4>
              <h4 style="margin-bottom: 10px" v-else>Исполнитель</h4>
              <div class="div-item">
                <div class="descripcion">
                  <span v-if="show_form_4">Басқарма атауы</span>
                  <span v-else>Наименование организации</span>
                </div>
                <div class="precio">
                  <span v-if="show_form_4">{{ scontract.requisites_zak_name_kaz }}</span>
                  <span v-else>{{ scontract.requisites_zak_name }}</span>
                </div>
              </div>
              <div class="div-item">
                <div class="descripcion">
                  <span v-if="show_form_4">Мекен жайы</span>
                  <span v-else>Адрес</span>
                </div>
                <div class="precio">
                  <span v-if="show_form_4">{{ scontract.requisites_zak_address_kaz }}</span>
                  <span v-else>{{ scontract.requisites_zak_address }}</span>
                </div>
              </div>
              <div class="div-item">
                <div class="descripcion">
                  <span v-if="show_form_4">БСН</span>
                  <span v-else>БИН</span>
                </div>
                <div class="precio">{{ scontract.requisites_zak_bin }}</div>
              </div>
              <div class="div-item">
                <div class="descripcion">
                  <span v-if="show_form_4">БСК</span>
                  <span v-else>БИК</span>
                </div>
                <div class="precio">{{ scontract.requisites_zak_bik }}</div>
              </div>
              <div class="div-item">
                <div class="descripcion">
                  <span v-if="show_form_4">ЖСК</span>
                  <span v-else>ИИК</span>
                </div>
                <div class="precio">{{ scontract.requisites_zak_iik }}</div>
              </div>
              <div class="div-item">
                <div class="descripcion">
                  <span v-if="show_form_4">Кбе</span>
                  <span v-else>Кбе</span>
                </div>
                <div class="precio">{{ scontract.requisites_zak_kbe }}</div>
              </div>
              <div class="div-item">
                <div class="descripcion">
                  <span v-if="show_form_4">Банктің атауы</span>
                  <span v-else>Наименование банка</span>
                </div>
                <div class="precio">
                  <span v-if="show_form_4">{{ scontract.requisites_zak_bank_name_kaz }}</span>
                  <span v-else>{{ scontract.requisites_zak_bank_name }}</span>
                </div>
              </div>
              <div class="div-item">
                <div class="descripcion">Тел</div>
                <div class="precio">{{ scontract.requisites_zak_phone }}</div>
              </div>
              <div class="div-item">
                <div class="descripcion">
                  <span v-if="show_form_4">СТН</span>
                  <span v-else>РНН</span>
                </div>
                <div class="precio">{{ scontract.requisites_zak_rnn }}</div>
              </div>
            </div>
            <div class="event-left-part">
              <h4 style="margin-bottom: 10px" v-if="show_form_4">Тапсырысшы</h4>
              <h4 style="margin-bottom: 10px" v-else>Заказчик</h4>
              <div class="div-item">
                <div class="descripcion">
                  <span v-if="show_form_4">Басқарма атауы</span>
                  <span v-else>Наименование организации</span>
                </div>
                <div class="precio">
                  <span v-if="show_form_4">{{ scontract.requisites_impl_name_kaz }}</span>
                  <span v-else>{{ scontract.requisites_impl_name }}</span>
                </div>
              </div>
              <div class="div-item">
                <div class="descripcion">
                  <span v-if="show_form_4">Мекен жайы</span>
                  <span v-else>Адрес</span>
                </div>
                <div class="precio">
                  <span v-if="show_form_4">{{ scontract.requisites_impl_address_kaz }}</span>
                  <span v-else>{{ scontract.requisites_impl_address }}</span>
                </div>
              </div>
              <div class="div-item">
                <div class="descripcion">
                  <span v-if="show_form_4">БСН</span>
                  <span v-else>БИН</span>
                </div>
                <div class="precio">{{ scontract.requisites_impl_bin }}</div>
              </div>
              <div class="div-item">
                <div class="descripcion">
                  <span v-if="show_form_4">БСК</span>
                  <span v-else>БИК</span>
                </div>
                <div class="precio">{{ scontract.requisites_impl_bik }}</div>
              </div>
              <div class="div-item">
                <div class="descripcion">
                  <span v-if="show_form_4">ЖСК</span>
                  <span v-else>ИИК</span>
                </div>
                <div class="precio">{{ scontract.requisites_impl_iik }}</div>
              </div>
              <div class="div-item">
                <div class="descripcion">
                  <span v-if="show_form_4">Кбе</span>
                  <span v-else>Кбе</span>
                </div>
                <div class="precio">{{ scontract.requisites_impl_kbe }}</div>
              </div>
              <div class="div-item">
                <div class="descripcion">
                  <span v-if="show_form_4">Банктің атауы</span>
                  <span v-else>Наименование банка</span>
                </div>
                <div class="precio">
                  <span v-if="show_form_4">{{ scontract.requisites_impl_bank_name_kaz }}</span>
                  <span v-else>{{ scontract.requisites_impl_bank_name }}</span>
                </div>
              </div>
              <div class="div-item">
                <div class="descripcion">Тел</div>
                <div class="precio">{{ scontract.requisites_impl_phone }}</div>
              </div>
              <div class="div-item">
                <div class="descripcion">
                  <span v-if="show_form_4">Электрондық пошта</span>
                  <span v-else>Электронная почта</span>
                  </div>
                <div class="precio">{{ scontract.requisites_impl_email }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="right-section flex-column-animated" id="right-section"
             style="background: #e4e9f7 !important; overflow: hidden">
      <button class="section-btn" v-if="is_OWNER && scontract.status === 'Подписано, посредством ЭЦП' && !scontract.number_edited" v-on:click="showNumberEditModal"
              title="Назначить номер">
        <i class="material-icons section-btn-bx" style="background: #1da1f2;">dialpad</i>
      </button>
      <button class="section-btn" v-if="is_OWNER && scontract.status === 'На АБП (исполнитель)'" v-on:click="editSContract"
              title="Редактировать">
        <i class="material-icons section-btn-bx" style="background: #1da1f2;">edit</i>
      </button>
<!--      <button v-if="is_APPROVER && is_ECONOMIST && curr_APPR" class="section-btn"-->
<!--              title="Редактировать" @click="showEconomistEditModal">-->
<!--        <i class="bx bx-edit-alt section-btn-bx" style="background: #1da1f2;"></i>-->
<!--      </button>-->
<!--      <button v-if="is_IMPL && act.status === 'На подрядчике' &&-->
<!--                    is_OWNER && act.approvers.filter(i => i.is_impl).length > 0"-->
<!--              class="section-btn" title="Отправить на согласование" @click="sendToApproversImpl">-->
<!--        <i class="bx bx-arrow-to-right section-btn-bx" style="background: #0c8;"></i>-->
<!--      </button>-->

<!--      <button v-if="all_sap_entered_global && is_ABP && curr_ABP && act.status === 'На Администраторе Бюджетной Программы' &&-->
<!--                    act.approvers.filter(i => !i.is_impl).length > 1"-->
<!--              class="section-btn" title="Отправить на согласование" @click="sendToApproversImpl">-->
<!--        <i class="bx bx-arrow-to-right section-btn-bx" style="background: #0c8;"></i>-->
<!--      </button>-->
      <button v-if="is_IMPL && scontract.status === 'На АБП (заказчик)' && curr_ABPIMPL"
              class="section-btn" title="Назначить список подписантов" @click="showAddApproverModalImpl">
        <i class="material-icons section-btn-bx" style="background: #1da1f2;">groups</i>
      </button>
<!--      <button v-if="is_IMPL && act.status === 'На подрядчике' && is_OWNER &&-->
<!--                    act.approvers.filter(i => i.is_impl).length > 0" class="section-btn"-->
<!--              title="Назначить новый список подписантов" @click="showAddApproverModalImpl">-->
<!--        <i class="bx bx-group section-btn-bx" style="background: #1da1f2;"></i>-->
<!--      </button>-->
<!--      <button v-if="is_IMPL && act.status === 'На подрядчике' && is_OWNER" class="section-btn"-->
<!--              title="Удалить" @click="deleteAct">-->
<!--        <i class="bx bx-trash section-btn-bx" style="background: #f36;"></i>-->
<!--      </button>-->
      <button v-if="is_OWNER && scontract.status === 'На АБП (исполнитель)'"
              class="section-btn" title="Назначить список подписантов"
              @click="showAddApproverModalABP">
        <i class="material-icons section-btn-bx" style="background: #1da1f2;">groups</i>
      </button>
<!--      <button v-if="all_sap_entered_global && is_ABP && curr_ABP &&-->
<!--                    act.approvers.filter(i => !i.is_impl).length > 1"-->
<!--              class="section-btn" title="Назначить новый список подписантов"-->
<!--              @click="showAddApproverModalABP">-->
<!--        <i class="bx bx-group section-btn-bx" style="background: #1da1f2;"></i>-->
<!--      </button>-->
<!--      <button v-if="is_IMPL && is_OWNER &&-->
<!--                    act.status !== 'На подрядчике' &&-->
<!--                    act.status !== 'Подписано, посредством ЭЦП' &&-->
<!--                    act.status !== 'Подписано, без ЭЦП'"-->
<!--              class="section-btn" title="Отозвать" @click="bringBackAct">-->
<!--        <i class="bx bx-arrow-to-left section-btn-bx" style="background: #f36;"></i>-->
<!--      </button>-->
<!--      <button v-if="is_ABP && act.contract && act.contract.abp.id === curr_user.id &&-->
<!--                    act.status !== 'На подрядчике' &&-->
<!--                    act.status !== 'На подписании у подрядчика' &&-->
<!--                    act.status !== 'На Администраторе Бюджетной Программы' &&-->
<!--                    act.status !== 'Подписано, посредством ЭЦП' &&-->
<!--                    act.status !== 'Подписано, без ЭЦП'"-->
<!--              class="section-btn" title="Отозвать" @click="bringBackAct">-->
<!--        <i class="bx bx-arrow-to-left section-btn-bx" style="background: #f36;"></i>-->
<!--      </button>-->
      <button v-if="curr_APPR && curr_RAT" class="section-btn" title="Подписать посредством ЭЦП" @click="showSignModal">
        <i class="material-icons section-btn-bx" style="background: #0c8;">check</i>
      </button>
      <button v-if="curr_APPR && !curr_RAT && !curr_ABP && !curr_ABPIMPL" class="section-btn" title="Согласовать"
              @click="accept_reject('accept')">
        <i class="material-icons section-btn-bx" style="background: #0c8;">done</i>
      </button>
      <button v-if="curr_APPR && !curr_ABP" class="section-btn" title="Отклонить" @click="showRejectModal()">
        <i class="material-icons section-btn-bx" style="background: #f36;">block</i>
      </button>
<!--      <button v-if="!act.is_archived && act.status && act.status.includes('Подписано')" class="section-btn"-->
<!--              title="Переместить в архив" @click="addToArchive">-->
<!--        <i class="bx bx-archive-in section-btn-bx" style="background: #0259fc;"></i>-->
<!--      </button>-->
<!--      <button v-if="act.is_archived" class="section-btn"-->
<!--              title="Убрать из архива" @click="rmFromArchive">-->
<!--        <i class="bx bx-archive-out section-btn-bx" style="background: #f36;"></i>-->
<!--      </button>-->
<!--      <button class="section-btn" title="Скачать историю согласования" @click="downloadHistoryPDF">-->
<!--        <i class="bx bx-history section-btn-bx" style="background: #ff8c00;"></i>-->
<!--      </button>-->
<!--      <button class="section-btn" title="Скачать договор" @click="downloadSContractPDFNew">-->
<!--        <i class="material-icons section-btn-bx" style="background: #8c233f;">download</i>-->
<!--      </button>-->
      <button class="section-btn" title="Скачать договор" @click="downloadSContractPDF">
        <i class="material-icons section-btn-bx" style="background: #23468c;">download</i>
      </button>
      <modal name="number-edit-modal" :height="300" :width="600">
        <div class="modal-inner" style="height: auto !important; width: inherit; text-align: left; overflow: auto">
          <div style="width: 100%; display: flex; justify-content: space-between; padding: .5rem; height: 5rem;">
            <h3 class="modal-title" style="padding-bottom: 0;">Назначение номера договора</h3>
            <button
                class="transition-all ease-in-out duration-150 transform hover:scale-110 bg-green-500 hover:bg-green-800 px-5 py-1 rounded-lg text-white"
                v-if="new_number !== '' && new_number.toUpperCase() !== 'НЕИЗВЕСТНО'"
                @click="setSContractNumber">
              Сохранить
            </button>
          </div>
          <div class="modal-info"
               style="width: 100%; height: auto; display: flex; justify-content: center; align-items: center; flex-direction: column">
              <input v-model="new_number" placeholder="Введите номер договора" class="select_button">
          </div>
        </div>
      </modal>
      <modal name="add-approver-modal-impl" :height="700" :width="950">
        <div class="modal-inner" style="height: auto !important; width: inherit; text-align: left; overflow: auto">
          <div style="width: 100%; display: flex; justify-content: space-between; padding: .5rem; height: 5rem;">
            <h3 class="modal-title" style="padding-bottom: 0">СПИСОК ПОДПИСАНТОВ</h3>
            <button
                class="transition-all ease-in-out duration-150 transform hover:scale-110 bg-green-500 hover:bg-green-800 px-5 py-1 rounded-lg text-white"
                v-if="impl_ratificator !== ''"
                @click="setImplApprovers">
              Отправить
            </button>
          </div>
          <div class="modal-info"
               style="width: 100%; height: auto; display: flex; justify-content: center; align-items: center; flex-direction: column">
            <select class="select_button" id="select_approver" v-on:change="change_impl" v-model="selected_impl">
              <option class="default_option" value="">Выберите следующего согласующего</option>
              <option class="default_option" v-for="ouser in all_collegues" :key="ouser.id" :value="ouser"
                      :title="ouser.full_name.toUpperCase() + ' / ' + ouser.position.toUpperCase() + ' / ' + ouser.company.name.toUpperCase()">
                {{ ouser.full_name.toUpperCase() }} /
                {{ ouser.position_short.toUpperCase() }}
              </option>
            </select>
            <select class="select_button" id="select_ratifitator" v-on:change="change_rat" v-model="selected_rat">
              <option class="default_option" value="">Выберите утверждащего</option>
              <option class="default_option" v-for="ouser in all_collegues" :key="ouser.id" :value="ouser"
                      :title="ouser.full_name.toUpperCase() + ' / ' + ouser.position.toUpperCase() + ' / ' + ouser.company.name.toUpperCase()">
                {{ ouser.full_name.toUpperCase() }} /
                {{ ouser.position_short.toUpperCase() }}
              </option>
            </select>
            <select v-if="impl_approvers.length > 1" class="select_button" v-model="selected_approve_mode">
              <option class="default_option" value="Последовательное согласование">Последовательное согласование
              </option>
              <option class="default_option" value="Параллельное согласование">Параллельное согласование</option>
            </select>
            <!--            <div class="table-responsive">-->
            <table class="table table-hover table-nowrap">
              <thead class="thead-light">
              <tr>
                <th>№</th>
                <th>ФИО</th>
                <th>Должность</th>
                <th>Огранизация</th>
                <th>Тип согласования</th>
                <th>Ваш комментарий</th>
                <th>Действия</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(impl, index) in impl_approvers" :key="index">
                <td>{{ index + 1 }}</td>
                <td>
                  {{ impl.fio }}
                </td>
                <td :title="impl.position">
                  {{ impl.position_short }}
                </td>
                <td>
                  {{ impl.company_name }}
                </td>
                <td>
                  {{ impl.type }}
                </td>
                <td style="padding: 0; height: 30px;">
                  <input class="" style="width: 100% !important; height: 100% !important; padding: .5rem"
                         v-model="impl.comment">
                </td>
                <td>
                  <button class="btn-danger" style="border-radius: .5rem" @click="rm_approver(index)">Исключить</button>
                </td>
              </tr>
              <tr v-if="impl_ratificator !== ''" style="font-weight: bold">
                <td>{{ impl_approvers.length + 1 }}</td>
                <td>
                  {{ impl_ratificator.fio }}
                </td>
                <td :title="impl_ratificator.position">
                  {{ impl_ratificator.position_short }}
                </td>
                <td>
                  {{ impl_ratificator.company_name }}
                </td>
                <td>
                  {{ impl_ratificator.type }}
                </td>
                <td style="padding: 0; height: 30px;">
                  <input style="width: 100% !important; height: 100% !important; padding: .5rem"
                         v-model="impl_ratificator.comment">
                </td>
                <td>
                  <button class="btn-danger" style="border-radius: .5rem" @click="rm_ratificator">Исключить</button>
                </td>
              </tr>
              </tbody>
            </table>
            <div style="display: flex; justify-content: space-between; gap: 1rem; width: 100%; height: 300px">
              <div style="width: 50%">
                <h5 class="p-5" style="padding-bottom: 0 !important; margin-bottom: 0!important;">Тапсырыс беруші туралы мәліметтерді нақтылау</h5>
                <div class="p-5" style="width: 100%; height: 90px; padding-top: 0!important; margin-bottom: 1rem !important;">
                  <textarea v-model="req_dop_impl_kaz" style="border: 1px gray solid; font-size: small; border-radius: 0.5rem; width: 100%; height: 80px; padding: 0.5rem"></textarea>
                  <p>
                    бір жағынан ...
                  </p>
                  <br>
                  <br>
                  <input v-model="req_position_impl_kaz" style="border: 1px gray solid; font-size: small; border-radius: 0.5rem; width: 100%; height: 40px; padding: 0.5rem" placeholder="Бекітушінің қызмет атауын енгізіңіз (қаз)">
                </div>
              </div>
              <div style="width: 50%">
                <h5 class="p-5" style="padding-bottom: 0 !important; margin-bottom: 0!important;">Уточнение реквизитов заказчика</h5>
                <div class="p-5" style="width: 100%; height: 90px; padding-top: 0!important; margin-bottom: 1rem !important;">
                  <textarea v-model="req_dop_impl" placeholder="Товарищество с ограниченной ответственностью ..., именуемое в дальнейшем «Заказчик» в лице ...,  действующего на основании доверенности ..." style="border: 1px gray solid; font-size: small; border-radius: 0.5rem; width: 100%; height: 80px; padding: 0.5rem"></textarea>
                  <p>
                    с одной стороны ...
                  </p>
                  <br>
                  <br>
                  <input v-model="req_position_impl" style="border: 1px gray solid; font-size: small; border-radius: 0.5rem; width: 100%; height: 40px; padding: 0.5rem" placeholder="Введите должность утверждающего (рус)">
                </div>
                <br>
              </div>
            </div>
            <!--            </div>-->
          </div>
        </div>
      </modal>
<!--      <modal name="economist-edit-modal" :height="700" :width="1500">-->
<!--        <div class="modal-inner" style="height: inherit; width: inherit; text-align: left; overflow: auto">-->
<!--          <div style="display: flex; justify-content: space-between; padding: .5rem; height: 5rem;">-->
<!--            <h3 class="modal-title" style="padding-bottom: 0">Редактирование данных акта</h3>-->
<!--            <button class="select_button"-->
<!--                    style="width: 200px; border-radius: .5rem; background: #23468c !important" @click="set_changes_eco">-->
<!--              Внести изменения-->
<!--            </button>-->
<!--          </div>-->
<!--          <div class="modal-info"-->
<!--               style="width: 100%; height: 80%;">-->
<!--            <table class="m-table-act" v-if="act.type === 'Стандартный (458-562)'">-->
<!--              <thead class="">-->
<!--              <tr>-->
<!--                <th rowspan="2" style="width: 100px">Номер по порядку</th>-->
<!--                <th rowspan="2" style="width: 100px">Код затрат</th>-->
<!--                <th rowspan="2" style="width: 100px">Код ВЗ, МВЗ</th>-->
<!--                <th rowspan="2" style="width: 100px">Номер заказа</th>-->
<!--                <th rowspan="2" style="width: 300px;">Наименование ВЗ, МВЗ</th>-->
<!--                <th rowspan="2" style="width: 300px">Наименование работ (услуг) (в разрезе их подвидов в соответствии с-->
<!--                  технической спецификацией, заданием, графиком выполнения работ (услуг) при их наличии)-->
<!--                </th>-->
<!--                <th rowspan="2">Единица измерения</th>-->
<!--                <th colspan="3" rowspan="1">Выполнено работ (оказано услуг)</th>-->
<!--              </tr>-->
<!--              <tr>-->
<!--                <th>Количество</th>-->
<!--                <th>Цена за единицу</th>-->
<!--                <th>Стоимость (в тенге)</th>-->
<!--              </tr>-->
<!--              <tr>-->
<!--                <td v-for="i in [1,2,3,4,5,6,7,8,9,10]" :key="i">{{ i }}</td>-->
<!--              </tr>-->
<!--              </thead>-->
<!--              <tbody>-->
<!--              <tr style="height: 40px; background: white" v-for="row in this.act.rows" :key="row.id">-->
<!--                <td data-label="">{{ row.serial_number }}</td>-->
<!--                <td data-label="">-->
<!--                  <input v-model="row.new_cost_code" class="m-input-cell">-->
<!--                </td>-->
<!--                <td data-label="">-->
<!--                  <input v-model="row.new_mvz_code" class="m-input-cell">-->
<!--                </td>-->
<!--                <td data-label="">-->
<!--                  <input v-model="row.new_tora_zakaz" class="m-input-cell">-->
<!--                </td>-->
<!--                <td data-label="">{{ row.mvz_name }}</td>-->
<!--                <td data-label="">{{ row.work_name }}</td>-->
<!--                <td data-label="">{{ row.measure_unit }}</td>-->
<!--                <td data-label="">{{ prettyPrice(row.count) }}</td>-->
<!--                <td data-label="">{{ prettyPrice(row.unit_price) }}</td>-->
<!--                <td data-label="">{{ prettyPrice(row.total) }}</td>-->
<!--              </tr>-->
<!--              </tbody>-->
<!--            </table>-->
<!--            <table class="m-table-act" v-else-if="act.type === 'ДБиКРС'">-->
<!--              <thead class="">-->
<!--              <tr>-->
<!--                <th rowspan="3" style="width: 100px">Номер по порядку</th>-->
<!--                <th rowspan="3" style="width: 100px">Код затрат</th>-->
<!--                <th rowspan="3" style="width: 100px">Код ВЗ, МВЗ</th>-->
<!--                <th rowspan="3" style="width: 100px">Номер заказа</th>-->
<!--                <th rowspan="3" style="width: 300px;">Наименование ВЗ, МВЗ</th>-->
<!--                <th rowspan="3" style="width: 300px">Наименование работ (услуг) (в разрезе их подвидов в соответствии с-->
<!--                  технической спецификацией, заданием, графиком выполнения работ (услуг) при их наличии)-->
<!--                </th>-->
<!--                <th rowspan="3">Единица измерения</th>-->
<!--                <th colspan="5" rowspan="1">Выполнено работ (оказано услуг)</th>-->
<!--              </tr>-->
<!--              <tr>-->
<!--                <th rowspan="2">количество</th>-->
<!--                <th rowspan="2">цена за единицу</th>-->
<!--                <th rowspan="1" colspan="2">снижение стоимости</th>-->
<!--                <th rowspan="2">стоимость</th>-->
<!--              </tr>-->
<!--              <tr>-->
<!--                <th>основание*</th>-->
<!--                <th>сумма снижения, тенге</th>-->
<!--              </tr>-->
<!--              <tr>-->
<!--                <td v-for="i in [1,2,3,4,5,6,7,8,9,10,11,12]" :key="i">{{ i }}</td>-->
<!--              </tr>-->
<!--              </thead>-->
<!--              <tbody>-->
<!--              <tr style="height: 40px; background: white" v-for="row in this.act.rows" :key="row.id">-->
<!--                <td data-label="">{{ row.serial_number }}</td>-->
<!--                <td data-label="">-->
<!--                  <input v-model="row.new_cost_code" class="m-input-cell">-->
<!--                </td>-->
<!--                <td data-label="">-->
<!--                  <input v-model="row.new_mvz_code" class="m-input-cell">-->
<!--                </td>-->
<!--                <td data-label="">-->
<!--                  <input v-model="row.new_tora_zakaz" class="m-input-cell">-->
<!--                </td>-->
<!--                <td data-label="">{{ row.mvz_name }}</td>-->
<!--                <td data-label="">{{ row.work_name }}</td>-->
<!--                <td data-label="">{{ row.measure_unit }}</td>-->
<!--                <td data-label="">{{ prettyPrice(row.count) }}</td>-->
<!--                <td data-label="">{{ prettyPrice(row.unit_price) }}</td>-->
<!--                <td data-label="">{{ row.reason }}</td>-->
<!--                <td data-label="">{{ prettyPrice(row.reason_cost) }}</td>-->
<!--                <td data-label="">{{ prettyPrice(row.total) }}</td>-->
<!--              </tr>-->
<!--              </tbody>-->
<!--            </table>-->
<!--            <table class="m-table-act" v-else-if="act.type === 'ДКС'">-->
<!--              <thead>-->
<!--              <tr style="height: 40px">-->
<!--                <th style="width: 3%">№п/п</th>-->
<!--                <th style="width: 8%">Код затрат</th>-->
<!--                <th style="width: 11%">Месторождение № скважин</th>-->
<!--                <th style="width: 9%">Код ресурса</th>-->
<!--                <th style="width: 9%">СПП, ВЗ, МВЗ</th>-->
<!--                <th style="width: 19%">Наименование работ</th>-->
<!--                <th style="width: 5%">Ед.изм.</th>-->
<!--                <th style="width: 6.5%">Кол-во</th>-->
<!--                <th style="width: 8%">Цена, тенге</th>-->
<!--                <th style="width: 10%">Сумма, тенге</th>-->
<!--              </tr>-->
<!--              <tr>-->
<!--                <td v-for="i in [1,2,3,4,5,6,7,8,9,10]" :key="i">{{ i }}</td>-->
<!--              </tr>-->
<!--              </thead>-->
<!--              <tbody>-->
<!--              <tr style="height: 40px; background: white" v-for="row in this.act.rows" :key="row.id">-->
<!--                <td data-label="">{{ row.serial_number }}</td>-->
<!--                <td data-label="">-->
<!--                  <input v-model="row.new_cost_code" class="m-input-cell">-->
<!--                </td>-->
<!--                <td data-label="">{{ row.mvz_name }}</td>-->
<!--                <td data-label="">-->
<!--                  <input v-model="row.new_source_code" class="m-input-cell">-->
<!--                </td>-->
<!--                <td data-label="">-->
<!--                  <input v-model="row.new_mvz_code" class="m-input-cell">-->
<!--                </td>-->
<!--                <td data-label="">{{ row.work_name }}</td>-->
<!--                <td data-label="">{{ row.measure_unit }}</td>-->
<!--                <td data-label="">{{ prettyPrice(row.count) }}</td>-->
<!--                <td data-label="">{{ prettyPrice(row.unit_price) }}</td>-->
<!--                <td data-label="">{{ prettyPrice(row.total) }}</td>-->
<!--              </tr>-->
<!--              </tbody>-->
<!--            </table>-->
<!--          </div>-->
<!--        </div>-->
<!--      </modal>-->
<!--      <modal name="abp-sap-modal" v-if="is_ABP && curr_ABP && act.status === 'На Администраторе Бюджетной Программы'"-->
<!--             :height="700" :width="1300">-->
<!--        <div class="modal-inner" style="height: inherit; width: inherit; text-align: left; overflow: auto">-->
<!--          <div style="display: flex; justify-content: space-between; padding: .5rem; height: 5rem;">-->
<!--            <h3 class="modal-title" style="padding-bottom: 0">Назначение заказов SAP</h3>-->
<!--            <button class="select_button" v-if="all_sap_entered"-->
<!--                    style="width: 200px; border-radius: .5rem; background: #23468c !important"-->
<!--                    @click="set_changes_sap_abp">-->
<!--              Назначить-->
<!--            </button>-->
<!--          </div>-->
<!--          <div class="modal-info"-->
<!--               style="width: 100%; height: 80%;">-->
<!--            <table class="m-table-act">-->
<!--              <thead class="">-->
<!--              <tr>-->
<!--                <th style="width: 100px">Номер по порядку</th>-->
<!--                <th style="width: 300px">Наименование работ (услуг)</th>-->
<!--                <th>Заказ</th>-->
<!--                <th>Позиция</th>-->
<!--                <th>Услуга</th>-->
<!--                <th style="width: 140px;">Действие</th>-->
<!--              </tr>-->
<!--              <tr>-->
<!--                <td v-for="i in [1,2,3,4,5]" :key="i">{{ i }}</td>-->
<!--              </tr>-->
<!--              </thead>-->
<!--              <tbody>-->
<!--              <tr style="height: 40px; background: white" v-for="row in this.act.rows" :key="row.id">-->
<!--                <td data-label="">{{ row.serial_number }}</td>-->
<!--                <td data-label="">{{ row.work_name }}</td>-->

<!--                <td data-label="">-->
<!--                  <select :name="'select_zakaz_' + row.serial_number"-->
<!--                          :id="'select_zakaz_' + row.serial_number"-->
<!--                          class="m-input-cell-create zakaz_class"-->
<!--                          v-model="row.new_sap_zakaz"-->
<!--                          style="width: 100%; height: 40px; text-align-last: center;">-->
<!--                    <option value data-isdefault="true">Выберите заказ</option>-->
<!--                    <option v-for="(zakaz_value, zakaz_id) in zakaz_dict" :key="zakaz_id" :value="zakaz_id"-->
<!--                            style="text-align-last: center;" class="m-input-cell-create">{{ zakaz_id }}-->
<!--                    </option>-->
<!--                  </select>-->
<!--                </td>-->

<!--                <td data-label="">-->
<!--                  <select-->
<!--                      v-if="row.new_sap_zakaz && row.new_sap_zakaz !== '' && zakaz_dict.hasOwnProperty(row.new_sap_zakaz)"-->
<!--                      :name="'select_pos_' + row.serial_number"-->
<!--                      :id="'select_pos_' + row.serial_number"-->
<!--                      class="m-input-cell-create pos_class"-->
<!--                      style="width: 100%; height: 40px; text-align-last: center;"-->
<!--                      v-model="row.new_sap_position">-->
<!--                    <option value data-isdefault="true">Выберите позицию</option>-->
<!--                    <option v-for="(position_value, position_id) in zakaz_dict[row.new_sap_zakaz]" :key="position_id"-->
<!--                            :value="position_id" style="text-align-last: center;" class="m-input-cell-create">-->
<!--                      {{ position_id }}-->
<!--                    </option>-->
<!--                  </select>-->
<!--                </td>-->

<!--                <td data-label="">-->
<!--                  <select-->
<!--                      v-if="row.new_sap_position && row.new_sap_position !== '' && zakaz_dict.hasOwnProperty(row.new_sap_zakaz) && zakaz_dict[row.new_sap_zakaz].hasOwnProperty(row.new_sap_position)"-->
<!--                      :name="'select_service_' + row.serial_number"-->
<!--                      :id="'select_service_' + row.serial_number"-->
<!--                      class="m-input-cell-create service_class"-->
<!--                      v-model="row.new_sap_service"-->
<!--                      style="width: 100%; height: 40px; text-align-last: center;">-->
<!--                    <option value data-isdefault="true">Выберите услугу</option>-->
<!--                    <option v-for="(service_value, service_id) in zakaz_dict[row.new_sap_zakaz][row.new_sap_position]"-->
<!--                            :key="service_id" :value="service_value[0]" style="text-align-last: center;"-->
<!--                            class="m-input-cell-create">{{ service_value[0] }} : {{ service_value[1] }}-->
<!--                    </option>-->

<!--                  </select>-->
<!--                </td>-->
<!--                <td data-label="" style="width: 140px;">-->
<!--                  <button class="text-center"-->
<!--                          v-if="row.new_sap_service !== '' && row.new_sap_position !== '' && row.new_sap_zakaz !== ''"-->
<!--                          @click="copy_to_all_next(row)"-->
<!--                          style="border-radius: .5rem; background: #23468c !important; color: white; padding: 0.5rem 1rem 0.5rem 1rem">-->
<!--                    Дублировать вниз-->
<!--                  </button>-->
<!--                </td>-->
<!--              </tr>-->
<!--              </tbody>-->
<!--            </table>-->
<!--          </div>-->
<!--        </div>-->
<!--      </modal>-->
<!--      <modal name="booker-sap-modal"-->
<!--             v-if='act.status && act.status.includes("Подписано") && is_BOOKER && act.acc_id_list && !act.acc_id_list.includes(";")'-->
<!--             :height="700"-->
<!--             :width="1300">-->
<!--        <div class="modal-inner" style="height: inherit; width: inherit; text-align: left; overflow: auto">-->
<!--          <div style="display: flex; justify-content: space-between; padding: .5rem; height: 5rem;">-->
<!--            <h3 class="modal-title" style="padding-bottom: 0">Создание ведомости ML81n</h3>-->
<!--            <button class="select_button" v-if="all_sap_entered_booker"-->
<!--                    style="width: 200px; border-radius: .5rem; background: #23468c !important"-->
<!--                    @click="set_changes_sap_booker">-->
<!--              Создать-->
<!--            </button>-->
<!--          </div>-->
<!--          <div class="modal-info"-->
<!--               style="width: 100%; height: 80%;">-->
<!--            <div style="display: flex; justify-content: center">-->
<!--              <div>-->
<!--                Дата проводки:-->
<!--                <input v-if="!act.act_date" name="real_date" type="date" :value="act.created_datetime_short"-->
<!--                       :id="'real_date'">-->
<!--                <input v-else name="real_date" type="date" :value="act.act_date" :id="'real_date'">-->
<!--              </div>-->
<!--            </div>-->
<!--            <table class="m-table-act">-->
<!--              <thead class="">-->
<!--              <tr>-->
<!--                <th rowspan="2" style="width: 100px">Номер по порядку</th>-->
<!--                <th rowspan="2" style="width: 300px">Наименование работ (услуг)</th>-->
<!--                <th rowspan="2">Заказ</th>-->
<!--                <th rowspan="2">Позиция</th>-->
<!--                <th rowspan="2">Услуга</th>-->
<!--                <th colspan="2">Заполняется только одно</th>-->
<!--                <th rowspan="2">Кол-во</th>-->
<!--                <th rowspan="2">Ед. изм.</th>-->
<!--                <th rowspan="2">Цена без НДС</th>-->
<!--              </tr>-->
<!--              <tr>-->
<!--                <th>Код ВЗ, МВЗ</th>-->
<!--                <th>Номер заказа</th>-->
<!--              </tr>-->
<!--              <tr>-->
<!--                <td v-for="i in [1,2,3,4,5,6,7,8,9,10]" :key="i">{{ i }}</td>-->
<!--              </tr>-->
<!--              </thead>-->
<!--              <tbody>-->
<!--              <tr style="height: 40px; background: white" v-for="row in this.act.rows" :key="row.id">-->
<!--                <td data-label="">{{ row.serial_number }}</td>-->
<!--                <td data-label="">{{ row.work_name }}</td>-->

<!--                <td data-label="">-->
<!--                  <select :name="'select_zakaz_' + row.serial_number"-->
<!--                          :id="'select_zakaz_' + row.serial_number"-->
<!--                          class="m-input-cell-create zakaz_class"-->
<!--                          v-model="row.new_sap_zakaz"-->
<!--                          style="width: 100%; height: 40px; text-align-last: center;">-->
<!--                    <option value data-isdefault="true">Выберите заказ</option>-->
<!--                    <option v-for="(zakaz_value, zakaz_id) in zakaz_dict" :key="zakaz_id" :value="zakaz_id"-->
<!--                            style="text-align-last: center;" class="m-input-cell-create">{{ zakaz_id }}-->
<!--                    </option>-->
<!--                  </select>-->
<!--                </td>-->

<!--                <td data-label="">-->
<!--                  <select-->
<!--                      v-if="row.new_sap_zakaz && row.new_sap_zakaz !== '' && zakaz_dict.hasOwnProperty(row.new_sap_zakaz)"-->
<!--                      :name="'select_pos_' + row.serial_number"-->
<!--                      :id="'select_pos_' + row.serial_number"-->
<!--                      class="m-input-cell-create pos_class"-->
<!--                      style="width: 100%; height: 40px; text-align-last: center;"-->
<!--                      v-model="row.new_sap_position">-->
<!--                    <option value data-isdefault="true">Выберите позицию</option>-->
<!--                    <option v-for="(position_value, position_id) in zakaz_dict[row.new_sap_zakaz]" :key="position_id"-->
<!--                            :value="position_id" style="text-align-last: center;" class="m-input-cell-create">-->
<!--                      {{ position_id }}-->
<!--                    </option>-->
<!--                  </select>-->
<!--                </td>-->

<!--                <td data-label="">-->
<!--                  <select-->
<!--                      v-if="row.new_sap_position && row.new_sap_position !== '' && zakaz_dict.hasOwnProperty(row.new_sap_zakaz) && zakaz_dict[row.new_sap_zakaz].hasOwnProperty(row.new_sap_position)"-->
<!--                      :name="'select_service_' + row.serial_number"-->
<!--                      :id="'select_service_' + row.serial_number"-->
<!--                      class="m-input-cell-create service_class"-->
<!--                      v-model="row.new_sap_service"-->
<!--                      style="width: 100%; height: 40px; text-align-last: center;">-->
<!--                    <option value data-isdefault="true">Выберите услугу</option>-->
<!--                    <option v-for="(service_value, service_id) in zakaz_dict[row.new_sap_zakaz][row.new_sap_position]"-->
<!--                            :key="service_id" :value="service_value[0]" style="text-align-last: center;"-->
<!--                            class="m-input-cell-create">{{ service_value[0] }} : {{ service_value[1] }}-->
<!--                    </option>-->

<!--                  </select>-->
<!--                </td>-->

<!--                <td data-label="">-->
<!--                  <input v-if="row.sap_is_mvz"-->
<!--                         :name="'mvz_code_' + row.serial_number"-->
<!--                         :id="'mvz_code_' + row.serial_number"-->
<!--                         class="m-input-cell-create placeholder-disabled"-->
<!--                         style="width: 100%; height: 40px; text-align-last: center;"-->
<!--                         :value="row.mvz_code"-->
<!--                         v-on:click="mvzActivate('mvz_code_' + row.serial_number, row)">-->
<!--                  <input v-else-->
<!--                         :name="'mvz_code_' + row.serial_number"-->
<!--                         :id="'mvz_code_' + row.serial_number"-->
<!--                         class="m-input-cell-create placeholder-disabled"-->
<!--                         style="width: 100%; height: 40px; text-align-last: center;"-->
<!--                         v-on:click="mvzActivate('mvz_code_' + row.serial_number, row)">-->
<!--                </td>-->
<!--                <td data-label="">-->
<!--                  <input v-if="row.sap_is_mvz"-->
<!--                         :name="'zak_code_' + row.serial_number"-->
<!--                         :id="'zak_code_' + row.serial_number"-->
<!--                         class="m-input-cell-create placeholder-disabled"-->
<!--                         style="width: 100%; height: 40px; text-align-last: center;"-->
<!--                         v-on:click="zakActivate('zak_code_' + row.serial_number, row)">-->
<!--                  <input v-else-->
<!--                         :name="'zak_code_' + row.serial_number"-->
<!--                         :id="'zak_code_' + row.serial_number"-->
<!--                         class="m-input-cell-create placeholder-disabled"-->
<!--                         style="width: 100%; height: 40px; text-align-last: center;"-->
<!--                         :value="row.new_tora_zakaz"-->
<!--                         v-on:click="zakActivate('zak_code_' + row.serial_number, row)">-->
<!--                </td>-->
<!--                <td data-label="">{{ row.count }}</td>-->
<!--                <td data-label="">{{ row.measure_unit }}</td>-->
<!--                <td data-label="">{{ row.unit_price }}</td>-->
<!--              </tr>-->
<!--              </tbody>-->
<!--            </table>-->
<!--          </div>-->
<!--        </div>-->
<!--      </modal>-->
      <modal name="add-approver-modal-abp" :height="700" :width="950">
        <div class="modal-inner" style="height: auto !important; width: inherit; text-align: left; overflow: auto">
          <div style="width: 100%; display: flex; justify-content: space-between; padding: .5rem; height: 5rem;">
            <h3 class="modal-title" style="padding-bottom: 0">СПИСОК ПОДПИСАНТОВ</h3>
            <button
                class="transition-all ease-in-out duration-150 transform hover:scale-110 bg-green-500 hover:bg-green-800 px-5 py-1 rounded-lg text-white"
                v-if="dzo_ratificator !== ''"
                @click.once="setDZOApprovers">
              Отправить
            </button>
          </div>
          <div class="modal-info"
               style="width: 100%; height: 500px; overflow-y: auto; display: flex; justify-content: flex-start; align-items: flex-start; flex-direction: column">
            <input class="select_button" list="all_collegues_list" placeholder="Начинайте вводить фамилию согласующего" v-model="selected_dzo_approver_fullname_upper" v-on:change="change_approver">
            <datalist id="all_collegues_list">
              <option class="default_option" value="">Выберите следующего согласующего</option>
              <option class="default_option" v-for="ouser in all_collegues" :key="ouser.id" :value="ouser.full_name.toUpperCase()"
                      :title="ouser.full_name.toUpperCase() + ' / ' + ouser.position.toUpperCase() + ' / ' + ouser.company.name.toUpperCase()">
<!--                {{ ouser.full_name.toUpperCase() }} /-->
<!--                {{ ouser.position_short.toUpperCase() }}-->
              </option>
            </datalist>
<!--            <select class="select_button" id="select_approver" v-on:change="change_approver"-->
<!--                    v-model="selected_dzo_approver">-->
<!--              <option class="default_option" value="">Выберите следующего согласующего</option>-->
<!--              <option class="default_option" v-for="ouser in all_collegues" :key="ouser.id" :value="ouser"-->
<!--                      :title="ouser.full_name.toUpperCase() + ' / ' + ouser.position.toUpperCase() + ' / ' + ouser.company.name.toUpperCase()">-->
<!--                {{ ouser.full_name.toUpperCase() }} /-->
<!--                {{ ouser.position_short.toUpperCase() }}-->
<!--              </option>-->
<!--            </select>-->
            <input class="select_button" list="all_collegues_list_rat" placeholder="Начинайте вводить фамилию утверждащего" v-model="selected_dzo_rat_fullname_upper" v-on:change="change_dzo_rat">
            <datalist id="all_collegues_list_rat">
              <option class="default_option" value="">Выберите утверждащего</option>
              <option class="default_option" v-for="ouser in all_collegues" :key="ouser.id" :value="ouser.full_name.toUpperCase()"
                      :title="ouser.full_name.toUpperCase() + ' / ' + ouser.position.toUpperCase() + ' / ' + ouser.company.name.toUpperCase()">
                <!--                {{ ouser.full_name.toUpperCase() }} /-->
                <!--                {{ ouser.position_short.toUpperCase() }}-->
              </option>
            </datalist>


<!--            <select class="select_button" id="select_ratifitator" v-on:change="change_dzo_rat"-->
<!--                    v-model="selected_dzo_rat">-->
<!--              <option class="default_option" value="">Выберите утверждащего</option>-->
<!--              <option class="default_option" v-for="ouser in all_collegues" :key="ouser.id" :value="ouser"-->
<!--                      :title="ouser.full_name.toUpperCase() + ' / ' + ouser.position.toUpperCase() + ' / ' + ouser.company.name.toUpperCase()">-->
<!--                {{ ouser.full_name.toUpperCase() }} /-->
<!--                {{ ouser.position_short.toUpperCase() }}-->
<!--              </option>-->
<!--            </select>-->

<!--            <input class="input-field" placeholder="Введите полную должность утверждающего (рус)">-->
<!--            <input class="input-field" placeholder="Введите полную должность утверждающего ">-->
            <select v-if="dzo_approvers.length > 1" class="select_button" v-model="selected_dzo_approve_mode">
              <option class="default_option" value="Последовательное согласование">Последовательное согласование
              </option>
              <option class="default_option" value="Параллельное согласование">Параллельное согласование</option>
            </select>
            <table class="table table-hover table-nowrap">
              <thead class="thead-light">
              <tr>
                <th>№</th>
                <th>ФИО</th>
                <th>Должность</th>
                <th>Тип согласования</th>
                <th>Ваш комментарий</th>
                <th>Действия</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(dzo_appr, index) in dzo_approvers" :key="index">
                <td>{{ index + 1 }}</td>
                <td>
                  {{ dzo_appr.fio }}
                </td>
                <td :title="dzo_appr.position">
                  {{ dzo_appr.position_short }}
                </td>
                <td>
                  {{ dzo_appr.type }}
                </td>
                <td style="padding: 0; height: 30px;">
                  <input class="" style="width: 100% !important; height: 100% !important; padding: .5rem"
                         v-model="dzo_appr.comment">
                </td>
                <td>
                  <button class="btn-danger" style="border-radius: .5rem" @click="rm_dzo_approver(index)">Исключить</button>
                </td>
              </tr>
              <tr v-if="dzo_ratificator !== ''" style="font-weight: bold">
                <td>{{ dzo_approvers.length + 1 }}</td>
                <td>
                  {{ dzo_ratificator.fio }}
                </td>
                <td :title="dzo_ratificator.position">
                  {{ dzo_ratificator.position_short }}
                </td>
                <td>
                  {{ dzo_ratificator.type }}
                </td>
                <td style="padding: 0; height: 30px;">
                  <input class="" style="width: 100% !important; height: 100% !important; padding: .5rem"
                         v-model="dzo_ratificator.comment">
                </td>
                <td>
                  <button class="btn-danger" style="border-radius: .5rem" @click="rm_dzo_ratificator">Исключить</button>
                </td>
              </tr>
              </tbody>
            </table>
            <div style="display: flex; justify-content: space-between; gap: 1rem">
              <div>
                <h5 class="p-5" style="padding-bottom: 0 !important; margin-bottom: 0!important;">Орындаушы туралы мәліметтерді нақтылау</h5>
                <p class="p-5">
                  ... бұдан әрі «Орындаушы» деп аталатын «Ембімұнайгаз» акционерлік қоғамы атынан
                </p>
                <div class="p-5" style="width: 100%; height: 90px; padding-top: 0!important; margin-bottom: 1rem !important;">
                  <textarea v-model="req_dop_kaz" style="border: 1px gray solid; font-size: small; border-radius: 0.5rem; width: 100%; height: 80px; padding: 0.5rem"></textarea>
                  <br>
                  <br>
                  <input v-model="req_position_kaz" style="border: 1px gray solid; font-size: small; border-radius: 0.5rem; width: 100%; height: 40px; padding: 0.5rem" placeholder="Бекітушінің қызмет атауын енгізіңіз (қаз)">
                </div>
              </div>
              <div>
                <h5 class="p-5" style="padding-bottom: 0 !important; margin-bottom: 0!important;">Уточнение реквизитов исполнителя</h5>
                <p class="p-5">
                  ... акционерное общество «Эмбамунайгаз», именуемое в дальнейшем «Исполнитель», в лице
                </p>
                <div class="p-5" style="width: 100%; height: 90px; padding-top: 0!important; margin-bottom: 1rem !important;">
                  <textarea v-model="req_dop" style="border: 1px gray solid; font-size: small; border-radius: 0.5rem; width: 100%; height: 80px; padding: 0.5rem"></textarea>
                  <br>
                  <br>
                  <input v-model="req_position" style="border: 1px gray solid; font-size: small; border-radius: 0.5rem; width: 100%; height: 40px; padding: 0.5rem" placeholder="Введите должность утверждающего (рус)">
                </div>
                <br>
              </div>
            </div>
            <br>
            <br>
            <div style="display: flex; justify-content: space-between; gap: 1rem; width: 100%; height: 300px">
              <div style="width: 50%">
                <hr>
                <h5 class="p-5" style="padding-bottom: 0 !important; margin-bottom: 0!important;">Тапсырыс беруші туралы мәліметтерді нақтылау</h5>
                <br>
                <div class="p-5" style="width: 100%; height: 90px; padding-top: 0!important; margin-bottom: 1rem !important;">
                  <textarea v-model="req_dop_impl_kaz" style="border: 1px gray solid; font-size: small; border-radius: 0.5rem; width: 100%; height: 80px; padding: 0.5rem"></textarea>
                  <p>
                    бір жағынан ...
                  </p>
                  <br>
                  <br>
                  <input v-model="req_position_impl_kaz" style="border: 1px gray solid; font-size: small; border-radius: 0.5rem; width: 100%; height: 40px; padding: 0.5rem" placeholder="Бекітушінің қызмет атауын енгізіңіз (қаз)">
                </div>
              </div>
              <div style="width: 50%">
                <hr>
                <h5 class="p-5" style="padding-bottom: 0 !important; margin-bottom: 0!important;">Уточнение реквизитов заказчика</h5>
                <br>
                <div class="p-5" style="width: 100%; height: 90px; padding-top: 0!important; margin-bottom: 1rem !important;">
                  <textarea v-model="req_dop_impl" placeholder="Товарищество с ограниченной ответственностью ..., именуемое в дальнейшем «Заказчик» в лице ...,  действующего на основании доверенности ..." style="border: 1px gray solid; font-size: small; border-radius: 0.5rem; width: 100%; height: 80px; padding: 0.5rem"></textarea>
                  <p>
                    с одной стороны ...
                  </p>
                  <br>
                  <br>
                  <input v-model="req_position_impl" style="border: 1px gray solid; font-size: small; border-radius: 0.5rem; width: 100%; height: 40px; padding: 0.5rem" placeholder="Введите должность утверждающего (рус)">
                </div>
                <br>
              </div>
            </div>
          </div>
        </div>
      </modal>
      <modal name="ds-impl-modal">
        <div class="modal-inner">
          <h3 style="text-align: center; padding: 20px">Подписать через ЭЦП</h3>
          <form method="POST" id="auth-form" @submit.prevent="sendSignXml">
            <div hidden>
              <select id="storageSelect">
                <option value="PKCS12" selected>PKCS12</option>
              </select>
            </div>

            <p class="auth-moda-explanations" style="text-align: center">
              Для успешной подписи рекомендуется <br/>
              выбирать ключ <b>GOSTKNCA</b>
            </p>

            <textarea
                hidden
                id="xmlToSign"
                name="xmlToSign"
                v-model="this.xmlToSign"
                rows="6"
            ></textarea>
            <textarea
                hidden
                class="form-control"
                id="signedXml"
                name="signedXml"
                rows="6"
                readonly
            ></textarea>
            <div style="display: flex; justify-content: center">
              <button class="auth-choose-cert prev-next-item" type="button" style="margin: 30px; height: 40px"
                      @click="signXmlCall">
                Выбрать сертификат
              </button>
            </div>
            <button id="test" type="submit" hidden>submit</button>
          </form>
        </div>
      </modal>
      <modal name="reject-modal" :height="600">
        <div class="modal-inner"
             style="display: flex; justify-content: center; flex-direction: column; align-items: center; gap: 1rem">
          <h3 style="text-align: center; padding: 20px">Отклонение акта</h3>
          <textarea v-model="reject_comment"
                    style="width: 80%; margin: 1rem; padding: .5rem; height: 5rem; border: 1px gray solid; border-radius: .5rem"
                    placeholder="Введите причину отклонения"></textarea>

          <div class="dropzone" style="width: 80%">
            <input type="file" class="input-field" id="input-field-multiple" ref="files_reject" multiple
                   accept=".pdf, image/png, image/jpeg" @change="reuploadVl1"/>
            <div v-if="!uploading" class="call-to-action">
              Перетащите файлы сюда подтверждающие файлы или нажмите, чтобы загрузить
              <p>
                <small>типы загружаемых файлов: .pdf, .png, .jpeg</small>
              </p>
            </div>
            <div v-if="uploading" class="uploaded-msg">
              Файлы успешно загружены
            </div>
          </div>

          <button v-if="reject_comment !== ''" class="btn file-selector"
                  style="width: 80%; color: white; background: rgb(255, 51, 102) !important;"
                  @click="accept_reject('reject')">Отклонить
          </button>
        </div>
      </modal>
    </section>
  </div>
</template>
<script async>
import {signXml} from "../nclayer/ncalayer.js";
import router from "../router";

export default {
  components: {},
  data() {
    return {
      fast_filter: "0",
      xmlToSign: '',
      show_form_3: true,
      new_number: '',
      show_form_4: true,
      impl_approvers: [],
      impl_ratificator: '',
      selected_impl: '',
      selected_rat: '',
      selected_approve_mode: 'Последовательное согласование',
      all_collegues: [],
      dzo_approvers: [],
      dzo_ratificator: {
        comment: "",
        company_name: "АО Эмбамунайгаз",
        fio: "Шарафетдинова Гульбану",
        id: 97,
        position: "Директор департамента управления персоналом и оплаты труда",
        position_short: "Директор департамента управлен...",
        type: "Утверждающий",
      },
      dzo_current_approver: '',
      selected_dzo_approver: '',
      selected_dzo_approver_fullname_upper: '',
      selected_dzo_rat: '',
      selected_dzo_rat_fullname_upper: '',
      selected_dzo_current_approver: '',
      selected_dzo_approve_mode: 'Последовательное согласование',
      req_dop_kaz: '12.09.2022 ж. №103-08/60 сенімхат негізінде іс-әрекет ететін бас директордың кадр және әлеуметтік мәселелер жөніндегі орынбасары Шарафетдинова Г.А.',
      req_dop: 'заместителя генерального директора по кадровым и социальным вопросам  Шарафетдиновой Г.А., действующей на основании доверенности №103-08/60 от 12.09.2022 г.,',
      req_position_kaz: '«Ембімұнайгаз» АҚ бас директорының кадр және әлеуметтік мәселелер жөніндегі орынбасары',
      req_position: 'Заместитель генерального директора АО «Эмбамунайгаз» по кадровым и социальным вопросам',
      req_dop_impl_kaz: 'Бұдан әрі «Тапсырысшы» деп аталатын ... жауапкершілігі шектеулі серіктестігі атынан ... сенімхат негізінде іс-әрекет етуші ...  бір жағынан',
      req_dop_impl: 'Товарищество с ограниченной ответственностью ..., именуемое в дальнейшем «Заказчик» в лице ...,  действующего на основании доверенности ...',
      req_position_impl_kaz: '',
      req_position_impl: '',
      uploading: false,
      scontract: {},
      curr_user: {},
      reject_comment: '',
      curr_APPR: false,
      curr_ABP: false,
      curr_ABPIMPL: false,
      curr_RAT: false,
      is_IMPL: false,
      is_OWNER: false,
      is_ABP: false,
      is_APPROVER: false,
      is_RATIFICATOR: false,
      is_ECONOMIST: false,
      is_BOOKER: false,
      is_DZOADM: false,
    };
  },
  created() {
    this.getSContractDetail();
    this.current_user();
    this.is_IMPL = this.isIMPL();
    this.is_ABP = this.isABP();
    this.is_APPROVER = this.isAPPROVER();
    this.is_RATIFICATOR = this.isRATIFICATOR();
    this.is_ECONOMIST = this.isECONOMIST();
    this.is_BOOKER = this.isBOOKER();
    this.is_DZOADM = this.isDZOADM();
  },
  methods: {
    signXmlCall() {
      console.log('signXmlCall-started');
      let selectedStorage = "PKCS12";
      console.log('xmlToSign');
      console.log(this.xmlToSign);
      signXml(selectedStorage, "SIGNATURE", this.xmlToSign);
      console.log('signXmlCall-ended');
    },
    sendSignXml() {
      console.log('sendSignXml-started');
      let xmlToSign = document.getElementById("xmlToSign");
      let signedXml = document.getElementById("signedXml");
      console.log('signedXml');
      console.log(signedXml.value);

      let formData = {
        signedXml: signedXml.value,
        xmlToSign: xmlToSign.value,
        contract_id: this.scontract.id,
      };
      this.$store.dispatch("sendSignXmlSContract", formData)
          .then((response) => {
            console.log('sendSignXml-middle');
            console.log(response);
            location.reload();
            // router.push({ name: 'actview', params: {id: response.data.id} });
            console.log('sendSignXml-ended');
          })
          .then(() => {
            this.$store.dispatch('addNotification', {
              type: 'success',
              message: 'Договор успешно подписан',
            })
          })
          .catch((error) => {
            console.log(error.response.data.detail);
            let errorMessage = error.response.data.detail
            this.$store.dispatch('addNotification', {
              type: 'error',
              message: errorMessage,
            })
            // this.closeModal();
          });

    },
    showSignModal() {
      this.$modal.show("ds-impl-modal");
    },
    showRejectModal() {
      this.$modal.show("reject-modal");
    },
    rm_approver(index) {
      this.impl_approvers.splice(index, 1);
    },
    rm_dzo_approver(index) {
      this.dzo_approvers.splice(index, 1);
    },
    rm_ratificator() {
      this.impl_ratificator = '';
    },
    rm_dzo_ratificator() {
      this.dzo_ratificator = '';
    },
    reuploadVl1() {
      // const file = this.$refs.file.files[0];
      let fileName = document.getElementById("input-field-multiple").value,
          idxDot = fileName.lastIndexOf(".") + 1,
          extFile = fileName.substr(idxDot, fileName.length).toLowerCase();


      if (!(extFile === "png" || extFile === "pdf" || extFile === "jpg")) {
        this.$store.dispatch('addNotification', {
          type: 'error',
          message: 'Следует загружать следующие типы файлов: pdf, png, jpg',
        })
        this.file = null;
      } else {
        this.uploading = true;
      }
    },
    accept_reject(decision) {
      let formData = new FormData();
      formData.append('contract_id', this.scontract.id);
      formData.append('decision', decision);
      formData.append('reject_comment', this.reject_comment);
      console.log('files_reject');
      console.log(this.$refs.length);
      if (this.$refs['files_reject']) {
        for (let i = 0; i < this.$refs['files_reject'].files.length; i++) {
          let file = this.$refs['files_reject'].files[i];
          formData.append('files_reject-' + i, file);
        }
      }

      this.$store.dispatch("acceptRejectSContract", formData).then((response) => {
        this.$store.dispatch('addNotification', {
          type: 'success',
          message: response.data.detail,
        })
        location.reload();
      }).catch((error) => {
        this.$store.dispatch('addNotification', {
          type: 'error',
          message: error.response.data.detail,
        })
      });
    },
    setImplApprovers() {
      let formData = {
        contract_id: this.scontract.id,
        selected_approve_mode: this.selected_approve_mode,
        impl_approvers: this.impl_approvers,
        impl_ratificator: this.impl_ratificator,
        req_dop_kaz: this.req_dop_impl_kaz,
        req_dop: this.req_dop_impl,
        req_position_kaz: this.req_position_impl_kaz,
        req_position: this.req_position_impl,
      }
      this.$store.dispatch("setImplApproversSContract", formData).then((response) => {
        this.$store.dispatch('addNotification', {
          type: 'success',
          message: response.data.detail,
        })
        location.reload();
      }).catch((error) => {
        this.$store.dispatch('addNotification', {
          type: 'error',
          message: error.response.data.detail,
        })
      });
    },
    setSContractNumber() {
      let formData = {
        contract_id: this.scontract.id,
        new_number: this.new_number,
      }
      this.$store.dispatch("setSContractNumber", formData).then((response) => {
        if (response.data.success) {
          this.$store.dispatch('addNotification', {
            type: 'success',
            message: response.data.detail,
          });
          location.reload();
        } else {
          this.$store.dispatch('addNotification', {
            type: 'warning',
            message: response.data.detail,
          });
        }
      }).catch((error) => {
        this.$store.dispatch('addNotification', {
          type: 'error',
          message: error.response.data.detail,
        })
      });
    },
    sendToApproversImpl() {
      this.$store.dispatch("sendToApproversImpl", this.scontract.id).then((response) => {
        this.$store.dispatch('addNotification', {
          type: 'success',
          message: response.data.detail,
        })
        location.reload();
      }).catch((error) => {
        this.$store.dispatch('addNotification', {
          type: 'error',
          message: error.response.data.detail,
        })
      });
    },
    setDZOApprovers() {
      let formData = {
        scontract_id: this.scontract.id,
        selected_dzo_approve_mode: this.selected_dzo_approve_mode,
        dzo_approvers: this.dzo_approvers,
        dzo_ratificator: this.dzo_ratificator,
        req_dop_kaz: this.req_dop_kaz,
        req_dop: this.req_dop,
        req_dop_impl: this.req_dop_impl,
        req_dop_impl_kaz: this.req_dop_impl_kaz,
        req_position_kaz: this.req_position_kaz,
        req_position_impl_kaz: this.req_position_impl_kaz,
        req_position_impl: this.req_position_impl,
        req_position: this.req_position,
      }
      this.$store.dispatch("setDZOApproversSContract", formData).then((response) => {
        console.log(response);
        location.reload();
      }).catch((error) => {
        this.$store.dispatch('addNotification', {
          type: 'error',
          message: error.response.data.detail,
        })
      });
    },
    // changeDZOCurrentApprovers(approve_user_id) {
    //   let formData = {
    //     act_id: this.act.id,
    //     old_user_id: approve_user_id,
    //     dzo_current_approver: this.dzo_current_approver
    //   }
    //   this.$store.dispatch("changeDZOCurrentApprovers", formData).then((response) => {
    //     console.log(response);
    //     location.reload();
    //   }).catch((error) => {
    //     this.$store.dispatch('addNotification', {
    //       type: 'error',
    //       message: error.response.data.detail,
    //     })
    //   });
    // },
    // removeDZOCurrentApprovers(approve_user_id) {
    //   let formData = {
    //     act_id: this.act.id,
    //     old_user_id: approve_user_id
    //   }
    //   this.$store.dispatch("removeDZOCurrentApprovers", formData).then((response) => {
    //     console.log(response);
    //     location.reload();
    //   }).catch((error) => {
    //     this.$store.dispatch('addNotification', {
    //       type: 'error',
    //       message: error.response.data.detail,
    //     })
    //   });
    // },
    change_impl() {
      if (this.selected_impl !== '') {
        let already_exist = false;
        for (let i = 0; i < this.impl_approvers.length; i++) {
          let impl_in_approver_list = this.impl_approvers[i];
          if (impl_in_approver_list.id === this.selected_impl.id) {
            already_exist = true;
            break;
          }
        }
        if (this.impl_ratificator.id === this.selected_impl.id) {
          already_exist = true;
        }
        if (!already_exist) {
          let approver = {
            id: this.selected_impl.id,
            fio: this.selected_impl.full_name,
            position: this.selected_impl.position,
            position_short: this.selected_impl.position_short,
            type: "Согласующий",
            company_name: this.selected_impl.company.name,
            comment: '',
          };
          this.impl_approvers.push(approver);
          this.selected_impl = '';
        } else {
          this.$store.dispatch('addNotification', {
            type: 'error',
            message: 'Данный пользователь ' + this.selected_impl.full_name + ' уже добавлен в список согласующих / утверждающих',
          })
          this.selected_impl = '';
        }
      }

    },
    change_approver() {

      console.log(this.selected_dzo_approver_fullname_upper);
      this.selected_dzo_approver = '';
      for (let i = 0; i < this.all_collegues.length; i++) {
        let ouser = this.all_collegues[i];
        if (ouser.full_name.toUpperCase() === this.selected_dzo_approver_fullname_upper) {
          this.selected_dzo_approver = ouser;
          break;
        }
      }

      if (this.selected_dzo_approver !== '') {
        let already_exist = false;
        for (let i = 0; i < this.dzo_approvers.length; i++) {
          let dzo_in_approver_list = this.dzo_approvers[i];
          if (dzo_in_approver_list.id === this.selected_dzo_approver.id) {
            already_exist = true;
            break;
          }
        }
        if (this.dzo_ratificator.id === this.selected_dzo_approver.id || this.selected_dzo_approver.id === this.scontract.zak_abp.ouser.id) {
          already_exist = true;
        }
        if (!already_exist) {
          let approver = {
            id: this.selected_dzo_approver.id,
            fio: this.selected_dzo_approver.full_name,
            position: this.selected_dzo_approver.position,
            position_short: this.selected_dzo_approver.position_short,
            type: "Согласующий",
            company_name: this.selected_dzo_approver.company.name,
            comment: '',
          };
          this.dzo_approvers.push(approver);
          this.selected_dzo_approver = '';
        } else {
          this.$store.dispatch('addNotification', {
            type: 'error',
            message: 'Данный пользователь ' + this.selected_dzo_approver.full_name + ' уже добавлен в список согласующих / утверждающих!',
          })
          this.selected_dzo_approver = '';
        }
      }
      this.selected_dzo_approver_fullname_upper = '';
    },
    change_rat() {
      if (this.selected_rat !== '') {
        let already_exist = false;
        for (let i = 0; i < this.impl_approvers.length; i++) {
          let impl_in_approver_list = this.impl_approvers[i];
          if (impl_in_approver_list.id === this.selected_rat.id) {
            already_exist = true;
            break;
          }
        }
        if (this.selected_rat.id === this.impl_ratificator.id) {
          already_exist = true;
        }
        if (!already_exist) {
          this.impl_ratificator = {
            id: this.selected_rat.id,
            fio: this.selected_rat.full_name,
            type: 'Утверждающий',
            company_name: this.selected_rat.company.name,
            position: this.selected_rat.position,
            position_short: this.selected_rat.position_short,
            comment: '',
          };
          this.selected_rat = '';
        } else {
          this.$store.dispatch('addNotification', {
            type: 'error',
            message: 'Данный пользователь ' + this.selected_rat.full_name + ' уже добавлен в список согласующих',
          })
          this.selected_rat = '';
        }
      }

    },
    change_dzo_rat() {
      console.log(this.selected_dzo_rat_fullname_upper);
      // this.new_scontract.impl_company = {};
      this.selected_dzo_rat = '';
      for (let i = 0; i < this.all_collegues.length; i++) {
        let ouser = this.all_collegues[i];
        if (ouser.full_name.toUpperCase() === this.selected_dzo_rat_fullname_upper) {
          this.selected_dzo_rat = ouser;
          break;
        }
      }

      if (this.selected_dzo_rat !== '') {
        let already_exist = false;
        for (let i = 0; i < this.dzo_approvers.length; i++) {
          let dzo_in_approver_list = this.dzo_approvers[i];
          if (dzo_in_approver_list.id === this.selected_dzo_rat.id) {
            already_exist = true;
            break;
          }
        }
        if (this.selected_dzo_rat.id === this.dzo_ratificator.id || this.selected_dzo_rat.id === this.scontract.zak_abp.ouser.id) {
          already_exist = true;
        }
        if (!already_exist) {
          this.dzo_ratificator = {
            id: this.selected_dzo_rat.id,
            fio: this.selected_dzo_rat.full_name,
            type: 'Утверждающий',
            company_name: this.selected_dzo_rat.company.name,
            position: this.selected_dzo_rat.position,
            position_short: this.selected_dzo_rat.position_short,
            comment: '',
          };
          this.selected_dzo_rat = '';
        } else {
          this.$store.dispatch('addNotification', {
            type: 'error',
            message: 'Данный пользователь ' + this.selected_dzo_rat.full_name + ' уже добавлен в список согласующих / утверждающих',
          })
          this.selected_dzo_rat = '';
        }
      }
      this.selected_dzo_rat_fullname_upper = '';

    },
    // change_dzo_current_approver() {
    //   if (this.selected_dzo_current_approver !== '') {
    //     let already_exist = false;
    //     for (let i = 0; i < this.act.approvers.length; i++) {
    //       let dzo_in_approver_list = this.act.approvers[i];
    //       if (dzo_in_approver_list.user.ouser.id === this.selected_dzo_current_approver.id) {
    //         already_exist = true;
    //         break;
    //       }
    //     }
    //     if (!already_exist) {
    //       this.dzo_current_approver = {
    //         id: this.selected_dzo_current_approver.id,
    //         fio: this.selected_dzo_current_approver.full_name,
    //         type: 'Согласующий',
    //         company_name: this.selected_dzo_current_approver.company.name,
    //         position: this.selected_dzo_current_approver.position,
    //         position_short: this.selected_dzo_current_approver.position_short,
    //         comment: '',
    //       };
    //       this.selected_dzo_current_approver = '';
    //     } else {
    //       this.$store.dispatch('addNotification', {
    //         type: 'error',
    //         message: 'Данный пользователь ' + this.selected_dzo_current_approver.full_name + ' уже присутствует в списке согласующих',
    //       })
    //       this.selected_dzo_current_approver = '';
    //     }
    //   }
    //
    // },
    // editAct() {
    //   router.push({name: 'edit_act', params: {id: this.act.id}});
    // },
    // copyAct() {
    //   this.$store.dispatch("copyAct", this.act.id).then((response) => {
    //     console.log(response);
    //     router.push({name: 'edit_act', params: {id: response.data.act_id}});
    //     location.reload();
    //   }).catch((error) => {
    //     console.log(error);
    //   });
    //   router.push({name: 'edit_act', params: {id: this.act.id}});
    // },
    // deleteAct() {
    //   this.$store.dispatch('deleteAct', this.$route.params.id)
    //       .then((response) => {
    //         console.log(response);
    //         router.push({name: 'acts'});
    //       })
    //       .catch((error) => {
    //         console.log(error);
    //         location.reload();
    //       });
    // },
    // bringBackAct() {
    //   this.$store.dispatch('bringBackAct', this.$route.params.id)
    //       .then((response) => {
    //         console.log(response);
    //         location.reload();
    //       })
    //       .catch((error) => {
    //         console.log(error);
    //         location.reload();
    //       });
    // },
    // addToArchive() {
    //   let formData = new FormData();
    //   let new_archiveds_obj = {
    //     act_id: this.act.id
    //   }
    //   const json = JSON.stringify(new_archiveds_obj);
    //
    //   formData.append('new_archiveds_obj', json);
    //
    //   this.$store.dispatch('addToArchive', formData).then((response) => {
    //     console.log(response.data.new_archived_id);
    //     if (response.data.new_archived_id !== '') {
    //       location.reload();
    //     } else {
    //       // alert('Ошибка');
    //       this.$store.dispatch('addNotification', {
    //         type: 'error',
    //         message: response.data.detail,
    //       })
    //     }
    //   }).catch((error) => {
    //     // alert(error);
    //     this.$store.dispatch('addNotification', {
    //       type: 'error',
    //       message: error.response.data.detail,
    //     })
    //   });
    // },
    // rmFromArchive() {
    //   let archiveds_id = this.act.archiveds_id;
    //   if (archiveds_id !== -1) {
    //     this.$store.dispatch("rmFromArchive", archiveds_id).then((response) => {
    //       this.$store.dispatch('addNotification', {
    //         type: 'success',
    //         message: response.data.detail,
    //       });
    //       location.reload();
    //     }).catch((error) => {
    //       this.$store.dispatch('addNotification', {
    //         type: 'error',
    //         message: error.response.data.detail,
    //       })
    //     });
    //   } else {
    //     this.$store.dispatch('addNotification', {
    //       type: 'warning',
    //       message: 'Данный акт не найден в архиве',
    //     })
    //   }
    //
    // },
    downloadSContractPDF() {
      let scontract_id = this.scontract.id;
      this.$store.dispatch("downloadSContractPDF", scontract_id).then((response) => {
        const url = URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'AVR_Договор_ID0.C._' + scontract_id + '.pdf');
        document.body.appendChild(link);
        link.click();
      }).catch((error) => {
        console.log(error);
      });
    },
    downloadSContractPDFNew() {
      let scontract_id = this.scontract.id;
      this.$store.dispatch("downloadSContractPDFNew", scontract_id).then((response) => {
        const url = URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'AVR_Договор_ID0.C._' + scontract_id + '.pdf');
        document.body.appendChild(link);
        link.click();
      }).catch((error) => {
        console.log(error);
      });
    },
    // downloadHistoryPDF() {
    //   let act_id = this.act.id;
    //   this.$store.dispatch("downloadHistoryPDF", act_id).then((response) => {
    //     const url = URL.createObjectURL(new Blob([response.data]));
    //     const link = document.createElement('a');
    //     link.href = url;
    //     link.setAttribute('download', 'AVR_История_акта_ID0_' + act_id + '.pdf');
    //     document.body.appendChild(link);
    //     link.click();
    //   }).catch((error) => {
    //     console.log(error);
    //   });
    // },
    isIMPL() {
      return localStorage.getItem('roles').includes(1);
    },
    isOWNER() {
      let user = JSON.parse(localStorage.getItem('user'));
      return user['id'] === this.scontract.zak_abp.id;
    },
    current_user() {
      this.curr_user = JSON.parse(localStorage.getItem('user'));
    },
    isABP() {
      return localStorage.getItem('roles').includes(2);
    },
    isAPPROVER() {
      return localStorage.getItem('roles').includes(3);
    },
    isRATIFICATOR() {
      return localStorage.getItem('roles').includes(4);
    },
    isECONOMIST() {
      return localStorage.getItem('roles').includes(5);
    },
    isBOOKER() {
      return localStorage.getItem('roles').includes(6)
    },
    isDZOADM() {
      return localStorage.getItem('roles').includes(7);
    },
    prettyPrice(price) {
      if (typeof price == "number") {
        return price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
      } else {
        return price;
      }
    },
    showAddApproverModalImpl() {
      this.$modal.show("add-approver-modal-impl");
    },
    showNumberEditModal() {
      this.$modal.show("number-edit-modal");
    },
    editSContract() {
      router.push({name: 'edit_scontract', params: {id: this.scontract.id}});
    },
    showAddApproverModalABP() {
      this.$modal.show("add-approver-modal-abp");
    },
    showChangeApproverModalABP(approve_user_id) {
      this.$modal.show("change-approver-modal-abp-" + approve_user_id);
    },
    showDoubleImageModal(index) {
      this.$modal.show("double-modal-" + index);
    },
    slideClass(index) {
      if (index % 2 !== 0) {
        return 'slide-right'
      }
      return 'slide-left'
    },
    fast_active(to_filter) {
      if (to_filter.toString() === this.fast_filter.toString()) {
        return 'active';
      }
      return '';
    },
    fast_active_form_3(to_filter) {
      if (to_filter === this.show_form_3) {
        return 'active';
      }
      return '';
    },
    fast_active_form_4(to_filter) {
      if (to_filter === this.show_form_4) {
        return 'active';
      }
      return '';
    },
    change_fast_filter(curr_filter) {
      this.fast_filter = curr_filter;
    },
    change_fast_form_3(curr_filter) {
      this.show_form_3 = curr_filter;
    },
    change_fast_form_4(curr_filter) {
      this.show_form_4 = curr_filter;
    },
    getSContractDetail() {
      this.$store.dispatch('getSContractDetail', this.$route.params.id).then((response) => {
        this.scontract = response.data;
        this.new_number = this.scontract.number;
        this.xmlToSign = this.scontract.xmlToSign;
        if (this.scontract.req_position_impl_kaz.length > 5) {
          this.req_position_impl_kaz = this.scontract.req_position_impl_kaz;
        }
        if (this.scontract.req_position_impl.length > 5) {
          this.req_position_impl = this.scontract.req_position_impl;
        }
        if (this.scontract.req_dop_impl_kaz.length > 5) {
          this.req_dop_impl_kaz = this.scontract.req_dop_impl_kaz;
        }
        if (this.scontract.req_dop_impl.length > 5) {
          this.req_dop_impl = this.scontract.req_dop_impl;
        }

        this.is_OWNER = this.isOWNER();
        for (let i = 0; i < this.scontract.approvers.length; i++) {
          let approve = this.scontract.approvers[i];
          if (approve.decision === 'Назначен' && approve.user.id === this.curr_user.id) {
            this.curr_APPR = true;
            if (approve.ratifier) {
              this.curr_RAT = true;
            } else if (approve.user.id === this.scontract.zak_abp.id) {
              this.curr_ABP = true;
            } else if (approve.user.id === this.scontract.impl_abp.id) {
              this.curr_ABPIMPL = true;
            }
            break;
          }
        }
        // this.$store.dispatch('getXmlToSign', this.act.id).then((response) => {
        //   this.xmlToSign = response.data.xmlToSign;
        // });
        this.$store.dispatch('getAllCollegues').then((response) => {
          this.all_collegues = response.data;
        });
      }).catch(e => {
        console.log(e)
      })
    },
  },
  mounted() {
    let recaptchaScript1 = document.createElement('script');
    recaptchaScript1.setAttribute('src', 'https://unpkg.com/img-comparison-slider@3/dist/component/component.esm.js');
    recaptchaScript1.setAttribute('type', 'module');
    document.head.appendChild(recaptchaScript1);

    let recaptchaScript2 = document.createElement('script');
    recaptchaScript2.setAttribute('src', 'https://unpkg.com/img-comparison-slider@3/dist/component/component.js');
    recaptchaScript2.setAttribute('nomodule', '');
    document.head.appendChild(recaptchaScript2);

    let recaptchaScript3 = document.createElement('link');
    recaptchaScript3.setAttribute('href', 'https://unpkg.com/img-comparison-slider@3/dist/collection/styles/initial.css');
    recaptchaScript3.setAttribute('rel', 'stylesheet');
    document.head.appendChild(recaptchaScript3);

  },
};
</script>

<style scoped>
@import "../assets/css/webpixels.css";

@keyframes example {
  from {
    flex: 1
  }
  to {
    flex: none
  }
}

.event-left-part {
  height: 400px;
  width: 48% !important;
  display: flex;
  justify-content: flex-start;
  gap: 0.7rem;
  flex-direction: column;
}

.div-item {
  height: 40px;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}

.descripcion {
  /*background-color: green;*/
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.descripcion:after {
  content: " ........................................................................................................................................................................................................................................................................................."
}

.precio {
  /*background-color: red;*/
  flex-shrink: 0;
}

.in_sap_card {

}

.sap-success {
  height: 100px;
  position: fixed;
  top: 35%;
  left: 450px;
  font-size: 100px;
  border: 5px solid #0c8;
  opacity: 0.3;
  text-align: center;
  /*transform: rotateZ(-30deg);*/
  padding: 4rem;
  border-radius: 1.5rem;
}

.sap-warning {
  height: 100px;
  position: fixed;
  top: 35%;
  left: 450px;
  font-size: 100px;
  border: 5px solid #ff8c00;
  opacity: 0.3;
  text-align: center;
  /*transform: rotateZ(-30deg);*/
  padding: 4rem;
  border-radius: 1.5rem;
}

.archived-warning {
  height: 100px;
  width: 350px;
  position: fixed;
  top: 4%;
  right: 150px;
  font-size: 40px;
  background: rgb(35, 70, 140);
  color: white;
  /*border: 5px solid rgb(35, 70, 140);*/
  opacity: 0.3;
  text-align: center;
  /*transform: rotateZ(-30deg);*/
  padding: 2rem;
  padding-top: 2.5rem;
  border-radius: 1.5rem;
}

.dropzone {
  /*margin-top: 3rem;*/
  height: 250px;
  min-height: 250px;
  padding: 10px;
  position: relative;
  cursor: pointer;
  border: 2px dashed gray;
  outline-offset: 10px;
  background: #d9e3f7;
  color: dimgray;
}

.input-field {
  opacity: 0;
  width: 100%;
  height: 100%;
  left: 0;
  position: absolute;
  cursor: pointer;
}

.dropzone:hover {
  background: lightblue;
  transition: all 300ms ease;
}

.dropzone .call-to-action {
  font-size: 1.5rem;
  text-align: center;
  padding: 35px 0;
}

.call-to-action > div {
  font-size: 1rem;
  padding: 1rem 2rem;
  background: #00aacc;
  max-width: 300px;
  margin: 1rem auto;
  border-radius: 20px;
  color: #fff;
}

.placeholder-disabled::placeholder {
  text-decoration: line-through;
  color: lightgray;
}

.uploaded-msg {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

img-comparison-slider {
  --divider-width: 2px;
  --divider-color: #c0c0c0;
  --handle-opacity: 0.3;
  height: auto;
  width: auto;
  /*background-color: black;*/
}

.flex-column-animated {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 2rem;
  padding-bottom: 2rem;
}

.section-btn {
  background: inherit;
  animation: example 1000ms ease;

  position: relative;
}

.section-btn-bx:hover {
  transform: scale(1.2) translateX(-0.6rem);
}

.section-btn-bx {
  color: white;
  font-size: 40px;
  border-radius: 50%;
  padding: 0.5rem;
  transition-duration: .5s;
}

.section-btn-bx-static {
  color: white;
  width: 70px;
  font-size: 30px;
  animation: example 3000ms ease;
  border-radius: .5rem;
  padding: 0.2rem;
  transition-duration: .5s;
  overflow: hidden;
}

.section-btn-bx-static:hover {
  width: 150px;
  text-align: center;
}

.section-btn-bx-static-change:hover::before {
  content: 'Заменить';
  font-size: 27px;
  font-family: 'Quicksand', 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
}

.section-btn-bx-static-remove:hover::before {
  content: 'Исключить';
  font-size: 27px;
  font-family: 'Quicksand', 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;

}

.bx-disabled {
  opacity: 0.2;
  /*background: lightgray !important;*/
}

.hp::first-letter {
  text-transform: capitalize;
}

.m-table-act {
  width: 100%;

  text-align: center;
  font-size: x-small;
  border: 1px solid black;
  border-collapse: collapse;
}

.m-table-act td {
  border: 1px solid black;
  border-collapse: collapse;
}

.m-table-act th {
  -moz-transition: all 1s ease-out;
  -o-transition: all 1s ease-out;
  -webkit-transition: all 1s ease-out;
  border: 1px solid black;
  border-collapse: collapse;
  background: #f5f9fc;
}


.m-table-act th:hover {
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -o-transform: scale(1.2);
  /*background-color: white;*/
}

.table-abp thead tr th {
  border-top-width: 0 !important;
}

.section-header {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
}

.section-header h2 {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 16px;
  color: black;
}

.section-header hr {
  border: 1px solid gray;
  width: 120px;
}

.bg-gradient_solid {
  color: #fff;
}

.steps {
  position: relative;
  margin-top: 32px;
}

.steps::after {
  content: "";
  position: absolute;
  width: 1px;
  background-color: white;
  opacity: 1;
  top: 0;
  bottom: 0;
  left: 50%;
}

.steps .content p {
  color: #676767;
  font-size: 16px;
}

.steps .content h2 {
  font-weight: 600;
  font-size: 16px;
  color: #676767;
}

.steps-container {
  position: relative;
  background-color: inherit;
  width: calc(50% + 32px);
}

.steps-container .content {
  padding: 32px;
  background-color: white;
  position: relative;
  border-radius: 0px 0px 80px 0px;
  transition: all 1.3s linear;
  box-shadow: 0px 16px 27px rgb(0 11 30 / 10%);
}

.steps .steps-container:nth-child(even) {
  left: calc(50% - 32px);
  flex-direction: row-reverse;
}

.steps-container {
  display: flex;
}

.steps .steps-container .date {
  font-weight: 900;
  font-size: 16px;
  color: #ffffff;
  margin-bottom: 10px;
  width: 62px;
  height: 62px;
  background-color: var(--primary);
  border-radius: 50%;
  flex-shrink: 0;
  align-items: center;
  display: flex;
  justify-content: center;
  z-index: 777;
}

.step-line {
  width: 40px;
  background-color: white;
  height: 1px;
  margin-top: 31px;
  opacity: 1;
  flex-shrink: 0;
}

@media (max-width: 767px) {
  .steps::after {
    left: 32px;
  }

  .steps-container {
    left: 0;
    flex-direction: row-reverse;
    width: auto;
    margin-bottom: 16px;
  }

  .steps .steps-container:nth-child(even) {
    left: 0;
  }
}

.table-bordered2 thead tr th {
  border-left: 1px #e7eaf0 solid;
  border-right: 1px #e7eaf0 solid
}

.table-bordered2 tbody tr td {
  border-left: 1px #e7eaf0 solid;
  border-right: 1px #e7eaf0 solid
}

.item-wrap {
  display: flex;
  justify-content: space-between;
}

.left-item ul {
  display: flex;
  justify-content: left;
  gap: 1rem;
}

@media screen and (max-width: 1100px) {
  .left-item {
    flex-direction: column;
    gap: 0.2rem;
    height: 100px !important;
  }

  .ul-li {
    height: 30px !important;
  }
}

.item {
  height: 50px;
  width: 50%;
}

.innernavbar-wrap {
  width: 100%;
}

.ul-li {
  list-style-type: none;
  width: 250px !important;
  min-width: 250px !important;
  height: 30px;
  border-radius: 10px;
  background: #d9e3f7 !important;
  text-align: center;
  cursor: pointer;
  color: #666 !important;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  border: 1px solid #d9e3f7;
}

.ul-li:hover {
  background: #2b6de8 !important;
  color: white !important;
}

.active {
  color: white !important;
  background: #23468c !important;
}

@keyframes progress {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

th {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

td {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.tr-main {
  cursor: pointer
}

@keyframes wave {
  50% {
    transform: scale(0.9);
  }
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.select_button {
  /*background: rgb(29, 161, 242);*/
  background: white;
  border: 1px solid gray;
  -webkit-appearance: none;
  background-position-x: 97%;
  /*color: white;*/
  width: 98%;
  margin: .5rem;
  padding: .5rem 1.5rem;
  border-radius: 1rem;
  text-align: left;
}

.default_option {
  background: white !important;
  color: black !important;
}

.m-input-cell {
  width: 100%;
  /*border-width: 1px;*/
  height: 35px;
  text-align: center;
}

</style>