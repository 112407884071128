<template>
  <div class="home-section-wrap">
    <section class="home-section" id="home-section">
      <div class="p-10" style="padding-bottom: 0 !important; display: flex; justify-content: space-between; gap: 1rem">
        <h3>Акт №
          <a :href="'/acts/' + this.act.id">
            {{ this.act.number }} {{ this.act.name }}
          </a> от {{ this.act.act_date }}
        </h3>
        <button class="only-mobile actions-btn" @click="showActionsModal">Действия</button>
      </div>
      <div class="innernavbar-wrap p-10" style="padding-bottom: 0 !important;">
        <div class="item-wrap">
          <div class="item left-item">
            <ul class="mobile-flex">
              <li>
                <button
                    class="filter-btn transition-all ease-in-out duration-150 transform hover:scale-110 bg-indigo-500 hover:bg-indigo-600 px-5 py-1 rounded-lg max-h-12 whitespace-nowrap"
                    :class="fast_active('1')"
                    @click="change_fast_filter('1')">
                  Табличные данные
                </button>
              </li>
              <li v-if="act.type === 'ДКС'">
                <button
                    class="filter-btn transition-all ease-in-out duration-150 transform hover:scale-110 bg-indigo-500 hover:bg-indigo-600 px-5 py-1 rounded-lg max-h-12 whitespace-nowrap"
                    :class="fast_active('5')"
                    @click="change_fast_filter('5')">
                  Дополнительные формы
                </button>
              </li>
              <li>
                <button
                    class="filter-btn transition-all ease-in-out duration-150 transform hover:scale-110 bg-indigo-500 hover:bg-indigo-600 px-5 py-1 rounded-lg max-h-12 whitespace-nowrap"
                    :class="fast_active('2')"
                    @click="change_fast_filter('2')">
                  История
                </button>
              </li>
              <li>
                <button
                    class="filter-btn transition-all ease-in-out duration-150 transform hover:scale-110 bg-indigo-500 hover:bg-indigo-600 px-5 py-1 rounded-lg max-h-12 whitespace-nowrap"
                    :class="fast_active('3')"
                    @click="change_fast_filter('3')">
                  Согласующие
                </button>
              </li>
              <li>
                <button
                    class="filter-btn transition-all ease-in-out duration-150 transform hover:scale-110 bg-indigo-500 hover:bg-indigo-600 px-5 py-1 rounded-lg max-h-12 whitespace-nowrap"
                    :class="fast_active('4')"
                    @click="change_fast_filter('4')">
                  Файлы
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="p-10" style="padding-top: 1rem !important; padding-bottom: 0 !important;">
        <div class="card">
          <div class="table-responsive">
            <table class="table table-hover table-nowrap">
              <thead class="thead-light">
              <tr>
                <th scope="col" class="" style="border-top-left-radius: 0.75rem !important;">ID</th>
                <th scope="col" class="mainless">Номер акта</th>
                <th scope="col" class="">Дата создания</th>
                <th scope="col" class="mainless">Дата акта</th>
                <th scope="col" class="" v-if="act.contract">Договор</th>
                <th scope="col" class="mainless" v-if="!act.contract.is_mirror">НГДУ</th>
                <th scope="col" class="mainless" v-else>Поставщик</th>
                <th scope="col" class="mainless">
                  <span v-if="!is_BOOKER">Время с начала</span>
                  <span v-else>№ ведомостей SAP</span>
                </th>
                <th scope="col" class="mainless">Всего</th>
                <th scope="col" class="mainless">Тип</th>
                <th scope="col" class="" v-if="!act.contract.is_mirror">Поставщик</th>
                <th scope="col" class="" v-else>Заказчик</th>
                <th scope="col" class="" style="border-top-right-radius: 0.75rem !important;">Статус</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td data-label="" class="">
                  <a class="custom-link" :href="'/acts/' + act.id">
                    {{ act.id }}
                  </a>
                </td>
                <td data-label="" class="mainless">
                  {{ act.number }}
                </td>
                <td data-label="" class="">
                  {{ act.created_datetime_short }}
                </td>
                <td data-label="" class="mainless">
                  {{ act.act_date }}
                </td>
                <td data-label="" class="" v-if="act.contract">
                  <a class="custom-link" :href="'/contracts/' + act.contract.id" :title="act.contract.name">
                    {{ act.contract.number }}
                  </a>
                </td>
                <td data-label="" class="mainless" v-if="!act.contract.is_mirror">
                  {{ act.ngdu_name }}
                </td>
                <td data-label="" class="mainless" v-else>
                  {{ act.contract.abp.ouser.company.name }}
                </td>
                <td data-label="" class="mainless">
                  <span v-if="!is_BOOKER">{{ act.time_to_end }}</span>
                  <span v-else>{{ act.acc_id_list }}</span>

                </td>
                <td data-label="" class="mainless">
                  {{ prettyPrice(act.total_with_nds) }} тг
                </td>
                <td data-label="" class="mainless">
                  {{ act.type }}
                </td>
                <td data-label="" class="">
                  <span v-if="act.contract">
                    {{ act.contract.impl_company.name }}
                  </span>
                </td>
                <td data-label="" class="">
                  <span :class="act.color + ' act-status'">{{ act.status }}</span>
                </td>

              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="p-10" v-if="this.fast_filter === '1'">
        <div class="section-header">
          <h2>Табличные данные</h2>
          <hr>
        </div>
        <div class="card p-10">
          <!--          <div style="display: flex; justify-content: space-between;">-->
          <!--            <h4 style="margin-bottom: 10px">Табличные данные</h4>-->
          <!--          </div>-->
          <div class="table-responsive">
            <table class="m-table-act" v-if="act.type === 'Стандартный (458-562)'">
              <thead class="">
              <tr>
                <td colspan="12" style="height: 40px; text-align: center"><b>АКТ ВЫПОЛНЕНИЯ РАБОТ (ОКАЗАННЫХ УСЛУГ)*</b>
                </td>
              </tr>
              <tr>
                <th rowspan="2" style="width: 100px">Номер по порядку</th>
                <th rowspan="2" style="width: 100px" v-if="!act.contract.is_mirror">Код затрат</th>
                <th rowspan="2" style="width: 100px" v-if="!act.contract.is_mirror">Код МВЗ</th>
                <th rowspan="2" style="width: 100px" v-if="!act.contract.is_mirror">Заказ ТОРО</th>
                <th rowspan="2" style="width: 300px;" v-if="!act.contract.is_mirror">Наименование МВЗ</th>
                <th rowspan="2" style="width: 300px">Наименование работ (услуг) (в разрезе их подвидов в соответствии с
                  технической спецификацией, заданием, графиком выполнения работ (услуг) при их наличии)
                </th>
                <th rowspan="2" style="width: 300px">Дата выполнения работ (оказания услуг)**</th>
                <th rowspan="2" style="width: 250px;">Сведения об отчете о научных исследованиях, маркетинговых,
                  консультационных и прочих услугах (дата, номер, количество страниц) (при их наличии)***
                </th>
                <th rowspan="2">Единица измерения</th>
                <th colspan="3" rowspan="1">Выполнено работ (оказано услуг)</th>
              </tr>
              <tr>
                <th>Количество</th>
                <th>Цена за единицу</th>
                <th>Стоимость (в тенге)</th>
              </tr>
              <tr v-if="!act.contract.is_mirror">
                <td v-for="i in [1,2,3,4,5,6,7,8,9,10,11,12]" :key="i">{{ i }}</td>
              </tr>
              <tr v-else>
                <td v-for="i in [1,2,3,4,5,6,7,8]" :key="i">{{ i }}</td>
              </tr>
              <tr style="height: 40px; background: white"
                  v-if="act.global_work + act.global_mvz !== '' && act.global_work + act.global_mvz !== '--' && !act.contract.is_mirror">
                <td v-for="i in [1,2,3,4,5,6,7,8,9,10,11,12]" :key="i">
                  <span v-if="i === 5 && act.global_mvz !== 'Отсутствует'">{{ act.global_mvz }}</span>
                  <span v-else-if="i === 5 && act.global_mvz === 'Отсутствует'">—</span>
                  <span v-else-if="i === 6 && act.global_work !== 'Отсутствует'">{{ act.global_work }}</span>
                  <span v-else-if="i === 6 && act.global_work === 'Отсутствует'">—</span>
                </td>
              </tr>
              </thead>
              <tbody>
              <tr style="height: 40px; background: white" v-for="row in this.act.rows" :key="row.id">
                <td data-label="">{{ row.serial_number }}</td>
                <td data-label="" v-if="!act.contract.is_mirror"><span v-if="row.cost_code !== 'Отсутствует'">{{ row.cost_code }}</span><span
                    v-else>—</span></td>
                <td data-label="" v-if="!act.contract.is_mirror"><span v-if="row.mvz_code !== 'Отсутствует'">{{ row.mvz_code }}</span><span
                    v-else>—</span></td>
                <td data-label="" v-if="!act.contract.is_mirror"><span v-if="row.new_tora_zakaz !== 'Отсутствует'">{{ row.new_tora_zakaz }}</span><span
                    v-else>—</span></td>
                <td data-label="" v-if="!act.contract.is_mirror">{{ row.mvz_name }}</td>
                <td data-label="">{{ row.work_name }}</td>
                <td data-label="" v-if="row.double_date">{{ row.start_date }} : {{ row.end_date }}</td>
                <td data-label="" v-else>{{ row.row_date }}</td>
                <td data-label=""><span v-if="row.research_report !== 'Отсутствует'">{{
                    row.research_report
                  }}</span><span v-else>—</span></td>
                <td data-label="">{{ row.measure_unit }}</td>
                <td data-label="">{{ prettyPrice(row.count) }}</td>
                <td data-label="">{{ prettyPrice(row.unit_price) }}</td>
                <td data-label="">{{ prettyPrice(row.total) }}</td>
              </tr>
              <tr style="height: 40px; background: white" v-if="!act.contract.is_mirror">
                <td v-for="i in [1,2,3,4,5,6,7,8,9,10,11,12]" :key="i">
                  <span v-if="i === 6">Итого:</span>
                  <span v-else-if="i === 12">{{ prettyPrice(act.total) }}</span>
                </td>
              </tr>
              <tr style="height: 40px; background: white" v-else>
                <td v-for="i in [1,2,3,4,5,6,7,8]" :key="i">
                  <span v-if="i === 5">Итого:</span>
                  <span v-else-if="i === 8">{{ prettyPrice(act.total) }}</span>
                </td>
              </tr>
              <tr style="height: 40px; background: white" v-if="!act.contract.is_mirror">
                <td v-for="i in [1,2,3,4,5,6,7,8,9,10,11,12]" :key="i">
                  <span v-if="i === 6">Сумма НДС:</span>
                  <span v-else-if="i === 12">{{ prettyPrice(act.nds) }}</span>
                </td>
              </tr>
              <tr style="height: 40px; background: white" v-else>
                <td v-for="i in [1,2,3,4,5,6,7,8]" :key="i">
                  <span v-if="i === 5">Сумма НДС:</span>
                  <span v-else-if="i === 8">{{ prettyPrice(act.nds) }}</span>
                </td>
              </tr>
              <tr style="height: 40px; background: white" v-if="!act.contract.is_mirror">
                <td v-for="i in [1,2,3,4,5,6,7,8,9,10,11,12]" :key="i">
                  <span v-if="i === 6">Всего:</span>
                  <span v-else-if="i === 12">{{ prettyPrice(act.total_with_nds) }}</span>
                </td>
              </tr>
              <tr style="height: 40px; background: white" v-else>
                <td v-for="i in [1,2,3,4,5,6,7,8]" :key="i">
                  <span v-if="i === 5">Всего:</span>
                  <span v-else-if="i === 8">{{ prettyPrice(act.total_with_nds) }}</span>
                </td>
              </tr>
              </tbody>
            </table>
            <table class="m-table-act" v-else-if="act.type === 'ДБиКРС'">
              <thead class="">
              <tr>
                <td colspan="14" style="height: 40px; text-align: center"><b>АКТ ВЫПОЛНЕНИЯ РАБОТ (ОКАЗАННЫХ УСЛУГ)*</b>
                </td>
              </tr>
              <tr>
                <th rowspan="3" style="width: 100px">Номер по порядку</th>
                <th rowspan="3" style="width: 100px">Код затрат</th>
                <th rowspan="3" style="width: 100px">Код МВЗ</th>
                <th rowspan="3" style="width: 100px">Заказ ТОРО</th>
                <th rowspan="3" style="width: 300px;">Наименование МВЗ</th>
                <th rowspan="3" style="width: 300px">Наименование работ (услуг) (в разрезе их подвидов в соответствии с
                  технической спецификацией, заданием, графиком выполнения работ (услуг) при их наличии)
                </th>
                <th rowspan="3" style="width: 300px">Дата выполнения работ (оказания услуг)**</th>
                <th rowspan="3" style="width: 250px">Сведения об отчете о научных исследованиях, маркетинговых,
                  консультационных и прочих услугах (дата, номер, количество страниц) (при их наличии)***
                </th>
                <th rowspan="3">Единица измерения</th>
                <th colspan="5" rowspan="1">Выполнено работ (оказано услуг)</th>
              </tr>
              <tr>
                <th rowspan="2">количество</th>
                <th rowspan="2">цена за единицу</th>
                <th rowspan="1" colspan="2">снижение стоимости</th>
                <th rowspan="2">стоимость</th>
              </tr>
              <tr>
                <th>основание*</th>
                <th>сумма снижения, тенге</th>
              </tr>
              <tr>
                <td v-for="i in [1,2,3,4,5,6,7,8,9,10,11,12,13,14]" :key="i">{{ i }}</td>
              </tr>
              <tr style="height: 40px; background: white"
                  v-if="act.global_work + act.global_mvz !== '' && act.global_work + act.global_mvz !== '--'">
                <td v-for="i in [1,2,3,4,5,6,7,8,9,10,11,12,13]" :key="i">
                  <span v-if="i === 4 && act.global_mvz !== 'Отсутствует'">{{ act.global_mvz }}</span>
                  <span v-else-if="i === 4 && act.global_mvz === 'Отсутствует'">—</span>
                  <span v-else-if="i === 5 && act.global_work !== 'Отсутствует'">{{ act.global_work }}</span>
                  <span v-else-if="i === 5 && act.global_work === 'Отсутствует'">—</span>
                </td>
              </tr>
              </thead>
              <tbody>
              <tr style="height: 40px; background: white" v-for="row in this.act.rows" :key="row.id">
                <td data-label="">{{ row.serial_number }}</td>
                <td data-label=""><span v-if="row.cost_code !== 'Отсутствует'">{{ row.cost_code }}</span><span
                    v-else>—</span></td>
                <td data-label=""><span v-if="row.mvz_code !== 'Отсутствует'">{{ row.mvz_code }}</span><span
                    v-else>—</span></td>
                <td data-label=""><span v-if="row.new_tora_zakaz !== 'Отсутствует'">{{ row.new_tora_zakaz }}</span><span
                    v-else>—</span></td>
                <td data-label="">{{ row.mvz_name }}</td>
                <td data-label="">{{ row.work_name }}</td>
                <td data-label="" v-if="row.double_date">{{ row.start_date }} : {{ row.end_date }}</td>
                <td data-label="" v-else>{{ row.row_date }}</td>
                <td data-label=""><span v-if="row.research_report !== 'Отсутствует'">{{
                    row.research_report
                  }}</span><span v-else>—</span></td>
                <td data-label="">{{ row.measure_unit }}</td>
                <td data-label="">{{ prettyPrice(row.count) }}</td>
                <td data-label="">{{ prettyPrice(row.unit_price) }}</td>
                <td data-label="">{{ row.reason }}</td>
                <td data-label="">{{ prettyPrice(row.reason_cost) }}</td>
                <td data-label="">{{ prettyPrice(row.total) }}</td>
              </tr>
              <tr style="height: 40px; background: white">
                <td v-for="i in [1,2,3,4,5,6,7,8,9,10,11,12,13]" :key="i">
                  <span v-if="i === 5">Итого:</span>
                  <span v-else-if="i === 13">{{ prettyPrice(act.total) }}</span>
                </td>
              </tr>
              <tr style="height: 40px; background: white">
                <td v-for="i in [1,2,3,4,5,6,7,8,9,10,11,12,13]" :key="i">
                  <span v-if="i === 5">Сумма НДС:</span>
                  <span v-else-if="i === 13">{{ prettyPrice(act.nds) }}</span>
                </td>
              </tr>
              <tr style="height: 40px; background: white">
                <td v-for="i in [1,2,3,4,5,6,7,8,9,10,11,12,13]" :key="i">
                  <span v-if="i === 5">Всего:</span>
                  <span v-else-if="i === 13">{{ prettyPrice(act.total_with_nds) }}</span>
                </td>
              </tr>
              </tbody>
            </table>
            <table class="m-table-act" v-else-if="act.type === 'ДКС'">
              <thead>
              <tr>
                <td colspan="11" style="height: 40px; text-align: center"><b>АКТ ВЫПОЛНЕНИЯ РАБОТ (ОКАЗАННЫХ УСЛУГ)*</b>
                </td>
              </tr>
              <tr style="height: 40px">
                <th style="width: 3%">№п/п</th>
                <th style="width: 8%">Код затрат</th>
                <th style="width: 11%">Месторождение № скважин</th>
                <th style="width: 9%">Код ресурса</th>
                <th style="width: 9%">СПП, ВЗ, МВЗ</th>
                <th style="width: 19%">Наименование работ</th>
                <th style="width: 5%">Ед.изм.</th>
                <th style="width: 6.5%">Кол-во</th>
                <th style="width: 8%">Цена, тенге</th>
                <th style="width: 10%">Сумма, тенге</th>
                <th style="width: 11.5%">Примечание</th>
              </tr>
              <tr>
                <td v-for="i in [1,2,3,4,5,6,7,8,9,10,11]" :key="i">{{ i }}</td>
              </tr>
              </thead>
              <tbody>
              <tr style="height: 40px; background: white" v-for="row in this.act.rows" :key="row.id">
                <td data-label="">{{ row.serial_number }}</td>
                <td data-label=""><span v-if="row.cost_code !== 'Отсутствует'">{{ row.cost_code }}</span><span
                    v-else>—</span></td>
                <td data-label="">{{ row.mvz_name }}</td>
                <td data-label=""><span v-if="row.source_code !== 'Отсутствует'">{{ row.source_code }}</span><span
                    v-else>—</span></td>
                <td data-label=""><span v-if="row.mvz_code !== 'Отсутствует'">{{ row.mvz_code }}</span><span
                    v-else>—</span></td>
                <td data-label="">{{ row.work_name }}</td>
                <td data-label="">{{ row.measure_unit }}</td>
                <td data-label="">{{ prettyPrice(row.count) }}</td>
                <td data-label="">{{ prettyPrice(row.unit_price) }}</td>
                <td data-label="">{{ prettyPrice(row.total) }}</td>
                <td data-label="">{{ row.research_report }}</td>
              </tr>
              <tr style="height: 40px; background: white">
                <td style="font-weight: bold" colspan="2">
                  Итого
                </td>
                <td v-for="i in [3,4,5,6,7,8,9,10,11]" :key="i" style="font-weight: bold">
                  <span v-if="i === 10">{{ prettyPrice(act.total) }}</span>
                </td>
              </tr>
              <tr style="height: 40px; background: white">
                <td style="font-weight: bold" colspan="2">
                  Сумма НДС
                </td>
                <td v-for="i in [3,4,5,6,7,8,9,10,11]" :key="i" style="font-weight: bold">
                  <span v-if="i === 10">{{ prettyPrice(act.nds) }}</span>
                </td>
              </tr>
              <tr style="height: 40px; background: white">
                <td style="font-weight: bold" colspan="2">
                  Итого с НДС
                </td>
                <td v-for="i in [3,4,5,6,7,8,9,10,11]" :key="i" style="font-weight: bold">
                  <span v-if="i === 10">{{ prettyPrice(act.total_with_nds) }}</span>
                </td>
              </tr>
              </tbody>
            </table>
            <h4 class="hp" style="padding: 1rem; text-align: right;">
              {{ act.total_numwords }}
            </h4>
          </div>
        </div>
      </div>
      <div class="p-10" v-if="this.fast_filter === '2'">
        <div class="bg-gradient_solid">
          <div class="container">
            <div class="section-header">
              <h2>История / действия</h2>
              <hr>
            </div>
            <div class="steps">
              <div :class="'steps-container ' + slideClass(index)" v-for="(action, index) in this.act.actions"
                   :key="action.id" :id="'action-tr-' + action.id">
                <div class="content" v-if="true" style="min-width: 85%">
                  <!--                  is_IMPL && !action.private || !is_IMPL-->
                  <h2>{{ action.user.first_name }} {{ action.user.last_name }} / {{ action.datetime_point_parsed }}</h2>
                  <p v-if="action.name !== 'Содержание акта редактировано'">{{ action.name }}. {{ action.comment }}</p>
                  <p v-if="action.name === 'Содержание акта редактировано'">{{ action.name }}.</p>
                  <div v-if="action.name === 'Содержание акта редактировано'">
                    <p v-for="(action_row, index) in action.comment.split(';')" :key="index">
                      <span v-if="index === action.comment.split(';').length - 1">{{ action_row }}.</span>
                      <span v-else>{{ action_row }}, </span>
                    </p>
                  </div>
                </div>
                <div class="content" v-else style="min-width: 85%">
                  <h2>Информация скрыта настройками приватности</h2>
                  <p>Информация скрыта настройками приватности, для подробной информации обратитесь к администратору
                    системы</p>
                </div>
                <i class="step-line"></i>
                <div class="date">{{ action.short_date }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="p-10" v-if="this.fast_filter === '3'">
        <div class="section-header">
          <h2>Согласующие</h2>
          <hr>
          <p>Текущий статус подсвечивается <b
              style="background-color: #23468c; color: whitesmoke">cиним</b></p>

        </div>
        <div class="card">
          <div class="table-responsive">
            <table class="table table-hover table-nowrap">
              <thead class="thead-light">
              <tr>
                <th scope="col" class="" style="border-top-left-radius: 0.75rem !important;">№</th>
                <th scope="col" class="">ФИО</th>
                <th scope="col" class="">Должность</th>
                <th scope="col" class="">Организация</th>
                <th scope="col" class="mainless">Контакты</th>
                <th scope="col" class="" style="border-top-right-radius: 0.75rem !important;">Решение</th>
                <th scope="col" class="mainless text-center"
                    style="border-top-right-radius: 0.75rem !important; width: 250px !important; "
                    v-if="is_ABP && act.status !== 'На подписании у подрядчика' && act.status !== 'На подрядчике' && !act.contract.is_mirror">
                  Действия
                </th>
              </tr>
              </thead>
              <tbody v-for="approver in this.act.approvers" :key="approver.id">
              <tr v-if="approver.decision === 'Назначен'" style="background: #23468c; color: whitesmoke">
                <td data-label="" class="-x" style="padding: 1.5rem; height: 40px">
                  {{ approver.serial_number }}
                </td>
                <td data-label="" class="" style="padding: 1.5rem; height: 40px">
                  {{ approver.const_last_name }} {{ approver.const_first_name }}
                  <span v-if="approver.const_middle_name && approver.const_middle_name !== 'None'">{{ approver.const_middle_name }}</span>
                </td>
                <td data-label="" class="" style="padding: 1.5rem; height: 40px">
                  {{ approver.const_position }}
                </td>
                <td data-label="" class="" style="padding: 1.5rem; height: 40px">
                  {{ approver.const_company_name }}
                </td>
                <td data-label="" class="mainless" style="padding: 1.5rem; height: 40px">
                  {{ approver.user.ouser.phone }} {{ approver.user.email }}
                </td>
                <td data-label="" class="" style="padding: 1.5rem; height: 40px">
                  {{ approver.decision }}
                </td>
                <td data-label="" class="mainless" style="width: 250px !important; height: 40px;"
                    v-if="is_ABP && act.status !== 'На подписании у подрядчика' && act.status !== 'На подрядчике' && !act.contract.is_mirror">
                  <div v-if="is_ABP && approver.user.id !== act.contract.abp.id && !approver.is_impl &&
                             act.status !== 'На подписании у подрядчика' && act.status !== 'На подрядчике' &&
                            'Назначен, Отклонен, Проект'.includes(approver.decision)"
                       style="display: flex; justify-content: center; gap: 1rem">
                    <i class="material-icons section-btn-bx-static section-btn-bx-static-change text-center"
                       style="background: #1da1f2;" @click="showChangeApproverModalABP(approver.user.id)">edit</i>
                    <i v-if="!approver.ratifier" @click="removeDZOCurrentApprovers(approver.user.id)"
                       class="material-icons section-btn-bx-static section-btn-bx-static-remove text-center"
                       style="background: #f36;">delete</i>
                    <modal :name="'change-approver-modal-abp-' + approver.user.id" :height="300" :width="950">
                      <div class="modal-inner"
                           style="height: inherit; width: inherit; text-align: left; overflow: auto">
                        <div style="display: flex; justify-content: space-between; padding: .5rem; height: 4rem;">
                          <div>
                            <h3 style="padding: 1rem">Замена подписанта {{ approver.user.ouser.full_name }}</h3>
                          </div>
                          <button v-if="dzo_current_approver !== ''" class="select_button text-center"
                                  style="width: 200px; border-radius: .5rem; padding-right: 1rem; height: 100%; background: #23468c !important"
                                  @click="changeDZOCurrentApprovers(approver.user.id)">
                            Заменить
                          </button>
                        </div>
                        <div class="modal-info"
                             style="width: 100%; height: auto; display: flex; justify-content: flex-start; align-items: flex-start; flex-direction: column">
                          <select class="select_button" :id="'select_approver_' + approver.user.id"
                                  v-on:change="change_dzo_current_approver"
                                  v-model="selected_dzo_current_approver">
                            <option class="default_option" value="">Выберите нового согласующего</option>
                            <option class="default_option" v-for="ouser in all_collegues" :key="ouser.id" :value="ouser"
                                    :title="ouser.full_name.toUpperCase() + ' / ' + ouser.position.toUpperCase() + ' / ' + ouser.company.name.toUpperCase()">
                              {{ ouser.full_name.toUpperCase() }} /
                              {{ ouser.position_short.toUpperCase() }}
                            </option>
                          </select>
                          <table class="table table-hover table-nowrap">
                            <thead class="thead-light">
                            <tr>
                              <th>№</th>
                              <th>ФИО</th>
                              <th>Должность</th>
                              <th>Тип согласования</th>
                              <th>Ваш комментарий</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-if="dzo_current_approver !== ''" style="font-weight: bold">
                              <td>{{ approver.serial_number }}</td>
                              <td>
                                {{ dzo_current_approver.fio }}
                              </td>
                              <td :title="dzo_current_approver.position">
                                {{ dzo_current_approver.position_short }}
                              </td>
                              <td>
                                <span v-if="approver.ratifier">Утверждающий</span>
                                <span v-else>Согласующий</span>
                              </td>
                              <td style="padding: 0; height: 30px;">
                                <input class="" style="width: 100% !important; height: 100% !important; padding: .5rem"
                                       v-model="dzo_current_approver.comment">
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </modal>
                  </div>
                </td>
              </tr>
              <tr v-else>
                <td data-label="" class="-x" style="padding: 1.5rem; height: 40px">
                  {{ approver.serial_number }}
                </td>
                <td data-label="" class="" style="padding: 1.5rem; height: 40px">
                  {{ approver.const_last_name }} {{ approver.const_first_name }} {{ approver.const_middle_name }}
                </td>
                <td data-label="" class="" style="padding: 1.5rem; height: 40px">
                  {{ approver.const_position }}
                </td>
                <td data-label="" class="" style="padding: 1.5rem; height: 40px">
                  {{ approver.const_company_name }}
                </td>
                <td data-label="" class="mainless" style="padding: 1.5rem; height: 40px">
                  {{ approver.user.ouser.phone }} {{ approver.user.email }}
                </td>
                <td data-label="" class="" style="padding: 1.5rem; height: 40px">
                  {{ approver.decision }}
                </td>
                <td data-label="" class="mainless" style="width: 250px !important; height: 40px;"
                    v-if="is_ABP && act.status !== 'На подписании у подрядчика' && act.status !== 'На подрядчике' && !act.contract.is_mirror">
                  <div v-if="is_ABP && approver.user.id !== act.contract.abp.id && !approver.is_impl &&
                             act.status !== 'На подписании у подрядчика' && act.status !== 'На подрядчике' &&
                            'Назначен, Отклонен, Проект'.includes(approver.decision)"
                       style="display: flex; justify-content: center; gap: 1rem">
                    <i class="material-icons section-btn-bx-static section-btn-bx-static-change text-center"
                       style="background: #1da1f2;" @click="showChangeApproverModalABP(approver.user.id)">edit</i>
                    <i v-if="!approver.ratifier" @click="removeDZOCurrentApprovers(approver.user.id)"
                       class="material-icons section-btn-bx-static section-btn-bx-static-remove text-center"
                       style="background: #f36;">delete</i>
                    <modal :name="'change-approver-modal-abp-' + approver.user.id" :height="300" :width="950">
                      <div class="modal-inner"
                           style="height: inherit; width: inherit; text-align: left; overflow: auto">
                        <div style="display: flex; justify-content: space-between; padding: .5rem; height: 4rem;">
                          <div>
                            <h3 style="padding: 1rem">Замена подписанта {{ approver.user.ouser.full_name }}</h3>
                          </div>
                          <button v-if="dzo_current_approver !== ''" class="select_button text-center"
                                  style="width: 200px; border-radius: .5rem; padding-right: 1rem; height: 100%; background: #23468c !important"
                                  @click="changeDZOCurrentApprovers(approver.user.id)">
                            Заменить
                          </button>
                        </div>
                        <div class="modal-info"
                             style="width: 100%; height: auto; display: flex; justify-content: flex-start; align-items: flex-start; flex-direction: column">
                          <select class="select_button" :id="'select_approver_' + approver.user.id"
                                  v-on:change="change_dzo_current_approver"
                                  v-model="selected_dzo_current_approver">
                            <option class="default_option" value="">Выберите нового согласующего</option>
                            <option class="default_option" v-for="ouser in all_collegues" :key="ouser.id" :value="ouser"
                                    :title="ouser.full_name.toUpperCase() + ' / ' + ouser.position.toUpperCase() + ' / ' + ouser.company.name.toUpperCase()">
                              {{ ouser.full_name.toUpperCase() }} /
                              {{ ouser.position_short.toUpperCase() }}
                            </option>
                          </select>
                          <table class="table table-hover table-nowrap">
                            <thead class="thead-light">
                            <tr>
                              <th>№</th>
                              <th>ФИО</th>
                              <th>Должность</th>
                              <th>Тип согласования</th>
                              <th>Ваш комментарий</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-if="dzo_current_approver !== ''" style="font-weight: bold">
                              <td>{{ approver.serial_number }}</td>
                              <td>
                                {{ dzo_current_approver.fio }}
                              </td>
                              <td :title="dzo_current_approver.position">
                                {{ dzo_current_approver.position_short }}
                              </td>
                              <td>
                                <span v-if="approver.ratifier">Утверждающий</span>
                                <span v-else>Согласующий</span>
                              </td>
                              <td style="padding: 0; height: 30px;">
                                <input class="" style="width: 100% !important; height: 100% !important; padding: .5rem"
                                       v-model="dzo_current_approver.comment">
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </modal>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="p-10" v-if="this.fast_filter === '4'">
        <div class="section-header">
          <h2>Файлы</h2>
          <hr>
        </div>
        <div class="card">
          <div class="table-responsive">
            <table class="table table-hover table-nowrap">
              <thead class="thead-light">
              <tr>
                <th scope="col" class="">№ п/п</th>
                <th scope="col" class="mainless">Наименование</th>
                <th scope="col" class="">Файл(-ы)</th>
                <th scope="col" class="mainless">Тип файла</th>
                <th scope="col" class="mainless">Расширения</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(add_file, index) in this.act.additional_files" :key="add_file.id"
                  :id="'req-tr-' + add_file.id" class="tr-main">
                <td data-label="" class="">
                  {{ index + 1 }}
                </td>
                <td data-label="" class="mainless">
                  {{ add_file.name }}
                </td>
                <td data-label="" class="">
                  <a class="custom-link" target="_blank" :href="add_file.file">
                    {{ add_file.file }}
                  </a>
                </td>
                <td data-label="" class="mainless">
                  Доп. файл
                </td>
                <td data-label="" class="mainless">
                  {{ add_file.extension }}
                </td>
              </tr>
              <tr v-for="(double_im, index) in this.act.before_after_images" :key="double_im.id"
                  :id="'req-tr-d-' + double_im.id" class="tr-main">
                <td data-label="" class="">
                  {{ index + 1 + act.additional_files.length }}
                </td>
                <td data-label="" class="mainless">
                  Изображения до-после №{{ index + 1 }}
                </td>
                <td data-label="" class="">
                  <a class="custom-link" @click="showDoubleImageModal(double_im.id)">
                    Просмотр изображений
                  </a>
                  <modal :name="'double-modal-' + double_im.id" :height="900" :width="1000">
                    <div class="modal-inner" style="height: inherit; width: inherit; text-align: left; overflow: auto">
                      <h3 class="modal-title" style="padding-bottom: 0">Изображение до-после №{{ index + 1 }}</h3>
                      <div class="modal-info"
                           style="width: auto; height: 100%; display: flex; justify-content: center; align-items: center; flex-direction: column">
                        <img-comparison-slider>
                          <img slot="before" :src="double_im.file_fst"
                               style="width: auto; height: auto; object-fit: contain !important;"/>
                          <img slot="after" :src="double_im.file_snd"
                               style="width: 100%; height: auto; object-fit: contain !important;"/>
                        </img-comparison-slider>
                      </div>
                    </div>
                  </modal>
                </td>
                <td data-label="" class="mainless">
                  Двойное изображение
                </td>
                <td data-label="" class="mainless">
                  {{ double_im.file_fst.split('.')[double_im.file_fst.split('.').length - 1].toUpperCase() }},
                  {{ double_im.file_snd.split('.')[double_im.file_snd.split('.').length - 1].toUpperCase() }}
                </td>
              </tr>
              </tbody>
            </table>
          </div>

        </div>
      </div>
      <div class="p-10" v-if="this.fast_filter === '5'">
        <div class="section-header">
          <h2>Дополнительные формы</h2>
          <hr>
          <div class="innernavbar-wrap p-10"
               style="padding-top: 0 !important; display: flex; justify-content: center; gap: 1rem">
            <li :class="'ul-li ' + fast_active_form_3(true)" @click="change_fast_form_3(true)">Акт приемки</li>
            <li :class="'ul-li ' + fast_active_form_3(false)" @click="change_fast_form_3(false)">Справка</li>
          </div>
        </div>
        <div class="card p-10" v-if="this.show_form_3">
          <div style="display: flex; justify-content: center; align-items: center; flex-direction: column;">
            <h4>Акт приемки выполненных работ №{{ act.number }}</h4>
            <h4>за {{ act.act_month }} месяц {{ act.act_date.split('-')[0] }} г.</h4>
            <h4>{{ act.dks_name }}</h4>
          </div>
          <div
              style="display: flex; justify-content: space-between; gap: 1rem; margin-top: .5rem; height: 2rem !important;">
            <span>{{ act.price_comment }}</span>
            <span>Название объекта: {{ act.object_name_short }}</span>
          </div>
          <div class="table-responsive">
            <table class="m-table-act" id="price42" style="border: none !important;">
              <tr>
                <th rowspan="3" style="">№ п/п</th>
                <th rowspan="3" style="">Шифр норм, код ресурса</th>
                <th rowspan="3" style="">Наименование работ</th>
                <th rowspan="3" style="">Единица измерения</th>
                <th rowspan="3" style="">Количество</th>
                <th colspan="2" style="">Стоимость единицы, тенге</th>
                <th colspan="3" style="">Общая стоимость, тенге</th>
                <th rowspan="2" style="">Накладные расходы, тенге</th>
                <th rowspan="3" style="">Всего стоимость с НР и СП, тенге</th>
              </tr>
              <tr>
                <th>Всего</th>
                <th>эксплуатация машин</th>
                <th>Всего</th>
                <th>эксплуатация машин</th>
                <th>материалы</th>
              </tr>
              <tr>
                <th>зарплата рабочих- стоителей</th>
                <th>в т.ч. зарплата машинистов</th>
                <th>зарплата рабочих- стоителей</th>
                <th>в т.ч. зарплата машинистов</th>
                <th>оборудование, мебель, инвентарь</th>
                <th>Сметная прибыль, тенге</th>
              </tr>
              <tr>
                <th v-for="i in [1,2,3,4,5,6,7,8,9,10,11,12]" :key="i">{{ i }}</th>
              </tr>
              <tbody v-for="(row, index) in act.rows_accept" :key="index">
              <tr v-if="row.type === 'Пустая строка'">
                <td colspan="12" style="height: 35px"></td>
              </tr>
              <tr v-else-if="row.type === 'Отдел/раздел'">
                <td colspan="12" style="font-weight: bold; font-size: 16px">{{ row.work_name }}</td>
              </tr>
              <tr v-else-if="row.type === 'Одна строка'">
                <td></td>
                <td></td>
                <td>{{ row.work_name }}</td>
                <td>{{ row.measure_unit }}</td>
                <td><span v-if="row.count > 0">{{ row.count }}</span></td>
                <td><span v-if="row.unit_all > 0">{{ row.unit_all }}</span></td>
                <td><span v-if="row.unit_car > 0">{{ row.unit_car }}</span></td>
                <td><span v-if="row.total_all > 0">{{ row.total_all }}</span></td>
                <td><span v-if="row.total_car > 0">{{ row.total_car }}</span></td>
                <td><span v-if="row.total_material > 0">{{ row.total_material }}</span></td>
                <td><span v-if="row.overheads > 0">{{ row.overheads }}</span></td>
                <td><span v-if="row.cost_total > 0">{{ row.cost_total }}</span></td>
              </tr>
              <tr v-else-if="row.type === 'Одна строка - объединенная'">
                <td></td>
                <td colspan="2">{{ row.work_name }}</td>
                <td>{{ row.measure_unit }}</td>
                <td><span v-if="row.count > 0">{{ row.count }}</span></td>
                <td><span v-if="row.unit_all > 0">{{ row.unit_all }}</span></td>
                <td><span v-if="row.unit_car > 0">{{ row.unit_car }}</span></td>
                <td><span v-if="row.total_all > 0">{{ row.total_all }}</span></td>
                <td><span v-if="row.total_car > 0">{{ row.total_car }}</span></td>
                <td><span v-if="row.total_material > 0">{{ row.total_material }}</span></td>
                <td><span v-if="row.overheads > 0">{{ row.overheads }}</span></td>
                <td><span v-if="row.cost_total > 0">{{ row.cost_total }}</span></td>
              </tr>
              <tr v-if="row.type === 'Двойная строка'">
                <td rowspan="2">{{ row.serial_number }}</td>
                <td rowspan="2">{{ row.source_code }}</td>
                <td rowspan="2">{{ row.work_name }}</td>
                <td rowspan="2">{{ row.measure_unit }}</td>
                <td rowspan="2"><span v-if="row.count > 0">{{ row.count }}</span></td>
                <td><span v-if="row.unit_all > 0">{{ row.unit_all }}</span><span v-else>---</span></td>
                <td><span v-if="row.unit_car > 0">{{ row.unit_car }}</span><span v-else>---</span></td>
                <td><span v-if="row.total_all > 0">{{ row.total_all }}</span><span v-else>---</span></td>
                <td><span v-if="row.total_car > 0">{{ row.total_car }}</span><span v-else>---</span></td>
                <td><span v-if="row.total_material > 0">{{ row.total_material }}</span><span v-else>---</span></td>
                <td><span v-if="row.overheads > 0">{{ row.overheads }}</span><span v-else>---</span></td>
                <td rowspan="2"><span v-if="row.cost_total > 0">{{ row.cost_total }}</span><span v-else>---</span></td>
              </tr>
              <tr v-if="row.type === 'Двойная строка'">
                <td><span v-if="row.unit_salary > 0">{{ row.unit_salary }}</span><span v-else>---</span></td>
                <td><span v-if="row.unit_salary_car > 0">{{ row.unit_salary_car }}</span><span v-else>---</span></td>
                <td><span v-if="row.total_salary > 0">{{ row.total_salary }}</span><span v-else>---</span></td>
                <td><span v-if="row.total_salary_car > 0">{{ row.total_salary_car }}</span><span v-else>---</span></td>
                <td><span v-if="row.total_furniture > 0">{{ row.total_furniture }}</span><span v-else>---</span></td>
                <td><span v-if="row.estimated_profit > 0">{{ row.estimated_profit }}</span><span v-else>---</span></td>
              </tr>
              <tr v-if="row.type === 'Двойная строка - итого'">
                <td rowspan="2"></td>
                <td rowspan="2" colspan="2">{{ row.work_name }}</td>
                <td rowspan="2">{{ row.measure_unit }}</td>
                <td rowspan="2"><span v-if="row.count > 0">{{ row.count }}</span></td>
                <td><span v-if="row.unit_all > 0">{{ row.unit_all }}</span><span v-else>---</span></td>
                <td><span v-if="row.unit_car > 0">{{ row.unit_car }}</span><span v-else>---</span></td>
                <td><span v-if="row.total_all > 0">{{ row.total_all }}</span><span v-else>---</span></td>
                <td><span v-if="row.total_car > 0">{{ row.total_car }}</span><span v-else>---</span></td>
                <td><span v-if="row.total_material > 0">{{ row.total_material }}</span><span v-else>---</span></td>
                <td><span v-if="row.overheads > 0">{{ row.overheads }}</span><span v-else>---</span></td>
                <td rowspan="2"><span v-if="row.cost_total > 0">{{ row.cost_total }}</span><span v-else>---</span></td>
              </tr>
              <tr v-if="row.type === 'Двойная строка - итого'">
                <td><span v-if="row.unit_salary > 0">{{ row.unit_salary }}</span><span v-else>---</span></td>
                <td><span v-if="row.unit_salary_car > 0">{{ row.unit_salary_car }}</span><span v-else>---</span></td>
                <td><span v-if="row.total_salary > 0">{{ row.total_salary }}</span><span v-else>---</span></td>
                <td><span v-if="row.total_salary_car > 0">{{ row.total_salary_car }}</span><span v-else>---</span></td>
                <td><span v-if="row.total_furniture > 0">{{ row.total_furniture }}</span><span v-else>---</span></td>
                <td><span v-if="row.estimated_profit > 0">{{ row.estimated_profit }}</span><span v-else>---</span></td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="card p-10" v-else>
          <div style="display: flex; justify-content: center; align-items: center; flex-direction: column;">
            <h4>Справка</h4>
            <h4>о стоимости выполненных строительных работ и затрат</h4>
            <h4>(по ресурсному методу)</h4>
            <h4>{{ act.dks_cert_name }}</h4>
          </div>
          <div
              style="display: flex; justify-content: space-between; gap: 1rem; margin-top: .5rem; height: 2rem !important;">
            <span>Адрес объекта: {{ act.object_address }}</span>
          </div>
          <div class="table-responsive">
            <table class="m-table-act" id="price52" style="">
              <tr>
                <th colspan="4">Код заказчика</th>
                <th colspan="2">Код подрядчика</th>
                <th colspan="2">Договор</th>
                <th colspan="3">Источник финансирования</th>
                <th colspan="2">Организационно-правовая форма</th>
              </tr>
              <tr>
                <th>код учереждения банка</th>
                <th colspan="3">№ счета</th>
                <th>код учереждения банка</th>
                <th>№ счета</th>
                <th>номер</th>
                <th>дата</th>
                <th>наименование</th>
                <th colspan="2">код</th>
                <th>наименование</th>
                <th>код</th>
              </tr>
              <tr>
                <td>{{ act.customer_bank_code }}</td>
                <td colspan="3">{{ act.customer_account_number }}</td>
                <td>{{ act.impl_bank_code }}</td>
                <td>{{ act.impl_account_number }}</td>
                <td>{{ act.contract_number }}</td>
                <td>{{ act.contract_date }}</td>
                <td>{{ act.finance_source }}</td>
                <td colspan="2">{{ act.finance_code }}</td>
                <td>{{ act.org_form_name }}</td>
                <td>{{ act.org_form_code }}</td>
              </tr>
              <tr>
                <th rowspan="4">Наименование пусковых, комплексов, объектов</th>
                <th colspan="10">Стоимость выполненных работ в тыс.тенге</th>
                <th rowspan="4">НДС</th>
                <th rowspan="4">всего к оплате с НДС</th>
              </tr>
              <tr>
                <th rowspan="3">с начала года по отчетный месяц включительно с НДС</th>
                <th rowspan="3">с начала строительства по отчетный месяц включительно без НДС</th>
                <th rowspan="3">Общая стоимость договора на без НДС</th>
                <th rowspan="3">в отчетном месяце (за {{ act.act_month }}) без НДС</th>
                <th colspan="6">из графы 1</th>
              </tr>
              <tr>
                <th rowspan="2">Строительно монтажные работы по смете</th>
                <th colspan="2">из них</th>
                <th rowspan="2">стоимость прочих затрат, не входящих в состав СМР по смете 2021г.</th>
                <th colspan="2">дополнительные затраты</th>
              </tr>
              <tr>
                <th>в отчетном месяце</th>
                <th>по монтажу оборудования</th>
                <th>на приобретение материалов</th>
                <th>другие дополнительные расходы</th>
              </tr>
              <tr>
                <th>A</th>
                <th v-for="i in [1,2,3,4,5,6,7,8,9,10,11,12]" :key="i">{{ i }}</th>
              </tr>
              <tr v-for="(row, index) in act.rows_cert" :key="index">
                <td>{{ row.work_name }}</td>
                <td>{{ prettyPrice(row.from_beg_year) }}</td>
                <td>{{ prettyPrice(row.from_beg_construction) }}</td>
                <td>{{ prettyPrice(row.contract_total) }}</td>
                <td>{{ prettyPrice(row.current_month) }}</td>
                <td><span v-if="row.constr_install > 0">{{ row.constr_install }}</span></td>
                <td><span v-if="row.constr_install > 0">{{ row.constr_install_month }}</span></td>
                <td><span v-if="row.constr_install > 0">{{ row.install_furniture }}</span></td>
                <td><span v-if="row.constr_install > 0">{{ row.other_expenses }}</span></td>
                <td><span v-if="row.constr_install > 0">{{ row.materials_purchase }}</span></td>
                <td><span v-if="row.constr_install > 0">{{ row.additional_costs }}</span></td>
                <td>{{ prettyPrice(row.nds) }}</td>
                <td>{{ prettyPrice(row.total_with_nds) }}</td>
              </tr>
              <tr>
                <td>Итого</td>
                <td>{{ prettyPrice(act.total_from_beg_year) }}</td>
                <td>{{ prettyPrice(act.total_from_beg_construction) }}</td>
                <td>{{ prettyPrice(act.total_contract_total) }}</td>
                <td>{{ prettyPrice(act.total_current_month) }}</td>
                <td v-for="i in [5,6,7,8,9,10]" :key="i"></td>
                <td>{{ prettyPrice(act.total_nds) }}</td>
                <td>{{ prettyPrice(act.total_twn) }}</td>
              </tr>
              <tr>
                <td>НДС 12%</td>
                <td></td>
                <td>{{ prettyPrice(act.nds_from_beg_construction) }}</td>
                <td>{{ prettyPrice(act.nds_contract_total) }}</td>
                <td>{{ prettyPrice(act.nds_current_month) }}</td>
                <td v-for="i in [5,6,7,8,9,10,11,12]" :key="i"></td>
              </tr>
              <tr>
                <td>Итого с НДС</td>
                <td></td>
                <td>{{ prettyPrice(act.twn_from_beg_construction) }}</td>
                <td>{{ prettyPrice(act.twn_contract_total) }}</td>
                <td>{{ prettyPrice(act.twn_current_month) }}</td>
                <td v-for="i in [5,6,7,8,9,10,11,12]" :key="i"></td>
              </tr>
            </table>
          </div>
        </div>
      </div>

      <div class="in_sap_card"
           v-if='act.status && act.status.includes("Подписано") && is_BOOKER && act.acc_id_list.includes(";")'>
        <span class="badge bg-soft-success text-success sap-success">Проведено в SAP</span>
      </div>
      <div class="in_sap_card"
           v-if='act.status && act.status.includes("Подписано") && is_BOOKER && (act.contract.impl_company.id == 97 || act.contract.is_tora)'>
        <span class="badge bg-soft-warning text-warning sap-warning" style="left: 6.8% !important;">Не требует проведения в SAP</span>
      </div>
      <div class="archived_card"
           v-if='act.is_archived'>
        <span class="badge archived-warning">Архивировано</span>
      </div>

      <modal name="actions-modal" :height="200">
        <h4 class="p-10" style="text-align: center; margin-bottom: 30px">Выберите действие над актом</h4>
        <br>
        <div class="modal-inner" style="display: flex; justify-content: space-between; gap: 2rem">
          <button v-if="curr_APPR && !curr_RAT && !curr_ABP" class="one-action-btn" title="Согласовать"
                  @click="accept_reject('accept')">
            <i class="material-icons section-btn-bx" style="background: #0c8;">done</i>
          </button>
          <button v-if="curr_APPR && !act.contract.is_mirror" class="one-action-btn" title="Отклонить" @click="showRejectModal()">
            <i class="material-icons section-btn-bx" style="background: #f36;">block</i>
          </button>
          <button v-if="curr_APPR && act.contract.is_mirror && curr_user.id !== act.contract.abp.id" class="one-action-btn" title="Отклонить" @click="showRejectModal()">
            <i class="material-icons section-btn-bx" style="background: #f36;">block</i>
          </button>
        </div>
      </modal>
      <modal name="reject-modal" :height="600">
        <div class="modal-inner"
             style="display: flex; justify-content: center; flex-direction: column; align-items: center; gap: 1rem">
          <h3 style="text-align: center; padding: 20px">Отклонение акта</h3>
          <textarea v-model="reject_comment"
                    style="width: 80%; margin: 1rem; padding: .5rem; height: 5rem; border: 1px gray solid; border-radius: .5rem"
                    placeholder="Введите причину отклонения"></textarea>

          <div class="dropzone" style="width: 80%">
            <input type="file" class="input-field" id="input-field-multiple" ref="files_reject" multiple
                   accept=".pdf, image/png, image/jpeg" @change="reuploadVl1"/>
            <div v-if="!uploading" class="call-to-action">
              Перетащите файлы сюда подтверждающие файлы или нажмите, чтобы загрузить
              <p>
                <small>типы загружаемых файлов: .pdf, .png, .jpeg</small>
              </p>
            </div>
            <div v-if="uploading" class="uploaded-msg">
              Файлы успешно загружены
            </div>
          </div>

          <button v-if="reject_comment !== ''" class="btn file-selector"
                  style="width: 80%; color: white; background: rgb(255, 51, 102) !important;"
                  @click="accept_reject('reject')">Отклонить
          </button>
        </div>
      </modal>
    </section>
    <section class="right-section flex-column-animated" id="right-section"
             style="background: #e4e9f7 !important; overflow: hidden">
      <button v-if="is_IMPL && act.status === 'На подрядчике' && is_OWNER && !act.contract.is_mirror" class="section-btn"
              title="Редактировать" @click="editAct">
        <i class="material-icons section-btn-bx" style="background: #1da1f2;">edit</i>
      </button>
      <button v-if="is_ABP && act.status === 'На подрядчике' && is_OWNERMIRROR" class="section-btn"
              title="Редактировать" @click="editActMirror">
        <i class="material-icons section-btn-bx" style="background: #1da1f2;">edit</i>
      </button>
      <button v-if="is_IMPL && is_OWNER && !act.contract.is_mirror" class="section-btn"
              title="Создать копию" @click="copyAct">
        <i class="material-icons section-btn-bx" style="background: #1da1f2;">file_copy</i>
      </button>
      <button v-if="is_ABP && curr_ABP && act.contract.impl_company.id !== 97 && !act.contract.is_tora && act.status === 'На Администраторе Бюджетной Программы'" class="section-btn"
              title="'Назначить заказы SAP" @click="showABPSAPModal">
        <i class="material-icons section-btn-bx" style="background: #0259fc;">edit_note</i>
      </button>
      <button v-if="is_BOOKER && act.acc_id_list && !act.acc_id_list.includes(';') && act.contract.impl_company.id !== 97 && !act.contract.is_tora && act.status && act.status.includes('Подписано')" class="section-btn"
              title="Провести в SAP" @click="showBOOKERSAPModal">
        <i class="material-icons section-btn-bx" style="background: #0259fc;">arrow_forward</i>
      </button>
      <button v-if="is_APPROVER && is_ECONOMIST && curr_APPR && !act.contract.is_mirror" class="section-btn"
              title="Редактировать" @click="showEconomistEditModal">
        <i class="material-icons section-btn-bx" style="background: #1da1f2;">edit</i>
      </button>
      <button v-if="is_IMPL && act.status === 'На подрядчике' &&
                    is_OWNER && act.approvers.filter(i => i.is_impl).length > 0 && !act.contract.is_mirror"
              class="section-btn" title="Отправить на согласование" @click="sendToApproversImpl">
        <i class="material-icons section-btn-bx" style="background: #0c8;">arrow_forward</i>
      </button>

      <button v-if="all_sap_entered_global && is_ABP && curr_ABP && act.status === 'На Администраторе Бюджетной Программы' &&
                    act.approvers.filter(i => !i.is_impl).length > 1"
              class="section-btn" title="Отправить на согласование" @click="sendToApproversImpl">
        <i class="material-icons section-btn-bx" style="background: #0c8;">arrow_forward</i>
      </button>
      <button v-if="is_IMPL && act.status === 'На подрядчике' && is_OWNER &&
                    act.approvers.filter(i => i.is_impl).length === 0"
              class="section-btn" title="Назначить список подписантов" @click="showAddApproverModalImpl">
        <i class="material-icons section-btn-bx" style="background: #1da1f2;">groups</i>
      </button>
      <button v-if="is_IMPL && act.status === 'На подрядчике' && is_OWNER &&
                    act.approvers.filter(i => i.is_impl).length > 0 && !act.contract.is_mirror" class="section-btn"
              title="Назначить новый список подписантов" @click="showAddApproverModalImpl">
        <i class="material-icons section-btn-bx" style="background: #1da1f2;">groups</i>
      </button>
      <button v-if="is_IMPL && act.contract.is_mirror && act.status === 'На Администраторе Бюджетной Программы' && is_OWNER"
              class="section-btn" title="Назначить список подписантов" @click="showAddApproverModalImpl">
        <i class="material-icons section-btn-bx" style="background: #1da1f2;">groups</i>
      </button>
      <button v-if="is_IMPL && act.status === 'На подрядчике' && is_OWNER && !act.contract.is_mirror" class="section-btn"
              title="Удалить" @click="deleteAct">
        <i class="material-icons section-btn-bx" style="background: #f36;">delete</i>
      </button>
      <button v-if="all_sap_entered_global && is_ABP && curr_ABP &&
                    act.approvers.filter(i => !i.is_impl).length === 1"
              class="section-btn" title="Назначить список подписантов"
              @click="showAddApproverModalABP">
        <i class="material-icons section-btn-bx" style="background: #1da1f2;">groups</i>
      </button>
      <button v-if="is_ABP && is_OWNERMIRROR && act.contract.is_mirror && act.status === 'На подрядчике'"
              class="section-btn" title="Назначить список подписантов"
              @click="showAddApproverModalABP">
        <i class="material-icons section-btn-bx" style="background: #1da1f2;">groups</i>
      </button>
      <button v-if="all_sap_entered_global && is_ABP && curr_ABP &&
                    act.approvers.filter(i => !i.is_impl).length > 1"
              class="section-btn" title="Назначить новый список подписантов"
              @click="showAddApproverModalABP">
        <i class="material-icons section-btn-bx" style="background: #1da1f2;">groups</i>
      </button>
      <!--      <button class="section-btn" title="Архивировать"><i class="bx bx-archive-in section-btn-bx bx-disabled"-->
      <!--                                                          style="background: #f5f595;"></i></button>-->
      <!--      <button class="section-btn" title="Провести в SAP">-->
      <!--        <i class="bx bx-arrow-to-right section-btn-bx bx-disabled" style="background: #0c8;"></i>-->
      <!--      </button>-->
      <button v-if="is_IMPL && is_OWNER && !act.contract.is_mirror &&
                    act.status !== 'На подрядчике' &&
                    act.status !== 'Подписано, посредством ЭЦП' &&
                    act.status !== 'Подписано, без ЭЦП'"
              class="section-btn" title="Отозвать" @click="bringBackAct">
        <i class="material-icons section-btn-bx" style="background: #f36;">arrow_back</i>
      </button>
      <button v-if="is_ABP && act.contract && act.contract.abp.id === curr_user.id &&
                    act.status !== 'На подрядчике' &&
                    act.status !== 'На подписании у подрядчика' &&
                    act.status !== 'На Администраторе Бюджетной Программы' &&
                    act.status !== 'Подписано, посредством ЭЦП' &&
                    act.status !== 'Подписано, без ЭЦП'"
              class="section-btn" title="Отозвать" @click="bringBackAct">
        <i class="material-icons section-btn-bx" style="background: #f36;">arrow_back</i>
      </button>
      <button v-if="curr_APPR && curr_RAT" class="section-btn" title="Подписать посредством ЭЦП" @click="showSignModal">
        <i class="material-icons section-btn-bx" style="background: #0c8;">check</i>
      </button>
      <button v-if="curr_APPR && !curr_RAT && !curr_ABP" class="section-btn" title="Согласовать"
              @click="accept_reject('accept')">
        <i class="material-icons section-btn-bx" style="background: #0c8;">check</i>
      </button>
      <button v-if="curr_APPR && !act.contract.is_mirror" class="section-btn" title="Отклонить" @click="showRejectModal()">
        <i class="material-icons section-btn-bx" style="background: #f36;">block</i>
      </button>
      <button v-if="curr_APPR && act.contract.is_mirror && curr_user.id !== act.contract.abp.id" class="section-btn" title="Отклонить" @click="showRejectModal()">
        <i class="material-icons section-btn-bx" style="background: #f36;">block</i>
      </button>
      <button v-if="!act.is_archived && act.status && act.status.includes('Подписано') && !act.contract.is_mirror" class="section-btn"
              title="Переместить в архив" @click="addToArchive">
        <i class="material-icons section-btn-bx" style="background: #0259fc;">archive</i>
      </button>
      <button v-if="act.is_archived" class="section-btn"
              title="Убрать из архива" @click="rmFromArchive">
        <i class="material-icons section-btn-bx" style="background: #f36;">unarchive</i>
      </button>
      <button class="section-btn" title="Скачать историю согласования" @click="downloadHistoryPDF">
        <i class="material-icons section-btn-bx" style="background: #ff8c00;">schedule</i>
      </button>
      <button class="section-btn" title="Скачать акт" @click="downloadActPDF">
        <i class="material-icons section-btn-bx" style="background: #23468c;">download</i>
      </button>
      <modal name="add-approver-modal-impl" :height="700" :width="950">
        <div class="modal-inner" style="height: auto !important; width: inherit; text-align: left; overflow: auto">
          <div style="width: 100%; display: flex; justify-content: space-between; padding: .5rem; height: 5rem;">
            <h3 class="modal-title" style="padding-bottom: 0">СПИСОК ПОДПИСАНТОВ</h3>
            <button
                class="transition-all ease-in-out duration-150 transform hover:scale-110 bg-green-500 hover:bg-green-800 px-5 py-1 rounded-lg text-white"
                v-if="impl_ratificator !== ''"
                @click="setImplApprovers">
              Отправить
            </button>
          </div>
          <div class="modal-info"
               style="width: 100%; height: auto; display: flex; justify-content: center; align-items: center; flex-direction: column">
            <select class="select_button" id="select_approver" v-on:change="change_impl" v-model="selected_impl">
              <option class="default_option" value="">Выберите следующего согласующего</option>
              <option class="default_option" v-for="ouser in all_collegues" :key="ouser.id" :value="ouser"
                      :title="ouser.full_name.toUpperCase() + ' / ' + ouser.position.toUpperCase() + ' / ' + ouser.company.name.toUpperCase()">
                {{ ouser.full_name.toUpperCase() }} /
                {{ ouser.position_short.toUpperCase() }}
              </option>
            </select>
            <select class="select_button" id="select_ratifitator" v-on:change="change_rat" v-model="selected_rat">
              <option class="default_option" value="">Выберите утверждащего</option>
              <option class="default_option" v-for="ouser in all_collegues" :key="ouser.id" :value="ouser"
                      :title="ouser.full_name.toUpperCase() + ' / ' + ouser.position.toUpperCase() + ' / ' + ouser.company.name.toUpperCase()">
                {{ ouser.full_name.toUpperCase() }} /
                {{ ouser.position_short.toUpperCase() }}
              </option>
            </select>
            <select v-if="impl_approvers.length > 1" class="select_button" v-model="selected_approve_mode">
              <option class="default_option" value="Последовательное согласование">Последовательное согласование
              </option>
              <option class="default_option" value="Параллельное согласование">Параллельное согласование</option>
            </select>
            <!--            <div class="table-responsive">-->
            <table class="table table-hover table-nowrap">
              <thead class="thead-light">
              <tr>
                <th>№</th>
                <th>ФИО</th>
                <th>Должность</th>
                <th>Огранизация</th>
                <th>Тип согласования</th>
                <th>Ваш комментарий</th>
                <th>Действия</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(impl, index) in impl_approvers" :key="index">
                <td>{{ index + 1 }}</td>
                <td>
                  {{ impl.fio }}
                </td>
                <td :title="impl.position">
                  {{ impl.position_short }}
                </td>
                <td>
                  {{ impl.company_name }}
                </td>
                <td>
                  {{ impl.type }}
                </td>
                <td style="padding: 0; height: 30px;">
                  <input class="" style="width: 100% !important; height: 100% !important; padding: .5rem"
                         v-model="impl.comment">
                </td>
                <td>
                  <button class="btn-danger" style="border-radius: .5rem" @click="rm_approver(index)">Исключить</button>
                </td>
              </tr>
              <tr v-if="impl_ratificator !== ''" style="font-weight: bold">
                <td>{{ impl_approvers.length + 1 }}</td>
                <td>
                  {{ impl_ratificator.fio }}
                </td>
                <td :title="impl_ratificator.position">
                  {{ impl_ratificator.position_short }}
                </td>
                <td>
                  {{ impl_ratificator.company_name }}
                </td>
                <td>
                  {{ impl_ratificator.type }}
                </td>
                <td style="padding: 0; height: 30px;">
                  <input style="width: 100% !important; height: 100% !important; padding: .5rem"
                         v-model="impl_ratificator.comment">
                </td>
                <td>
                  <button class="btn-danger" style="border-radius: .5rem" @click="rm_ratificator">Исключить</button>
                </td>
              </tr>
              </tbody>
            </table>
            <!--            </div>-->
          </div>
        </div>
      </modal>
      <modal name="economist-edit-modal" :height="700" :width="1500">
        <div class="modal-inner" style="height: inherit; width: inherit; text-align: left; overflow: auto">
          <div style="display: flex; justify-content: space-between; padding: .5rem; height: 5rem;">
            <h3 class="modal-title" style="padding-bottom: 0">Редактирование данных акта</h3>
            <button class="select_button"
                    style="width: 200px; border-radius: .5rem; background: #23468c !important" @click="set_changes_eco">
              Внести изменения
            </button>
          </div>
          <div>Так же вы можете
            <button class="upload-btn" @click="download_act_for_eco">
              скачать шаблон
            </button>
            , заполнить его, и
            <input type="file" name="file" id="file" class="inputfile" :ref="'file_template'" v-on:change="uploadTemplateEco">
            <label for="file" class="upload-from-xls">загрузить обратно</label>
          </div>
          <div class="modal-info"
               style="width: 100%; height: 80%;">
            <table class="m-table-act" v-if="act.type === 'Стандартный (458-562)'">
              <thead class="">
              <tr>
                <th rowspan="2" style="width: 100px">Номер по порядку</th>
                <th rowspan="2" style="width: 100px">Код затрат</th>
                <th rowspan="2" style="width: 100px">Код ВЗ, МВЗ</th>
                <th rowspan="2" style="width: 100px">Номер заказа</th>
                <th rowspan="2" style="width: 300px;">Наименование ВЗ, МВЗ</th>
                <th rowspan="2" style="width: 300px">Наименование работ (услуг) (в разрезе их подвидов в соответствии с
                  технической спецификацией, заданием, графиком выполнения работ (услуг) при их наличии)
                </th>
                <th rowspan="2">Единица измерения</th>
                <th colspan="3" rowspan="1">Выполнено работ (оказано услуг)</th>
              </tr>
              <tr>
                <th>Количество</th>
                <th>Цена за единицу</th>
                <th>Стоимость (в тенге)</th>
              </tr>
              <tr>
                <td v-for="i in [1,2,3,4,5,6,7,8,9,10]" :key="i">{{ i }}</td>
              </tr>
              </thead>
              <tbody>
              <tr style="height: 40px; background: white" v-for="row in this.act.rows" :key="row.id">
                <td data-label="">{{ row.serial_number }}</td>
                <td data-label="">
                  <input v-model="row.new_cost_code" class="m-input-cell">
                </td>
                <td data-label="">
                  <input v-model="row.new_mvz_code" class="m-input-cell">
                </td>
                <td data-label="">
                  <input v-model="row.new_tora_zakaz" class="m-input-cell">
                </td>
                <td data-label="">{{ row.mvz_name }}</td>
                <td data-label="">{{ row.work_name }}</td>
                <td data-label="">{{ row.measure_unit }}</td>
                <td data-label="">{{ prettyPrice(row.count) }}</td>
                <td data-label="">{{ prettyPrice(row.unit_price) }}</td>
                <td data-label="">{{ prettyPrice(row.total) }}</td>
              </tr>
              </tbody>
            </table>
            <table class="m-table-act" v-else-if="act.type === 'ДБиКРС'">
              <thead class="">
              <tr>
                <th rowspan="3" style="width: 100px">Номер по порядку</th>
                <th rowspan="3" style="width: 100px">Код затрат</th>
                <th rowspan="3" style="width: 100px">Код МВЗ</th>
                <th rowspan="3" style="width: 100px">Номер заказа</th>
                <th rowspan="3" style="width: 300px;">Наименование МВЗ</th>
                <th rowspan="3" style="width: 300px">Наименование работ (услуг) (в разрезе их подвидов в соответствии с
                  технической спецификацией, заданием, графиком выполнения работ (услуг) при их наличии)
                </th>
                <th rowspan="3">Единица измерения</th>
                <th colspan="5" rowspan="1">Выполнено работ (оказано услуг)</th>
              </tr>
              <tr>
                <th rowspan="2">количество</th>
                <th rowspan="2">цена за единицу</th>
                <th rowspan="1" colspan="2">снижение стоимости</th>
                <th rowspan="2">стоимость</th>
              </tr>
              <tr>
                <th>основание*</th>
                <th>сумма снижения, тенге</th>
              </tr>
              <tr>
                <td v-for="i in [1,2,3,4,5,6,7,8,9,10,11,12]" :key="i">{{ i }}</td>
              </tr>
              </thead>
              <tbody>
              <tr style="height: 40px; background: white" v-for="row in this.act.rows" :key="row.id">
                <td data-label="">{{ row.serial_number }}</td>
                <td data-label="">
                  <input v-model="row.new_cost_code" class="m-input-cell">
                </td>
                <td data-label="">
                  <input v-model="row.new_mvz_code" class="m-input-cell">
                </td>
                <td data-label="">
                  <input v-model="row.new_tora_zakaz" class="m-input-cell">
                </td>
                <td data-label="">{{ row.mvz_name }}</td>
                <td data-label="">{{ row.work_name }}</td>
                <td data-label="">{{ row.measure_unit }}</td>
                <td data-label="">{{ prettyPrice(row.count) }}</td>
                <td data-label="">{{ prettyPrice(row.unit_price) }}</td>
                <td data-label="">{{ row.reason }}</td>
                <td data-label="">{{ prettyPrice(row.reason_cost) }}</td>
                <td data-label="">{{ prettyPrice(row.total) }}</td>
              </tr>
              </tbody>
            </table>
            <table class="m-table-act" v-else-if="act.type === 'ДКС'">
              <thead>
              <tr style="height: 40px">
                <th style="width: 3%">№п/п</th>
                <th style="width: 8%">Код затрат</th>
                <th style="width: 11%">Месторождение № скважин</th>
                <th style="width: 9%">Код ресурса</th>
                <th style="width: 9%">СПП, ВЗ, МВЗ</th>
                <th style="width: 19%">Наименование работ</th>
                <th style="width: 5%">Ед.изм.</th>
                <th style="width: 6.5%">Кол-во</th>
                <th style="width: 8%">Цена, тенге</th>
                <th style="width: 10%">Сумма, тенге</th>
              </tr>
              <tr>
                <td v-for="i in [1,2,3,4,5,6,7,8,9,10]" :key="i">{{ i }}</td>
              </tr>
              </thead>
              <tbody>
              <tr style="height: 40px; background: white" v-for="row in this.act.rows" :key="row.id">
                <td data-label="">{{ row.serial_number }}</td>
                <td data-label="">
                  <input v-model="row.new_cost_code" class="m-input-cell">
                </td>
                <td data-label="">{{ row.mvz_name }}</td>
                <td data-label="">
                  <input v-model="row.new_source_code" class="m-input-cell">
                </td>
                <td data-label="">
                  <input v-model="row.new_mvz_code" class="m-input-cell">
                </td>
                <td data-label="">{{ row.work_name }}</td>
                <td data-label="">{{ row.measure_unit }}</td>
                <td data-label="">{{ prettyPrice(row.count) }}</td>
                <td data-label="">{{ prettyPrice(row.unit_price) }}</td>
                <td data-label="">{{ prettyPrice(row.total) }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </modal>
      <modal name="abp-sap-modal" v-if="is_ABP && curr_ABP && act.status === 'На Администраторе Бюджетной Программы'"
             :height="700" :width="1300">
        <div class="modal-inner" style="height: inherit; width: inherit; text-align: left; overflow: auto">
          <div style="display: flex; justify-content: space-between; padding: .5rem; height: 5rem;">
            <h3 class="modal-title" style="padding-bottom: 0">Назначение заказов SAP</h3>
            <button class="select_button" v-if="all_sap_entered"
                    style="width: 200px; border-radius: .5rem; background: #23468c !important"
                    @click="set_changes_sap_abp">
              Назначить
            </button>
          </div>
          <div class="modal-info"
               style="width: 100%; height: 80%;">
            <table class="m-table-act">
              <thead class="">
              <tr>
                <th style="width: 100px">Номер по порядку</th>
                <th style="width: 300px">Наименование работ (услуг)</th>
                <th>Заказ</th>
                <th>Позиция</th>
                <th>Услуга</th>
                <th style="width: 140px;">Действие</th>
              </tr>
              <tr>
                <td v-for="i in [1,2,3,4,5]" :key="i">{{ i }}</td>
              </tr>
              </thead>
              <tbody>
              <tr style="height: 40px; background: white" v-for="row in this.act.rows" :key="row.id">
                <td data-label="">{{ row.serial_number }}</td>
                <td data-label="">{{ row.work_name }}</td>

                <td data-label="">
                  <select :name="'select_zakaz_' + row.serial_number"
                          :id="'select_zakaz_' + row.serial_number"
                          class="m-input-cell-create zakaz_class"
                          v-model="row.new_sap_zakaz"
                          style="width: 100%; height: 40px; text-align-last: center;">
                    <option value data-isdefault="true">Выберите заказ</option>
                    <option v-for="(zakaz_value, zakaz_id) in zakaz_dict" :key="zakaz_id" :value="zakaz_id"
                            style="text-align-last: center;" class="m-input-cell-create">{{ zakaz_id }}
                    </option>
                  </select>
                </td>

                <td data-label="">
                  <select
                      v-if="row.new_sap_zakaz && row.new_sap_zakaz !== '' && zakaz_dict.hasOwnProperty(row.new_sap_zakaz)"
                      :name="'select_pos_' + row.serial_number"
                      :id="'select_pos_' + row.serial_number"
                      class="m-input-cell-create pos_class"
                      style="width: 100%; height: 40px; text-align-last: center;"
                      v-model="row.new_sap_position">
                    <option value data-isdefault="true">Выберите позицию</option>
                    <option v-for="(position_value, position_id) in zakaz_dict[row.new_sap_zakaz]" :key="position_id"
                            :value="position_id" style="text-align-last: center;" class="m-input-cell-create">
                      {{ position_id }}
                    </option>
                  </select>
                </td>

                <td data-label="">
                  <select
                      v-if="row.new_sap_position && row.new_sap_position !== '' && zakaz_dict.hasOwnProperty(row.new_sap_zakaz) && zakaz_dict[row.new_sap_zakaz].hasOwnProperty(row.new_sap_position)"
                      :name="'select_service_' + row.serial_number"
                      :id="'select_service_' + row.serial_number"
                      class="m-input-cell-create service_class"
                      v-model="row.new_sap_service"
                      style="width: 100%; height: 40px; text-align-last: center;">
                    <option value data-isdefault="true">Выберите услугу</option>
                    <option v-for="(service_value, service_id) in zakaz_dict[row.new_sap_zakaz][row.new_sap_position]"
                            :key="service_id" :value="service_value[0]" style="text-align-last: center;"
                            class="m-input-cell-create">{{ service_value[0] }} : {{ service_value[1] }}
                    </option>

                  </select>
                </td>
                <td data-label="" style="width: 140px;">
                  <button class="text-center"
                          v-if="row.new_sap_service !== '' && row.new_sap_position !== '' && row.new_sap_zakaz !== ''"
                          @click="copy_to_all_next(row)"
                          style="border-radius: .5rem; background: #23468c !important; color: white; padding: 0.5rem 1rem 0.5rem 1rem">
                    Дублировать вниз
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </modal>
      <modal name="booker-sap-modal"
             v-if='act.status && act.status.includes("Подписано") && is_BOOKER && act.acc_id_list && !act.acc_id_list.includes(";")'
             :height="700"
             :width="1300">
        <div class="modal-inner" style="height: inherit; width: inherit; text-align: left; overflow: auto">
          <div style="display: flex; justify-content: space-between; padding: .5rem; height: 5rem;">
            <h3 class="modal-title" style="padding-bottom: 0">Создание ведомости ML81n</h3>
            <button class="select_button" v-if="all_sap_entered_booker"
                    style="width: 200px; border-radius: .5rem; background: #23468c !important"
                    @click="set_changes_sap_booker">
              Создать
            </button>
          </div>
          <div class="modal-info"
               style="width: 100%; height: 80%;">
            <div style="display: flex; justify-content: center">
              <div>
                Дата проводки:
                <input v-if="!act.act_date" name="real_date" type="date" :value="act.created_datetime_short"
                       :id="'real_date'">
                <input v-else name="real_date" type="date" :value="act.act_date" :id="'real_date'">
              </div>
            </div>
            <table class="m-table-act">
              <thead class="">
              <tr>
                <th rowspan="2" style="width: 100px">Номер по порядку</th>
                <th rowspan="2" style="width: 300px">Наименование работ (услуг)</th>
                <th rowspan="2">Заказ</th>
                <th rowspan="2">Позиция</th>
                <th rowspan="2">Услуга</th>
                <th colspan="2">Заполняется только одно</th>
                <th rowspan="2">Кол-во</th>
                <th rowspan="2">Ед. изм.</th>
                <th rowspan="2">Цена без НДС</th>
              </tr>
              <tr>
                <th>Код МВЗ</th>
                <th>Номер заказа</th>
              </tr>
              <tr>
                <td v-for="i in [1,2,3,4,5,6,7,8,9,10]" :key="i">{{ i }}</td>
              </tr>
              </thead>
              <tbody>
              <tr style="height: 40px; background: white" v-for="row in this.act.rows" :key="row.id">
                <td data-label="">{{ row.serial_number }}</td>
                <td data-label="">{{ row.work_name }}</td>

                <td data-label="">
                  <select :name="'select_zakaz_' + row.serial_number"
                          :id="'select_zakaz_' + row.serial_number"
                          class="m-input-cell-create zakaz_class"
                          v-model="row.new_sap_zakaz"
                          style="width: 100%; height: 40px; text-align-last: center;">
                    <option value data-isdefault="true">Выберите заказ</option>
                    <option v-for="(zakaz_value, zakaz_id) in zakaz_dict" :key="zakaz_id" :value="zakaz_id"
                            style="text-align-last: center;" class="m-input-cell-create">{{ zakaz_id }}
                    </option>
                  </select>
                </td>

                <td data-label="">
                  <select
                      v-if="row.new_sap_zakaz && row.new_sap_zakaz !== '' && zakaz_dict.hasOwnProperty(row.new_sap_zakaz)"
                      :name="'select_pos_' + row.serial_number"
                      :id="'select_pos_' + row.serial_number"
                      class="m-input-cell-create pos_class"
                      style="width: 100%; height: 40px; text-align-last: center;"
                      v-model="row.new_sap_position">
                    <option value data-isdefault="true">Выберите позицию</option>
                    <option v-for="(position_value, position_id) in zakaz_dict[row.new_sap_zakaz]" :key="position_id"
                            :value="position_id" style="text-align-last: center;" class="m-input-cell-create">
                      {{ position_id }}
                    </option>
                  </select>
                </td>

                <td data-label="">
                  <select
                      v-if="row.new_sap_position && row.new_sap_position !== '' && zakaz_dict.hasOwnProperty(row.new_sap_zakaz) && zakaz_dict[row.new_sap_zakaz].hasOwnProperty(row.new_sap_position)"
                      :name="'select_service_' + row.serial_number"
                      :id="'select_service_' + row.serial_number"
                      class="m-input-cell-create service_class"
                      v-model="row.new_sap_service"
                      style="width: 100%; height: 40px; text-align-last: center;">
                    <option value data-isdefault="true">Выберите услугу</option>
                    <option v-for="(service_value, service_id) in zakaz_dict[row.new_sap_zakaz][row.new_sap_position]"
                            :key="service_id" :value="service_value[0]" style="text-align-last: center;"
                            class="m-input-cell-create">{{ service_value[0] }} : {{ service_value[1] }}
                    </option>

                  </select>
                </td>

                <td data-label="">
                  <input v-if="row.sap_is_mvz"
                         :name="'mvz_code_' + row.serial_number"
                         :id="'mvz_code_' + row.serial_number"
                         class="m-input-cell-create placeholder-disabled"
                         style="width: 100%; height: 40px; text-align-last: center;"
                         :value="row.mvz_code"
                         v-on:click="mvzActivate('mvz_code_' + row.serial_number, row)">
                  <input v-else
                         :name="'mvz_code_' + row.serial_number"
                         :id="'mvz_code_' + row.serial_number"
                         class="m-input-cell-create placeholder-disabled"
                         style="width: 100%; height: 40px; text-align-last: center;"
                         v-on:click="mvzActivate('mvz_code_' + row.serial_number, row)">
                </td>
                <td data-label="">
                  <input v-if="row.sap_is_mvz"
                         :name="'zak_code_' + row.serial_number"
                         :id="'zak_code_' + row.serial_number"
                         class="m-input-cell-create placeholder-disabled"
                         style="width: 100%; height: 40px; text-align-last: center;"
                         v-on:click="zakActivate('zak_code_' + row.serial_number, row)">
                  <input v-else
                         :name="'zak_code_' + row.serial_number"
                         :id="'zak_code_' + row.serial_number"
                         class="m-input-cell-create placeholder-disabled"
                         style="width: 100%; height: 40px; text-align-last: center;"
                         :value="row.new_tora_zakaz"
                         v-on:click="zakActivate('zak_code_' + row.serial_number, row)">
                </td>
                <td data-label="">{{ row.count }}</td>
                <td data-label="">{{ row.measure_unit }}</td>
                <td data-label="">{{ row.unit_price }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </modal>
      <modal name="add-approver-modal-abp" :height="700" :width="950">
        <div class="modal-inner" style="height: auto !important; width: inherit; text-align: left; overflow: auto">
          <div style="width: 100%; display: flex; justify-content: space-between; padding: .5rem; height: 5rem;">
            <h3 class="modal-title" style="padding-bottom: 0">СПИСОК ПОДПИСАНТОВ</h3>
            <button
                class="transition-all ease-in-out duration-150 transform hover:scale-110 bg-green-500 hover:bg-green-800 px-5 py-1 rounded-lg text-white"
                v-if="dzo_ratificator !== ''"
                @click.once="setDZOApprovers">
              Отправить
            </button>
          </div>
          <div class="modal-info"
               style="width: 100%; height: auto; display: flex; justify-content: flex-start; align-items: flex-start; flex-direction: column">
            <select class="select_button" id="select_approver" v-on:change="change_approver"
                    v-model="selected_dzo_approver">
              <option class="default_option" value="">Выберите следующего согласующего</option>
              <option class="default_option" v-for="ouser in all_collegues" :key="ouser.id" :value="ouser"
                      :title="ouser.full_name.toUpperCase() + ' / ' + ouser.position.toUpperCase() + ' / ' + ouser.company.name.toUpperCase()">
                {{ ouser.full_name.toUpperCase() }} /
                {{ ouser.position_short.toUpperCase() }}
              </option>
            </select>
            <select class="select_button" id="select_ratifitator" v-on:change="change_dzo_rat"
                    v-model="selected_dzo_rat">
              <option class="default_option" value="">Выберите утверждащего</option>
              <option class="default_option" v-for="ouser in all_collegues" :key="ouser.id" :value="ouser"
                      :title="ouser.full_name.toUpperCase() + ' / ' + ouser.position.toUpperCase() + ' / ' + ouser.company.name.toUpperCase()">
                {{ ouser.full_name.toUpperCase() }} /
                {{ ouser.position_short.toUpperCase() }}
              </option>
            </select>
            <select v-if="dzo_approvers.length > 1" class="select_button" v-model="selected_dzo_approve_mode">
              <option class="default_option" value="Последовательное согласование">Последовательное согласование
              </option>
              <option class="default_option" value="Параллельное согласование">Параллельное согласование</option>
            </select>
            <table class="table table-hover table-nowrap">
              <thead class="thead-light">
              <tr>
                <th>№</th>
                <th>ФИО</th>
                <th>Должность</th>
                <!--                <th>Огранизация</th>-->
                <th>Тип согласования</th>
                <th>Ваш комментарий</th>
                <th>Действия</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(dzo_appr, index) in dzo_approvers" :key="index">
                <td>{{ index + 1 }}</td>
                <td>
                  {{ dzo_appr.fio }}
                </td>
                <td :title="dzo_appr.position">
                  {{ dzo_appr.position_short }}
                </td>
                <!--                  <td>-->
                <!--                    {{ dzo_appr.company_name }}-->
                <!--                  </td>-->
                <td>
                  {{ dzo_appr.type }}
                </td>
                <td style="padding: 0; height: 30px;">
                  <input class="" style="width: 100% !important; height: 100% !important; padding: .5rem"
                         v-model="dzo_appr.comment">
                </td>
                <td>
                  <button class="btn-danger" style="border-radius: .5rem" @click="rm_dzo_approver(index)">Исключить
                  </button>
                </td>
              </tr>
              <tr v-if="dzo_ratificator !== ''" style="font-weight: bold">
                <td>{{ dzo_approvers.length + 1 }}</td>
                <td>
                  {{ dzo_ratificator.fio }}
                </td>
                <td :title="dzo_ratificator.position">
                  {{ dzo_ratificator.position_short }}
                </td>
                <!--                  <td>-->
                <!--                    {{ dzo_ratificator.company_name }}-->
                <!--                  </td>-->
                <td>
                  {{ dzo_ratificator.type }}
                </td>
                <td style="padding: 0; height: 30px;">
                  <input class="" style="width: 100% !important; height: 100% !important; padding: .5rem"
                         v-model="dzo_ratificator.comment">
                </td>
                <td>
                  <button class="btn-danger" style="border-radius: .5rem" @click="rm_dzo_ratificator">Исключить</button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </modal>
      <modal name="ds-impl-modal">
        <div class="modal-inner">
          <h3 style="text-align: center; padding: 20px">Подписать через ЭЦП</h3>
          <form method="POST" id="auth-form" @submit.prevent="sendSignXml">
            <div hidden>
              <select id="storageSelect">
                <option value="PKCS12" selected>PKCS12</option>
              </select>
            </div>

            <p class="auth-moda-explanations" style="text-align: center">
              Для успешной подписи рекомендуется <br/>
              выбирать ключ <b>GOSTKNCA</b>
            </p>

            <textarea
                hidden
                id="xmlToSign"
                name="xmlToSign"
                v-model="this.xmlToSign"
                rows="6"
            ></textarea>
            <textarea
                hidden
                class="form-control"
                id="signedXml"
                name="signedXml"
                rows="6"
                readonly
            ></textarea>
            <div style="display: flex; justify-content: center">
              <button class="auth-choose-cert prev-next-item" type="button" style="margin: 30px; height: 40px"
                      @click="signXmlCall">
                Выбрать сертификат
              </button>
            </div>
            <button id="test" type="submit" hidden>submit</button>
          </form>
        </div>
      </modal>
<!--      <modal name="reject-modal" :height="600">-->
<!--        <div class="modal-inner"-->
<!--             style="display: flex; justify-content: center; flex-direction: column; align-items: center; gap: 1rem">-->
<!--          <h3 style="text-align: center; padding: 20px">Отклонение акта</h3>-->
<!--          <textarea v-model="reject_comment"-->
<!--                    style="width: 80%; margin: 1rem; padding: .5rem; height: 5rem; border: 1px gray solid; border-radius: .5rem"-->
<!--                    placeholder="Введите причину отклонения"></textarea>-->

<!--          <div class="dropzone" style="width: 80%">-->
<!--            <input type="file" class="input-field" id="input-field-multiple" ref="files_reject" multiple-->
<!--                   accept=".pdf, image/png, image/jpeg" @change="reuploadVl1"/>-->
<!--            <div v-if="!uploading" class="call-to-action">-->
<!--              Перетащите файлы сюда подтверждающие файлы или нажмите, чтобы загрузить-->
<!--              <p>-->
<!--                <small>типы загружаемых файлов: .pdf, .png, .jpeg</small>-->
<!--              </p>-->
<!--            </div>-->
<!--            <div v-if="uploading" class="uploaded-msg">-->
<!--              Файлы успешно загружены-->
<!--            </div>-->
<!--          </div>-->

<!--          <button v-if="reject_comment !== ''" class="btn file-selector"-->
<!--                  style="width: 80%; color: white; background: rgb(255, 51, 102) !important;"-->
<!--                  @click="accept_reject('reject')">Отклонить-->
<!--          </button>-->
<!--        </div>-->
<!--      </modal>-->
    </section>
  </div>
</template>
<script async>
import {signXml} from "../nclayer/ncalayer.js";
import router from "../router";

export default {
  components: {},
  data() {
    return {
      fast_filter: "1",
      xmlToSign: '',
      show_form_3: true,
      impl_approvers: [],
      impl_ratificator: '',
      selected_impl: '',
      selected_rat: '',
      selected_approve_mode: 'Последовательное согласование',
      all_collegues: [],
      zakaz_dict: {},
      // zakaz_dict: {
      //   'z0001': {
      //     'p000011': [
      //       ['u00000111', 'Название услуги 1.1.1'],
      //       ['u00000112', 'Название услуги 1.1.2'],
      //       ['u00000113', 'Название услуги 1.1.3']
      //     ],
      //     'p000012': [
      //       ['u00000121', 'Название услуги 1.2.1'],
      //       ['u00000122', 'Название услуги 1.2.2'],
      //       ['u00000123', 'Название услуги 1.2.3']
      //     ]
      //   },
      //   'z0002': {
      //     'p000021': [
      //       ['u00000211', 'Название услуги 2.1.1'],
      //       ['u00000212', 'Название услуги 2.1.2'],
      //       ['u00000213', 'Название услуги 2.1.3']
      //     ],
      //     'p000022': [
      //       ['u00000221', 'Название услуги 2.2.1'],
      //       ['u00000222', 'Название услуги 2.2.2'],
      //       ['u00000223', 'Название услуги 2.2.3']
      //     ]
      //   }
      // },
      dzo_approvers: [],
      dzo_ratificator: '',
      dzo_current_approver: '',
      selected_dzo_approver: '',
      selected_dzo_rat: '',
      selected_dzo_current_approver: '',
      selected_dzo_approve_mode: 'Последовательное согласование',
      uploading: false,
      act: {},
      curr_user: {},
      reject_comment: '',
      curr_APPR: false,
      curr_ABP: false,
      curr_RAT: false,
      is_IMPL: false,
      is_OWNER: false,
      is_OWNERMIRROR: false,
      is_ABP: false,
      is_APPROVER: false,
      is_RATIFICATOR: false,
      is_ECONOMIST: false,
      is_BOOKER: false,
      is_DZOADM: false,
    };
  },
  created() {
    this.getActDetail();
    this.current_user();
    this.is_IMPL = this.isIMPL();
    this.is_ABP = this.isABP();
    this.is_APPROVER = this.isAPPROVER();
    this.is_RATIFICATOR = this.isRATIFICATOR();
    this.is_ECONOMIST = this.isECONOMIST();
    this.is_BOOKER = this.isBOOKER();
    this.is_DZOADM = this.isDZOADM();
  },
  computed: {
    all_sap_entered: function () {
      if (this.act.rows) {
        for (let i = 0; i < this.act.rows.length; i++) {
          let row = this.act.rows[i];
          if (row.new_sap_zakaz === '') {
            return false;
          } else if (row.new_sap_position === '') {
            return false;
          } else if (row.new_sap_service === '') {
            return false;
          }
        }
      }
      return true;
    },
    all_sap_entered_booker: function () {
      if (this.act.rows) {
        for (let i = 0; i < this.act.rows.length; i++) {
          let row = this.act.rows[i];

          let zak_code = document.getElementById('zak_code_' + row.serial_number);
          let mvz_code = document.getElementById('mvz_code_' + row.serial_number);

          if (!zak_code && !mvz_code) {
            if (row.sap_is_mvz) {
              mvz_code = {
                value: row.mvz_code
              }
            } else {
              zak_code = {
                value: row.new_tora_zakaz
              }
            }
          }

          console.log(zak_code);
          console.log(mvz_code);

          if (!zak_code) {
            zak_code = {
              value: ''
            }
          }
          if (!mvz_code) {
            mvz_code = {
              value: ''
            }
          }

          if (row.new_sap_zakaz === '') {
            return false;
          } else if (row.new_sap_position === '') {
            return false;
          } else if (row.new_sap_service === '') {
            return false;
          } else if (zak_code.value === '' && mvz_code.value === '') {
            return false;
          }
        }
      }
      return true;
    },
    all_sap_entered_global: function () {
      if (this.act.rows) {
        if (this.act.contract.impl_company.id === 97 || this.act.contract.is_tora) {
          return true;
        } else {
          for (let i = 0; i < this.act.rows.length; i++) {
            let row = this.act.rows[i];
            if (row.sap_zakaz === '' || row.sap_zakaz === 'Отсутствует') {
              return false;
            } else if (row.sap_position === '' || row.sap_position === 'Отсутствует') {
              return false;
            } else if (row.sap_service === '' || row.sap_service === 'Отсутствует') {
              return false;
            }
          }
        }

      }
      return true;
    }

  },
  methods: {
    copy_to_all_next(row) {
      if (row.new_sap_zakaz !== '' && row.new_sap_position !== '' && row.new_sap_service !== '') {
        this.act.rows.filter(i => i.serial_number > row.serial_number).forEach(function (item_row, index_row) {
          console.log(index_row);
          item_row.new_sap_zakaz = row.new_sap_zakaz;
          item_row.new_sap_position = row.new_sap_position;
          item_row.new_sap_service = row.new_sap_service;
        });
      } else {
        alert('Заполните номер заказа/позиции/услуги SAP по данной строке!');
      }
    },
    zakActivate(name, row) {
      let zakaz_i_list = name.split('_');
      let zakaz_id = zakaz_i_list[zakaz_i_list.length - 1];
      let mvz_code = document.getElementById('mvz_code_' + zakaz_id);

      mvz_code.value = '';
      mvz_code.removeAttribute('required');

      let zak = document.getElementById('zak_code_' + zakaz_id);
      zak.value = row.new_tora_zakaz;
      document.getElementById('zak_code_' + zakaz_id).setAttribute('required', 'required');
    },
    mvzActivate(name, row) {
      let zakaz_i_list = name.split('_');
      let zakaz_id = zakaz_i_list[zakaz_i_list.length - 1];
      let zak_code = document.getElementById('zak_code_' + zakaz_id);

      zak_code.value = '';
      zak_code.removeAttribute('required');

      let mvz = document.getElementById('mvz_code_' + zakaz_id);
      mvz.value = row.mvz_code;
      document.getElementById('mvz_code_' + zakaz_id).setAttribute('required', 'required');
    },
    reuploadVl1() {
      // const file = this.$refs.file.files[0];
      let fileName = document.getElementById("input-field-multiple").value,
          idxDot = fileName.lastIndexOf(".") + 1,
          extFile = fileName.substr(idxDot, fileName.length).toLowerCase();


      if (!(extFile === "png" || extFile === "pdf" || extFile === "jpg")) {
        this.$store.dispatch('addNotification', {
          type: 'error',
          message: 'Следует загружать следующие типы файлов: pdf, png, jpg',
        })
        this.file = null;
      } else {
        this.uploading = true;
      }
    },
    signXmlCall() {
      console.log('signXmlCall-started');
      let selectedStorage = "PKCS12";
      console.log('xmlToSign');
      console.log(this.xmlToSign);
      signXml(selectedStorage, "SIGNATURE", this.xmlToSign);
      console.log('signXmlCall-ended');
    },
    sendSignXml() {
      console.log('sendSignXml-started');
      let xmlToSign = document.getElementById("xmlToSign");
      let signedXml = document.getElementById("signedXml");
      console.log('signedXml');
      console.log(signedXml.value);

      let formData = {
        signedXml: signedXml.value,
        xmlToSign: xmlToSign.value,
        act_id: this.act.id,
      };
      this.$store.dispatch("sendSignXml", formData)
          .then((response) => {
            console.log('sendSignXml-middle');
            console.log(response);
            location.reload();
            // router.push({ name: 'actview', params: {id: response.data.id} });
            console.log('sendSignXml-ended');
          })
          .then(() => {
            this.$store.dispatch('addNotification', {
              type: 'success',
              message: 'Акт успешно подписан',
            })
          })
          .catch((error) => {
            console.log(error.response.data.detail);
            let errorMessage = error.response.data.detail
            this.$store.dispatch('addNotification', {
              type: 'error',
              message: errorMessage,
            })
            // this.closeModal();
          });

    },
    showSignModal() {
      this.$modal.show("ds-impl-modal");
    },
    showRejectModal() {
      this.$modal.show("reject-modal");
    },
    showActionsModal() {
      this.$modal.show("actions-modal");
    },
    rm_approver(index) {
      this.impl_approvers.splice(index, 1);
    },
    rm_dzo_approver(index) {
      this.dzo_approvers.splice(index, 1);
    },
    rm_ratificator() {
      this.impl_ratificator = '';
    },
    rm_dzo_ratificator() {
      this.dzo_ratificator = '';
    },
    accept_reject(decision) {
      let formData = new FormData();
      formData.append('act_id', this.act.id);
      formData.append('decision', decision);
      formData.append('reject_comment', this.reject_comment);
      console.log('files_reject');
      console.log(this.$refs.length);
      if (this.$refs['files_reject']) {
        for (let i = 0; i < this.$refs['files_reject'].files.length; i++) {
          let file = this.$refs['files_reject'].files[i];
          formData.append('files_reject-' + i, file);
        }
      }

      this.$store.dispatch("acceptReject", formData).then((response) => {
        this.$store.dispatch('addNotification', {
          type: 'success',
          message: response.data.detail,
        })
        location.reload();
      }).catch((error) => {
        this.$store.dispatch('addNotification', {
          type: 'error',
          message: error.response.data.detail,
        })
      });
    },
    setImplApprovers() {
      let formData = {
        act_id: this.act.id,
        selected_approve_mode: this.selected_approve_mode,
        impl_approvers: this.impl_approvers,
        impl_ratificator: this.impl_ratificator
      }
      this.$store.dispatch("setImplApprovers", formData).then((response) => {
        this.$store.dispatch('addNotification', {
          type: 'success',
          message: response.data.detail,
        })
        location.reload();
      }).catch((error) => {
        this.$store.dispatch('addNotification', {
          type: 'error',
          message: error.response.data.detail,
        })
      });
    },
    sendToApproversImpl() {
      this.$store.dispatch("sendToApproversImpl", this.act.id).then((response) => {
        this.$store.dispatch('addNotification', {
          type: 'success',
          message: response.data.detail,
        })
        location.reload();
      }).catch((error) => {
        this.$store.dispatch('addNotification', {
          type: 'error',
          message: error.response.data.detail,
        })
      });
    },
    setDZOApprovers() {
      let formData = {
        act_id: this.act.id,
        selected_dzo_approve_mode: this.selected_dzo_approve_mode,
        dzo_approvers: this.dzo_approvers,
        dzo_ratificator: this.dzo_ratificator
      }
      this.$store.dispatch("setDZOApprovers", formData).then((response) => {
        console.log(response);
        location.reload();
      }).catch((error) => {
        this.$store.dispatch('addNotification', {
          type: 'error',
          message: error.response.data.detail,
        })
      });
    },
    changeDZOCurrentApprovers(approve_user_id) {
      let formData = {
        act_id: this.act.id,
        old_user_id: approve_user_id,
        dzo_current_approver: this.dzo_current_approver
      }
      this.$store.dispatch("changeDZOCurrentApprovers", formData).then((response) => {
        console.log(response);
        location.reload();
      }).catch((error) => {
        this.$store.dispatch('addNotification', {
          type: 'error',
          message: error.response.data.detail,
        })
      });
    },
    removeDZOCurrentApprovers(approve_user_id) {
      let formData = {
        act_id: this.act.id,
        old_user_id: approve_user_id
      }
      this.$store.dispatch("removeDZOCurrentApprovers", formData).then((response) => {
        console.log(response);
        location.reload();
      }).catch((error) => {
        this.$store.dispatch('addNotification', {
          type: 'error',
          message: error.response.data.detail,
        })
      });
    },
    change_impl() {
      if (this.selected_impl !== '') {
        let already_exist = false;
        for (let i = 0; i < this.impl_approvers.length; i++) {
          let impl_in_approver_list = this.impl_approvers[i];
          if (impl_in_approver_list.id === this.selected_impl.id) {
            already_exist = true;
            break;
          }
        }
        if (this.impl_ratificator.id === this.selected_impl.id) {
          already_exist = true;
        }
        if (!already_exist) {
          let approver = {
            id: this.selected_impl.id,
            fio: this.selected_impl.full_name,
            position: this.selected_impl.position,
            position_short: this.selected_impl.position_short,
            type: "Согласующий",
            company_name: this.selected_impl.company.name,
            comment: '',
          };
          this.impl_approvers.push(approver);
          this.selected_impl = '';
        } else {
          this.$store.dispatch('addNotification', {
            type: 'error',
            message: 'Данный пользователь ' + this.selected_impl.full_name + ' уже добавлен в список согласующих / утверждающих',
          })
          this.selected_impl = '';
        }
      }

    },
    change_approver() {
      if (this.selected_dzo_approver !== '') {
        let already_exist = false;
        for (let i = 0; i < this.dzo_approvers.length; i++) {
          let dzo_in_approver_list = this.dzo_approvers[i];
          if (dzo_in_approver_list.id === this.selected_dzo_approver.id) {
            already_exist = true;
            break;
          }
        }
        if (this.dzo_ratificator.id === this.selected_dzo_approver.id || this.selected_dzo_approver.id === this.act.contract.abp.ouser.id) {
          already_exist = true;
        }
        if (!already_exist) {
          let approver = {
            id: this.selected_dzo_approver.id,
            fio: this.selected_dzo_approver.full_name,
            position: this.selected_dzo_approver.position,
            position_short: this.selected_dzo_approver.position_short,
            type: "Согласующий",
            company_name: this.selected_dzo_approver.company.name,
            comment: '',
          };
          this.dzo_approvers.push(approver);
          this.selected_dzo_approver = '';
        } else {
          this.$store.dispatch('addNotification', {
            type: 'error',
            message: 'Данный пользователь ' + this.selected_dzo_approver.full_name + ' уже добавлен в список согласующих / утверждающих!',
          })
          this.selected_dzo_approver = '';
        }
      }
    },
    change_rat() {
      if (this.selected_rat !== '') {
        let already_exist = false;
        for (let i = 0; i < this.impl_approvers.length; i++) {
          let impl_in_approver_list = this.impl_approvers[i];
          if (impl_in_approver_list.id === this.selected_rat.id) {
            already_exist = true;
            break;
          }
        }
        if (this.selected_rat.id === this.impl_ratificator.id) {
          already_exist = true;
        }
        if (!already_exist) {
          this.impl_ratificator = {
            id: this.selected_rat.id,
            fio: this.selected_rat.full_name,
            type: 'Утверждающий',
            company_name: this.selected_rat.company.name,
            position: this.selected_rat.position,
            position_short: this.selected_rat.position_short,
            comment: '',
          };
          this.selected_rat = '';
        } else {
          this.$store.dispatch('addNotification', {
            type: 'error',
            message: 'Данный пользователь ' + this.selected_rat.full_name + ' уже добавлен в список согласующих',
          })
          this.selected_rat = '';
        }
      }

    },
    change_dzo_rat() {
      if (this.selected_dzo_rat !== '') {
        let already_exist = false;
        for (let i = 0; i < this.dzo_approvers.length; i++) {
          let dzo_in_approver_list = this.dzo_approvers[i];
          if (dzo_in_approver_list.id === this.selected_dzo_rat.id) {
            already_exist = true;
            break;
          }
        }
        if (this.selected_dzo_rat.id === this.dzo_ratificator.id || this.selected_dzo_rat.id === this.act.contract.abp.ouser.id) {
          already_exist = true;
        }
        if (!already_exist) {
          this.dzo_ratificator = {
            id: this.selected_dzo_rat.id,
            fio: this.selected_dzo_rat.full_name,
            type: 'Утверждающий',
            company_name: this.selected_dzo_rat.company.name,
            position: this.selected_dzo_rat.position,
            position_short: this.selected_dzo_rat.position_short,
            comment: '',
          };
          this.selected_dzo_rat = '';
        } else {
          this.$store.dispatch('addNotification', {
            type: 'error',
            message: 'Данный пользователь ' + this.selected_dzo_rat.full_name + ' уже добавлен в список согласующих / утверждающих',
          })
          this.selected_dzo_rat = '';
        }
      }

    },
    download_act_for_eco() {
      this.$store.dispatch("downloadActForEco", this.act.id).then((response) => {
        const url = URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'AVR_шаблон.xlsx');
        document.body.appendChild(link);
        link.click();
      }).catch((error) => {
        this.$store.dispatch('addNotification', {
          type: 'error',
          message: error.response.data.detail,
        })
      });
    },

    uploadTemplateEco() {
      let formData = new FormData();

      let file = this.$refs['file_template'].files[0];

      formData.append('file_template', file);
      formData.append('act_id', this.act.id);

      this.$store.dispatch('uploadTemplateEco', formData)
          .then((response) => {
            let rows = response.data.rows;
            for (let i = 0; i < rows.length; i++) {
              let row = rows[i];
              this.act.rows[row.serial].new_cost_code = row.cost_code;
              this.act.rows[row.serial].new_mvz_code = row.mvz_code;
              this.act.rows[row.serial].new_tora_zakaz = row.new_tora_zakaz;
            }
          })
          .then(() => {
            this.$store.dispatch('addNotification', {
              type: 'success',
              message: "Данные успешно загружены",
            })
          })
          .catch((error) => {
            this.$store.dispatch('addNotification', {
              type: 'error',
              message: error.response.data.detail,
            })
           });
    },
    set_changes_eco() {
      let formData = {
        act: this.act,
      }
      this.$store.dispatch("setChangesEco", formData).then((response) => {
        console.log(response);
        location.reload();
      }).catch((error) => {
        this.$store.dispatch('addNotification', {
          type: 'error',
          message: error.response.data.detail,
        })
      });
    },
    set_changes_sap_abp() {
      let formData = {
        act: this.act,
      }
      this.$store.dispatch("setChangesSAPABP", formData).then((response) => {
        console.log(response);
        location.reload();
      }).catch((error) => {
        this.$store.dispatch('addNotification', {
          type: 'error',
          message: error.response.data.detail,
        })
      });
    },
    set_changes_sap_booker() {
      let real_date = document.getElementById('real_date');

      for (let i = 0; i < this.act.rows.length; i++) {
        let row = this.act.rows[i];
        row['new_mvz_sap'] = document.getElementById('mvz_code_' + row.serial_number).value;
        row['new_zak_sap'] = document.getElementById('zak_code_' + row.serial_number).value;
      }

      let formData = {
        act: this.act,
        real_date: real_date.value,
      }
      this.$store.dispatch("setChangesSAPBOOKER", formData).then((response) => {
        console.log(response);
        location.reload();
      }).catch((error) => {
        this.$store.dispatch('addNotification', {
          type: 'error',
          message: error.response.data.detail,
        })
      });
    },
    change_dzo_current_approver() {
      if (this.selected_dzo_current_approver !== '') {
        let already_exist = false;
        for (let i = 0; i < this.act.approvers.length; i++) {
          let dzo_in_approver_list = this.act.approvers[i];
          if (dzo_in_approver_list.user.ouser.id === this.selected_dzo_current_approver.id) {
            already_exist = true;
            break;
          }
        }
        if (!already_exist) {
          this.dzo_current_approver = {
            id: this.selected_dzo_current_approver.id,
            fio: this.selected_dzo_current_approver.full_name,
            type: 'Согласующий',
            company_name: this.selected_dzo_current_approver.company.name,
            position: this.selected_dzo_current_approver.position,
            position_short: this.selected_dzo_current_approver.position_short,
            comment: '',
          };
          this.selected_dzo_current_approver = '';
        } else {
          this.$store.dispatch('addNotification', {
            type: 'error',
            message: 'Данный пользователь ' + this.selected_dzo_current_approver.full_name + ' уже присутствует в списке согласующих',
          })
          this.selected_dzo_current_approver = '';
        }
      }

    },
    editAct() {
      router.push({name: 'edit_act', params: {id: this.act.id}});
    },
    editActMirror() {
      router.push({name: 'edit_act_mirror', params: {id: this.act.id}});
    },
    copyAct() {
      this.$store.dispatch("copyAct", this.act.id).then((response) => {
        console.log(response);
        router.push({name: 'edit_act', params: {id: response.data.act_id}});
        location.reload();
      }).catch((error) => {
        console.log(error);
      });
      router.push({name: 'edit_act', params: {id: this.act.id}});
    },
    deleteAct() {
      this.$store.dispatch('deleteAct', this.$route.params.id)
          .then((response) => {
            console.log(response);
            router.push({name: 'acts'});
          })
          .catch((error) => {
            console.log(error);
            location.reload();
          });
    },
    bringBackAct() {
      this.$store.dispatch('bringBackAct', this.$route.params.id)
          .then((response) => {
            console.log(response);
            location.reload();
          })
          .catch((error) => {
            console.log(error);
            location.reload();
          });
    },
    addToArchive() {
      let formData = new FormData();
      let new_archiveds_obj = {
        act_id: this.act.id
      }
      const json = JSON.stringify(new_archiveds_obj);

      formData.append('new_archiveds_obj', json);

      this.$store.dispatch('addToArchive', formData).then((response) => {
        console.log(response.data.new_archived_id);
        if (response.data.new_archived_id !== '') {
          location.reload();
        } else {
          // alert('Ошибка');
          this.$store.dispatch('addNotification', {
            type: 'error',
            message: response.data.detail,
          })
        }
      }).catch((error) => {
        // alert(error);
        this.$store.dispatch('addNotification', {
          type: 'error',
          message: error.response.data.detail,
        })
      });
    },
    rmFromArchive() {
      let archiveds_id = this.act.archiveds_id;
      if (archiveds_id !== -1) {
        this.$store.dispatch("rmFromArchive", archiveds_id).then((response) => {
          this.$store.dispatch('addNotification', {
            type: 'success',
            message: response.data.detail,
          });
          location.reload();
        }).catch((error) => {
          this.$store.dispatch('addNotification', {
            type: 'error',
            message: error.response.data.detail,
          })
        });
      } else {
        this.$store.dispatch('addNotification', {
          type: 'warning',
          message: 'Данный акт не найден в архиве',
        })
      }

    },
    downloadActPDF() {
      let act_id = this.act.id;
      this.$store.dispatch("downloadActPDF", act_id).then((response) => {
        const url = URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'AVR_Акт_ID0_' + act_id + '.pdf');
        document.body.appendChild(link);
        link.click();
      }).catch((error) => {
        console.log(error);
      });
    },
    downloadHistoryPDF() {
      let act_id = this.act.id;
      this.$store.dispatch("downloadHistoryPDF", act_id).then((response) => {
        const url = URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'AVR_История_акта_ID0_' + act_id + '.pdf');
        document.body.appendChild(link);
        link.click();
      }).catch((error) => {
        console.log(error);
      });
    },
    isIMPL() {
      return localStorage.getItem('roles').includes(1);
    },
    isOWNER() {
      let user = JSON.parse(localStorage.getItem('user'));
      return user['id'] === this.act.impl_owner.id;
    },
    isOWNERMIRROR() {
      let user = JSON.parse(localStorage.getItem('user'));
      return user['id'] === this.act.contract.abp.id;
    },
    current_user() {
      this.curr_user = JSON.parse(localStorage.getItem('user'));
    },
    isABP() {
      return localStorage.getItem('roles').includes(2);
    },
    isAPPROVER() {
      return localStorage.getItem('roles').includes(3);
    },
    isRATIFICATOR() {
      return localStorage.getItem('roles').includes(4);
    },
    isECONOMIST() {
      return localStorage.getItem('roles').includes(5);
    },
    isBOOKER() {
      return localStorage.getItem('roles').includes(6)
    },
    isDZOADM() {
      return localStorage.getItem('roles').includes(7);
    },
    prettyPrice(price) {
      if (typeof price == "number") {
        return price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
      } else {
        return price;
      }
    },
    showAddApproverModalImpl() {
      this.$modal.show("add-approver-modal-impl");
    },
    showEconomistEditModal() {
      this.$modal.show("economist-edit-modal");
    },
    showABPSAPModal() {
      this.$modal.show("abp-sap-modal");
    },
    showBOOKERSAPModal() {
      this.$modal.show("booker-sap-modal");
    },
    showAddApproverModalABP() {
      this.$modal.show("add-approver-modal-abp");
    },
    showChangeApproverModalABP(approve_user_id) {
      this.$modal.show("change-approver-modal-abp-" + approve_user_id);
    },
    showDoubleImageModal(index) {
      this.$modal.show("double-modal-" + index);
    },
    slideClass(index) {
      if (index % 2 !== 0) {
        return 'slide-right'
      }
      return 'slide-left'
    },
    fast_active(to_filter) {
      if (to_filter.toString() === this.fast_filter.toString()) {
        return 'active';
      }
      return '';
    },
    fast_active_form_3(to_filter) {
      if (to_filter === this.show_form_3) {
        return 'active';
      }
      return '';
    },
    change_fast_filter(curr_filter) {
      this.fast_filter = curr_filter;
    },
    change_fast_form_3(curr_filter) {
      this.show_form_3 = curr_filter;
    },
    getActDetail() {
      this.$store.dispatch('getActDetail', this.$route.params.id).then((response) => {
        this.act = response.data;

        this.$store.dispatch('getSapZakaz', this.act.contract.id).then((response) => {
          this.zakaz_dict = response.data.zakaz_dict;
        });

        this.is_OWNER = this.isOWNER();
        this.is_OWNERMIRROR = this.isOWNERMIRROR();
        for (let i = 0; i < this.act.approvers.length; i++) {
          let approve = this.act.approvers[i];
          if (approve.decision === 'Назначен' && approve.user.id === this.curr_user.id) {
            this.curr_APPR = true;
            if (approve.ratifier) {
              this.curr_RAT = true;
            } else if (approve.user.id === this.act.contract.abp.id) {
              this.curr_ABP = true;
            }
            break;
          }
        }
        this.$store.dispatch('getXmlToSign', this.act.id).then((response) => {
          this.xmlToSign = response.data.xmlToSign;
        });
        this.$store.dispatch('getAllCollegues').then((response) => {
          this.all_collegues = response.data;
        });
      }).catch(e => {
        console.log(e)
      })
    },
  },
  mounted() {
    let recaptchaScript1 = document.createElement('script');
    recaptchaScript1.setAttribute('src', 'https://unpkg.com/img-comparison-slider@3/dist/component/component.esm.js');
    recaptchaScript1.setAttribute('type', 'module');
    document.head.appendChild(recaptchaScript1);

    let recaptchaScript2 = document.createElement('script');
    recaptchaScript2.setAttribute('src', 'https://unpkg.com/img-comparison-slider@3/dist/component/component.js');
    recaptchaScript2.setAttribute('nomodule', '');
    document.head.appendChild(recaptchaScript2);

    let recaptchaScript3 = document.createElement('link');
    recaptchaScript3.setAttribute('href', 'https://unpkg.com/img-comparison-slider@3/dist/collection/styles/initial.css');
    recaptchaScript3.setAttribute('rel', 'stylesheet');
    document.head.appendChild(recaptchaScript3);

  },
};
</script>

<style scoped>
@import "../assets/css/webpixels.css";

@keyframes example {
  from {
    flex: 1
  }
  to {
    flex: none
  }
}

.in_sap_card {

}

.sap-success {
  height: 100px;
  position: fixed;
  top: 35%;
  left: 450px;
  font-size: 100px;
  border: 5px solid #0c8;
  opacity: 0.3;
  text-align: center;
  /*transform: rotateZ(-30deg);*/
  padding: 4rem;
  border-radius: 1.5rem;
}

.sap-warning {
  height: 100px;
  position: fixed;
  top: 35%;
  left: 450px;
  font-size: 100px;
  border: 5px solid #ff8c00;
  opacity: 0.3;
  text-align: center;
  /*transform: rotateZ(-30deg);*/
  padding: 4rem;
  border-radius: 1.5rem;
}

.archived-warning {
  height: 100px;
  width: 350px;
  position: fixed;
  top: 4%;
  right: 150px;
  font-size: 40px;
  background: rgb(35, 70, 140);
  color: white;
  /*border: 5px solid rgb(35, 70, 140);*/
  opacity: 0.3;
  text-align: center;
  /*transform: rotateZ(-30deg);*/
  padding: 2rem;
  padding-top: 2.5rem;
  border-radius: 1.5rem;
}

.dropzone {
  /*margin-top: 3rem;*/
  height: 250px;
  min-height: 250px;
  padding: 10px;
  position: relative;
  cursor: pointer;
  border: 2px dashed gray;
  outline-offset: 10px;
  background: #d9e3f7;
  color: dimgray;
}

.input-field {
  opacity: 0;
  width: 100%;
  height: 100%;
  left: 0;
  position: absolute;
  cursor: pointer;
}

.dropzone:hover {
  background: lightblue;
  transition: all 300ms ease;
}

.dropzone .call-to-action {
  font-size: 1.5rem;
  text-align: center;
  padding: 35px 0;
}

.call-to-action > div {
  font-size: 1rem;
  padding: 1rem 2rem;
  background: #00aacc;
  max-width: 300px;
  margin: 1rem auto;
  border-radius: 20px;
  color: #fff;
}

.placeholder-disabled::placeholder {
  text-decoration: line-through;
  color: lightgray;
}

.uploaded-msg {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

img-comparison-slider {
  --divider-width: 2px;
  --divider-color: #c0c0c0;
  --handle-opacity: 0.3;
  height: auto;
  width: auto;
  /*background-color: black;*/
}

.flex-column-animated {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 2rem;
  padding-bottom: 2rem;
}

.section-btn {
  background: inherit;
  animation: example 1000ms ease;

  position: relative;
}

.section-btn-bx:hover {
  transform: scale(1.2) translateX(-0.6rem);
}

.section-btn-bx {
  color: white;
  font-size: 40px;
  border-radius: 50%;
  padding: 0.5rem;
  transition-duration: .5s;
}

.section-btn-bx-static {
  color: white;
  width: 70px;
  font-size: 30px;
  animation: example 3000ms ease;
  border-radius: .5rem;
  padding: 0.2rem;
  transition-duration: .5s;
  overflow: hidden;
}

.section-btn-bx-static:hover {
  width: 150px;
  text-align: center;
}

.section-btn-bx-static-change:hover::before {
  content: 'Заменить';
  font-size: 27px;
  font-family: 'Quicksand', 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
}

.section-btn-bx-static-remove:hover::before {
  content: 'Исключить';
  font-size: 27px;
  font-family: 'Quicksand', 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;

}

.bx-disabled {
  opacity: 0.2;
  /*background: lightgray !important;*/
}

.hp::first-letter {
  text-transform: capitalize;
}

.m-table-act {
  width: 100%;

  text-align: center;
  font-size: x-small;
  border: 1px solid black;
  border-collapse: collapse;
}

.m-table-act td {
  border: 1px solid black;
  border-collapse: collapse;
}

.m-table-act th {
  -moz-transition: all 1s ease-out;
  -o-transition: all 1s ease-out;
  -webkit-transition: all 1s ease-out;
  border: 1px solid black;
  border-collapse: collapse;
  background: #f5f9fc;
}


.m-table-act th:hover {
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -o-transform: scale(1.2);
  /*background-color: white;*/
}

.table-abp thead tr th {
  border-top-width: 0 !important;
}

.section-header {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
}

.section-header h2 {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 16px;
  color: black;
}

.section-header hr {
  border: 1px solid gray;
  width: 120px;
}

.bg-gradient_solid {
  color: #fff;
}

.steps {
  position: relative;
  margin-top: 32px;
}

.steps::after {
  content: "";
  position: absolute;
  width: 1px;
  background-color: white;
  opacity: 1;
  top: 0;
  bottom: 0;
  left: 50%;
}

.steps .content p {
  color: #676767;
  font-size: 16px;
}

.steps .content h2 {
  font-weight: 600;
  font-size: 16px;
  color: #676767;
}

.steps-container {
  position: relative;
  background-color: inherit;
  width: calc(50% + 32px);
}

.steps-container .content {
  padding: 32px;
  background-color: white;
  position: relative;
  border-radius: 0px 0px 80px 0px;
  transition: all 1.3s linear;
  box-shadow: 0px 16px 27px rgb(0 11 30 / 10%);
}

.steps .steps-container:nth-child(even) {
  left: calc(50% - 32px);
  flex-direction: row-reverse;
}

.steps-container {
  display: flex;
}

.steps .steps-container .date {
  font-weight: 900;
  font-size: 16px;
  color: #ffffff;
  margin-bottom: 10px;
  width: 62px;
  height: 62px;
  background-color: var(--primary);
  border-radius: 50%;
  flex-shrink: 0;
  align-items: center;
  display: flex;
  justify-content: center;
  z-index: 777;
}

.step-line {
  width: 40px;
  background-color: white;
  height: 1px;
  margin-top: 31px;
  opacity: 1;
  flex-shrink: 0;
}

@media (max-width: 767px) {
  .steps::after {
    left: 32px;
  }

  .steps-container {
    left: 0;
    flex-direction: row-reverse;
    width: auto;
    margin-bottom: 16px;
  }

  .steps .steps-container:nth-child(even) {
    left: 0;
  }
}

.table-bordered2 thead tr th {
  border-left: 1px #e7eaf0 solid;
  border-right: 1px #e7eaf0 solid
}

.table-bordered2 tbody tr td {
  border-left: 1px #e7eaf0 solid;
  border-right: 1px #e7eaf0 solid
}

.item-wrap {
  display: flex;
  justify-content: space-between;
}

.left-item ul {
  display: flex;
  justify-content: left;
  gap: 1rem;
}

@media screen and (max-width: 1100px) {
  .left-item {
    flex-direction: column;
    gap: 0.2rem;
    height: 100px !important;
  }

  .ul-li {
    height: 30px !important;
  }
}

.item {
  height: 50px;
  width: 50%;
}

.innernavbar-wrap {
  width: 100%;
}

.ul-li {
  list-style-type: none;
  width: 250px !important;
  min-width: 250px !important;
  height: 30px;
  border-radius: 10px;
  background: #d9e3f7 !important;
  text-align: center;
  cursor: pointer;
  color: #666 !important;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  border: 1px solid #d9e3f7;
}

.ul-li:hover {
  background: #2b6de8 !important;
  color: white !important;
}

.active {
  color: white !important;
  background: #23468c !important;
}

@keyframes progress {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

th {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

td {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.tr-main {
  cursor: pointer
}

@keyframes wave {
  50% {
    transform: scale(0.9);
  }
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.select_button {
  /*background: rgb(29, 161, 242);*/
  background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) no-repeat right #00aacc;
  -webkit-appearance: none;
  background-position-x: 97%;
  color: white;
  width: 98%;
  margin: .5rem;
  padding: .5rem 1.5rem;
  border-radius: 1rem;
  text-align: left;
}

.default_option {
  background: white !important;
  color: black !important;
}

.m-input-cell {
  width: 100%;
  /*border-width: 1px;*/
  height: 35px;
  text-align: center;
}

.only-mobile {
  display: none;
}

@media (max-width: 1000px) {
  .mainless {
    display: none;
  }

  .only-mobile {
    display: block !important;
  }

  .actions-btn {
    /*border: 1px solid grey;*/
    padding: 0.5rem;
    background: #23468c;
    border-radius: 10px;
    color: white;
    /*position: absolute;*/
    /*bottom: 100px;*/
    /*right: 100px;*/
  }

  .right-section {
    display: none !important;
  }

  .mobile-flex {
    display: flex;
    flex-direction: column;
  }
  .mobile-flex li {
    width: 100%;
  }
  .mobile-flex li button{
    width: 100%;
  }
  .innernavbar-wrap {
    height: 200px;
  }
  .item-wrap {
    width: 100% !important;
  }
  .item {
    width: 100%;
  }

  .p-10 {
    padding: 10px !important;
    margin: 0 !important;
  }


}

.upload-btn {
  width: 200px;
  border-radius: .5rem;
  color: white;
  background: #23468c !important;
}


.inputfile {
  /* visibility: hidden etc. wont work */
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputfile:focus + label {
  /* keyboard navigation */
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}

.inputfile + label * {
  pointer-events: none;
}

.upload-from-xls {
  padding: 0.3rem 0.7rem 0.3rem 0.7rem;
  background-color: #00aacc;
  color: white;
  border-radius: 5px;
}

</style>
