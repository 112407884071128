<template>
  <div>
    <div class="messenger container">
      <div class="messenger-container">
        <div class="messenger-user-list">
          <div class="messenger-user-list-search">
            <!-- <i class="fa fa-search icon"></i> -->
            <input class="messenger-search-input" list="users-list" name="users-list" id="user-list-input" placeholder="Поиск по фамилии" v-model="search" @change="userSelected($event)">
            <datalist id="users-list">
              <option v-for="user in allUsers" :value="user.last_name" ></option>
            </datalist>
<!--            <input-->
<!--                class="messenger-search-input"-->
<!--                type="text"-->
<!--                v-model="search"-->
<!--                placeholder="Поиск по фамилии"-->
<!--            />-->
            <!-- <button class="messenger-search-btn">
                <i class="fa fa-plus icon"></i>
                Добавить
              </button> -->
          </div>
          <div
              class="messenger-user-card"
              v-for="userWithChat in usersList"
              :key="userWithChat.id"
          >
            <img
                class="messenger-user-card-avatar"
                src="@/assets/avatar.png"
                alt=""
            />
            <div
                class="messenger-user-card-caption"
                @click="
								openChat(
									userWithChat.id,
									userWithChat.first_name,
									userWithChat.last_name
								)
							"
            >
              <p class="user-name">
                {{ userWithChat.last_name | TitleCase }}
                {{ userWithChat.first_name | TitleCase }}
              </p>
              <!-- <p>Мы должны разработать новую CRM-систему.</p> -->
              <!-- <span class="last-message-time">15:45</span> -->
            </div>
          </div>
        </div>
        <div class="messenger-active-chat">
          <div class="chat-header">
            <div class="chat-header-left-side">
              <img
                  v-if="chatPartner"
                  class="messenger-chat-card-avatar"
                  src="@/assets/avatar.png"
                  alt=""
              />
              <div class="messenger-chat-card-caption">
                <p>{{ chatPartner | TitleCase }}</p>
                <!-- <span class="chat-user-status">В сети</span> -->
              </div>
            </div>
            <!-- <div class="chat-header-right-side">
              <i class="fa fa-search icon"></i>
              <i class="fas fa-ellipsis-h"></i>
            </div> -->
          </div>
          <div class="chat-messages-container">
            <div class="chat-messages">
              <ChatRoom
                  v-if="renderChatRoom"
                  :roomName.sync="roomName"
              />
              <div class="choose-partner" v-else>
                Выберите собеседника
                <img class="choose-partner-img" src="../assets/team.png" alt="Team">
              </div>
              <!-- Модуль в разработке -->
            </div>
            <!-- <div class="chat-message-input">
                <input class="message-input" type="text" placeholder="Введите сообщение">
                <i class="far fa-paper-plane icon"></i>
              </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {mapGetters} from 'vuex';
import ChatRoom from './ChatRoom';
import store from '../store';
import NProgress from 'nprogress';

export default {
  name: 'Room',
  components: {
    ChatRoom,
  },
  data() {
    return {
      roomName: '',
      renderChatRoom: false,
      search: '',
      users: '',
      userWithChats: '',
      chatPartner: '',
    };
  },
  beforeRouteEnter(to, from, next) {
    NProgress.start();
    // Calling action from store to get Chat Users
    store.dispatch('getChatRoomUsers').then((response) => {
      store.dispatch('getAllChatUsers').then((response) => {
        NProgress.done();
        next();
      })
    });
  },
  computed: {
    // Receiving users by getters from Vuex store
    usersList() {
      return this.$store.getters.usersList;
    },
    // Filtering users by users request search
    filteredUsers() {
      return this.usersList.filter(user => user.last_name.toLowerCase().includes(this.search.toLowerCase()));
      // return this.usersList.filter((user) => {
      //   return user.last_name.toLowerCase().indexOf(this.search.toUpperCase()) > -1;
      // });
    },
    // Receiving current user
    ...mapGetters(['userId', 'allUsers']),
  },
  methods: {
    userSelected(e) {
      let value = e.target.value
      const user = this.allUsers.find(u => u.last_name === value)
      this.openChat(user.id, user.first_name, user.last_name)
    },
    openChat(secondUserId, first_name, last_name) {
      axios
          .post('chat/create/', {
            first_participant: this.userId,
            second_participant: secondUserId,
          })
          .then((response) => {
            // this.$router.push({name: 'ChatRoom', params: {roomName: response.data.id}})
            this.roomName = response.data.id;
            this.renderChatRoom = false;
            this.$nextTick(() => {
              // Add the component back in
              this.renderChatRoom = true;
            });

            this.chatPartner = `${last_name} ${first_name}`;
          })
          .catch((error) => {
            console.log(error);
          });
    },
  },
};
</script>

<style scoped>
.messenger {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.messenger-container {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 1rem;
}

.messenger-user-list {
  max-height: 90vh;
  min-height: 90vh;
  overflow: auto;
  min-width: 500px;
  background: #fff;
  box-shadow: 4px 4px 8px 4px rgba(0, 0, 0, 0.2),
  4px 4px 4px 4px rgba(0, 0, 0, 0.19);
  padding: 1rem;
  border-radius: 10px;
}

.messenger-user-list::-webkit-scrollbar {
  display: none;
}

.messenger-user-list::-webkit-scrollbar {
  width: 0.25rem;
  width: 13px;
  height: 13px;
}

.messenger-user-list::-webkit-scrollbar-track {
  background: #e3e3e3;
  -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
  border-radius: 15px;
}

.messenger-user-list::-webkit-scrollbar-thumb {
  background: #36429e;
  border-radius: 100px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.messenger-user-list-search {
  margin-bottom: 1rem;
}

.messenger-search-input {
  margin-top: 0.2rem;
  border-radius: 10px;
  border: 1px solid #e3e3e3;
  outline: none;
  padding: 1rem 2rem;
  width: 100%;
}

.messenger-user-card {
  border-radius: 10px;
  border: 1px solid #e3e3e3;
  padding: 1rem 2rem;
  background: #fcfcfc;
  cursor: pointer;
  margin-bottom: 0.5rem;

  display: flex;
}

.messenger-user-card-caption {
  padding: 0.5rem 1rem;
  position: relative;
}

.messenger-user-card-caption .user-name {
  font-weight: bold;
  font-size: 1.2rem;
}

.last-message-time {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
}

.messenger-user-card-avatar {
  width: 65px;
  height: 65px;
  border-radius: 50%;
}

/* .messenger-search-btn {
  background: #36429E;
  color: #fff;
  padding: .1rem 1rem;
  border-radius: 10px;
  border: none;
  outline: none;
  font-size: 1.5rem;
}

.messenger-search-btn i::before {
  font-size: 1.5rem;
  color: #fff;
} */

.messenger-active-chat {
  max-height: 90vh;
  background: #fff;
  box-shadow: 4px 4px 8px 4px rgba(0, 0, 0, 0.2),
  4px 4px 4px 4px rgba(0, 0, 0, 0.19);
  padding: 1rem 0;
  border-radius: 10px;
  overflow: hidden;
}

.chat-header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e5e5e5;
  padding: 0 1rem;
  padding-bottom: 0.5rem;
}

.chat-header-left-side {
  display: flex;
}

.messenger-chat-card-caption {
  padding: 0.2rem 1rem;
  position: relative;
  margin-bottom: 0.5rem;
  font-weight: bold;
  font-size: 1.3rem;
}

.messenger-chat-card-avatar {
  width: 65px;
  height: 65px;
  border-radius: 50%;
}

.chat-user-status {
  display: inline-block;
  background: #5fba8e;
  color: #fff;
  border-radius: 3px;
  padding: 0.3rem;
  margin: 0.2rem 0 0;
  font-size: 1rem;
}

.chat-header-right-side {
  margin-right: 1rem;
}

.chat-header-right-side i::before {
  font-size: 1.3rem;
  cursor: pointer;
}

.chat-messages-container {
  position: relative;
  width: 100%;
  /* height: 85%; */
  overflow: hidden;
}

.chat-messages {
  /* max-height: 50vh; */
  /* overflow: scroll; */
  /* For Demo Purpose */
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 2rem;
  position: relative;
}

.choose-partner {
  text-align: center;
  overflow: hidden;
}

.choose-partner-img {
  width: 100%;
  max-width: 500px;
  height: auto;
}

.chat-message-input {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.message-input {
  width: 90%;
  border: 1px solid #e3e3e3;
  padding: 1rem;
  border-radius: 15px;
  margin-left: 1rem;
}

.message-input:focus {
  outline: none;
}

.chat-message-input i::before {
  font-size: 2rem;
  padding-right: 1rem;
  color: #36429e;
}
</style>
