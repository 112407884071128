<template>
  <section class="home-section" id="home-section">
    <div class="p-10">
      <div>
        <div class="innernavbar-wrap" style="padding-bottom: 0 !important; padding-top: 0 !important;">
          <div class="item-wrap">
            <div class="item left-item">
              <ul>
                <li v-for="year in years_list" :key="year">
                  <button class="filter-btn transition-all ease-in-out duration-150 transform hover:scale-110 bg-indigo-500 hover:bg-indigo-600 px-5 py-1 rounded-lg"
                          :class="fast_active_year(year)"
                          @click="change_fast_filter_year(year)">
                    {{ year }}
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="innernavbar-wrap" style="padding-bottom: 0 !important; padding-top: 0 !important;" v-if="fast_filter_year !== 'Все года'">
          <div class="item-wrap">
            <div class="item left-item">
              <ul>
                <li v-for="(month, index) in month_list" :key="index">
                  <button class="filter-btn transition-all ease-in-out duration-150 transform hover:scale-110 bg-indigo-500 hover:bg-indigo-600 px-5 py-1 rounded-lg"
                          :class="fast_active_month(index)"
                          @click="change_fast_filter_month(index)">
                    {{ month }}
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div style="display: flex; justify-content: flex-start; gap: 1rem">
<!--        <select class="input-field" v-model="search_act_ngdu" style="margin-top: 0" type="text"-->
<!--                v-on:keyup.enter="getAllActs(1)">-->
<!--          <option value="">Выберите НГДУ</option>-->
<!--          <option v-for="ngdu in ngdu_list" :key="ngdu" :value="ngdu">{{ ngdu }}</option>-->
<!--        </select>-->
        <input class="input-field" v-model="search_act_contr" style="margin-top: 0" type="text" placeholder="Введите номер или наименование договора"
               v-on:keyup.enter="getAllApproves(1)">
        <input class="input-field" v-model="search_act_impl" style="margin-top: 0" type="text" placeholder="Введите БИН или наименование контрагента"
               v-on:keyup.enter="getAllApproves(1)">
        <!--          <button class="search-btn transition-all ease-in-out duration-150 transform hover:scale-110 px-5 py-1 rounded-lg my-4" style="margin-top: 0 !important;" @click="getAllActs(1)">Поиск</button>-->

        <input class="input-field" v-model="search_last_name" style="margin-top: 0" type="text" placeholder="Введите фамилию пользователя"
               v-on:keyup.enter="getAllApproves(1)">
        <button class="search-btn transition-all ease-in-out duration-150 transform hover:scale-110 px-5 py-1 rounded-lg my-4" style="margin-top: 0 !important;" @click="getAllApproves(1)">Поиск</button>
        <button class="search-btn transition-all ease-in-out duration-150 transform hover:scale-110 px-5 py-1 rounded-lg my-4 upload-from-xls" style="margin-top: 0 !important; background: #00a36d" @click="downloadApproves">Скачать EXCEL</button>

      </div>
      <div class="card">
        <div class="card-header" style="display: flex; justify-content: space-between">
          <h4>Сроки согласования актов</h4>
<!--            <button class="btn-danger" style="border-radius: 5px">Удалить все уведомления</button>-->
        </div>
        <div class="table-responsive">
          <table class="table table-hover table-nowrap">
            <thead class="thead-light">
            <tr>
              <th scope="col" class="">ID акта</th>
              <th scope="col" class="">Наименование</th>
              <th scope="col" class="">Поставщик</th>
              <th scope="col" class="">Статус акта</th>
              <th scope="col" class="">Пользователь</th>
              <th scope="col" class="">Детали</th>
              <th scope="col" class="">Срок</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(approve, index) in this.approves" :key="approve.id" :id="'req-tr-' + approve.id" class="tr-main">
              <td data-label="" class="">
                <router-link class="custom-link" :to="`/acts/${approve.act_id}`">
                  {{ approve.act_id }}
                </router-link>
              </td>
              <td data-label="" class="" :title="approve.act_name">{{ approve.act_name_short }}</td>
              <td data-label="" class="" :title="approve.act_impl_company">{{ approve.act_impl_company_short }}</td>
              <td data-label="" class="">{{ approve.act_status }}</td>
              <td data-label="" class="">{{ approve.user.ouser.full_name }}</td>
              <td data-label="" class="text-center">
                <i class="bx bx-detail" style="color: #6c63ff; transform: scale(1.5)" @click="showPositionModal(approve.id)"></i>
                <modal :name="'position-modal-' + approve.id" :height="500">
                  <div class="p-10">
                    <h4 class="text-center">Подробности</h4>
                    <hr>
                    <div class="div-item">
                      <div class="descripcion">ФИО</div>
                      <div class="precio">{{ approve.user.ouser.full_name }}</div>
                    </div>
                    <div class="div-item">
                      <div class="descripcion">Должность</div>
                      <div class="precio">{{ approve.user.ouser.position }}</div>
                    </div>
                    <div class="div-item">
                      <div class="descripcion">Организация</div>
                      <div class="precio">{{ approve.user.ouser.company.name }}</div>
                    </div>
                    <div class="div-item">
                      <div class="descripcion">Дата акта</div>
                      <div class="precio">{{ approve.act_date }}</div>
                    </div>
                    <div class="div-item">
                      <div class="descripcion">Договор</div>
                      <div class="precio">
                        <router-link class="custom-link" :to="`/contracts/${approve.contract_id}`">
                          {{ approve.contract_data }}
                        </router-link>
                      </div>
                    </div>
                    <div class="div-item">
                      <div class="descripcion">Дата начала согласования</div>
                      <div class="precio">{{ approve.started_datetime_parsed }}</div>
                    </div>
                    <div class="div-item">
                      <div class="descripcion">С момента назначения прошло</div>
                      <div class="precio">{{ approve.time_to_end }}</div>
                    </div>
                  </div>
                </modal>
<!--                {{ approve.user.ouser.position }}-->
              </td>
<!--              <td data-label="" class="">{{ approve.started_datetime_parsed }}</td>-->
              <td data-label="" class="">{{ approve.time_to_end }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="mt-5">
        <pagination :pageCount="max_pages" :currentPage="current_page" @pageClicked="getAllApproves" />
      </div>
    </div>
  </section>
</template>
<script async>
import Pagination from "../components/shared/Pagination.vue"
export default {
  components: {
    Pagination
  },
  data() {
    return {
      current_page: 1,
      max_pages: 1,
      search_act_contr: "",
      search_act_impl: "",
      search_last_name: "",
      years_list: [
        '2023',
        '2022',
        '2021',
        '2020',
        '2019',
        'Все года'
      ],
      month_list: [
        'Все месяца',
        'Январь',
        'Февраль',
        'Март',
        'Апрель',
        'Май',
        'Июнь',
        'Июль',
        'Август',
        'Сентябрь',
        'Октябрь',
        'Ноябрь',
        'Декабрь'
      ],
      fast_filter_year: "2023",
      fast_filter_month: 0,
      all_approves: [],
      approves_global: [],
      approves: []
    };
  },
  created() {
    this.getAllApproves(1);
  },
  mounted() {
  },
  methods: {
    showPositionModal(index) {
      this.$modal.show("position-modal-" + index);
    },
    downloadApproves() {
      let formData = {
        search_act_contr: this.search_act_contr,
        search_act_impl: this.search_act_impl,
        search_last_name: this.search_last_name,
        filter_year: this.fast_filter_year,
        filter_month: this.fast_filter_month,
      };
      this.$store.dispatch("downloadApproves", formData).then((response) => {
        const url = URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'AVR_Отчет.xlsx');
        document.body.appendChild(link);
        link.click();
      }).catch((error) => {
        this.$store.dispatch('addNotification', {
          type: 'error',
          message: error.response.data.detail,
        })
      });
    },
    getAllApproves(page) {
      this.approves = [];
      this.approves_global = [];
      this.loading = true
      if (page > 0 && page < this.max_pages + 1) {
        this.current_page = page;
        let formData = {
          // status: this.fast_filter,
          page: page,
          // search_act: this.search_act,
          // search_act_ngdu: this.search_act_ngdu,
          search_act_contr: this.search_act_contr,
          search_act_impl: this.search_act_impl,
          search_last_name: this.search_last_name,
          filter_year: this.fast_filter_year,
          filter_month: this.fast_filter_month,
        };
        this.$store.dispatch("getAllApproves", formData).then((response) => {
          this.approves = response.data.results;
          this.approves_global = response.data.results;
          this.all_approves = response.data;
          this.max_pages = parseInt(this.all_approves.count / 10) + 1;
        }).catch((error) => {
          this.$store.dispatch('addNotification', {
            type: 'error',
            message: error.response.data.detail,
          })
        });
      }
    },
    fast_active_year(to_filter) {
      if (to_filter.toString() === this.fast_filter_year.toString()) {
        return 'active';
      }
      return '';
    },
    fast_active_month(to_filter) {
      if (to_filter.toString() === this.fast_filter_month.toString()) {
        return 'active';
      }
      return '';
    },
    change_fast_filter_year(curr_filter) {
      this.fast_filter_year = curr_filter;
      this.getAllApproves(1);
    },
    change_fast_filter_month(curr_filter) {
      this.fast_filter_month = curr_filter;
      this.getAllApproves(1);
    },
  },
};
</script>

<style scoped>
@import "../assets/css/webpixels.css";

.tr-main {
  cursor: pointer
}

.item-wrap {
  display: flex;
  justify-content: space-between;
}

.left-item ul {
  display: flex;
  justify-content: left;
  gap: 1rem;
}

@media screen and (max-width: 1100px) {
  .left-item {
    flex-direction: column;
    gap: 0.2rem;
    height: 100px !important;
  }
}

.item {
  height: 50px;
  /*width: 50%;*/
}

.innernavbar-wrap {
  width: 100%;
}

.home-section {
  position: relative;
  background: #e4e9f7;
  min-height: 100vh;
  top: 0;
  left: 78px;
  width: calc(100% - 78px);
  transition: all 0.5s ease;
}

.sidebar.open ~ .home-section {
  left: 250px;
  width: calc(100% - 250px);
}

.home-section .text {
  display: inline-block;
  color: #11101d;
  font-size: 25px;
  font-weight: 500;
  margin: 18px;
}

.filter-btn {
  padding-left: 0.8rem !important;
  padding-right: 0.8rem !important;
}

.notification-status {
  padding: .5rem 1rem;
  border-radius: 12px;
}

.unread {
  background: #ebf8ee;
  border: 1px solid #c1e4c9;
}

.read {
  background: #e5effa;
  border: 1px solid #afcdef;
}

.input-field {
  width: 100%;
  padding: 10px;
  border: none;
  outline: none;
  margin: 1rem 0;
  border-radius: 10px;
}

.div-item {
  height: 40px;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}

.descripcion {
  /*background-color: green;*/
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.descripcion:after {
  content: " ........................................................................................................................................................................................................................................................................................."
}

.precio {
  /*background-color: red;*/
  flex-shrink: 0;
}

</style>