<template>
  <div>
    <div class="auth-section">
      <img
          class="login-logo"
          src="../assets/logo-header.png"
          alt="Logo"
      />
      <div class="auth-block">
        <div class="auth-form-container">
          <form action="" class="auth-form" @submit.prevent="signFromIin">
            <h1 class="auth-form-title">Вход в систему</h1>
            <div class="input-container login-fixed-width">
              <input
                class="input-field"
                type="text"
                placeholder="Введите логин"
                v-model="username"
                name="iin"
              />
            </div>
            <div class="input-container login-fixed-width">
              <input
                class="input-field input-password"
                type="password"
                placeholder="Введите пароль"
                v-model="password"
                name="password"
              />
              <a class="input-field forgot-pass custom-link" style="border: none" @click="showForgotPasswordModal">Забыли пароль?</a>
            </div>

            <button class="auth-btn login-fixed-width">Войти</button>
            <button class="auth-btn edc login-fixed-width" @click="showEcpModal" type="button">
              Войти (с помощью ЭЦП)
            </button>
            <router-link :to="{ name: 'registration' }">
              <button class="auth-btn login-fixed-width" type="button">
                Регистрация
              </button>
            </router-link>

          </form>
        </div>
      </div>
    </div>

    <!-- Forgot password modal -->
    <modal name="forgot-password-modal">
      <div class="modal-inner">
        <div>
          Для сброса пароля введите почту или логин/иин указанный при регистрации
        </div>
        <div class="input-container">
          <input
              class="input-field email-iin-fixed-width"
              type="text"
              style="width: 70% !important;"
              placeholder="Введите почту или логин/ИИН"
              v-model="recovery_username"
              name="iin_mail"
          />
        </div>
        <a>
          <button @click="password_recovery" style="border: 1px solid gray; padding: 1rem; border-radius: 10px">Сбросить пароль</button>
        </a>
      </div>
    </modal>

    <!-- Digital Signature modal -->
    <modal name="ds-modal" :height="400">
      <div class="modal-inner">
        <h3>Вход через ЭЦП</h3>
        <form method="POST" id="auth-form" @submit.prevent="sendSignData">
          <div hidden>
            <select id="storageSelect">
              <option value="PKCS12" selected>PKCS12</option>
            </select>
          </div>

          <p>
            Для успешной авторизации рекомендуется <br />
            выбирать ключ <b>GOSTKNCA</b>
          </p>
          <button
              class="auth-btn login-fixed-width"
              type="button"
              @click="getKeyInfoCall"
          >
            Выбрать сертификат
          </button>
          <br />
          <div hidden>
            <input id="keyId_sign" name="keyId_sign" type="text" />
            <input id="subjectDn_sign" name="subjectDn_sign" />
            <input id="serialNumber_sign" name="serialNumber_sign" />
            <input id="certNotAfter_sign" name="certNotAfter_sign" />
            <input id="certNotBefore_sign" name="certNotBefore_sign" />
            <textarea
              id="pem_sign"
              name="pem_sign"
              rows="7"
              readonly
            ></textarea>
            <input id="alias_sign" name="alias_sign" />
            <input id="algorithm_sign" name="algorithm_sign" />
            <input id="subjectCn_sign" name="subjectCn_sign" />
            <input id="issuerCn_sign" name="issuerCn_sign" />
            <input id="issuerDn_sign" name="issuerDn_sign" />
            <input
              id="authorityKeyIdentifier_sign"
              name="authorityKeyIdentifier_sign"
            />
          </div>

          <button id="auth_sign" type="submit" hidden>submit</button>
        </form>
      </div>
    </modal>
  </div>
</template>

<script async>
import { getKeyInfo } from "../nclayer/ncalayer";

export default {
  data() {
    return {
      username: "",
      password: "",
      recovery_username: "",
      showModal: false,
      showECPLogin: false,
      showRegModal: false,
      downloadRegistraionTemplate: `${process.env.VUE_APP_MEDIA_URL}/media/registrationTemplate.xlsx`,
      downloadInstruction: `${process.env.VUE_APP_MEDIA_URL}/media/instruction.pdf`,
    };
  },
  methods: {
    showForgotPasswordModal() {
      this.$modal.show("forgot-password-modal");
    },
    showEcpModal() {
      this.$modal.show("ds-modal");
    },
    getKeyInfoCall() {
      let selectedStorage = "PKCS12";
      getKeyInfo(selectedStorage);
    },
    sendSignData() {
      let keyId_sign = document.getElementById("keyId_sign");
      let subjectDn_sign = document.getElementById("subjectDn_sign");
      let serialNumber_sign = document.getElementById("serialNumber_sign");
      let certNotAfter_sign = document.getElementById("certNotAfter_sign");
      let certNotBefore_sign = document.getElementById("certNotBefore_sign");
      let pem_sign = document.getElementById("pem_sign");
      let alias_sign = document.getElementById("alias_sign");
      let algorithm_sign = document.getElementById("algorithm_sign");
      let subjectCn_sign = document.getElementById("subjectCn_sign");
      let issuerCn_sign = document.getElementById("issuerCn_sign");
      let issuerDn_sign = document.getElementById("issuerDn_sign");
      let authorityKeyIdentifier_sign = document.getElementById(
        "authorityKeyIdentifier_sign"
      );
      let formData = {
        alias_sign: alias_sign.value,
        keyId_sign: keyId_sign.value,
        algorithm_sign: algorithm_sign.value,
        subjectCn_sign: subjectCn_sign.value,
        subjectDn_sign: subjectDn_sign.value,
        issuerCn_sign: issuerCn_sign.value,
        issuerDn_sign: issuerDn_sign.value,
        serialNumber_sign: serialNumber_sign.value,
        certNotAfter_sign: certNotAfter_sign.value,
        certNotBefore_sign: certNotBefore_sign.value,
        authorityKeyIdentifier_sign: authorityKeyIdentifier_sign.value,
        pem_sign: pem_sign.value,
      };
      this.$store.dispatch("login", formData);
    },
    signFromIin() {
      let formData = {
        username: this.username,
        password: this.password,
      };
      this.$store.dispatch("loginWithIin", formData)
        .then(() => {
          this.$router.push({ name: "landing" });
        }).catch((error) => {
            this.$store.dispatch('addNotification', {
              type: 'error',
              message: error.response.data.detail,
            })
          });
    },
    password_recovery() {
      let formData = {
        username: this.recovery_username,
      };
      this.$store.dispatch("password_recovery_any", formData)
        .then((response) => {
          this.$modal.hide("forgot-password-modal");
          this.$store.dispatch('addNotification', {
            type: 'success',
            message: response.data.detail,
          });
        })
        .catch((error) => {
          this.$store.dispatch('addNotification', {
            type: 'error',
            message: error.response.data.detail,
          });
        });
    },
  },
};
</script>

<style scoped lang="scss">
.modal-inner {
  height: 100%;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  text-align: center;
}

.login-logo {
  margin-bottom: 2rem;
  width: 200px;
}

.modal-inner address {
  font-weight: bold;
}

.auth-section {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: #e4e9f7;
  flex-direction: column;
  /*grid-template-columns: 1fr 1fr;*/
}

/*@media screen and (max-width: 1100px) {*/
/*  .auth-section {*/
/*    grid-template-columns: 1fr;*/
/*  }*/
/*}*/

.auth-block {
  /*height: 50vh;*/
}

.mail-link {
  color: white;
  text-decoration: none;
}

.auth-block {
  background-color: white;
  padding: 2rem;
  border-radius: 1rem;
  -webkit-box-shadow: 0px 0px 20px 5px rgba(34, 60, 80, 0.45);
  -moz-box-shadow: 0px 0px 20px 5px rgba(34, 60, 80, 0.45);
  box-shadow: 0px 0px 20px 5px rgba(34, 60, 80, 0.45);
  /*background: #f6f8fa;*/
}

.instruction-block {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.auth-form-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.auth-form {
  display: flex;
  flex-direction: column;
  max-width: 26.25rem;

  &-title {
    text-align: center;
    font-size: 1.5rem;
    padding-bottom: .5rem;
  }
}

.auth-form-logo {
  display: inline;
}

.auth-top {
  display: grid;
  grid-template-columns: 0.3fr 1fr;
  margin-bottom: 30px;
}

.input-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.input-password {
  min-width: 250px;
  max-width: 250px;
}

.password-container {
  display: grid;
  width: 100%;
  grid-template-columns: 2fr 1fr;
}

.forgot-pass {
  width: 100%;
  text-decoration: none;
}

.custom-link {
  color: royalblue;
  /*overflow: hidden;*/
  background: linear-gradient(to right, midnightblue, midnightblue 50%, royalblue 50%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% 100%;
  background-position: 100%;
  transition: background-position 275ms ease-in-out .1s;
  text-decoration: none;
}

.custom-link:hover {
  background-position: 0 100%;
}

.login-fixed-width {
  min-width: 400px;
  max-width: 400px;
}

.login-logo-wrap {
  position: absolute;
  top: 10vh;
  left: calc(50% - 100px);
}

.login-logo-wrap-fixed {
  max-width: 200px !important;
  min-width: 200px !important;
}

.icon {
  padding: 0.9rem;
  color: #868686;
  min-width: 50px;
  text-align: center;
  font-size: 1.5rem;
}

.input-field {
  width: 100%;
  padding: 10px;
  /*border: none;*/
  outline: none;
  border: 1px solid #e8f0fe;
  margin: 0.5rem 0 1rem;
  border-radius: 10px;
}

.email-iin-fixed-width {
  width: 200px;
  border: 1px solid gray;
}

.auth-btn {
  background: #36429e;
  padding: 1rem;
  border: none;
  outline: none;
  color: white;
  border-radius: 10px;
  margin-bottom: 0.8rem;
  cursor: pointer;
}

.auth-btn.edc {
  background: #454757;
}

.downloadBtn {
  margin-top: 1rem;
  padding: 1rem 2rem;
  border: none;
  outline: none;
  border-radius: 10px;
  margin-left: 1rem;
  cursor: pointer;
  font-size: 1.1rem;
}

.contact-item-phone {
  margin-bottom: 24px;
  font-size: 16px;
  color: #322;
  display: flex;
  gap: 1rem;
  position: absolute;
  bottom: 0;
  right: 0;
}

.contact-item-phone ul {
  list-style-type: none;
  color: #fff;
  font-size: 1.2rem;
}

.contact-item-email {
  margin-bottom: 24px;
  font-size: 16px;
  color: #322;
  display: flex;
  gap: 1rem;
  position: absolute;
  bottom: 0;
  left: 0;
  padding-left: 1rem;
}

.contact-item-email ul {
  list-style-type: none;
  color: #fff;
  font-size: 1.2rem;
}

.custom-icon {
  width: 60px;
  height: 60px;
  line-height: 60px;
  background-color: #fff;
  text-align: center;
  color: #3494db;
  border-radius: 50%;
  margin-right: 16px;
}
</style>
