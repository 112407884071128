<template>
	<div id="app">
		<EcpModal />
		<component :is="layout">
			<router-view></router-view>
		</component>
	</div>
</template>

<script>
import EcpModal from '@/components/EcpModal';
const default_layout = 'default';
export default {
	components: {
		EcpModal,
	},
	computed: {
		// loggedIn() {
		// 	return this.$store.getters.loggedIn;
		// },
		layout() {
			return (this.$route.meta.layout || default_layout) + '-layout';
		},
	},
};
</script>

<style>
*,
*::before,
*::after {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
/* 
body::-webkit-scrollbar {
	display: none;
}

.container {
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;
}

@media (min-width: 768px) {
	.container {
		width: 750px;
	}
}

@media (min-width: 992px) {
	.container {
		width: 970px;
	}
}

@media (min-width: 1200px) {
	.container {
		width: 1300px;
	}
}

@media (min-width: 1900px) {
	.container {
		width: 1300px;
	}
}

@media (min-width: 2000px) {
	.container {
		width: 1500px;
	}
}

#app {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #2c3e50;
	min-height: 100vh;
} */


</style>
