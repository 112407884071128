<template>
  <div class="chat_room">
      <!-- Message container -->
      <div class="chat-message-container" ref="messagesBlock">
        <div v-for="message in getMessages" :key="message.id">
          <div v-if="message.send_messages_user.username == currentUser.username">
            <div class="message-content">
              <div class="message">{{ message.content }}</div>
            </div>
          </div>
          <div v-else>
            <div class="message-content">
              <div class="message message-partner">{{ message.content }}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- Message container END -->
      <!-- Message input -->
      <div class="chat-message-input">
        <input
            class="message-input"
            type="text"
            @keyup.enter="sendMessage"
            placeholder="Введите сообщение"
        />
        <!-- <i class="far fa-paper-plane icon" @click="sendMessage"></i> -->
        <i class='bx bx-send icon' @click="sendMessage"></i>
      </div>
      <!-- Message input END -->
  </div>
</template>

<script>
import axios from "axios";
import store from "../store";

import { mapGetters } from "vuex";

export default {
  name: "ChatRoom",
  props: ["roomName"],
  data() {
    return {
      // rooms: '',
      chatTexts: "",
      chatSocket: "",
      currentUser: "",
      messages: [],
    };
  },
  computed: {
    ...mapGetters([
      "USER",
      // 'getMessages'
    ]),
    getMessages() {
      return this.$store.getters.getMessages;
    },
  },
  watch: {
    roomName: function (oldVal, newVal) {
      store.dispatch("openSocket", this.roomName);
    },
    deep: true,
    immediate: true,
  },
  methods: {
    sendMessage() {
      const messageInputDom = document.querySelector(".message-input");
      let message = messageInputDom.value;
      if (message.trim().length == 0) {
        this.$store.dispatch("addNotification", {
          type: "warning",
          message: "Сообщение не может быть пустым",
        });
      } else {
        this.chatSocket.send(
            JSON.stringify({
              user_id: this.currentUser.id,
              message: message,
              command: "fetch_messages",
              chat_id: this.roomName,
            })
        );

      }

      messageInputDom.value = "";
      store.dispatch("openSocket", this.roomName);

      setTimeout(() => {
        let chatMessageContainer = document.querySelector(
            ".chat-message-container"
        );
        chatMessageContainer.scrollTop = chatMessageContainer.scrollHeight;
      });
    },
  },
  mounted() {
    // Start Socket Connection
    let currentUser = this.USER;
    // const roomName = this.$route.params.roomName;
    let roomName = this.roomName;
    const chatUrl = process.env.VUE_APP_ROOT_CHAT_URL;
    const chatSocket = new WebSocket(`wss://${chatUrl}/ws/chat/${roomName}/`);
    this.chatSocket = chatSocket;
    this.currentUser = currentUser;

    chatSocket.onopen = function (event) {
      console.log(event);
      store.dispatch("openSocket", roomName);
    };

    chatSocket.onmessage = function (e) {};

    chatSocket.onclose = function (e) {
      console.log(e);
      console.error("Chat socket closed unexpectedly");
    };
  },
};
</script>

<style>
.chat_room {
  width: 100%;
  height: 100%;
  padding: 0 1rem;
}

.chat-message-container {
  margin: 1rem auto 1rem;
  padding: 2rem;
  height: 65vh;
  overflow: auto;
  border: 1px solid #e3e3e3;
  border-radius: 10px;
  position: relative;
}

@media (max-width: 1500px) {
  .chat-message-container {
    height: 60vh;
  }
}

.chat-message-container::-webkit-scrollbar {
  width: 0.25rem;
  width: 13px;
  height: 13px;
}

.chat-message-container::-webkit-scrollbar-track {
  background: #e3e3e3;
  -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
  border-radius: 15px;
}

.chat-message-container::-webkit-scrollbar-thumb {
  background: #36429e;
  border-radius: 100px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.chat-message-container .message-content {
  width: 100%;
  margin: 0 0 15px;
  display: flex;
  flex-flow: column;
  align-items: flex-end;
}
.chat-message-container .message-content .message {
  padding: 10px 16px 16px 16px;
  border-radius: 15px;
  position: relative;
  background: rgba(100, 170, 0, 0.1);
  border: 2px solid rgba(100, 170, 0, 0.1);
  color: #6c6c6c;
  font-size: 14px;
}
.chat-message-container .message-content .message:after {
  content: "";
  position: absolute;
  border: 10px solid transparent;
}
.chat-message-container .message-content .message:not(.message-partner) {
  margin-inline-start: 20%;
}
.chat-message-container .message-content .message:not(.message-partner):after {
  border-top: 10px solid rgba(100, 170, 0, 0.2);
  border-right: none;
  bottom: -22px;
  right: 10px;
}
.chat-message-container .message-content .message.message-partner {
  background: rgba(0, 114, 135, 0.1);
  border: 2px solid rgba(0, 114, 135, 0.1);
  align-self: flex-start;
  margin-inline-end: 20%;
}
.chat-message-container .message-content .message.message-partner:after {
  border-top: 10px solid rgba(100, 170, 0, 0.2);
  border-left: none;
  bottom: -22px;
  left: 10px;
}
.chat-message-container .message-content .message p {
  margin: 0;
}
.chat-message-container .message-content .message .time {
  position: absolute;
  font-size: 10px;
  right: 0;
  bottom: 0;
  padding: 5px 10px;
  opacity: 0.5;
}

.chat-message-input {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.message-input {
  width: 90%;
  border: 1px solid #e3e3e3;
  padding: 1rem;
  border-radius: 15px;
  margin-left: 1rem;
  font-size: 14px;
}

.message-input:focus {
  outline: none;
}

.chat-message-input i {
  cursor: pointer;
}

.chat-message-input i::before {
  font-size: 2rem;
  color: #36429e;
}

.chat-message-container-empty {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
}
</style>
