import Vue from 'vue'
import VueRouter from 'vue-router'
import Landing from "@/views/Landing"
import Login from "@/views/Login"
import LoginUpdated from '@/views/LoginUpdated'
import Profile from "@/views/Profile"
import Contracts from "@/views/Contracts"
import Logout from "./views/Logout";
import Learning from "./views/Learning";
import Offers from './views/Offers'
import Tutorial from './views/Tutorial'
import Notifications from "./views/Notifications";
import ContractView from "./views/ContractView";
import Acts from "./views/Acts";
import ActView from "./views/ActView";
import CreateAct from "./views/CreateAct";
import CreateActMirror from "./views/CreateActMirror";
import ActVerification from "./views/ActVerification";
import EditAct from "./views/EditAct";
import EditActMirror from "./views/EditActMirror";
import DevPage from './views/DevPage'
import PageNotFound from './views/PageNotFound'
import Registration from "./views/Registration";
import UsersAndPermissibles from "./views/UsersAndPermissibles";
import EditSContract from "@/views/EditSContract";
import SContracts from "@/views/SContracts";
import CreateSContract from "@/views/CreateSContract";
import SContractView from "@/views/SContractView";
import Overdue from "@/views/Overdue";
import Statistics from "@/views/Statistics.vue";
import Room from "@/views/Room"
import Instruction from "@/views/Instruction";

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'landing',
		component: Landing,
		meta: {
			layout: 'landing',
			requiresAuth: true,
		},
	},
	// {
	// 	path: '/login',
	// 	name: 'login',
	// 	component: LoginUpdated,
	// 	meta: {
	// 		layout: 'landing',
	// 		requiresGuest: true,
	// 	},
	// },
	{
		path: '/login',
		name: 'login',
		component: Login,
		meta: {
			layout: 'landing',
			requiresGuest: true,
		},
	},
	{
		path: '/act_verification/:id',
		name: 'act_verification',
		component: ActVerification,
		meta: {
			layout: 'landing',
		},
	},
	{
		path: '/registration',
		name: 'registration',
		component: Registration,
		meta: {
			layout: 'landing',
			requiresGuest: true,
		},
	},
	{
		path: '/logout',
		name: 'logout',
		component: Logout,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/profile',
		name: 'profile',
		component: Profile,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/notifications',
		name: 'notifications',
		component: Notifications,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/overdue',
		name: 'overdue',
		component: Overdue,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/statistics',
		name: 'statistics',
		component: Statistics,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/learning',
		name: 'learning',
		component: Learning,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/instruction',
		name: 'instruction',
		component: Instruction,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/offers',
		name: 'offers',
		component: Offers,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/tutorial',
		name: 'tutorial',
		component: Tutorial,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/contracts',
		name: 'contracts',
		component: Contracts,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/scontracts',
		name: 'scontracts',
		component: SContracts,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/contracts/:id',
		name: 'contractview',
		component: ContractView,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/permissibles',
		name: 'permissibles',
		component: UsersAndPermissibles,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/acts',
		name: 'acts',
		component: Acts,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/acts/create/:id',
		name: 'create_act',
		component: CreateAct,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/acts/create_mirror',
		name: 'create_act_mirror',
		component: CreateActMirror,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/scontracts/create',
		name: 'create_scontract',
		component: CreateSContract,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/acts/:id/edit',
		name: 'edit_act',
		component: EditAct,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/scontracts/:id/edit',
		name: 'edit_scontract',
		component: EditSContract,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/acts/:id/edit_mirror',
		name: 'edit_act_mirror',
		component: EditActMirror,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/acts/:id',
		name: 'actview',
		component: ActView,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/scontracts/:id',
		name: 'scontractview',
		component: SContractView,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/messenger',
		name: 'messenger',
		component: Room,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/404',
		component: PageNotFound,
		name: '404',
		props: true,
		meta: {
			layout: 'landing',
		},
	},

	{
		path: '/indevelopment',
		name: 'developmentpage',
		component: DevPage,
	},
	{
		path: '*',
		redirect: { name: 'landing' },
	},
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
