var webSocket = new WebSocket('wss://127.0.0.1:13579/');
var callback = null;

function openNCALayerNotConnectedModal() {
    var modal = document.getElementById("myModal");
    var btn = document.getElementById("myBtn");
    var closeBtn = document.getElementsByClassName("close")[0];


    modal.style.display = "block";

    closeBtn.onclick = function () {
        modal.style.display = "none";
    }

    // When the user clicks anywhere outside of the modal, close it
    window.onclick = function (event) {
        if (event.target == modal) {
            modal.style.display = "none";
        }
    }
}


webSocket.onopen = function (event) {
    console.log(event);
    // console.log("Connection opened");
};

webSocket.onclose = function (event) {
    if (event.wasClean) {
        console.log('connection has been closed');
    } else {
        openNCALayerNotConnectedModal()
    }
    console.log('Code: ' + event.code + ' Reason: ' + event.reason);
};

webSocket.onmessage = function (event) {
    var result = JSON.parse(event.data);

    if (result != null) {
        var rw = {
            code: result['code'],
            message: result['message'],
            responseObject: result['responseObject'],
            getResult: function () {
                return this.result;
            },
            getMessage: function () {
                return this.message;
            },
            getResponseObject: function () {
                return this.responseObject;
            },
            getCode: function () {
                return this.code;
            }
        };
        if (callback != null) {
            if (callback === 'signXmlBack'){
                signXmlBack(rw);
            } else if (callback === 'getKeyInfoBack'){
                console.log('call getKeyInfoBack');
                getKeyInfoBack(rw);
            } else if (callback === 'signXmlsBack'){
                signXmlsBack(rw)
            }

            /*alert(callback);
            window[callback](rw);*/
        }
    }
    // console.log(event);
};

// function getActiveTokens(callBack) {
//     var getActiveTokens = {
// 		"module": "kz.gov.pki.knca.commonUtils",
//         "method": "getActiveTokens"
//     };
//     callback = callBack;
//     webSocket.send(JSON.stringify(getActiveTokens));
// }

function getKeyInfo(storageName) {
    var getKeyInfo = {
		"module": "kz.gov.pki.knca.commonUtils",
        "method": "getKeyInfo",
        "args": [storageName]
    };
    callback = 'getKeyInfoBack';
    webSocket.send(JSON.stringify(getKeyInfo));
}

function getKeyInfoBack(result) {
    console.log('start get key info');
    if (result['code'] === "500") {
        alert(result['message']);
    } else if (result['code'] === "200") {
        var res = result['responseObject'];
        console.log(res);

        var alias = res['alias'];
        var alias_sign = document.getElementById('alias_sign');
        console.log('alias')
        console.log(alias)
        alias_sign.value = alias

        var keyId = res['keyId'];
        var keyId_sign = document.getElementById('keyId_sign');
        keyId_sign.value = keyId

        var algorithm = res['algorithm'];
        var algorithm_sign = document.getElementById('algorithm_sign');
        algorithm_sign.value = algorithm

        var subjectCn = res['subjectCn'];
        var subjectCn_sign = document.getElementById('subjectCn_sign');
        subjectCn_sign.value = subjectCn

        var subjectDn = res['subjectDn'];
        var subjectDn_sign = document.getElementById('subjectDn_sign');
        subjectDn_sign.value = subjectDn

        var issuerCn = res['issuerCn'];
        var issuerCn_sign = document.getElementById('issuerCn_sign');
        issuerCn_sign.value = issuerCn

        var issuerDn = res['issuerDn'];
        var issuerDn_sign = document.getElementById('issuerDn_sign');
        issuerDn_sign.value = issuerDn

        var serialNumber = res['serialNumber'];
        var serialNumber_sign = document.getElementById('serialNumber_sign');
        serialNumber_sign.value = serialNumber

        var dateString = res['certNotAfter'];
        var date = new Date(Number(dateString));
        //$("#notafter").val(date.toLocaleString());
        var certNotAfter_sign = document.getElementById('certNotAfter_sign');
        certNotAfter_sign.value = date.toLocaleString()

        dateString = res['certNotBefore'];
        date = new Date(Number(dateString));
        //$("#notbefore").val(date.toLocaleString());
        var certNotBefore_sign = document.getElementById('certNotBefore_sign');
        certNotBefore_sign.value = date.toLocaleString()

        var authorityKeyIdentifier = res['authorityKeyIdentifier'];
        var authorityKeyIdentifier_sign = document.getElementById('authorityKeyIdentifier_sign');
        authorityKeyIdentifier_sign.value = authorityKeyIdentifier

        var pem = res['pem'];
        var pem_sign = document.getElementById('pem_sign');
        pem_sign.value = pem
        var form_btn = document.getElementById('auth_sign');
        form_btn.click();
    }
}

function signXml(storageName, keyType, xmlToSign) {
    console.log(storageName);
    console.log(keyType);
    console.log(xmlToSign);
    var signXml = {
        "module": "kz.gov.pki.knca.commonUtils",
        "method": "signXml",
        "args": [storageName, keyType, xmlToSign, "", ""]
    };
    callback = 'signXmlBack'
    webSocket.send(JSON.stringify(signXml));
}

function signXmlBack(result) {
    if (result['code'] === "500") {
        alert(result['message']);
    } else if (result['code'] === "200") {
        var res = result['responseObject'];
        var sign = document.getElementById('signedXml');
        sign.value = res;
        console.log('signedXml');
        console.log(res);
        var btn = document.getElementById('test');
        btn.click();
    }
}

function signXmls(storageName, keyType, xmlsToSign) {
    var signXmls = {
        "module": "kz.gov.pki.knca.commonUtils",
        "method": "signXmls",
        "args": [storageName, keyType, xmlsToSign, "", ""]
    };
    callback = 'signXmlsBack';
    webSocket.send(JSON.stringify(signXmls));
}
function signXmlsBack(result) {
    if (result['code'] === "500") {
        alert(result['message']);
    } else if (result['code'] === "200") {
        var res = result['responseObject'];
        for (let i = 0; i < res.length; i++) {
            let my_id = res[i].split("<id>")[1].split("</id>")[0];
            console.log('my_id: ', my_id);
            console.log('res[i]: ', res[i]);
            var sx = document.getElementById('signedXml_' + my_id);
            sx.value = res[i];
        }
        var btn = document.getElementById('test_list');
        console.log(btn);
        btn.click();
    }
}
export {signXml, getKeyInfo, signXmls}



// function createCAdESFromFile(storageName, keyType, filePath, flag, callBack) {
//     var createCAdESFromFile = {
// 		"module": "kz.gov.pki.knca.commonUtils",
//         "method": "createCAdESFromFile",
//         "args": [storageName, keyType, filePath, flag]
//     };
//     callback = callBack;
//     webSocket.send(JSON.stringify(createCAdESFromFile));
// }

// function createCAdESFromBase64(storageName, keyType, base64ToSign, flag, callBack) {
//     var createCAdESFromBase64 = {
// 		"module": "kz.gov.pki.knca.commonUtils",
//         "method": "createCAdESFromBase64",
//         "args": [storageName, keyType, base64ToSign, flag]
//     };
//     callback = callBack;
//     webSocket.send(JSON.stringify(createCAdESFromBase64));
// }

// function createCAdESFromBase64Hash(storageName, keyType, base64ToSign, callBack) {
//     var createCAdESFromBase64Hash = {
// 		"module": "kz.gov.pki.knca.commonUtils",
//         "method": "createCAdESFromBase64Hash",
//         "args": [storageName, keyType, base64ToSign]
//     };
//     callback = callBack;
//     webSocket.send(JSON.stringify(createCAdESFromBase64Hash));
// }

// function applyCAdEST(storageName, keyType, cmsForTS, callBack) {
//     var applyCAdEST = {
// 		"module": "kz.gov.pki.knca.commonUtils",
//         "method": "applyCAdEST",
//         "args": [storageName, keyType, cmsForTS]
//     };
//     callback = callBack;
//     webSocket.send(JSON.stringify(applyCAdEST));
// }

// function showFileChooser(fileExtension, currentDirectory, callBack) {
//     var showFileChooser = {
// 		"module": "kz.gov.pki.knca.commonUtils",
//         "method": "showFileChooser",
//         "args": [fileExtension, currentDirectory]
//     };
//     callback = callBack;
//     webSocket.send(JSON.stringify(showFileChooser));
// }

// function changeLocale(language) {
//     var changeLocale = {
// 		"module": "kz.gov.pki.knca.commonUtils",
//         "method": "changeLocale",
//         "args": [language]
//     };
//     callback = null;
//     webSocket.send(JSON.stringify(changeLocale));
// }

// function createCMSSignatureFromFile(storageName, keyType, filePath, flag, callBack) {
//     var createCMSSignatureFromFile = {
// 		"module": "kz.gov.pki.knca.commonUtils",
//         "method": "createCMSSignatureFromFile",
//         "args": [storageName, keyType, filePath, flag]
//     };
//     callback = callBack;
//     webSocket.send(JSON.stringify(createCMSSignatureFromFile));
// }

// function createCMSSignatureFromBase64(storageName, keyType, base64ToSign, flag, callBack) {
//     var createCMSSignatureFromBase64 = {
// 		"module": "kz.gov.pki.knca.commonUtils",
//         "method": "createCMSSignatureFromBase64",
//         "args": [storageName, keyType, base64ToSign, flag]
//     };
//     callback = callBack;
//     webSocket.send(JSON.stringify(createCMSSignatureFromBase64));
// }
