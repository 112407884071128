// import AuthService from '@/service/auth.service'
// import router from '../../router.js'
import axios from 'axios'

export default {
    getters: {
        // isActiveAccount(state) {
        //     return state.user.ouser.is_active;
        // },
        // USER(state) {
        //     return state.user
        // }
    },
    mutations: {
        test(state) {
            state.isTest = true;
        }
    },

    state: {
        isTest: false,
    },

    actions: {
        getAllContracts({commit}, formData) {
            console.log(commit);
            return axios.get('/docs/contracts/?page=' + formData.page, {
                params: {
                    status: formData.status,
                    search_contract: formData.search_contract
                }
            });
        },
        getAllPerms({commit}, formData) {
            console.log(commit);
            return axios.get('/auth/permissibles/?page=' + formData.page, {
                params: {
                    // status: formData.status,
                    // search_contract: formData.search_contract
                }
            });
        },
        getAllUsers({commit}, formData) {
            console.log(commit);
            return axios.get('/auth/users/?page=' + formData.page, {
                params: {
                }
            });
        },
        getMyContracts({commit}) {
            console.log(commit);
            return axios.get('/docs/get_my_contracts/');
        },
        getMyContractsMirror({commit}) {
            console.log(commit);
            return axios.get('/docs/get_my_contracts_mirror/');
        },
        getAllActs({commit}, formData) {
            console.log(commit);
            return axios.get('/docs/acts/?page=' + formData.page, {
                params: {
                    status: formData.status,
                    search_act: formData.search_act,
                    search_act_ngdu: formData.search_act_ngdu,
                    search_act_contr: formData.search_act_contr,
                    search_act_impl: formData.search_act_impl,
                    filter_year: formData.filter_year,
                    filter_month: formData.filter_month,
                }
            });
        },
        get_acts_total({commit}, formData) {
            console.log(commit);
            return axios.get('/docs/get_acts_total/', {
                params: {
                    status: formData.status,
                    search_act: formData.search_act,
                    search_act_ngdu: formData.search_act_ngdu,
                    search_act_contr: formData.search_act_contr,
                    search_act_impl: formData.search_act_impl,
                    filter_year: formData.filter_year,
                    filter_month: formData.filter_month,
                }
            });
        },
        checkInSap({commit}, formData) {
            console.log(commit);
            return axios.get('/docs/get_in_sap/', {
                params: {
                    number: formData.number,
                }
            });
        },
        submitCreateForm({commit}, formData) {
            console.log(commit);

            return axios.post('/docs/contracts/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Accept': 'application/json',
                }
            });
        },
        submitEditForm({commit}, formData) {
            console.log(commit);
            console.log('submitEditForm');
            let contract_id = formData.get('new_contract_id');
            console.log(contract_id);
            return axios.put(`/docs/contracts/${contract_id}/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Accept': 'application/json',
                }
            });
        },
        getContractDetail({commit}, id) {
            console.log(commit);
            return axios.get(`/docs/contracts/${id}/`);
        },
        copyAct({commit}, id) {
            console.log(commit);
            return axios.get(`/docs/acts/${id}/copy/`);
        },
        getXmlToSign({commit}, id) {
            console.log(commit);
            return axios.get(`/docs/acts/${id}/xml_to_sign/`);
        },
        getActDetail({commit}, id) {
            console.log(commit);
            return axios.get(`/docs/acts/${id}/`);
        },
        getActDetailVerify({commit}, id) {
            console.log(commit);
            return axios.get(`/docs/acts_verify/${id}/`);
        },
        getSapZakaz({commit}, id) {
            console.log(commit);
            return axios.get(`/docs/sap_zakaz/${id}/`);
        },
        getHasMirrorContracts({commit}) {
            console.log(commit);
            return axios.get(`/docs/has_mirror_contracts/`);
        },
        getABPStatistics({commit}) {
            console.log(commit);
            return axios.get(`/docs/get_abp_statistics/`);
        },
        getActStatistics({commit}) {
            console.log(commit);
            return axios.get(`/docs/get_act_count_statistics/`);
        },
        getActStatistics_20({commit}) {
            console.log(commit);
            return axios.get(`/docs/get_act_count_statistics_20/`);
        },
        getActStatistics_21({commit}) {
            console.log(commit);
            return axios.get(`/docs/get_act_count_statistics_21/`);
        },
        getActStatistics_23({commit}) {
            console.log(commit);
            return axios.get(`/docs/get_act_count_statistics_23/`);
        },
        getABPActStatistics({commit}) {
            console.log(commit);
            return axios.get(`/docs/get_abp_act_statistics/`);
        },
        getAllApproves({commit}, formData) {
            console.log(commit);
            return axios.get('/docs/approves/?page=' + formData.page, {
                params: {
                    // status: formData.status,
                    // search_act: formData.search_act,
                    // search_act_ngdu: formData.search_act_ngdu,
                    search_act_contr: formData.search_act_contr,
                    search_act_impl: formData.search_act_impl,
                    search_last_name: formData.search_last_name,
                    filter_year: formData.filter_year,
                    filter_month: formData.filter_month,
                    two_month: formData.two_month,
                }
            });
        },
        downloadActPDF({commit}, id) {
            console.log(commit);
            return axios.get(`/docs/download_act/${id}/`,{
                responseType: 'blob'
            });
        },
        downloadTemplate({commit}, formData) {
            console.log(commit);
            return axios.get(`/docs/download_template/`,{
                params: {
                    template_row_count: formData.template_row_count,
                    row_date_type_template: formData.row_date_type_template,
                },
                responseType: 'blob'
            });
        },
        downloadApproves({commit}, formData) {
            console.log(commit);
            return axios.get(`/docs/download_approves/`,{
                params: {
                    search_act_contr: formData.search_act_contr,
                    search_act_impl: formData.search_act_impl,
                    search_last_name: formData.search_last_name,
                    filter_year: formData.filter_year,
                    filter_month: formData.filter_month,
                    two_month: formData.two_month,
                },
                responseType: 'blob'
            });
        },
        downloadActForEco({commit}, id) {
            console.log(commit);
            return axios.get(`/docs/download_act_for_eco/${id}/`,{
                responseType: 'blob'
            });
        },
        downloadHistoryPDF({commit}, id) {
            console.log(commit);
            return axios.get(`/docs/download_act_history/${id}/`,{
                responseType: 'blob'
            });
        },
        createAct({commit}, formData) {
            console.log(commit);
            return axios.post('/docs/acts/', formData,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'multipart/form-data'
                    }
                }
            );
        },
        createActMirror({commit}, formData) {
            console.log(commit);
            return axios.post('/docs/acts_mirror/', formData,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'multipart/form-data'
                    }
                }
            );
        },
        addToArchive({commit}, formData) {
            console.log(commit);
            return axios.post('/docs/archiveds/', formData,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'multipart/form-data'
                    }
                }
            );
        },
        uploadTemplate({commit}, formData) {
            console.log(commit);
            return axios.post('/docs/upload_template/', formData,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'multipart/form-data'
                    }
                }
            );
        },
        uploadTemplateEco({commit}, formData) {
            console.log(commit);
            let act_id = formData.get('act_id');
            return axios.post(`/docs/upload_template_eco/${act_id}/`, formData,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'multipart/form-data'
                    }
                }
            );
        },
        updateAct({commit}, formData) {
            console.log(commit);
            let id = formData.get('act_id');
            return axios.put(`/docs/acts/${id}/`, formData,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'multipart/form-data'
                    }
                }
            );
        },
        deleteAct({commit}, id) {
            console.log(commit);
            return axios.delete(`/docs/acts/${id}/`);
        },
        rmFromArchive({commit}, id) {
            console.log(commit);
            return axios.delete(`/docs/archiveds/${id}/`);
        },
        deleteContract({commit}, id) {
            console.log(commit);
            return axios.delete(`/docs/contracts/${id}/`);
        },
        bringBackAct({commit}, id) {
            console.log(commit);
            return axios.post(`/docs/acts/${id}/bring_back/`);
        },
        sendToApproversImpl({commit}, id) {
            console.log(commit);
            return axios.post(`/docs/acts/${id}/send_to_approvers/`);
        },
        setImplApprovers({commit}, formData) {
            console.log(commit);
            return axios.post(`/docs/acts/${formData.act_id}/set_approvers/`, {
                selected_approve_mode: formData.selected_approve_mode,
                approvers: formData.impl_approvers,
                ratificator: formData.impl_ratificator,
            });
        },
        setDZOApprovers({commit}, formData) {
            console.log(commit);
            return axios.post(`/docs/acts/${formData.act_id}/set_approvers/`, {
                selected_approve_mode: formData.selected_dzo_approve_mode,
                approvers: formData.dzo_approvers,
                ratificator: formData.dzo_ratificator,
            });
        },
        setChangesEco({commit}, formData) {
            console.log(commit);
            return axios.post(`/docs/acts/${formData.act.id}/set_changes_eco/`, {
                act: formData.act,
            });
        },
        setChangesSAPABP({commit}, formData) {
            console.log(commit);
            return axios.post(`/docs/acts/${formData.act.id}/set_changes_sap_abp/`, {
                act: formData.act,
            });
        },
        setChangesSAPBOOKER({commit}, formData) {
            console.log(commit);
            return axios.post(`/docs/acts/${formData.act.id}/set_changes_sap_booker/`, {
                act: formData.act,
                real_date: formData.real_date,
            });
        },
        changeDZOCurrentApprovers({commit}, formData) {
            console.log(commit);
            return axios.post(`/docs/acts/${formData.act_id}/change_approver/`, {
                old_user_id: formData.old_user_id,
                dzo_current_approver: formData.dzo_current_approver,
            });
        },
        removeDZOCurrentApprovers({commit}, formData) {
            console.log(commit);
            return axios.post(`/docs/acts/${formData.act_id}/remove_approver/`, {
                old_user_id: formData.old_user_id
            });
        },
        acceptReject({commit}, formData) {
            console.log(commit);
            let act_id = formData.get('act_id');
            return axios.post(`/docs/acts/${act_id}/accept_reject/`, formData,{
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'multipart/form-data'
                    }
                });
        },
        sendSignXml({commit}, formData) {
            console.log(commit);

            return axios.post(`/docs/acts/${formData.act_id}/sign_ratificator/`, {
                xmlToSign: formData.xmlToSign,
                signedXml: formData.signedXml,
            });
        },
        sendSignXmls({commit}, formData) {
            console.log(commit);

            return axios.post(`/docs/acts/sign_ratificator_list/`, formData);
        },
    },
}