<template>
  <div class="sidebar" id="sidebar">
    <div class="logo-details">
      <a href="/">
        <img
          class="bx bxl-c-plus-plus icon sidebar-logo"
          src="../assets/logo-header.png"
          alt=""
          style="color: white"
        />
      </a>
      <div class="logo_name"></div>
      <i class="material-icons" id="btn">menu</i>
    </div>
    <ul class="mainless nav-list">
      <li>
        <router-link :to="{ name: 'landing' }" id="a-landing">
<!--          <i class='bx bx-grid-alt'></i>-->
          <i class="material-icons">grid_view</i>
          <span class="links_name">Главная</span>
        </router-link>
        <span class="tooltip">Главная</span>
      </li>
      <li>
        <router-link :to="{ name: 'profile' }" id="a-profile">
          <i class="material-icons">account_circle</i>
          <span class="links_name">Профиль</span>
        </router-link>
        <span class="tooltip">Профиль</span>
      </li>
      <li v-if="is_DZOADM || has_SCONTR">
        <router-link :to="{ name: 'scontracts' }" id="a-scontract">
<!--          <i class='bx bx-check-shield'></i>-->
          <i class="material-icons">description</i>
          <span class="links_name">Внутренние договоры</span>
        </router-link>
        <span class="tooltip">Внутренние договоры</span>
      </li>
      <li>
        <router-link :to="{ name: 'contracts' }" id="a-contract">
          <i class="material-icons">content_copy</i>
          <span class="links_name">Договоры</span>
        </router-link>
        <span class="tooltip">Договоры</span>
      </li>
      <li>
        <router-link :to="{ name: 'acts' }" id="a-act">
          <i class="material-icons">file_copy</i>
          <span class="links_name">Акты</span>
        </router-link>
        <span class="tooltip">Акты</span>
      </li>
      <li v-if="is_DZOADM">
        <router-link :to="{ name: 'permissibles' }" id="a-permissibles">
          <i class="material-icons">admin_panel_settings</i>
          <span class="links_name">Администрирование</span>
        </router-link>
        <span class="tooltip">Администрирование</span>
      </li>
      <li v-if="is_DZOADM">
        <router-link :to="{ name: 'overdue' }" id="a-overdue">
          <i class="material-icons">schedule</i>
          <span class="links_name">Сроки согласования</span>
        </router-link>
        <span class="tooltip">Сроки согласования</span>
      </li>
      <li v-if="is_DZOADM">
        <router-link :to="{ name: 'statistics' }" id="a-notification">
          <i class="material-icons">bar_chart</i>
          <span class="links_name">Статистика</span>
        </router-link>
        <span class="tooltip">Статистика</span>
      </li>
      <li>
        <router-link :to="{ name: 'notifications' }" id="a-notification">
          <i class="material-icons">notifications_active</i>
          <span class="links_name">Уведомления</span>
        </router-link>
        <span class="tooltip">Уведомления</span>
      </li>
      <li>
        <router-link :to="{ name: 'offers' }" id="a-offers">
          <i class="material-icons">sms</i>
          <span class="links_name">Предложения</span>
        </router-link>
        <span class="tooltip">Предложения</span>
      </li>
      <!-- <li>
        <router-link :to="{ name: 'tutorial' }" id="a-tutorial">
          <i class='bx bx-book-open'></i>
          <span class="links_name">Обучение</span>
        </router-link>
        <span class="tooltip">Обучение</span>
      </li> -->
      <li>
        <router-link :to="{ name: 'tutorial' }" id="a-tutorial">
          <i class="material-icons">school</i>
          <span class="links_name">Обучение</span>
        </router-link>
        <span class="tooltip">Обучение</span>
      </li>
      <li>
        <router-link :to="{ name: 'messenger' }" id="a-messenger">
          <i class="material-icons">forum</i>
          <span class="links_name">Чат</span>
        </router-link>
        <span class="tooltip">Чат</span>
      </li>
      <li>
        <router-link :to="{ name: 'learning' }" id="a-learning">
          <i class="material-icons">help</i>
          <span class="links_name">FAQ</span>
        </router-link>
        <span class="tooltip">FAQ</span>
      </li>
      <li class="profile">
        <div class="profile-details">
          <img src="../assets/avatar.png" alt="profileImg">
          <div class="name_job">
            <div class="name">{{ this.user_full_name }}</div>
            <div class="job"></div>
          </div>
        </div>
        <a href="/logout" id="a-logout">
<!--          <i class='bx bx-log-out' id="log_out"></i>-->
          <i class="material-icons" id="log_out">logout</i>
        </a>
      </li>
    </ul>
    <!-- Registration modal -->
    <!--    <modal name="create-req-modal">-->
    <!--      <div class="modal-inner">-->
    <!--        <h3 class="modal-title">Выберите тип заявки</h3>-->
    <!--        <div class="modal-two-btn">-->
    <!--          <a :href="'/req/create'"><button class="modal-btn">Заявка на корректировку</button></a>-->
    <!--          <a :href="'/req/create'"><button class="modal-btn">Новая завка</button></a>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </modal>-->
    <!-- Registration modal -->

  </div>

</template>

<script>
export default {
  name: 'Sidebar',
  data() {
    return {
      user_full_name: "",
      is_IMPL: false,
      is_ABP: false,
      is_APPROVER: false,
      is_RATIFICATOR: false,
      is_ECONOMIST: false,
      is_BOOKER: false,
      is_DZOADM: false,
      has_SCONTR: false,
      roles_name: "Гость",
    };
  },
  watch: {
    $route (to, from){
        let sidebar = document.querySelector(".sidebar");
        sidebar.classList.remove("open");
    }
  },
  created() {
    this.is_IMPL = this.isIMPL();
    this.is_ABP = this.isABP();
    this.is_APPROVER = this.isAPPROVER();
    this.is_RATIFICATOR = this.isRATIFICATOR();
    this.is_ECONOMIST = this.isECONOMIST();
    this.is_BOOKER = this.isBOOKER();
    this.is_DZOADM = this.isDZOADM();
    this.roles_name = this.roleName();
    this.user_full_name = this.userFullName();
    this.has_scontracts_init();
  },
  mounted() {
    let sidebar = document.querySelector(".sidebar");
    // let home_section_wrap = document.querySelector(".home-section-wrap");
    let closeBtn = document.querySelector("#btn");
    // let searchBtn = document.querySelector(".bx-search");

    closeBtn.addEventListener("click", () => {
      sidebar.classList.toggle("open");
      // if (home_section_wrap) {
      //   home_section_wrap.classList.toggle("open");
      // }
      menuBtnChange(); //calling the function(optional)
    });
    // searchBtn.addEventListener("click", () => { // Sidebar open when you click on the search iocn
    //   sidebar.classList.toggle("open");
    //   if (home_section_wrap) {
    //     home_section_wrap.classList.toggle("open");
    //   }
    //   menuBtnChange(); //calling the function(optional)
    // });

    // following are the code to change sidebar button(optional)
    function menuBtnChange() {
      if (sidebar.classList.contains("open")) {
        closeBtn.classList.replace("bx-menu", "bx-menu-alt-right"); //replacing the iocns class
      } else {
        closeBtn.classList.replace("bx-menu-alt-right", "bx-menu"); //replacing the iocns class
      }
    }

    this.setActiveItem();
  },
  methods: {
    has_scontracts_init() {
      this.$store.dispatch("getHasSContracts").then((response) => {
        this.has_SCONTR = response.data.res;
      }).catch((error) => {
        this.$store.dispatch('addNotification', {
          type: 'error',
          message: error.response.data.detail,
        })
      });
    },
    isIMPL() {
      return localStorage.getItem('roles').includes(1);
    },
    isABP() {
      return localStorage.getItem('roles').includes(2);
    },
    isAPPROVER() {
      return localStorage.getItem('roles').includes(3);
    },
    isRATIFICATOR() {
      return localStorage.getItem('roles').includes(4);
    },
    isECONOMIST() {
      return localStorage.getItem('roles').includes(5);
    },
    isBOOKER() {
      return localStorage.getItem('roles').includes(6)
    },
    isDZOADM() {
      return localStorage.getItem('roles').includes(7);
    },
    roleName() {
      return localStorage.getItem('roles_name');
    },
    userFullName() {
      let user = JSON.parse(localStorage.getItem('user'));
      return user["last_name"] + ' ' + user["first_name"][0] + '.';
    },
    setActiveItem() {
      let itemId = this.$route.path.split('/')[1];
      let activeItem = document.getElementById('a-' + itemId);
      if (activeItem) {
        activeItem.classList.toggle('active-bottom-border', true);
      }
    },
  },
}
</script>

<style scoped>

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /*font-family: "Poppins", sans-serif;*/
}

.active-bottom-border {
  border-bottom: solid 2px white;
}

.sidebar {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 78px;
  background: #11101d;
  padding: 6px 14px;
  z-index: 99;
  transition: all 0.5s ease;
}

.sidebar.open {
  width: 250px;
}

@media (max-width: 1000px) {
  .sidebar {
    height: auto !important;
  }
  .nav-list {
    display: none;
    overflow-y: scroll !important;
  }
  .sidebar.open .nav-list {
    display: block;
  }
}


.sidebar .logo-details {
  height: 60px;
  display: flex;
  align-items: center;
  position: relative;
}

.sidebar-logo {
  width: 100px;
}

.sidebar .logo-details .icon {
  opacity: 0;
  transition: all 0.5s ease;
}

.sidebar .logo-details .logo_name {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  opacity: 0;
  transition: all 0.5s ease;
}

.sidebar.open .logo-details .icon,
.sidebar.open .logo-details .logo_name {
  opacity: 1;
}

.sidebar .logo-details #btn {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  transition: all 0.4s ease;
  font-size: 23px;
  text-align: center;
  cursor: pointer;
}

.sidebar.open .logo-details #btn {
  text-align: right;
}

.sidebar i {
  color: #fff;
  height: 60px;
  min-width: 50px;
  font-size: 28px;
  text-align: center;
  line-height: 60px;
}

.sidebar .nav-list {
  margin-top: 20px;
  height: 100%;
}

.sidebar li {
  position: relative;
  margin: 8px 0;
  list-style: none;
}

.sidebar li .tooltip {
  position: absolute;
  top: -20px;
  left: calc(100% + 15px);
  z-index: 3;
  background: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 400;
  opacity: 0;
  white-space: nowrap;
  pointer-events: none;
  transition: 0s;
}

.sidebar li:hover .tooltip {
  opacity: 1;
  pointer-events: auto;
  transition: all 0.4s ease;
  top: 50%;
  transform: translateY(-50%);
}

.sidebar.open li .tooltip {
  display: none;
}

.sidebar input {
  font-size: 15px;
  color: #fff;
  font-weight: 400;
  outline: none;
  height: 50px;
  width: 100%;
  width: 50px;
  border: none;
  border-radius: 12px;
  transition: all 0.5s ease;
  background: #1d1b31;
}

.sidebar.open input {
  padding: 0 20px 0 50px;
  width: 100%;
}

.sidebar .bx-search {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  font-size: 22px;
  background: #1d1b31;
  color: #fff;
}

.sidebar.open .bx-search:hover {
  background: #1d1b31;
  color: #fff;
}

.sidebar .bx-search:hover {
  background: #fff;
  color: #11101d;
}

.sidebar li a {
  display: flex;
  height: 100%;
  width: 100%;
  border-radius: 12px;
  align-items: center;
  text-decoration: none;
  transition: all 0.4s ease;
  background: #11101d;
}

.sidebar li a:hover {
  background: #fff;
}

.sidebar li a .links_name {
  color: #fff;
  font-size: 15px;
  font-weight: 400;
  white-space: nowrap;
  opacity: 0;
  pointer-events: none;
  transition: 0.4s;
}

.sidebar.open li a .links_name {
  opacity: 1;
  pointer-events: auto;
}

.sidebar li a:hover .links_name,
.sidebar li a:hover i {
  transition: all 0.5s ease;
  color: #11101d;
}

.sidebar li i {
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  border-radius: 12px;
}

.sidebar li.profile {
  position: fixed;
  height: 60px;
  width: 78px;
  left: 0;
  bottom: -8px;
  padding: 10px 14px;
  background: #1d1b31;
  transition: all 0.5s ease;
  overflow: hidden;
}

.sidebar.open li.profile {
  width: 250px;
}

.sidebar li .profile-details {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.sidebar li img {
  height: 45px;
  width: 45px;
  object-fit: cover;
  border-radius: 6px;
  margin-right: 10px;
}

.sidebar li.profile .name,
.sidebar li.profile .job {
  font-size: 15px;
  font-weight: 400;
  color: #fff;
  white-space: nowrap;
}

.sidebar li.profile .job {
  font-size: 12px;
}

.sidebar .profile #log_out {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background: #1d1b31;
  width: 100%;
  height: 60px;
  line-height: 60px;
  border-radius: 0;
  transition: all 0.5s ease;
}

.sidebar.open .profile #log_out {
  width: 50px;
  background: none;
}

@media (max-width: 420px) {
  .sidebar li .tooltip {
    display: none;
  }
}

@media (max-width: 1000px) {
  .mainless {
    display: none;
  }
}

</style>
