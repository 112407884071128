<template>
  <div class="home-section-wrap-landing">
    <section class="home-section-landing" style="display: block !important;" id="home-section-landing">
      <div class="container">
        <h1 class="module-title">Создание акта выполненных работ и услуг</h1>
        <div class="wrapper">
          <div :class="get_arrow_steps_class() + ' clearfix'">
            <div v-for="(step_i, index) in this.all_steps" :key="index + 1"
                 :class="'step ' + curr_class(index + 1)">
              <span>Шаг {{ index + 1 }} ({{ step_i.name }})</span>
            </div>
          </div>
          <div :class="prev_next_btn_class()" style="height: 30px; margin-top: 2rem; display: flex">
            <a v-if="this.curr_module !== 1" @click="prevStep()">
              <button class="btn-success btn-blue-success" style="width: 200px; padding: .5rem; border-radius: .5rem">
                Вернуться
              </button>
            </a>
            <a v-if="all_required_exist" @click="nextStep()">
              <button class="btn-success" style="width: 200px; padding: .5rem; border-radius: .5rem">
                <span v-if="this.curr_module === 3">Сохранить</span>
                <span v-else>Продолжить</span>
              </button>
            </a>
          </div>
        </div>
        <div class="aiia-wizard-step" style="width: 100%; margin-bottom: 50px">
          <div>
            <h2>
              <span class="circle-number">{{ curr_module }}</span>
              <span style="font-size: 20px;">Инструкция</span>
              <span style="color: #23468c"> ({{ this.all_steps[curr_module - 1].name }})</span>
            </h2>
            <div class="step-content" style="padding-top: 0.7rem">
              <p>
                {{ this.all_steps[curr_module - 1].instruction }}
<!--                <span v-if="this.curr_module === 3">-->
<!--                  . Так же, вы можете-->
<!--                  <a class="custom-link" download="Шаблон для загрузки акта.xlsx" @click="showDownloadTemplate">скачать шаблон (excel)</a>-->
<!--                  , заполнить его и-->
<!--                  <input type="file" name="file" id="file" class="inputfile" :ref="'file_template'" v-on:change="uploadTemplate">-->
<!--                  <label for="file" class="upload-from-xls">загрузить обратно</label>-->
<!--                </span>-->

              </p>

<!--              <modal name="download-template-modal">-->
<!--                <div class="modal-inner">-->
<!--                  <h2>Формирование шаблона для загрузки акта</h2>-->
<!--                  <input class="input-field-template" type="number" placeholder="Введите количество строк в акте" v-model="template_row_count">-->
<!--                  <select class="input-field-template" id="row-date-type-template"-->
<!--                          style="margin-left: 15%; margin-right: 15%; width: 70%"-->
<!--                          v-on:change="change_row_date_type_template()">-->
<!--                    <option value="" selected>Выберите тип даты строк*</option>-->
<!--                    <option value="2">Период оказания работ / услуг</option>-->
<!--                    <option value="1">Дата оказания работ / услуг</option>-->
<!--                  </select>-->
<!--                  <button class="upload-from-xls" @click="downloadTemplate" v-if="template_row_count !== '' && row_date_type_template !== ''">Скачать шаблон</button>-->
<!--                </div>-->
<!--              </modal>-->
            </div>
          </div>
        </div>
        <div class="categories-container">
          <div v-show="this.curr_module === 1" class="flex-start-column gap-2r">
            <div class="flex-space-between gap-1r">
              <div class="flex-start-column div-half gap-half">
                <label>Номер акта</label>
                <input class="input-act-params" placeholder="Введите номер акта*" v-model="new_act.number">
              </div>
              <div class="flex-start-column div-full gap-half">
                <label>Наименование акта</label>
                <input class="input-act-params" placeholder="Введите наименование акта*" v-model="new_act.name">
              </div>
              <div class="flex-start-column div-half gap-half">
                <label>Дата акта</label>
                <input class="input-act-params" placeholder="Введите дату акта*" v-model="new_act.act_date"
                       v-on:focusout="check_act_date"
                       onfocus="(this.type='date')">
              </div>
            </div>
            <div class="flex-space-between gap-1r">
              <div class="flex-start-column div-full gap-half">
                <label>Договор</label>
                <select class="input-act-params" v-model="new_act.contract.id">
                  <option value="">Выберите договор оказания работ / услуг*</option>
                  <option v-for="(contract, index) in this.contracts" :key="index" :title="contract.name"
                          :value="contract.id">
                    ({{ contract.date_from }}) №{{ contract.number }} {{ contract.short_name }}
                  </option>
                </select>
              </div>
              <div class="flex-start-column div-half gap-half" hidden>
                <label>НГДУ</label>
                <select class="input-act-params" v-model="new_act.ngdu">
                  <option v-for="(ngdu_i, index) in this.ngdu_list" :key="index" :value="ngdu_i">{{ ngdu_i }}</option>
                </select>
              </div>
            </div>
          </div>
          <div v-show="this.curr_module === 2" class="flex-start-column gap-2r">
            <div class="dropzone">
              <input type="file" class="input-field" id="input-field-multiple" ref="files_additional" multiple
                     accept=".pdf, image/png, image/jpeg" @change="reuploadVl1"/>
              <div v-if="!new_act.uploading" class="call-to-action">
                Перетащите файлы сюда или нажмите, чтобы загрузить
                <p>
                  <small>максимальный размер файлов: 5MB</small><br>
                  <small>максимальный размер всех файлов: не больше 100 мб</small>
                </p>
                <p>
                  <small>типы загружаемых файлов: .pdf, .png, .jpeg</small>
                </p>
              </div>
              <div v-if="new_act.uploading" class="uploaded-msg">
                Файлы успешно загружены
              </div>
            </div>
            <modal name="file-modal-multiple">
              <div class="modal-inner">
                <div>Следует загружать следующие типы файлов: pdf, png, jpg</div>
                <button @click="closeModalMultuple">Понятно</button>
              </div>
            </modal>
            <div style="display: grid; grid-template-columns: 1fr 1fr; gap: 1rem"
                 v-for="(double_file, index) in new_act.double_files" :key="index">
              <div class="dropzone">
                <input type="file" class="input-field" :id="'input-field-d1-' + index" :ref="'file_d1_' + index"
                       accept="image/png, image/jpeg" @change="reuploadVl_d1(index)"/>
                <div v-if="!double_file.uploading_d1" class="call-to-action">
                  Перетащите фото сюда или нажмите, чтобы загрузить фото ДО ({{ index + 1 }})
                  <p>
                    <small>максимальный размер файла: 5MB</small>
                  </p>
                  <p>
                    <small>типы загружаемого файла: .png, .jpeg</small>
                  </p>
                </div>
                <div v-if="double_file.uploading_d1" class="uploaded-msg">
                  Файл ДО ({{ index + 1 }}) успешно загружен
                </div>
                <modal :name="'file-modal-d1-' + index">
                  <div class="modal-inner">
                    <div>Следует загружать следующие типы файлов: png, jpg</div>
                    <button @click="closeModalD1(index)">Понятно</button>
                  </div>
                </modal>
              </div>
              <div class="dropzone">
                <input type="file" class="input-field" :id="'input-field-d2-' + index" :ref="'file_d2_' + index"
                       accept="image/png, image/jpeg" @change="reuploadVl_d2(index)"/>
                <div v-if="!double_file.uploading_d2" class="call-to-action">
                  Перетащите фото сюда или нажмите, чтобы загрузить фото ПОСЛЕ ({{ index + 1 }})
                  <p>
                    <small>максимальный размер файла: 5MB</small>
                  </p>
                  <p>
                    <small>типы загружаемого файла: .png, .jpeg</small>
                  </p>
                </div>
                <div v-if="double_file.uploading_d2" class="uploaded-msg">
                  Файл ПОСЛЕ ({{ index + 1 }}) успешно загружен
                </div>
                <modal :name="'file-modal-d2-' + index">
                  <div class="modal-inner">
                    <div>Следует загружать следующие типы файлов: png, jpg</div>
                    <button @click="closeModalD2(index)">Понятно</button>
                  </div>
                </modal>
              </div>
            </div>
            <button @click="add_double_file" class="add_double_file">Добавить фото до/после</button>
          </div>
          <div v-show="this.curr_module === 3" class="flex-start-column gap-2r">
            <table class="m-table-act" id="price1" style="border: none">
              <tr>
                <th rowspan="2">Номер по порядку</th>
<!--                <th rowspan="2" style="width: 5.5%">Код затрат</th>-->
<!--                <th rowspan="2" style="width: 5.5%">Код ВЗ, МВЗ</th>-->
<!--                <th rowspan="2" style="width: 14.5%">Наименование ВЗ, МВЗ</th>-->
                <th rowspan="2">Наименование работ (услуг) (в разрезе их подвидов в соответствии с
                  технической спецификацией, заданием, графиком выполнения работ (услуг) при их наличии)
                </th>
                <th rowspan="2">Дата выполнения работ (оказания услуг)**</th>
                <th rowspan="2">Сведения об отчете о научных исследованиях, маркетинговых,
                  консультационных и прочих услугах (дата, номер, количество страниц) (при их наличии)***
                </th>
                <th rowspan="2">Единица измерения</th>
                <th colspan="3" rowspan="1">Выполнено работ (оказано услуг)</th>
                <th rowspan="2" class="no-border-right"></th>
              </tr>
              <tr>
                <th>Количество</th>
                <th>Цена за единицу</th>
                <th>Стоимость (в тенге)</th>
              </tr>
              <tr>
                <td v-for="i in [1,2,3,4,5,6,7,8]" :key="i">{{ i }}</td>
                <td class="no-border-right"></td>
              </tr>
              <tr v-for="(row, index) in new_act.rows" :key="index">
                <td>{{ index + 1 }}</td>
                <td>
                  <input v-model="row.work_name" class="m-input-cell required-cell"/>
                </td>
                <td v-if="row.double_date">
                  <input v-model="row.start_date" class="m-input-cell required-cell" type="date"
                         style="width: 50%; float: left; font-size: xx-small"/>
                  <input v-model="row.end_date" class="m-input-cell required-cell" type="date"
                         style="width: 50%; border-left: 1px solid gray; float: right; font-size: xx-small"/>
                </td>
                <td v-else>
                  <input v-model="row.row_date" class="m-input-cell required-cell" type="date"/>
                </td>
                <td><input v-model="row.research_report" class="m-input-cell"/></td>
                <td><input v-model="row.measure_unit" class="m-input-cell required-cell"/></td>
                <td>
                  <input v-model="row.count" min="0" type="number" class="m-input-cell"
                         v-on:change="auto_calc()"/>
                </td>
                <td>
                  <input v-model="row.unit_price" min="0" type="number" class="m-input-cell"
                         v-on:change="auto_calc()"/>
                </td>
                <td>
                  <span class="m-input-cell" style="background: inherit">
                    {{ prettyPrice(row.count * row.unit_price) }}
                  </span>
                </td>
                <td class="no-border-right"><button @click="rm_row_index(index)" type="button" class="rm_row_btn_small" title="Убрать строку">-</button></td>
              </tr>
              <tr>
                <td colspan="8">
                  <button @click="showAddRowModal" type="button" class="add_row_btn" title="Добавить строку (в конец)">
                    +
                  </button>
                  <button @click="rm_row" type="button" class="rm_row_btn" title="Убрать строку (с конца)">-</button>
                  <modal name="add-row-modal" :height="250">
                    <h3 class="modal-title" style="padding: 1rem">Выберите тип даты строки акта</h3>
                    <div class="modal-inner-short" style="text-align: left; width: 100%">
                      <div class="modal-info" style="width: inherit">
                        <select class="input-field-default" id="row-date-type"
                                style="margin-left: 15%; margin-right: 15%; width: 70%"
                                v-on:change="change_row_date_type()">
                          <option value="" selected>Выберите тип даты строки*</option>
                          <option value="2">Период оказания работ / услуг</option>
                          <option value="1">Дата оказания работ / услуг</option>
                        </select>
                      </div>
                      <div style="position: absolute; bottom: 0; width: 100%; padding: 1rem">
                        <button class="btn-success input-field-default" style="border-radius: 5px;" type="button"
                                v-if="this.row_date_type !== ''" @click="add_row">Добавить строку
                        </button>
                      </div>
                    </div>
                  </modal>
                </td>
                <td class="no-border-right"></td>
              </tr>
              <tr>
                <td v-for="i in [1,2,3,4,5,6,7,8]" :key="i" style="height: 35px">
                  <span v-if="i === 5">Итого:</span>
                  <span v-if="i === 8" class="m-input-cell" style="background: inherit">
                    {{ prettyPrice(new_act.total) }}
                  </span>
                </td>
                <td class="no-border-right"></td>
              </tr>
              <tr>
                <td v-for="i in [1,2,3,4,5,6,7,8]" :key="i" style="height: 35px">
                  <span v-if="i === 5">Сумма НДС:</span>
                  <input v-if="i === 8" v-model="new_act.nds" min="0" class="m-input-cell" v-on:change="auto_calc()"/>
                </td>
                <td class="no-border-right"></td>
              </tr>
              <tr>
                <td v-for="i in [1,2,3,4,5,6,7,8]" :key="i" style="height: 35px">
                  <span v-if="i === 5">Всего:</span>
                  <span v-if="i === 8" class="m-input-cell" style="background: inherit">
                    {{ prettyPrice(new_act.total_with_nds) }}
                  </span>
                </td>
                <td class="no-border-right"></td>
              </tr>
              <tr>
                <th colspan="8" rowspan="3"
                    style="text-align: center; font-size: small; padding-top: 0; padding-bottom: 0">
                  <p>*Применяется для приемки-передачи выполненных работ (оказанных услуг), за исключением
                    строительно-монтажных работ.</p>
                  <p>**Заполняется в случае, если даты выполненных работ (оказанных услуг) приходятся на различные
                    периоды, а также в случае, если даты выполнения работ (оказания услуг) и даты подписания (принятия)
                    работ (услуг) различны.</p>
                  <p>***Заполняется в случае наличия отчета о научных исследованиях, маркетинговых, консультационных и
                    прочих услугах.</p>
                </th>
                <th class="no-border-right"></th>
              </tr>
            </table>
          </div>
        </div>
      </div>

    </section>
  </div>
</template>

<script>
import router from "../router.js";

export default {
  name: 'CreateActSteps',
  data() {
    return {
      // downloadTemplate: `${process.env.VUE_APP_MEDIA_URL}/media/template_AVR.pdf`,
      curr_module: 1,
      all_steps: [
        {
          name: 'Параметры',
          instruction: 'На данном шаге введите основные параметры акта, формы в дальнейших шагах будут построены в зависимости от выбранных Вами параметров',
        },
        {
          name: 'Приложения',
          instruction: 'На данном шаге прикрепите все дополнительные файлы (документы / фотографии), необходимые для успешного согласования акта выполненных работ и услуг',
        },
        {
          name: 'Таблица',
          instruction: 'На данном шаге введите основные табличные данные по акту выполненных работ и услуг, учтите, что некоторые поля расчитываются автоматически',
        },
        // {
        //   name: 'Акт приемки',
        //   instruction: 'На данном шаге введите данные по акту приемки выполненных работ ДКС, учтите, что некоторые поля расчитываются автоматически',
        // },
        // {
        //   name: 'Справка',
        //   instruction: 'На данном шаге введите данные по справке актов ДКС, учтите, что некоторые поля расчитываются автоматически',
        // }
      ],
      ngdu_list: [
        'Работы (услуги) не велись на НГДУ',
        'НГДУ «Доссормунайгаз»',
        'НГДУ «Жайыкмунайгаз»',
        'НГДУ «Жылыоймунайгаз»',
        'НГДУ «Кайнармунайгаз»',
        'Управление производственно-технического обслуживания и комплектации оборудованием (УПТОиКО)',
        'Управление «Эмбамунайэнерго»'
      ],
      months_list: [
         "Январь",
         "Февраль",
         "Март",
         "Апрель",
         "Май",
         "Июнь",
         "Июль",
         "Август",
         "Сентябрь",
         "Октябрь",
         "Ноябрь",
         "Декабрь"
      ],
      contracts: [],
      cost_list: [],
      mvz_list: [],
      row_date_type: '',
      row_date_type_template: '',
      template_row_count: '',
      row_accept_type: '',
      row_accept_count: 1,
      curr_serial: 1,
      new_act: {
        uploading: false,
        object_name_short: '',
        object_address: '',
        dks_name: '',
        dks_cert_name: '',
        price_comment: '',
        customer_bank_code: '',
        customer_account_number: '',
        impl_bank_code: '',
        impl_account_number: '',
        contract_number: '',
        contract_date: '',
        finance_source: '',
        finance_code: '',
        org_form_name: '',
        org_form_code: '',

        total_from_beg_year: 0.0,
        total_from_beg_construction: 0.0,
        total_contract_total: 0.0,
        total_current_month: 0.0,
        total_nds: 0.0,
        total_twn: 0.0,
        nds_from_beg_construction: 0.0,
        nds_contract_total: 0.0,
        nds_current_month: 0.0,
        twn_from_beg_construction: 0.0,
        twn_contract_total: 0.0,
        twn_current_month: 0.0,

        type: 1,
        number: '',
        act_date: '',
        name: '',
        contract: {
          id: '',
        },
        ngdu: 'Работы (услуги) не велись на НГДУ',
        double_files: [],
        object_name: '',
        act_month: '',
        global_mvz: '',
        global_work: '',
        total: 0.0,
        nds: 0.0,
        total_with_nds: 0.0,
        rows: [],
        rows_accept: [],
        rows_certs: []
      }
    };
  },
  mounted() {
  },
  computed: {
    all_required_exist: function () {
      if (this.curr_module === 1) {
        if (this.new_act.number !== '' &&
            this.new_act.name !== '' &&
            this.new_act.act_date !== '' &&
            this.new_act.contract.id !== '') {
          return true;
        } else {
          return false;
        }
      } else if (this.curr_module === 3) {
        if (this.new_act.rows.length < 1) {
          return false;
        } else {
          for (let i = 0; i < this.new_act.rows.length; i++) {
            let row = this.new_act.rows[i];
            if (row.work_name === '' ||
                row.measure_unit === '' ||
                row.double_date && row.start_date === '' ||
                row.double_date && row.end_date === '' ||
                !row.double_date && row.row_date === '') {
              return false;
            }
          }
        }
      }
      return true;
    },
  },
  created() {
    this.getMyContracts();
    this.getCostList();
    this.getMVZList();
  },
  methods: {
    toFixedTruncFloat2(figure) {
      return parseFloat((parseInt(figure * 1000 / 10) / 100.0).toFixed(2));
    },
    toFixedFloat2(figure) {
      return parseFloat(parseFloat(figure).toFixed(2));
    },
    isPartitive2(value) {
      let partitive_value = value.toString().replace(',', '.').split('.');
      if (partitive_value.length > 1) {
        if (partitive_value[1].length > 2) {
          return true;
        }
      }
      return false;
    },
    auto_calc() {
      let total = 0.0;
      let rounding_flag = false;
      let negative_flag = false;
      for (let i = 0; i < this.new_act.rows.length; i++) {
        let row = this.new_act.rows[i];
        if (row.count === '') {
          row.count = 0.0;
        }
        if (row.unit_price === '') {
          row.unit_price = 0.0;
        }
        if (row.reason_cost === '') {
          row.reason_cost = 0.0;
        }

        row.count = parseFloat(row.count);
        rounding_flag = rounding_flag || this.isPartitive2(row.unit_price);
        row.unit_price = this.toFixedFloat2(row.unit_price);

        rounding_flag = rounding_flag || this.isPartitive2(row.reason_cost);
        row.reason_cost = this.toFixedFloat2(row.reason_cost);

        if (row.unit_price < 0) {
          row.unit_price = 0.0;
          negative_flag = true;
        }
        if (row.count < 0) {
          row.count = 0.0;
          negative_flag = true;
        }
        if (row.reason_cost < 0 || row.unit_price * row.count - row.reason_cost < 0) {
          row.reason_cost = 0.0;
          negative_flag = true;
        }

        // rounding_flag = rounding_flag || this.isPartitive2(row.count * row.unit_price);
        row.total = this.toFixedFloat2(row.count * row.unit_price - row.reason_cost);

        total += row.total;
      }

      if (this.new_act.nds === '') {
        this.new_act.nds = 0.0;
      }

      rounding_flag = rounding_flag || this.isPartitive2(this.new_act.nds);
      this.new_act.nds = this.toFixedFloat2(this.new_act.nds);
      if (this.new_act.nds < 0) {
        this.new_act.nds = 0.0;
        negative_flag = true;
      }

      this.new_act.total = total;
      this.new_act.total_with_nds = this.new_act.total + this.new_act.nds;

      if (rounding_flag && negative_flag) {
        // alert('1. Значения в числовых ячейках не могут быть < 0; 2. Все значения в числовых ячейках округляются до 2 (двух) знаков после запятой');
        this.$store.dispatch('addNotification', {
              type: 'warning',
              message: 'Значения не могут быть меньше нуля. Все значения округляются до 2 (двух) знаков после запятой',
          })
      } else if (rounding_flag) {
        // alert('Все значения в числовых ячейках округляются до 2 (двух) знаков после запятой');
        this.$store.dispatch('addNotification', {
              type: 'warning',
              message: 'Все значения округляются до 2 (двух) знаков после запятой',
          })
      } else if (negative_flag) {
        this.$store.dispatch('addNotification', {
              type: 'warning',
              message: 'Значения в числовых ячейках не могут быть отрицательными значениями',
          })
      }
    },
    auto_calc2() {
      let total_from_beg_year = 0.0;
      let total_from_beg_construction = 0.0;
      let total_contract_total = 0.0;
      let total_current_month = 0.0;
      let total_nds = 0.0;
      let total_twn = 0.0;

      let rounding_flag = false;
      let negative_flag = false;
      for (let i = 0; i < this.new_act.rows_certs.length; i++) {
        let row = this.new_act.rows_certs[i];
        let from_beg_year = 0.0;
        let from_beg_construction = 0.0;
        let contract_total = 0.0;
        let current_month = 0.0;

        if (row.from_beg_year !== '') {
          rounding_flag = rounding_flag || this.isPartitive2(row.from_beg_year);
          row.from_beg_year = this.toFixedFloat2(row.from_beg_year);
          if (row.from_beg_year < 0) {
            row.from_beg_year = 0.0;
            negative_flag = true;
          }
          from_beg_year = row.from_beg_year;
        }
        if (row.from_beg_construction !== '') {
          rounding_flag = rounding_flag || this.isPartitive2(row.from_beg_construction);
          row.from_beg_construction = this.toFixedFloat2(row.from_beg_construction);
          if (row.from_beg_construction < 0) {
            row.from_beg_construction = 0.0;
            negative_flag = true;
          }
          from_beg_construction = row.from_beg_construction;
        }
        if (row.contract_total !== '') {
          rounding_flag = rounding_flag || this.isPartitive2(row.contract_total);
          row.contract_total = this.toFixedFloat2(row.contract_total);
          if (row.contract_total < 0) {
            row.contract_total = 0.0;
            negative_flag = true;
          }
          contract_total = row.contract_total;
        }
        if (row.current_month !== '') {
          rounding_flag = rounding_flag || this.isPartitive2(row.current_month);
          row.current_month = this.toFixedFloat2(row.current_month);
          if (row.current_month < 0) {
            row.current_month = 0.0;
            negative_flag = true;
          }
          current_month = row.current_month;
        }

        row.nds = this.toFixedFloat2(current_month * 0.12);
        row.total_with_nds = this.toFixedFloat2(row.nds + current_month);

        total_from_beg_year += from_beg_year;
        total_from_beg_construction += from_beg_construction;
        total_contract_total += contract_total;
        total_current_month += current_month;
        total_nds += row.nds;
        total_twn += row.total_with_nds;
      }

      this.new_act.total_from_beg_year = total_from_beg_year;
      this.new_act.total_from_beg_construction = total_from_beg_construction;
      this.new_act.total_contract_total = total_contract_total;
      this.new_act.total_current_month = total_current_month;
      this.new_act.total_nds = total_nds;
      this.new_act.total_twn = total_twn;

      this.new_act.nds_from_beg_construction = this.toFixedFloat2(this.new_act.total_from_beg_construction * 0.12);
      this.new_act.nds_contract_total = this.toFixedFloat2(this.new_act.total_contract_total * 0.12);
      this.new_act.nds_current_month = this.toFixedFloat2(this.new_act.total_current_month * 0.12);

      this.new_act.twn_from_beg_construction = this.toFixedFloat2(this.new_act.total_from_beg_construction + this.new_act.nds_from_beg_construction);
      this.new_act.twn_contract_total = this.toFixedFloat2(this.new_act.total_contract_total + this.new_act.nds_contract_total);
      this.new_act.twn_current_month = this.toFixedFloat2(this.new_act.total_current_month + this.new_act.nds_current_month);

      if (rounding_flag && negative_flag) {
        // alert('1. Значения в числовых ячейках не могут быть < 0; 2. Все значения в числовых ячейках округляются до 2 (двух) знаков после запятой');
        this.$store.dispatch('addNotification', {
              type: 'warning',
              message: 'Значения не могут быть меньше нуля. Все значения округляются до 2 (двух) знаков после запятой',
          })
      } else if (rounding_flag) {
        // alert('Все значения в числовых ячейках округляются до 2 (двух) знаков после запятой');
        this.$store.dispatch('addNotification', {
              type: 'warning',
              message: 'Все значения округляются до 2 (двух) знаков после запятой',
          })
      } else if (negative_flag) {
        // alert('Значения в числовых ячейках не могут быть отрицательными значениями');
        this.$store.dispatch('addNotification', {
              type: 'warning',
              message: 'Значения в числовых ячейках не могут быть отрицательными значениями',
          })
      }
    },
    monthDiff(d1, d2) {
      var months;
      months = (d2.getFullYear() - d1.getFullYear()) * 12;
      months -= d1.getMonth();
      months += d2.getMonth();
      return months <= 0 ? 0 : months;
    },
    check_act_date() {
      let today = new Date();
      let act_date = new Date(this.new_act.act_date)

      if (this.monthDiff(act_date, today) > 2 || this.monthDiff(today, act_date) > 2) {
        this.$store.dispatch('addNotification', {
          type: 'warning',
          message: 'Дата акта отличается от текущей даты больше чем на 2 месяца',
        });
      }
    },
    reuploadVl1() {
      // const file = this.$refs.file.files[0];
      let fileName = document.getElementById("input-field-multiple").value,
          idxDot = fileName.lastIndexOf(".") + 1,
          extFile = fileName.substr(idxDot, fileName.length).toLowerCase();


      if (!(extFile === "png" || extFile === "pdf" || extFile === "jpg")) {
        this.$modal.show("file-modal-multiple");
        this.file = null;
      } else {
        this.new_act.uploading = true;
      }
    },
    reuploadVl_d1(index) {
      let fileName = document.getElementById("input-field-d1-" + index).value,
          idxDot = fileName.lastIndexOf(".") + 1,
          extFile = fileName.substr(idxDot, fileName.length).toLowerCase();

      if (!(extFile === "png" || extFile === "jpg")) {
        this.$modal.show("file-modal-d1-" + index);
        this.file = null;
      } else {
        this.new_act.double_files[index].uploading_d1 = true;
      }
    },
    reuploadVl_d2(index) {
      // const file = this.$refs['file_d2_' + index].files[0];
      let fileName = document.getElementById("input-field-d2-" + index).value,
          idxDot = fileName.lastIndexOf(".") + 1,
          extFile = fileName.substr(idxDot, fileName.length).toLowerCase();

      if (!(extFile === "png" || extFile === "jpg")) {
        this.$modal.show("file-modal-d2-" + index);
        this.file = null;
      } else {
        this.new_act.double_files[index].uploading_d2 = true;
      }
    },
    closeModalMultuple() {
      this.$modal.hide("file-modal-multiple");
    },
    closeModalD1(index) {
      this.$modal.hide("file-modal-d1-" + index);
    },
    closeModalD2(index) {
      this.$modal.hide("file-modal-d2-" + index);
    },
    add_double_file() {
      let a = {
        uploading_d1: false,
        uploading_d2: false
      };
      this.new_act.double_files.push(a);
    },
    change_row_date_type() {
      let row_date_type_element = document.getElementById('row-date-type');
      this.row_date_type = row_date_type_element.value;
    },
    change_row_date_type3() {
      let row_date_type_element = document.getElementById('row-date-type3');
      this.row_date_type = row_date_type_element.value;
    },
    change_row_date_type_template() {
      let row_date_type_element = document.getElementById('row-date-type-template');
      this.row_date_type_template = row_date_type_element.value;
    },
    change_row_accept_type() {
      let row_accept_type_element = document.getElementById('row-accept-type');
      this.row_accept_type = row_accept_type_element.value;
    },
    add_row() {
      let double_date = true;
      if (this.row_date_type === '') {
        this.$store.dispatch('addNotification', {
              type: 'warning',
              message: 'Выберите тип даты акта',
          })
        return;
      }
      if (this.row_date_type === '1') {
        double_date = false;
      }
      let row = {
        cost_code: 'Отсутствует',
        mvz_code: 'Отсутствует',
        source_code: '',
        mvz_name: '',
        work_name: '',
        double_date: double_date,
        start_date: '',
        end_date: '',
        row_date: '',
        research_report: '',
        measure_unit: 'услуга',
        count: 0.0,
        unit_price: 0.0,
        total: 0.0,
        reason: '',
        reason_cost: 0.0
      };
      this.new_act.rows.push(row);
      this.row_date_type = '';
      this.$modal.hide("add-row-modal");
    },
    rm_row() {
      this.new_act.rows.pop();
      this.auto_calc();
    },
    rm_row_index(index) {
      this.new_act.rows.splice(index, 1);
      this.auto_calc();
    },
    rm_row_cert() {
      this.new_act.rows_certs.pop();
    },
    rm_row_accept() {
      this.new_act.rows_accept.pop();
    },
    getMyContracts() {
      this.$store.dispatch("getMyContractsMirror").then((response) => {
        this.contracts = response.data;
      }).catch((error) => {
        this.$store.dispatch('addNotification', {
          type: 'error',
          message: error.response.data.detail,
        })
      });
    },
    getCostList() {
      this.$store.dispatch("getCostList").then((response) => {
        this.cost_list = response.data;
      }).catch((error) => {
        this.$store.dispatch('addNotification', {
          type: 'error',
          message: error.response.data.detail,
        })
      });
    },
    getMVZList() {
      this.$store.dispatch("getMVZList").then((response) => {
        this.mvz_list = response.data;
      }).catch((error) => {
        this.$store.dispatch('addNotification', {
          type: 'error',
          message: error.response.data.detail,
        })
      });
    },
    downloadTemplate() {
      let formData = {
        template_row_count: this.template_row_count,
        row_date_type_template: this.row_date_type_template,
      };
      this.$store.dispatch("downloadTemplate", formData).then((response) => {
        const url = URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'AVR_Template.xlsx');
        document.body.appendChild(link);
        link.click();
      }).catch((error) => {
        this.$store.dispatch('addNotification', {
          type: 'error',
          message: error.response.data.detail,
        })
      });
    },
    showAddRowModal() {
      this.$modal.show("add-row-modal");
    },
    // showDownloadTemplate() {
    //   this.$modal.show("download-template-modal");
    // },
    showAddRowAcceptModal() {
      this.$modal.show("add-row-accept-modal");
    },
    prettyPrice(price) {
      if (typeof price == "number") {
        return price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
      } else {
        return price;
      }
    },
    createAct() {
      let formData = new FormData();

      console.log(this.$refs);
      console.log('files_additional');
      console.log(this.$refs['files_additional']);
      for (let i = 0; i < this.$refs['files_additional'].files.length; i++) {
        let file = this.$refs['files_additional'].files[i];
        formData.append('files_additional-' + i, file);
      }

      for (let i = 0; i < this.new_act.double_files.length; i++) {
        let double_file = this.new_act.double_files[i];
        if (double_file.uploading_d1 && double_file.uploading_d2) {
          let file_d1 = this.$refs['file_d1_' + i][0].files[0];
          let file_d2 = this.$refs['file_d2_' + i][0].files[0];
          formData.append('file_d1-' + i, file_d1);
          formData.append('file_d2-' + i, file_d2);
        }
      }

      // formData.append('file', this.file);
      const json = JSON.stringify(this.new_act);

      formData.append('new_act', json);
      console.log('formData');
      console.log(formData);
      this.$store.dispatch('createActMirror', formData).then((response) => {
        console.log(response.data.act_id);
        if (response.data.act_id !== '') {
          router.push({name: 'actview', params: {id: response.data.act_id}});
        } else {
          // alert('Ошибка');
          this.$store.dispatch('addNotification', {
              type: 'error',
              message: response.data.detail,
          })
        }
      }).catch((error) => {
        // alert(error);
        this.$store.dispatch('addNotification', {
              type: 'error',
              message: error.response.data.detail,
          })
      });
    },
    uploadTemplate() {
      let formData = new FormData();


      let file = this.$refs['file_template'].files[0];
      console.log('file');
      console.log(file);
      formData.append('file_template', file);

      this.$store.dispatch('uploadTemplate', formData).then((response) => {
        console.log('success');
        console.log(response.data);
        this.new_act.rows = response.data.rows;
        this.auto_calc();
        // if (response.data.act_id !== '') {
        //   router.push({name: 'actview', params: {id: response.data.act_id}});
        // } else {
        //   // alert('Ошибка');
        //   this.$store.dispatch('addNotification', {
        //       type: 'error',
        //       message: 'Ошибка, попробуйте заново',
        //   })
        // }
      }).catch((error) => {
        // alert(error);
        this.$store.dispatch('addNotification', {
              type: 'error',
              message: error.response.data.detail,
          })
      });
    },
    curr_class(arg) {
      if (this.curr_module === arg) {
        return 'current';
      } else if (this.curr_module > arg) {
        return 'done';
      }
      return '';
    },
    getFilesHiddenClass(curr_mod) {
      console.log('curr_module');
      console.log(curr_mod);
      if (curr_mod !== 2) {
        return 'hidden-element';
      }
      return '';
    },
    prevStep() {
      if (this.curr_module > 1) {
        this.curr_module -= 1;
      }
    },
    nextStep() {
      if (this.curr_module < 3) {
        this.curr_module += 1;
      } else {
        this.createAct();
      }
    },
    prev_next_btn_class() {
      if (this.curr_module === 1) {
        return 'flex-end';
      } else {
        return 'flex-space-between';
      }
    },
    get_arrow_steps_class() {
      return 'arrow-steps';
    }
  }
}
</script>

<style scoped>
@import "../assets/css/Nunito.css";
@import "../assets/css/josefin.css";

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Nunito", sans-serif;
}

.upload-from-xls {
  padding: 0.3rem 0.7rem 0.3rem 0.7rem;
  background-color: #00aacc;
  color: white;
  border-radius: 5px;
}

.hidden-element {
  display: none !important;
}

.required-cell {
  /*  */
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.m-table-act {
  width: 100%;

  text-align: center;
  font-size: x-small;
  border: 1px solid black;
  border-collapse: collapse;
}

.m-table-act td {
  border: 1px solid black;
  border-collapse: collapse;
}

.m-table-act th {
  -moz-transition: all 1s ease-out;
  -o-transition: all 1s ease-out;
  -webkit-transition: all 1s ease-out;
  border: 1px solid black;
  border-collapse: collapse;
}

.input-field-default {
  width: 100%;
  padding: 10px;
  border: 1px solid gray;
  text-align: center;
  font-size: 1rem;
  outline: none;
  margin: 0.5rem 0 1rem;
  border-radius: 10px;
}

.input-field-template {
  width: 300px !important;
  padding: 10px;
  border: 1px solid gray;
  text-align: center;
  font-size: 1rem;
  outline: none;
  margin: 0.5rem 0 1rem;
  border-radius: 10px;
}

.inputfile {
  /* visibility: hidden etc. wont work */
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputfile:focus + label {
  /* keyboard navigation */
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}

.inputfile + label * {
  pointer-events: none;
}

.no-border-right {
  border-right: none !important;
  border-top: none !important;
  border-bottom: none !important;
}

.m-input-cell {
  width: 100%;
  /*border-width: 1px;*/
  height: 35px;
  text-align: center;
}

.m-textarea-cell {
  display: block;
  width: 100%;
  padding: .5rem;
  /*border-width: 1px;*/
  height: 72px;
  text-align: left;
  resize: none;
}

.m-input-cell-big {
  display: block;
  width: 100%;
  /*border-width: 1px;*/
  height: 72px;
  text-align: center;
}

.dropzone {
  /*margin-top: 3rem;*/
  height: 250px;
  min-height: 250px;
  padding: 10px;
  position: relative;
  cursor: pointer;
  border: 2px dashed gray;
  outline-offset: 10px;
  background: #d9e3f7;
  color: dimgray;
}

.input-field {
  opacity: 0;
  width: 100%;
  height: 100%;
  left: 0;
  position: absolute;
  cursor: pointer;
}

.dropzone:hover {
  background: lightblue;
  transition: all 300ms ease;
}

.dropzone .call-to-action {
  font-size: 1.5rem;
  text-align: center;
  padding: 70px 0;
}

.call-to-action > div {
  font-size: 1rem;
  padding: 1rem 2rem;
  background: #00aacc;
  max-width: 300px;
  margin: 1rem auto;
  border-radius: 20px;
  color: #fff;
}

.uploaded-msg {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.add_double_file {
  padding: .5rem;
  margin: .5rem;
  background: #23468c;
  color: white;
  border-radius: 10px;
}

.add_row_btn {
  padding: .5rem;
  margin: 1rem;
  background: #0c8;
  width: 2.6rem;
  font-size: 1rem;
  color: white;
  border-radius: 100%;
}

.rm_row_btn {
  padding: .5rem;
  margin: 1rem;
  background: rgba(255, 0, 0, 0.76);
  width: 2.6rem;
  font-size: 1rem;
  color: white;
  border-radius: 100%;
}

.rm_row_btn_small {
  /*padding: .5rem;*/
  margin: 0.4rem;
  background: rgba(255, 0, 0, 0.76);
  width: 1.5rem;
  font-size: 1rem;
  color: white;
  border-radius: 100%;
}

.rm_row_btn_small:hover {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
}

.modal-inner {
  height: 100%;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  text-align: center;
}

.modal-inner-short {
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  text-align: center;
}

@media only screen and (max-width: 1366px) {
  .title-img {
    width: 300px;
  }
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1500px;
  }
}

@media (min-width: 2000px) {
  .container {
    width: 1700px;
  }
}

@keyframes wave {
  50% {
    transform: scale(0.9);
  }
}

.input-act-params {
  background: white;
  height: 60px;
  /*border: 1px solid gray;*/
  border-radius: .5rem;
  width: 100% !important;
  padding: 1rem;
}

input[type=checkbox] {
  appearance: none;
}

input[type=checkbox]:checked + label {
  border: 0.3rem solid #06aef0;
  box-shadow: 0 0 5px #333;
  opacity: 1;
}

.selected-items-wrapper {
  width: 300px;
  height: calc(100% - 300px);
  position: absolute;
  top: 300px;
  right: -300px;
  opacity: 0.8;
  padding: 1rem;
  overflow-y: auto;
  color: gray;
}

.selected-items-wrapper::-webkit-scrollbar {
  width: 13px;
  height: 13px;
}

.selected-items-wrapper::-webkit-scrollbar-track {
  border-radius: 15px;
}

.selected-items-wrapper::-webkit-scrollbar-thumb {
  border-radius: 100px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.categories-container {
  width: 100%;
}

.circle-number {
  border-radius: 300px;
  background: #23468c;
  color: #fff;
  float: left;
  font-size: 3rem;
  height: 80px;
  margin-right: 10px;
  margin-top: 5px;
  text-align: center;
  width: 80px;
  font-weight: bold;
  padding-top: 8px;
}

.module-title {
  padding-top: 30px;
  font-size: 24px;
}

.clearfix:after {
  clear: both;
  content: "";
  display: block;
  height: 0;
  background: inherit;
}

.wrapper {
  display: table-cell;
  height: 200px;
  vertical-align: middle;
}

a, a:active {
  color: #333;
  text-decoration: none;
}

a:hover {
  color: #999;
}

.arrow-steps .step {
  font-size: 14px;
  text-align: center;
  color: #666;
  cursor: default;
  margin: 0 3px;
  padding: 10px 10px 10px 30px;
  min-width: 325px;
  float: left;
  position: relative;
  background-color: #d9e3f7;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: background-color 0.2s ease;
}

.arrow-steps-short .step {
  font-size: 14px;
  text-align: center;
  color: #666;
  cursor: default;
  margin: 0 3px;
  padding: 10px 10px 10px 30px;
  min-width: 220px;
  float: left;
  position: relative;
  background-color: #d9e3f7;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: background-color 0.2s ease;
}

@media (max-width: 2000px) {
  .arrow-steps .step {
    min-width: 480px !important;
  }
  .arrow-steps-short .step {
    min-width: 287px !important;
  }
}

.arrow-steps .step:after,
.arrow-steps .step:before {
  content: " ";
  position: absolute;
  top: 0;
  right: -17px;
  width: 0;
  height: 0;
  border-top: 19px solid transparent;
  border-bottom: 24px solid transparent;
  border-left: 17px solid #d9e3f7;
  z-index: 2;
  transition: border-color 0.2s ease;
}

.arrow-steps .step:before {
  right: auto;
  left: 0;
  border-left: 17px solid #e4e9f7;
  z-index: 0;
}

.arrow-steps .step:first-child:before {
  border: none;
}

.arrow-steps .step:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.arrow-steps .step span {
  position: relative;
}

.arrow-steps .step span:before {
  opacity: 0;
  content: "✔";
  position: absolute;
  top: -2px;
  left: -20px;
}

.arrow-steps .step.done span:before {
  opacity: 1;
  -webkit-transition: opacity 0.3s ease 0.5s;
  -moz-transition: opacity 0.3s ease 0.5s;
  -ms-transition: opacity 0.3s ease 0.5s;
  transition: opacity 0.3s ease 0.5s;
}

.arrow-steps .step.current {
  color: #fff;
  background-color: #23468c;
}

.arrow-steps .step.current:after {
  border-left: 17px solid #23468c;
}



.arrow-steps-short .step:after,
.arrow-steps-short .step:before {
  content: " ";
  position: absolute;
  top: 0;
  right: -17px;
  width: 0;
  height: 0;
  border-top: 19px solid transparent;
  border-bottom: 24px solid transparent;
  border-left: 17px solid #d9e3f7;
  z-index: 2;
  transition: border-color 0.2s ease;
}

.arrow-steps-short .step:before {
  right: auto;
  left: 0;
  border-left: 17px solid #e4e9f7;
  z-index: 0;
}

.arrow-steps-short .step:first-child:before {
  border: none;
}

.arrow-steps-short .step:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.arrow-steps-short .step span {
  position: relative;
}

.arrow-steps-short .step span:before {
  opacity: 0;
  content: "✔";
  position: absolute;
  top: -2px;
  left: -20px;
}

.arrow-steps-short .step.done span:before {
  opacity: 1;
  -webkit-transition: opacity 0.3s ease 0.5s;
  -moz-transition: opacity 0.3s ease 0.5s;
  -ms-transition: opacity 0.3s ease 0.5s;
  transition: opacity 0.3s ease 0.5s;
}

.arrow-steps-short .step.current {
  color: #fff;
  background-color: #23468c;
}

.arrow-steps-short .step.current:after {
  border-left: 17px solid #23468c;
}

</style>
