<template>
  <div></div>
</template>

<script>
export default {
    created() {
        this.$store.dispatch('destroyToken').then(response => {
            console.log(response);
            this.$router.push({ name: 'login' });
        })
        .then(() => {
             this.$store.dispatch('addNotification', {
                type: 'success',
                message: 'Вы успешно вышли из системы',
            })
        })
    }
}
</script>

<style>

</style>
