<template>
	<div class="page">
		<img class="wave" src="@/assets/wave.png" />
		<div class="container">
			<div class="img">
				<img src="@/assets/bg.svg" />
			</div>
			<div class="login-content">
				<form @submit.prevent="login">
					<img src="@/assets/avatar.svg" />
					<h2 class="title">Добро пожаловать</h2>
					<div class="input-div one">
						<div class="i">
							<i class="fas fa-user"></i>
						</div>
						<div class="div">
							<h5>Логин</h5>
							<input
								v-model="username"
								type="text"
								class="input"
							/>
						</div>
					</div>
					<div class="input-div pass">
						<div class="i">
							<i class="fas fa-lock"></i>
						</div>
						<div class="div">
							<h5>Пароль</h5>
							<input
								v-model="password"
								type="password"
								class="input"
							/>
						</div>
					</div>
					<input type="submit" class="btn" value="Войти" />
				</form>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			username: '',
			password: '',
		};
	},
	methods: {
		async login() {
			let data = {
				username: this.username,
				password: this.password,
			};

			this.$store.dispatch('loginWithIin', data).then(() => {
				this.$router.push({ name: 'landing' }).catch(() => {});
			}).catch((error) => {
        this.$store.dispatch('addNotification', {
          type: 'error',
          message: error.response.data.detail,
        })
      });
		},
	},
	mounted() {
		const inputs = document.querySelectorAll('.input');

		function addcl() {
			let parent = this.parentNode.parentNode;
			parent.classList.add('focus');
		}

		function remcl() {
			let parent = this.parentNode.parentNode;
			if (this.value == '') {
				parent.classList.remove('focus');
			}
		}

		inputs.forEach(input => {
			input.addEventListener('focus', addcl);
			input.addEventListener('blur', remcl);
		});
	},
};
</script>

<style scoped>
.wave {
	position: fixed;
	bottom: 0;
	left: 0;
	height: 100%;
}

.container {
	width: 100vw;
	height: 100vh;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 7rem;
	padding: 0 2rem;
}

.img {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.login-content {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	text-align: center;
}

.img img {
	width: 500px;
	z-index: 2;
}

form {
	width: 360px;
}

.login-content img {
	height: 100px;
}

.login-content h2 {
	margin: 15px 0;
	color: #333;
	text-transform: uppercase;
	font-size: 2.9rem;
}

.login-content .input-div {
	position: relative;
	display: grid;
	grid-template-columns: 7% 93%;
	margin: 25px 0;
	padding: 5px 0;
	border-bottom: 2px solid #d9d9d9;
}

.login-content .input-div.one {
	margin-top: 0;
}

.i {
	color: #d9d9d9;
	display: flex;
	justify-content: center;
	align-items: center;
}

.i i {
	transition: 0.3s;
}

.input-div > div {
	position: relative;
	height: 45px;
}

.input-div > div > h5 {
	position: absolute;
	left: 10px;
	top: 50%;
	transform: translateY(-50%);
	color: #999;
	font-size: 18px;
	transition: 0.3s;
}

.input-div:before,
.input-div:after {
	content: '';
	position: absolute;
	bottom: -2px;
	width: 0%;
	height: 2px;
	background-color: #6c63ff;
	transition: 0.4s;
}

.input-div:before {
	right: 50%;
}

.input-div:after {
	left: 50%;
}

.input-div.focus:before,
.input-div.focus:after {
	width: 50%;
}

.input-div.focus > div > h5 {
	top: -5px;
	font-size: 15px;
}

.input-div.focus > .i > i {
	color: #6c63ff;
}

.input-div > div > input {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	border: none;
	outline: none;
	background: none;
	padding: 0.5rem 0.7rem;
	font-size: 1.2rem;
	color: #555;
	font-family: 'poppins', sans-serif;
}

.input-div.pass {
	margin-bottom: 4px;
}

a {
	display: block;
	text-align: right;
	text-decoration: none;
	color: #999;
	font-size: 0.9rem;
	transition: 0.3s;
}

a:hover {
	color: #38d39f;
}

.btn {
	display: block;
	width: 100%;
	height: 50px;
	border-radius: 25px;
	outline: none;
	border: none;
	background-image: linear-gradient(to right, #6c63ff, #9b97ff, #6c63ff);
	background-size: 200%;
	font-size: 1.2rem;
	color: #fff;
	font-family: 'Poppins', sans-serif;
	text-transform: uppercase;
	margin: 1rem 0;
	cursor: pointer;
	transition: 0.5s;
}
.btn:hover {
	background-position: right;
}

@media screen and (max-width: 1050px) {
	.container {
		grid-gap: 5rem;
	}
}

@media screen and (max-width: 1000px) {
	form {
		width: 290px;
	}

	.login-content h2 {
		font-size: 2.4rem;
		margin: 8px 0;
	}

	.img img {
		width: 400px;
	}
}

@media screen and (max-width: 900px) {
	.container {
		grid-template-columns: 1fr;
	}

	.img {
		display: none;
	}

	.wave {
		display: none;
	}

	.login-content {
		justify-content: center;
	}
}
</style>
