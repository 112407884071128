<template>
  <div class="home-section-wrap">
    <section class="home-section" id="home-section">
<!--      <div>-->
<!--        <div class="innernavbar-wrap p-10" style="padding-bottom: 0 !important;">-->
<!--          <div class="item-wrap">-->
<!--            <div class="item left-item">-->
<!--              <ul>-->
<!--                <li>-->
<!--                  <button class="filter-btn transition-all ease-in-out duration-150 transform hover:scale-110 px-5 py-1 rounded-lg max-h-12 whitespace-nowrap"-->
<!--                  :class="fast_active('all')"-->
<!--                  @click="change_fast_filter('all')">-->
<!--                    Все договоры-->
<!--                  </button>-->
<!--                </li>-->
<!--                <li>-->
<!--                  <button class="filter-btn transition-all ease-in-out duration-150 transform hover:scale-110 px-5 py-1 rounded-lg"-->
<!--                  :class="fast_active('work')"-->
<!--                  @click="change_fast_filter('work')">-->
<!--                    Активные-->
<!--                  </button>-->
<!--                </li>-->
<!--                <li>-->
<!--                  <button class="filter-btn transition-all ease-in-out duration-150 transform hover:scale-110 px-5 py-1 rounded-lg"-->
<!--                  :class="fast_active('done')"-->
<!--                  @click="change_fast_filter('done')">-->
<!--                    Завершенные-->
<!--                  </button>-->
<!--                </li>-->
<!--              </ul>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="folder-mode">-->
<!--        </div>-->
<!--      </div>-->

      <div class="p-10" style="padding-top: 0.5rem !important;">
        <div style="display: flex; justify-content: flex-start; gap: 1rem">
          <input class="input-field" v-model="search_scontract" type="text" @input="getAllSContracts(1)"
                 placeholder="Введите номер или наименование договора">
          <button class="search-btn transition-all ease-in-out duration-150 transform hover:scale-110 px-5 py-1 rounded-lg my-4" 
          @click="getAllSContracts(1)">
            Поиск
          </button>
        </div>
        <div class="card">
          <div class="card-header" style="display: flex; justify-content: space-between">
            <h4>Внутренние договоры</h4>
            <a href="/scontracts/create">
              <button class="transition-all ease-in-out duration-150 transform hover:scale-110 bg-indigo-500 hover:bg-indigo-600 px-5 py-1 rounded-lg text-white" v-if="is_ABP">
                Создать договор
              </button>
            </a>
          </div>
          <div class="table-responsive">
            <table class="table table-hover table-nowrap">
              <thead class="thead-light">
              <tr>
                <th scope="col" class="">ID</th>
                <th scope="col" class="">№</th>
                <th scope="col" :class="getHideClass()">Наименование</th>
                <th scope="col" class="" v-if="!is_IMPL">Наименование заказчика</th>
                <th scope="col" :class="getHideClass()" v-if="!is_IMPL">БИН заказчика</th>
                <th scope="col" class="">Администратор поставщика</th>
                <th scope="col" class="">Дата истечения</th>
                <th scope="col" class="">Статус</th>
              </tr>
              </thead>
              <tbody v-if="!loading">
                <tr v-for="contract in this.scontracts" :key="contract.id" :id="'req-tr-' + contract.id"
                    :class="'tr-main ' + getSelectedClass(contract.id)">
                  <td data-label="" class="">
                    <router-link class="custom-link" :to="`/scontracts/${contract.id}`">
                      {{ contract.id }}
                    </router-link>
                  </td>
                  <td data-label="" class="" @click="showInfoFull(contract)">
                    {{ contract.number }}
                  </td>
                  <td data-label="" :class="getHideClass()" @click="showInfoFull(contract)">
                    {{ contract.name }}
                  </td>
                  <td data-label="" class="" @click="showInfoFull(contract)" v-if="!is_IMPL">
                    {{ contract.impl_company.name }}
                  </td>
                  <td data-label="" :class="getHideClass()" @click="showInfoFull(contract)" v-if="!is_IMPL">
                    {{ contract.impl_company.bin }}
                  </td>
                  <td data-label="" class="" @click="showInfoFull(contract)">
                    {{ contract.zak_abp.ouser.full_name }}
                  </td>
                  <td data-label="" class="" @click="showInfoFull(contract)">
                    {{ contract.date_to }}
                  </td>
                  <td data-label="" class="" @click="showInfoFull(act)">
                    <span :class="contract.color + ' act-status'">{{ contract.status }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="empty-table" v-if="!loading && scontracts.length === 0">
              Договоры отсутствуют
            </div>
            <!-- Loader START -->
            <div class="loader-container" v-if="loading">
              <svg version="1.1" id="L7" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 100 100" enable-background="new 0 0 100 100" xml:space="preserve">
                <path fill="#5d61f4" d="M31.6,3.5C5.9,13.6-6.6,42.7,3.5,68.4c10.1,25.7,39.2,38.3,64.9,28.1l-3.1-7.9c-21.3,8.4-45.4-2-53.8-23.3
                  c-8.4-21.3,2-45.4,23.3-53.8L31.6,3.5z">
                      <animateTransform 
                        attributeName="transform" 
                        attributeType="XML" 
                        type="rotate"
                        dur="2s" 
                        from="0 50 50"
                        to="360 50 50" 
                        repeatCount="indefinite" />
                  </path>
                <path fill="#5d61f4" d="M42.3,39.6c5.7-4.3,13.9-3.1,18.1,2.7c4.3,5.7,3.1,13.9-2.7,18.1l4.1,5.5c8.8-6.5,10.6-19,4.1-27.7
                  c-6.5-8.8-19-10.6-27.7-4.1L42.3,39.6z">
                      <animateTransform 
                        attributeName="transform" 
                        attributeType="XML" 
                        type="rotate"
                        dur="1s" 
                        from="0 50 50"
                        to="-360 50 50" 
                        repeatCount="indefinite" />
                  </path>
                <path fill="#5d61f4" d="M82,35.7C74.1,18,53.4,10.1,35.7,18S10.1,46.6,18,64.3l7.6-3.4c-6-13.5,0-29.3,13.5-35.3s29.3,0,35.3,13.5
                  L82,35.7z">
                      <animateTransform 
                        attributeName="transform" 
                        attributeType="XML" 
                        type="rotate"
                        dur="2s" 
                        from="0 50 50"
                        to="360 50 50" 
                        repeatCount="indefinite" />
                  </path>
                </svg>
            </div>
            <!-- Loader END -->
          </div>
        </div>
        <div class="mt-5">
          <pagination :pageCount="max_pages" :currentPage="current_page" @pageClicked="getAllSContracts" />
        </div>
        <!-- <div id="pagination">
          <a v-on:click="prev_page()">◄</a>
          <span>
            <a v-on:click="getAllContracts(page);" v-for="page in this.max_pages" :key="page"
               :class="get_current_class(page)">{{ page }}</a>
          </span>
          <a v-on:click="next_page()">►</a>
        </div> -->
      </div>
    </section>
    <section class="right-section scrl-y" id="right-section">
      <div class="card-header flex-space-between font-50"
           style="border-bottom: none; padding-bottom: 0; display: flex; justify-content: space-between; align-items: center">
        <i class="bx bx-chevrons-left"
           id="bx-chevrons"
           @click="hideInfo">
        </i>
        <span v-if="selected.mode" class="card-title"
              style="padding-right: 0; font-size: 30px; margin-bottom: 0 !important; text-align: right">
          Договор №<a class="custom-link">{{ selected.number }}</a>
          от <small v-if="selected.date_from">{{ selected.date_from_short }} г/ж.</small>
          <small v-else>__.__.____ г/ж.</small>
        </span>
      </div>
      <div
          style="border-bottom: 1px solid #eceef3; display: flex; justify-content: right; padding-bottom: 20px; padding-right: 20px">
        <!--        <span v-if="selected.mode" :class="selected.color + ' req-status-inner'" style="height: 30px; max-width: 150px; min-width: 150px; padding: 7px">{{ selected.status }}</span>-->
      </div>
      <div class="p-5" v-if="selected.mode">
        <!--        <div style="width: 100%; display: flex; justify-content: center; gap: 4rem; height: 100px">-->
        <!--          <i class="bx bx-spreadsheet bx-btn" title="Скачать заявку/отчет (XLS)" style="color: #0c8; font-size: 80px; position: relative; margin-bottom: 10px" @click="download_archive_xls(selected.id)">-->
        <!--            <strong style="font-size: 10px !important; position: absolute; bottom: 0; left: 8px">Скачать XLS</strong>-->
        <!--          </i>-->
        <!--          <i class="bx bxs-file-pdf bx-btn" v-if="selected.done_count === selected.positions.length" title="Скачать заявку/отчет (PDF)" style="color: #f36; font-size: 80px; position: relative; margin-bottom: 10px" @click="download_archive_pdf(selected.id)">-->
        <!--            <strong style="font-size: 10px !important; position: absolute; bottom: 0; left: 8px">Скачать PDF</strong>-->
        <!--          </i>-->
        <!--          <i class="bx bx-git-merge bx-btn" v-if="selected.done_count > 0 && selected.done_count < selected.positions.length" title="Разделить выполненные позиции в отдельную отчет" style="color: #337eff; font-size: 80px; position: relative; margin-bottom: 10px" @click="unmerge(selected.id)">-->
        <!--            <strong style="font-size: 10px !important; position: absolute; bottom: 0; left: 8px">Разделить позиции</strong>-->
        <!--          </i>-->
        <!--        </div>-->

        <div class="p-5 info-card big-card scrl-y img-wrap">
          <div class="flex justify-center mb-5">
            <router-link :to="`/scontracts/${selected.id}`">
              <button class="transition-all ease-in-out duration-150 transform hover:scale-110 bg-indigo-500 hover:bg-indigo-600 px-10 py-3 rounded-lg text-white margin-auto">Подробнее о договоре</button>
            </router-link>
          </div>
          <div class="info-card-item" style="display: flex; justify-content: center">
            <span class="span-title" style="text-align: center">{{ selected.name }}</span>
          </div>
          <hr>
          <div class="info-card-item">
            <span class="span-title">Заказчик</span>
            <span style="font-weight: bold">{{ selected.impl_company.name }}</span>
          </div>
          <div class="info-card-item margin-bottom-20">
            <span class="span-title">БИН заказчика</span>
            <span>{{ selected.impl_company.bin }}</span>
          </div>
          <div class="info-card-item margin-bottom-20">
            <span class="span-title">Адрес заказчика</span>
            <span>{{ selected.impl_company.address }}</span>
          </div>
          <div class="info-card-item">
            <span class="span-title">Администратор со стороны заказчика</span>
            <span>{{ selected.impl_abp.ouser.full_name }}</span>
          </div>
          <hr>
          <div class="info-card-item">
            <span class="span-title">Поставщик</span>
            <span style="font-weight: bold">{{ selected.zak_abp.ouser.company.name }}</span>
          </div>
          <div class="info-card-item">
            <span class="span-title">БИН поставщика</span>
            <span>{{ selected.zak_abp.ouser.company.bin }}</span>
          </div>
          <div class="info-card-item margin-bottom-20">
            <span class="span-title">Адрес поставщика</span>
            <span>{{ selected.zak_abp.ouser.company.address }}</span>
          </div>
          <div class="info-card-item">
            <span class="span-title">Администратор со стороны поставщика</span>
            <span>{{ selected.zak_abp.ouser.full_name }}</span>
          </div>
          <hr>
          <div class="info-card-item">
            <span class="span-title">Дата создания в системе</span>
            <span>{{ selected.created_date }}</span>
          </div>
          <div class="info-card-item" style="justify-content: center !important;">
            <h5 style="font-style: italic">Период оказания услуг</h5>
          </div>
          <div class="div-item">
            <div class="descripcion">{{ selected.date_start }}</div>
            <div class="precio">{{ selected.date_to }}</div>
          </div>
          <div class="info-card-item">
            <span></span>
            <span></span>
          </div>
<!--          <div style="display: flex; justify-content: flex-end; padding-bottom: 5px;">-->
<!--            <button class="transition-all ease-in-out duration-150 transform hover:scale-110 bg-indigo-500 hover:bg-indigo-600 px-10 py-3 rounded-lg text-white"-->
<!--                    @click="selected.file_visible=!selected.file_visible">Основной файл-->
<!--            </button>-->
<!--          </div>-->
<!--          <div v-if="selected.file_visible">-->
<!--            <embed :src="selected.ts_file.file" id="embed_kp" class="embed_full"/>-->
<!--          </div>-->
        </div>

      </div>
    </section>
  </div>
</template>
<script async>
import Pagination from "../components/shared/Pagination.vue"
import router from "../router.js";

export default {
  components: {
    Pagination
  },
  data() {
    return {
      selected: {},
      search_scontract: "",
      fast_filter: "all",
      scontracts_global: [],
      all_scontracts: [],
      scontracts: [],
      is_IMPL: false,
      is_ABP: false,
      is_APPROVER: false,
      is_RATIFICATOR: false,
      is_ECONOMIST: false,
      is_BOOKER: false,
      is_DZOADM: false,
      current_page: 1,
      pages: [],
      max_pages: 1,
      companies: [],
      abp_list: [],
      files: {},
      loading: false,
    };
  },
  created() {
    this.is_IMPL = this.isIMPL();
    this.is_ABP = this.isABP();
    this.is_APPROVER = this.isAPPROVER();
    this.is_RATIFICATOR = this.isRATIFICATOR();
    this.is_ECONOMIST = this.isECONOMIST();
    this.is_BOOKER = this.isBOOKER();
    this.is_DZOADM = this.isDZOADM();
    let a = {
      class: 'current',
      number: 1,
      search_scontract: ""
    }

    this.pages.push(a);
    this.getAllSContracts(1);
    this.getAllImplCompanies();
    this.getAllABPList();
  },
  mounted() {
  },
  methods: {
    showInfo() {
      console.log('showInfo');
      let bla = document.getElementById('home-section');
      bla.classList.toggle('open', true);
      let right_arrow = document.getElementById('bx-chevrons');
      right_arrow.classList.toggle('bx-chevrons-left', false);
      right_arrow.classList.toggle('bx-chevrons-right', true);

      let req_names = document.getElementsByClassName('req-name');

      Array.prototype.forEach.call(req_names, function (el) {
        el.classList.toggle('open', true);
      });

      // let to_hide_elements = document.getElementsByClassName('to-hide');
      //
      // Array.prototype.forEach.call(to_hide_elements, function (el) {
      //   if (el) {
      //     el.classList.toggle('hiden-element', true);
      //   }
      // });

      let another_selected = document.getElementsByClassName('selected_element');
      Array.prototype.forEach.call(another_selected, function (el) {
        el.classList.toggle('selected_element', false);
      });

      this.selected['mode'] = true;

    },
    hideInfo() {
      console.log('hideInfo');
      let bla = document.getElementById('home-section');
      bla.classList.toggle('open', false);
      let right_arrow = document.getElementById('bx-chevrons');
      right_arrow.classList.toggle('bx-chevrons-right', false);
      right_arrow.classList.toggle('bx-chevrons-left', true);

      let req_names = document.getElementsByClassName('req-name');

      Array.prototype.forEach.call(req_names, function (el) {
        el.classList.toggle('open', false);
      });

      // let to_hide_elements = document.getElementsByClassName('to-hide');
      //
      // Array.prototype.forEach.call(to_hide_elements, function (el) {
      //   if (el) {
      //     el.classList.toggle('hiden-element', false);
      //   }
      // });

      let another_selected = document.getElementsByClassName('selected_element');
      Array.prototype.forEach.call(another_selected, function (el) {
        el.classList.toggle('selected_element', false);
      });
      this.selected = {};
      this.selected['mode'] = false;
      // this.selected.file_visible = false;
    },
    showInfoFull(req) {
      if (this.selected === req) {
        this.hideInfo();
      } else {
        this.selected = req;
        this.showInfo();
      }
    },
    isIMPL() {
      return localStorage.getItem('roles').includes(1);
    },
    isABP() {
      return localStorage.getItem('roles').includes(2);
    },
    isAPPROVER() {
      return localStorage.getItem('roles').includes(3);
    },
    isRATIFICATOR() {
      return localStorage.getItem('roles').includes(4);
    },
    isECONOMIST() {
      return localStorage.getItem('roles').includes(5);
    },
    isBOOKER() {
      return localStorage.getItem('roles').includes(6)
    },
    isDZOADM() {
      return localStorage.getItem('roles').includes(7);
    },
    get_current_class(page_number) {
      if (page_number === this.current_page) {
        return "current";
      } else {
        return '';
      }
    },
    next_page() {
      if (this.current_page < this.max_pages) {
        this.current_page += 1;
        this.getAllSContracts(this.current_page);
      }
    },
    prev_page() {
      if (this.current_page > 1) {
        this.current_page -= 1;
        this.getAllSContracts(this.current_page);
      }
    },
    prettyPrice(price) {
      if (typeof price == "number") {
        return price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
      } else {
        return price;
      }
    },
    getAllSContracts(page) {
      this.loading = true
      if (page > 0 && page < this.max_pages + 1) {
        this.current_page = page;
        let formData = {
          status: this.fast_filter,
          page: page,
          search_scontract: this.search_scontract,
        };

        this.$store.dispatch("getAllSContracts", formData).then((response) => {
          this.scontracts = response.data.results;
          this.scontracts_global = response.data.results;
          this.all_scontracts = response.data;
          this.max_pages = parseInt(this.all_scontracts.count / 10) + 1;
        }).then(() => {
          this.loading = false
        })
        .catch((error) => {
          this.$store.dispatch('addNotification', {
            type: 'error',
            message: error.response.data.detail,
          })
        });
      }
    },
    getAllImplCompanies() {
      this.$store.dispatch("getAllImplCompanies").then((response) => {
        this.companies = response.data;
      }).catch((error) => {
        this.$store.dispatch('addNotification', {
          type: 'error',
          message: error.response.data.detail,
        })
      });
    },
    getAllABPList() {
      this.$store.dispatch("getAllABPList").then((response) => {
        this.abp_list = response.data;
      }).catch((error) => {
        this.$store.dispatch('addNotification', {
          type: 'error',
          message: error.response.data.detail,
        })
      });
    },
    getHideClass() {
      if (this.selected.mode) {
        return 'hiden-element';
      }
      return '';
    },
    fast_active(to_filter) {
      if (to_filter.toString() === this.fast_filter.toString()) {
        return 'active';
      }
      return '';
    },
    change_fast_filter(curr_filter) {
      this.fast_filter = curr_filter;
      this.getAllSContracts(1);
    },
    getSelectedClass(req_id) {
      if (this.selected.mode) {
        if (this.selected.id === req_id) {
          return 'selected_element';
        }
      }
      return '';
    },
    checkBxFolderOpen(req) {
      if (req.open) {
        return 'bxs-folder-open'
      } else {
        return 'bxs-folder'
      }
    },
  },
};
</script>

<style scoped>

@import "../assets/css/webpixels.css";

.div-item {
  height: 40px;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}

.descripcion {
  /*background-color: green;*/
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.descripcion:after {
  content: " ........................................................................................................................................................................................................................................................................................."
}

.precio {
  /*background-color: red;*/
  flex-shrink: 0;
}

.info-card-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px
}

.embed_full {
  width: 100%;
  height: 30vh;
}

.input-field {
  width: 100%;
  padding: 10px;
  border: none;
  outline: none;
  margin: 1rem 0;
  border-radius: 10px;
}

.btn-blue {
  background: #23468c;
}

.req-name {
  max-width: 300px !important;
}

@keyframes progress {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.folder-mode {
  width: 100%;
  display: flex;
  justify-content: right;
  padding-right: 40px
}

@keyframes wave {
  50% {
    transform: scale(0.9);
  }
}

.tr-main td {
  padding: 1.2rem !important;
}


.item-wrap {
  display: flex;
  justify-content: space-between;
}

.left-item ul {
  display: flex;
  justify-content: left;
  gap: 1rem;
}

@media screen and (max-width: 1100px) {
  .left-item {
    flex-direction: column;
    gap: 0.2rem;
    height: 100px !important;
  }
}

.item {
  height: 50px;
  width: 50%;
}

.innernavbar-wrap {
  width: 100%;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

svg{
  width: 100px;
  height: 100px;
  margin: 20px;
  display:inline-block;
}

.empty-table {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 1rem;
}
</style>