<template>
  <div class="profile-module">
    <div class="profile">
      <img class="profile-img" src="@/assets/avatar.svg" />
      <div class="profile-inner">
        <div class="profile-info">
          <p class="mini-block-title">Персональные данные</p>
          <p>{{ user_full_name}}</p>
          <p v-if="this.ouser.ouser">Должность: {{ this.ouser.ouser.position }}</p>
          <p v-if="this.ouser.ouser">ИИН: {{ this.ouser.ouser.iin_only }}</p>
          <p class="accessible-roles">Доступные роли: </p>
          <span v-for="item in roles_name" :key="item.id" >
            {{ item }}
          </span>
        </div>
        <div class="org-info">
          <p class="mini-block-title">Данные организации</p>
          <p v-if="this.ouser.ouser">{{ this.ouser.ouser.company.name }}</p>
          <p v-if="this.ouser.ouser">БИН: {{ this.ouser.ouser.company.bin }}</p>
          <p v-if="this.ouser.ouser">{{ this.ouser.ouser.company.address }}</p>
          <p class="ecp-info">Данные ЭЦП: </p>
          <p v-if="this.ouser.ouser">{{ this.ouser.ouser.short_pem_with_date }}</p>
        </div>
        <div class="profile-contacts">
          <a v-if="this.ouser.ouser" :href="`tel:${this.ouser.ouser.phone}`">
            <div class="contact-box">
              <i class="material-icons">call</i>
              <p>{{ this.ouser.ouser.phone }}</p>
            </div>
          </a>
          <a :href="`mailto:${this.ouser.email}`">
            <div class="contact-box">
              <i class="material-icons">mail</i>
              <p>{{ this.ouser.email }}</p>
            </div>
          </a>
        </div>
        <div class="profile-actions">
          <button class="transition-all ease-in-out duration-150 transform hover:scale-110 bg-indigo-500 hover:bg-indigo-600 px-10 py-3 rounded-lg text-white" @click="showPassChangeModal">Смена пароля</button>
          <button class="transition-all ease-in-out duration-150 transform hover:scale-110 bg-indigo-500 hover:bg-indigo-600 px-10 py-3 rounded-lg text-white" @click="showMailChangeModal">Смена почты</button>
          <button class="transition-all ease-in-out duration-150 transform hover:scale-110 bg-indigo-500 hover:bg-indigo-600 px-10 py-3 rounded-lg text-white" @click="showEcpModal">Обновить ЭЦП</button>
        </div>
      </div>
    </div>

    <!-- Modals -->
    <!-- Change Password Modal START -->
    <modal name="pass-change">
      <div class="modal-inner">
        <h3>Смена пароля</h3>
        <div class="password">
          <input type="password" id="password-input-new" class="password-input" placeholder="Введите новый пароль*"
                  v-model="newPassword">
          <a href="#" class="password-control" id="password-input-new-a"
              @click="show_hide_password('password-input-new-a', 'password-input-new');"></a>
        </div>
        <div class="password">
          <input type="password" id="password-input-new2" class="password-input"
                  placeholder="Введите повторно новый пароль*" v-model="passwordConfirmation">
          <a href="#" class="password-control" id="password-input-new2-a"
              @click="show_hide_password('password-input-new2-a', 'password-input-new2');"></a>
        </div>
        <button
            class="transition-all ease-in-out duration-150 transform hover:scale-110 bg-indigo-500 hover:bg-indigo-600 px-10 py-3 rounded-lg text-white"
            @click="changePassword">
          Сохранить
        </button>
      </div>
    </modal>
    <modal name="mail-change">
      <div class="modal-inner">
        <h3>Смена почтового адреса</h3>
        <br>
        <div class="password">
          <input type="email" id="mail-input-new" class="password-input" placeholder="Введите новую почту*"
                  v-model="newMail">
        </div>
        <br>

        <button
            class="transition-all ease-in-out duration-150 transform hover:scale-110 bg-indigo-500 hover:bg-indigo-600 px-10 py-3 rounded-lg text-white"
            @click="changeMail">
          Сохранить
        </button>
      </div>
    </modal>
    <!-- Change Password Modal END -->
    <!-- Digital Signature Modal Start -->
    <modal name="ds-modal">
        <div class="modal-inner">
          <form method="POST" id="auth-form" @submit.prevent="sendSignData">
            <h3>Обновление / загрузка ЭЦП</h3>
            <div hidden>
              <select id="storageSelect">
                <option value="PKCS12" selected>PKCS12</option>
              </select>
            </div>

            <p>
              Для успешного обновления рекомендуется <br> выбирать ключ <b>GOSTKNCA</b>
            </p>

            <button
                type="button"
                class="profile-action-btn"
                @click="getKeyInfoCall">
              Выбрать сертификат
            </button>
            <br/>
            <div hidden>
              <input id="keyId_sign" name="keyId_sign" type="text"/>
              <input id="subjectDn_sign" name="subjectDn_sign"/>
              <input id="serialNumber_sign" name="serialNumber_sign"/>
              <input id="certNotAfter_sign" name="certNotAfter_sign"/>
              <input id="certNotBefore_sign" name="certNotBefore_sign"/>
              <textarea
                  id="pem_sign"
                  name="pem_sign"
                  rows="7"
                  readonly
              ></textarea>
              <input id="alias_sign" name="alias_sign"/>
              <input id="algorithm_sign" name="algorithm_sign"/>
              <input id="subjectCn_sign" name="subjectCn_sign"/>
              <input id="issuerCn_sign" name="issuerCn_sign"/>
              <input id="issuerDn_sign" name="issuerDn_sign"/>
              <input
                  id="authorityKeyIdentifier_sign"
                  name="authorityKeyIdentifier_sign"
              />
            </div>

            <button id="auth_sign" type="submit" hidden>submit</button>
          </form>
        </div>
      </modal>
      <!-- Digital Signature Modal END -->


  </div>
</template>

<script async>

import {getKeyInfo} from "../nclayer/ncalayer";
import axios from "axios";

export default {
  data() {
    return {
      user_full_name: "",
      roles_name: "Гость",
      ouser: {},
      oldPassword: '',
      newPassword: '',
      newMail: '',
      passwordConfirmation: '',
    };
  },
  created() {
    this.getProfileRoles()
    this.user_full_name = this.userFullName();
    this.getUserInfo();
  },
  methods: {
    show_hide_password(target_id, input_id) {
      let target = document.getElementById(target_id);
      console.log(target);
      let input = document.getElementById(input_id);
      if (input.getAttribute('type') === 'password') {
        target.classList.add('view');
        input.setAttribute('type', 'text');
      } else {
        target.classList.remove('view');
        input.setAttribute('type', 'password');
      }
      return false;
    },
    password_recovery() {
      if (confirm('Уверены в сбросе пароля? Новый пароль будет отправлен вам на почту ' + this.ouser.email)) {
        this.$store.dispatch("password_recovery").then((response) => {
          // alert(response.data.detail);
          this.$store.dispatch('addNotification', {
              type: 'success',
              message: response.data.detail,
          })
        }).catch((error) => {
          this.$store.dispatch('addNotification', {
            type: 'error',
            message: error.response.data.detail,
          })
        });
      }
    },
    changePassword() {
      if (this.newPassword !== this.passwordConfirmation) {
        this.clearChangePasswordForm()
        this.$store.dispatch('addNotification', {
            type: 'error',
            message: 'Пароли не совпадают',
        })
      }
      let formData = {
        new_pass: this.newPassword,
      }

      this.$store.dispatch("password_change", formData).then((response) => {
        this.$store.dispatch('addNotification', {
            type: 'success',
            message: 'Пароль успешно изменен',
        })
      }).catch((error) => {
        this.$store.dispatch('addNotification', {
          type: 'error',
          message: error.response.data.detail,
        })
      });
      this.$modal.hide("pass-change");
      this.clearChangePasswordForm()
    },
    changeMail() {
      let formData = {
        new_mail: this.newMail,
      }

      this.$store.dispatch("mail_change", formData).then((response) => {
        this.$store.dispatch('addNotification', {
          type: 'success',
          message: 'Почта успешно изменена',
        })
        this.getUserInfo();
      }).catch((error) => {
        this.$store.dispatch('addNotification', {
          type: 'error',
          message: error.response.data.detail,
        })
      });
      this.$modal.hide("mail-change");
      this.clearChangeMailForm()
    },
    clearChangePasswordForm() {
      this.oldPassword = ''
      this.newPassword = ''
      this.passwordConfirmation = ''
    },
    clearChangeMailForm() {
      this.newMail = ''
    },
    showPassChangeModal() {
      this.$modal.show("pass-change");
    },
    showMailChangeModal() {
      this.$modal.show("mail-change");
    },
    showEcpModal() {
      this.$modal.show("ds-modal");
    },
    getKeyInfoCall() {
      let selectedStorage = "PKCS12";
      getKeyInfo(selectedStorage);
    },
    sendSignData() {
      let keyId_sign = document.getElementById("keyId_sign");
      let subjectDn_sign = document.getElementById("subjectDn_sign");
      let serialNumber_sign = document.getElementById("serialNumber_sign");
      let certNotAfter_sign = document.getElementById("certNotAfter_sign");
      let certNotBefore_sign = document.getElementById("certNotBefore_sign");
      let pem_sign = document.getElementById("pem_sign");
      let alias_sign = document.getElementById("alias_sign");
      let algorithm_sign = document.getElementById("algorithm_sign");
      let subjectCn_sign = document.getElementById("subjectCn_sign");
      let issuerCn_sign = document.getElementById("issuerCn_sign");
      let issuerDn_sign = document.getElementById("issuerDn_sign");
      let authorityKeyIdentifier_sign = document.getElementById(
          "authorityKeyIdentifier_sign"
      );
      let formData = {
        alias_sign: alias_sign.value,
        keyId_sign: keyId_sign.value,
        algorithm_sign: algorithm_sign.value,
        subjectCn_sign: subjectCn_sign.value,
        subjectDn_sign: subjectDn_sign.value,
        issuerCn_sign: issuerCn_sign.value,
        issuerDn_sign: issuerDn_sign.value,
        serialNumber_sign: serialNumber_sign.value,
        certNotAfter_sign: certNotAfter_sign.value,
        certNotBefore_sign: certNotBefore_sign.value,
        authorityKeyIdentifier_sign: authorityKeyIdentifier_sign.value,
        pem_sign: pem_sign.value,
      };
      this.$store.dispatch("update_ecp_profile", formData).then((response) => {
        this.$store.dispatch('addNotification', {
              type: 'success',
              message: response.data.detail,
          })
        // alert(response.data.detail);
        this.$router.go()
      }).catch((error) => {
        this.$store.dispatch('addNotification', {
          type: 'error',
          message: error.response.data.detail,
        })
      });
    },
    getUserInfo() {
      let user = JSON.parse(localStorage.getItem('user'));
      let id = user['id'];
      this.$store.dispatch("getUserInfo", id).then((response) => {
        this.ouser = response.data;
      }).catch((error) => {
        this.$store.dispatch('addNotification', {
          type: 'error',
          message: error.response.data.detail,
        })
      });
    },
    getProfileRoles() {
      // Getting role names from Local Storage
      let ls_roles_names = localStorage.getItem('roles_name');
      // Splitting them by comma
      ls_roles_names = ls_roles_names.split(/\s*,\s*/);
      // Filtering empty values
      let result = ls_roles_names.filter(i => i)
      this.roles_name = result
    },
    userFullName() {
      let user = JSON.parse(localStorage.getItem('user'));
      return user["last_name"] + ' ' + user["first_name"];
    },
  },
};
</script>

<style scoped>

.profile-module {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #e4eaf7;
}

.profile {
  width: auto;
  /* max-width: 700px; */
  min-height: 300px;
  height: auto;
  background: #fff;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  padding: 1rem 5rem;
  position: relative;
}

.profile-img {
  position: absolute;
  top: -80px;
  left: 50%;
  margin-left: -50px;
  width: 100px;
  height: 100px;
}

.profile-inner {
  display: grid;
  gap: 1rem;
  min-width: 700px;
  /* grid-template-columns: 1fr 1fr; */
}

.profile-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid rgb(99 102 241);
  border-radius: 15px;
  padding: .5rem;
  margin-top: 2rem;
}

.profile-info:hover {
  background: rgb(99 102 241);
  color: #fff;
  transition: all .3s;
  cursor: pointer;
}

.org-info {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border: 1px solid rgb(99 102 241);
  border-radius: 15px;
  padding: .5rem;
}

.org-info:hover {
  background: rgb(99 102 241);
  color: #fff;
  transition: all .3s;
  cursor: pointer;
}

.profile-contacts {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.contact-box {
  display: flex;
  min-width: 200px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid rgb(99 102 241);
  border-radius: 15px;
  padding: .5rem;
  padding: 1rem;
}

.contact-box:hover {
  background: rgb(99 102 241);
  color: #fff;
  transition: all .3s;
  cursor: pointer;
}

.contact-box i {
  margin-bottom: .5rem;
}

.profile-actions {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.profile-action-btn {
  border: 1px solid rgb(99 102 241);
  border-radius: 15px;
  padding: .5rem;
  padding: 1rem;
  background: rgb(99 102 241);
  color: #fff;
}

.profile-action-btn:hover {
  background: #fff;
  color: rgb(99 102 241);
  transition: all .3s;
}


/* Old styles */

.password {
  width: 60%;
  /*margin: 15px auto;*/
  position: relative;
}

.password-input {
  width: 100%;
  padding: 5px 0;
  height: 40px;
  line-height: 40px;
  text-indent: 10px;
  /*margin: 0 0 15px 0;*/
  border-radius: 5px;
  border: 1px solid #999;
  font-size: 18px;
}

.password-control {
  position: absolute;
  top: 11px;
  right: 6px;
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url(https://snipp.ru/demo/495/view.svg) 0 0 no-repeat;
}

.password-control.view {
  background: url(https://snipp.ru/demo/495/no-view.svg) 0 0 no-repeat;
}

.home-section {
  position: relative;
  background: #e4e9f7;
  min-height: 100vh;
  top: 0;
  left: 78px;
  width: calc(100% - 78px);
  transition: all 0.5s ease;
}

.sidebar.open ~ .home-section {
  left: 250px;
  width: calc(100% - 250px);
}

.home-section .text {
  display: inline-block;
  color: #11101d;
  font-size: 25px;
  font-weight: 500;
  margin: 18px;
}

.input-readonly {
  background: inherit;
  height: 60px !important;
  border-radius: 10px;
  padding: .5rem;
  overflow: hidden;
}

.profile-row {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 2rem;
  height: 50px;
  padding: 1rem !important;
}

.roles-row {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 2rem;
  padding: 1rem !important;
  padding-bottom: 0 !important;
}

@keyframes example {
  from {
    opacity: 0.2
  }
  to {
    opacity: 1.0
  }
}

.main-flex-column {
  width: 40%;
  min-width: 600px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  box-shadow: 0 20px 60px -2px rgba(27, 33, 58, 0.4);
  align-items: center;
  gap: 1rem;
  padding-bottom: 1rem;
}

.main-flex-column-wrap {
  height: 100vh;
  animation-name: example;
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.mini-block-title {
  margin-bottom: .5rem;
  font-size: 18px;
  font-weight: bold;
}

.accessible-roles,
.ecp-info {
  margin-top: 1rem;
  font-weight: bold;
}

</style>