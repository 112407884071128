<template>
  <div class="home-section-wrap">
    <section class="home-section" id="home-section">
      <div>
        <div class="innernavbar-wrap p-10" style="padding-bottom: 0 !important;">
          <div class="item-wrap">
            <div class="item left-item">
              <ul class="mobile-flex">
                <li v-if="is_ABP || is_IMPL || is_APPROVER || is_ECONOMIST || is_RATIFICATOR">
                  <button class="filter-btn transition-all ease-in-out duration-150 transform hover:scale-110 bg-indigo-500 hover:bg-indigo-600 px-5 py-1 rounded-lg max-h-12 whitespace-nowrap"
                  :class="fast_active('onme')"
                  @click="change_fast_filter('onme')">
                    На мне
                  </button>
                </li>
                <li v-if="is_ABP || is_IMPL || is_APPROVER || is_ECONOMIST || is_RATIFICATOR || is_DZOADM">
                  <button class="filter-btn transition-all ease-in-out duration-150 transform hover:scale-110 bg-indigo-500 hover:bg-indigo-600 px-5 py-1 rounded-lg max-h-12 whitespace-nowrap"
                  :class="fast_active('all')"
                  @click="change_fast_filter('all')">
                    Все акты
                  </button>
                </li>
                <li v-if="is_ABP || is_IMPL || is_APPROVER || is_ECONOMIST || is_RATIFICATOR || is_DZOADM">
                  <button class="filter-btn transition-all ease-in-out duration-150 transform hover:scale-110 bg-indigo-500 hover:bg-indigo-600 px-5 py-1 rounded-lg"
                  :class="fast_active('contractor')"
                  @click="change_fast_filter('contractor')">
                    На подрядчике
                  </button>
                </li>

                <li v-if="is_ABP || is_APPROVER || is_ECONOMIST || is_RATIFICATOR || is_DZOADM">
                  <button class="filter-btn transition-all ease-in-out duration-150 transform hover:scale-110 bg-indigo-500 hover:bg-indigo-600 px-5 py-1 rounded-lg"
                  :class="fast_active('abp')"
                  @click="change_fast_filter('abp')">
                    АБП
                  </button>
                </li>
                <li v-if="is_ABP || is_IMPL || is_APPROVER || is_ECONOMIST || is_RATIFICATOR || is_DZOADM">
                  <button class="filter-btn transition-all ease-in-out duration-150 transform hover:scale-110 bg-indigo-500 hover:bg-indigo-600 px-5 py-1 rounded-lg"
                  :class="fast_active('approve')"
                  @click="change_fast_filter('approve')">
                    На согласовании
                  </button>
                </li>
                <li v-if="is_ABP || is_IMPL || is_APPROVER || is_ECONOMIST || is_RATIFICATOR || is_DZOADM">
                  <button class="filter-btn transition-all ease-in-out duration-150 transform hover:scale-110 bg-indigo-500 hover:bg-indigo-600 px-5 py-1 rounded-lg"
                  :class="fast_active('rat')"
                  @click="change_fast_filter('rat')">
                    На утверждении
                  </button>
                </li>
                <li v-if="is_ABP || is_IMPL || is_APPROVER || is_ECONOMIST || is_RATIFICATOR || is_DZOADM || is_BOOKER">
                  <button class="filter-btn transition-all ease-in-out duration-150 transform hover:scale-110 bg-indigo-500 hover:bg-indigo-600 px-5 py-1 rounded-lg"
                  :class="fast_active('done')"
                  @click="change_fast_filter('done')">
                    <span v-if="is_BOOKER">Все акты</span>
                    <span v-else>Завершенные</span>
                  </button>
                </li>
                <li v-if="is_BOOKER">
                  <button class="filter-btn transition-all ease-in-out duration-150 transform hover:scale-110 bg-indigo-500 hover:bg-indigo-600 px-5 py-1 rounded-lg"
                          :class="fast_active('in_sap')"
                          @click="change_fast_filter('in_sap')">
                    Проведено в SAP
                  </button>
                </li>
                <li v-if="is_BOOKER">
                  <button class="filter-btn transition-all ease-in-out duration-150 transform hover:scale-110 bg-indigo-500 hover:bg-indigo-600 px-5 py-1 rounded-lg"
                          :class="fast_active('not_in_sap')"
                          @click="change_fast_filter('not_in_sap')">
                    Отсутствует ведомость
                  </button>
                </li>
                <li v-if="is_BOOKER">
                  <button class="filter-btn transition-all ease-in-out duration-150 transform hover:scale-110 bg-indigo-500 hover:bg-indigo-600 px-5 py-1 rounded-lg"
                          :class="fast_active('not_done')"
                          @click="change_fast_filter('not_done')">
                    Не завершенные
                  </button>
                </li>
                <li v-if="is_ABP || is_IMPL || is_APPROVER || is_ECONOMIST || is_RATIFICATOR || is_DZOADM || is_BOOKER">
                  <button class="filter-btn transition-all ease-in-out duration-150 transform hover:scale-110 bg-indigo-500 hover:bg-indigo-600 px-5 py-1 rounded-lg"
                          :class="fast_active('archived')"
                          @click="change_fast_filter('archived')">
                    Архивированные
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="innernavbar-wrap p-10" style="padding-bottom: 0 !important; padding-top: 0 !important;" v-if="is_BOOKER || fast_filter === 'archived'">
          <div class="item-wrap">
            <div class="item left-item">
              <ul>
                <li v-if="is_BOOKER || fast_filter === 'archived'" v-for="year in years_list" :key="year">
                  <button class="filter-btn transition-all ease-in-out duration-150 transform hover:scale-110 bg-indigo-500 hover:bg-indigo-600 px-5 py-1 rounded-lg"
                          :class="fast_active_year(year)"
                          @click="change_fast_filter_year(year)">
                    {{ year }}
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="innernavbar-wrap p-10" style="padding-bottom: 0 !important; padding-top: 0 !important;" v-if="(is_BOOKER  || fast_filter === 'archived') &&  fast_filter_year !== 'Все года'">
          <div class="item-wrap">
            <div class="item left-item">
              <ul>
                <li v-if="is_BOOKER || fast_filter === 'archived'" v-for="(month, index) in month_list" :key="index">
                  <button class="filter-btn transition-all ease-in-out duration-150 transform hover:scale-110 bg-indigo-500 hover:bg-indigo-600 px-5 py-1 rounded-lg"
                          :class="fast_active_month(index)"
                          @click="change_fast_filter_month(index)">
                    {{ month }}
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="p-10" style="padding-top: 0.5rem !important;">
        <div style="display: flex; justify-content: flex-start; gap: 1rem">
          <select class="input-field" v-model="search_act_ngdu" v-if="is_BOOKER" style="margin-top: 0" type="text"
                 v-on:keyup.enter="getAllActs(1)">
            <option value="">Выберите НГДУ</option>
            <option v-for="ngdu in ngdu_list" :key="ngdu" :value="ngdu">{{ ngdu }}</option>
          </select>
          <input class="input-field" v-model="search_act_contr" v-if="is_BOOKER" style="margin-top: 0" type="text" placeholder="Введите номер или наименование договора"
                 v-on:keyup.enter="getAllActs(1)">
          <input class="input-field" v-model="search_act_impl" v-if="is_BOOKER" style="margin-top: 0" type="text" placeholder="Введите БИН или наименование контрагента"
                 v-on:keyup.enter="getAllActs(1)">
<!--          <button class="search-btn transition-all ease-in-out duration-150 transform hover:scale-110 px-5 py-1 rounded-lg my-4" style="margin-top: 0 !important;" @click="getAllActs(1)">Поиск</button>-->

          <input class="input-field" v-model="search_act" style="margin-top: 0" type="text" placeholder="Введите номер или наименование акта"
                 v-on:keyup.enter="getAllActs(1)">
          <button class="search-btn transition-all ease-in-out duration-150 transform hover:scale-110 px-5 py-1 rounded-lg my-4" style="margin-top: 0 !important;" @click="getAllActs(1)">Поиск</button>
        </div>
        <div class="card">
          <div class="card-header flex justify-between justify-items-center">
            <h4>Акты выполненных работ и услуг</h4>
            <button class="transition-all ease-in-out duration-150 transform hover:scale-110 bg-green-500 hover:bg-green-800 px-5 py-1 rounded-lg text-white"
                    v-if="is_IMPL"
                    @click="showCreateActModal">
                  Создать акт
            </button>
            <button class="transition-all ease-in-out duration-150 transform hover:scale-110 bg-green-500 hover:bg-green-800 px-5 py-1 rounded-lg text-white"
                    v-if="is_ABP && has_mirror_contracts"
                    @click="redirect_to_create_act_mirror">
              Создать акт
            </button>
            <button class="transition-all ease-in-out duration-150 transform hover:scale-110 bg-green-500 hover:bg-green-800 px-5 py-1 rounded-lg text-white"
                    v-show="is_RATIFICATOR && fast_filter === 'rat'"
                    @click="showSignModalList">
                  Подписать выбранные
            </button>
            <modal name="ds-list-modal" :height="800">
              <div class="modal-inner">
                <h3 style="text-align: center; padding: 20px">Подписать через ЭЦП</h3>
                <form method="POST" id="auth-form" @submit.prevent="sendSignXmls">
                  <div hidden>
                    <select id="storageSelect">
                      <option value="PKCS12" selected>PKCS12</option>
                    </select>
                  </div>

                  <p class="auth-moda-explanations" style="text-align: center">
                    Для успешной подписи рекомендуется <br/>
                    выбирать ключ <b>GOSTKNCA</b>
                  </p>
                  <div hidden>
                    <div v-for="act in this.acts" :key="act.id" style="width: 300px">
                      <textarea class="form-control" v-model="act.xmlToSign" :id="'xmlToSign_' + act.id" :name="'xmlToSign_' + act.id"></textarea>
                      <textarea class="form-control" :id="'signedXml_' + act.id" :name="'signedXml_' + act.id" readonly></textarea>
                    </div>
                  </div>
                  <div style="display: flex; justify-content: center">
                    <button class="auth-choose-cert prev-next-item" type="button" style="margin: 30px; height: 40px"
                            @click="signXmlsCall">
                      Выбрать сертификат
                    </button>
                  </div>
                  <button id="test_list" type="submit" hidden>submit</button>
                </form>
              </div>
            </modal>
          </div>
          <div class="table-responsive">
            <table class="table table-hover table-nowrap custom-table">
              <thead class="thead-light">
                <tr>
                  <th scope="col" class="" style="border-top-left-radius: 10px; border-top-right-radius: 10px">ID</th>
                  <th scope="col" class="mainless">№</th>
                  <th scope="col" :class="'mainless width-300 scrl-x ' + getHideClass()">Наименование</th>
                  <th scope="col" class="">Дата</th>
                  <th scope="col" class="text-center" v-if="fast_filter === 'done'">Дата подписания</th>
                  <th scope="col" class="mainless">Тип</th>
                  <th scope="col" class="">Сумма</th>
                  <th scope="col" class="" v-if="!is_IMPL">Поставщик</th>
                  <th scope="col" class="mainless text-center" v-if="!is_IMPL">Прогресс</th>
                  <th scope="col" class="" v-if="!is_BOOKER || fast_filter === 'not_done'">Статус</th>
                  <th scope="col" class="mainless" v-show="is_RATIFICATOR && fast_filter === 'rat'">Выбор</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="act in this.acts" :key="act.id" :id="'req-tr-' + act.id"
                    :class="'tr-main ' + getSelectedClass(act.id)">
                  <td data-label="" class="">
                    <a class="custom-link" :href="'/acts/' + act.id"  v-if="!is_BOOKER">
                    {{ act.id }}
                    </a>
                    <a class="custom-link" :href="'/acts/' + act.id" target="_blank" v-else>
                    {{ act.id }}
                    </a>
                  </td>
                  <td data-label="" class="mainless" @click="showInfoFull(act)">
                    {{ act.number }}
                  </td>
                  <td data-label="" :class="'mainless width-300 scrl-x ' + getHideClass()" @click="showInfoFull(act)">
                    {{ act.name }}
                  </td>
                  <td data-label="" class="" @click="showInfoFull(act)">
                    {{ act.act_date }}
                  </td>
                  <td data-label="" class="text-center" @click="showInfoFull(act)" v-if="fast_filter === 'done'">
                    {{ act.ratified_datetime }}
                  </td>
                  <td data-label="" class="mainless" @click="showInfoFull(act)">
                    {{ act.type }}
                  </td>
                  <td data-label="" class="" @click="showInfoFull(act)">
                    {{ prettyPrice(act.total_with_nds) }} тг
                  </td>
                  <td data-label="" class="" @click="showInfoFull(act)"  v-if="!is_IMPL">
                    <span v-if="!act.contract.is_mirror">
                      {{ act.contract.impl_company.name }} ({{ act.contract.impl_company.bin }})
                    </span>
                    <span v-else>
                      {{ act.contract.abp.ouser.company.name }} ({{ act.contract.abp.ouser.company.bin }})
                    </span>
                  </td>
               	  <td data-label="" class="mainless text-center" @click="showInfoFull(act)" :title="act.progress_name" v-if="!is_IMPL">
                    {{ act.progress }}
                  </td>
                  <td data-label="" class="" v-if="!is_BOOKER || fast_filter === 'not_done'" @click="showInfoFull(act)">
                    <span :class="act.color + ' act-status'">{{ act.status }}</span>
                  </td>
                  <td data-label="" class="mainless text-center" v-show="is_RATIFICATOR && fast_filter === 'rat'" style="padding: 1.2rem !important;">
                    <input type="checkbox" style="width: 25px; height: 25px" v-model="act.checked">
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="empty-table" v-if="!loading && this.acts.length === 0">
                Акты отсутствуют
              </div>
              <!-- Loader START -->
              <div class="loader-container" v-if="loading">
                <svg version="1.1" id="L7" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                  viewBox="0 0 100 100" enable-background="new 0 0 100 100" xml:space="preserve">
                  <path fill="#5d61f4" d="M31.6,3.5C5.9,13.6-6.6,42.7,3.5,68.4c10.1,25.7,39.2,38.3,64.9,28.1l-3.1-7.9c-21.3,8.4-45.4-2-53.8-23.3
                    c-8.4-21.3,2-45.4,23.3-53.8L31.6,3.5z">
                        <animateTransform 
                          attributeName="transform" 
                          attributeType="XML" 
                          type="rotate"
                          dur="2s" 
                          from="0 50 50"
                          to="360 50 50" 
                          repeatCount="indefinite" />
                    </path>
                  <path fill="#5d61f4" d="M42.3,39.6c5.7-4.3,13.9-3.1,18.1,2.7c4.3,5.7,3.1,13.9-2.7,18.1l4.1,5.5c8.8-6.5,10.6-19,4.1-27.7
                    c-6.5-8.8-19-10.6-27.7-4.1L42.3,39.6z">
                        <animateTransform 
                          attributeName="transform" 
                          attributeType="XML" 
                          type="rotate"
                          dur="1s" 
                          from="0 50 50"
                          to="-360 50 50" 
                          repeatCount="indefinite" />
                    </path>
                  <path fill="#5d61f4" d="M82,35.7C74.1,18,53.4,10.1,35.7,18S10.1,46.6,18,64.3l7.6-3.4c-6-13.5,0-29.3,13.5-35.3s29.3,0,35.3,13.5
                    L82,35.7z">
                        <animateTransform 
                          attributeName="transform" 
                          attributeType="XML" 
                          type="rotate"
                          dur="2s" 
                          from="0 50 50"
                          to="360 50 50" 
                          repeatCount="indefinite" />
                    </path>
                  </svg>
                </div>
                <!-- Loader END -->
          </div>
        </div>
        <div class="mt-5">
          <p class="text-center">Сумма актов по заданным параметрам: <strong style="font-size: 20px">{{ this.acts_total }} тг</strong></p>
          <p class="text-center" style="font-style: italic">{{ this.acts_total_str }}</p>
        </div>
        <div class="mt-5">
          <pagination :pageCount="max_pages" :currentPage="current_page" @pageClicked="getAllActs" />
        </div>
        <!-- <pagination :pageCount="max_pages_u" @pageClicked="loadPage" /> -->
        <!-- <div id="pagination">
          <a v-on:click="prev_page()">◄</a>
          <span>
            <a v-on:click="getAllActs(page);" v-for="page in this.max_pages" :key="page" :class="get_current_class(page)">{{ page }}</a>
          </span>
          <a v-on:click="next_page()">►</a>
        </div> -->
      </div>
    </section>
    <section class="right-section scrl-y" id="right-section">
      <div class="card-header flex-space-between font-50" style="border-bottom: none; padding-bottom: 0; display: flex; justify-content: space-between; align-items: center">
        <i class="bx bx-chevrons-left"
           id="bx-chevrons"
           @click="hideInfo">
        </i>
        <span v-if="selected.mode" class="card-title" style="padding-right: 0; font-size: 30px; margin-bottom: 0 !important; text-align: right">Акт №<a class="custom-link">{{ selected.number }}</a> от {{ selected.act_date }}</span>
      </div>
      <div style="border-bottom: 1px solid #eceef3; display: flex; justify-content: right; padding-bottom: 20px; padding-right: 20px">
<!--        <span v-if="selected.mode" :class="selected.color + ' req-status-inner'" style="height: 30px; max-width: 150px; min-width: 150px; padding: 7px">{{ selected.status }}</span>-->
      </div>
      <div class="p-5" v-if="selected.mode">
        <div class="p-5 info-card big-card scrl-y img-wrap">
          <div class="flex justify-center mb-5">
            <router-link :to="`/acts/${selected.id}`">
              <button class="transition-all ease-in-out duration-150 transform hover:scale-110 bg-indigo-500 hover:bg-indigo-600 px-10 py-3 rounded-lg text-white margin-auto">Подробнее об акте</button>
            </router-link>
          </div>
          <div class="info-card-item" style="display: flex; justify-content: center">
            <span class="span-title">по договору №<a class="custom-link" :href="'/contracts/' + selected.contract.id">{{ selected.contract.number }}</a></span>
          </div>
          <div class="info-card-item" style="display: flex; justify-content: center">
            <span class="span-title" style="text-align: center">{{ selected.contract.name }}</span>
          </div>
          <hr>
          <div class="info-card-item">
            <span class="span-title" v-if="!selected.contract.is_mirror">Заказчик</span>
            <span class="span-title" v-else>Поставщик</span>
            <span style="font-weight: bold">{{ selected.contract.abp.ouser.company.name }}</span>
          </div>
          <div class="info-card-item">
            <span class="span-title" v-if="!selected.contract.is_mirror">БИН заказчика</span>
            <span class="span-title" v-else>БИН поставщика</span>
            <span>{{ selected.contract.abp.ouser.company.bin }}</span>
          </div>
          <div class="info-card-item">
            <span class="span-title" v-if="!selected.contract.is_mirror">Администратор бюджетной программы</span>
            <span class="span-title" v-else>Администратор со стороны поставщика</span>
            <span>{{ selected.contract.abp.ouser.full_name }}</span>
          </div>
          <hr>
          <div class="info-card-item">
            <span class="span-title" v-if="!selected.contract.is_mirror">Поставщик</span>
            <span class="span-title" v-else>Заказчик</span>
            <span style="font-weight: bold">{{ selected.contract.impl_company.name }}</span>
          </div>
          <div class="info-card-item margin-bottom-20">
            <span class="span-title" v-if="!selected.contract.is_mirror">БИН поставщика</span>
            <span class="span-title" v-else>БИН заказчика</span>
            <span>{{ selected.contract.impl_company.bin }}</span>
          </div>
          <div class="info-card-item" v-if="selected.contract.is_mirror">
            <span class="span-title">Администратор бюджетной программы</span>
            <span>{{ selected.impl_owner.ouser.full_name }}</span>
          </div>
          <div class="info-card-item" style="font-weight: bold">
            <span class="span-title">Общая сумма с НДС</span>
            <span>{{ prettyPrice(selected.total_with_nds) }} тг</span>
          </div>
          <div class="info-card-item" style="display: flex; justify-content: center">
            <span class="span-title" style="text-align: center"></span>
          </div>
          <div class="info-card card">
            <div class="table-responsive p-0 scrl-x">
              <table class="table table-hover table-nowrap">
                <thead class="thead-light">
                <tr>
                  <th scope="col" class="scrl-x">п/п</th>
                  <th scope="col" class="scrl-x">Название работ</th>
                  <th scope="col" class="scrl-x">Ед.изм</th>
                  <th scope="col" class="scrl-x">Кол-во</th>
                  <th scope="col" class="scrl-x">Цена за ед.</th>
                </tr>
                </thead>
                <tbody class="scrl-y">
                <tr v-for="row in this.selected.rows" :key="row.id" :id="'req-pos-tr-' + row.id" class="tr-main">
                  <td data-label="" class="scrl-x">{{ row.serial_number }}</td>
                  <td data-label="" class="scrl-x">{{ row.work_name }}</td>
                  <td data-label="" class="scrl-x">{{ row.measure_unit }}</td>
                  <td data-label="" class="scrl-x">{{ prettyPrice(row.count) }}</td>
                  <td data-label="" class="scrl-x">{{ prettyPrice(row.unit_price) }} тг</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

      </div>
    </section>
    <!-- Modals -->
    <modal name="create-act-modal" :height="250">
      <div class="modal-inner">
        <!-- <h3 class="modal-title">Выберите тип акта</h3> -->
        <div class="modal-info">
          <select class="input-field" style="border: 1px solid whitesmoke" id="act-type" v-on:change="change_act_type()">
            <option value="">Выберите тип акта выполненных работ и услуг*</option>
            <option v-for="(act_type_i, index) in this.act_types" :key="index" :value="act_type_i">{{ act_type_i }}</option>
          </select>

        </div>
        <div>
          <button class="transition-all ease-in-out duration-150 transform hover:scale-110 bg-green-500 hover:bg-green-800 px-10 py-3 rounded-lg text-white"
            v-if="this.act_type !== ''"
            @click="redirect_to_create_act">
              Создать акт
          </button>
        </div>
      </div>
    </modal>
  </div>
</template>
<script async>
import {signXmls} from '@/nclayer/ncalayer';
import router from '../router';
import Pagination from "../components/shared/Pagination.vue"
// import store from '../store';
// import NProgress from 'nprogress';
// import { mapGetters } from 'vuex';
export default {
  components: {
    Pagination
  },
  data() {
    return {
      selected: {},
      ngdu_list: [
        'Работы (услуги) не велись на НГДУ',
        'НГДУ «Доссормунайгаз»',
        'НГДУ «Жайыкмунайгаз»',
        'НГДУ «Жылыоймунайгаз»',
        'НГДУ «Кайнармунайгаз»',
        'Управление производственно-технического обслуживания и комплектации оборудованием (УПТОиКО)',
        'Управление «Эмбамунайэнерго»'
      ],
      search_act: "",
      search_act_ngdu: "",
      search_act_contr: "",
      search_act_impl: "",
      fast_filter: "all",
      fast_filter_year: "Все года",
      fast_filter_month: 0,
      acts_global: [],
      all_acts: [],
      acts_total: '',
      acts_total_str: '',
      acts: [],
      has_mirror_contracts: false,
      is_IMPL: false,
      is_ABP: false,
      is_APPROVER: false,
      is_RATIFICATOR: false,
      is_ECONOMIST: false,
      is_BOOKER: false,
      is_DZOADM: false,
      current_page: 1,
      pages: [],
      years_list: [
        'Все года',
        '2019',
        '2020',
        '2021',
        '2022',
        '2023',
        '2024'
      ],
      month_list: [
          'Все месяца',
          'Январь',
          'Февраль',
          'Март',
          'Апрель',
          'Май',
          'Июнь',
          'Июль',
          'Август',
          'Сентябрь',
          'Октябрь',
          'Ноябрь',
          'Декабрь'
      ],
      max_pages: 1,
      act_type: '',
      act_types: [
        "Стандартный (458-562)",
        "ДКС",
        "ДБиКРС",
      ],
      loading: false,
    };
  },
  // beforeRouteEnter(to, from, next) {
	// 	NProgress.start();
	// 	store.dispatch('getAllActs', to.params.id).then(response => {
	// 		NProgress.done();
	// 		next();
	// 	});
	// },
  created() {
    this.is_IMPL = this.isIMPL();
    this.is_ABP = this.isABP();
    this.is_APPROVER = this.isAPPROVER();
    this.is_RATIFICATOR = this.isRATIFICATOR();
    this.is_ECONOMIST = this.isECONOMIST();
    this.is_BOOKER = this.isBOOKER();
    this.is_DZOADM = this.isDZOADM();
    if (this.is_IMPL || this.is_ABP || this.is_RATIFICATOR || this.is_ECONOMIST || this.is_APPROVER) {
      this.fast_filter = "onme"
    }
    let a = {
      class: 'current',
      number: 1,
      search_act: "",
      search_act_contr: "",
      search_act_impl: "",
    }
    if (this.is_BOOKER && !this.is_IMPL && !this.is_ABP && !this.is_APPROVER && !this.is_APPROVER && !this.is_RATIFICATOR && !this.is_ECONOMIST && !this.is_DZOADM) {
      this.fast_filter = 'done';
    }
    this.pages.push(a);
    this.getAllActs(1);
  },
  methods: {
    redirect(link) {
      router.push({name: link});
    },
    signXmlsCall() {
      var xmlsToSign = [];
      this.acts.filter(i => i.checked).forEach(function (item_act, index_act) {
        console.log(index_act);
        xmlsToSign.push(item_act.xmlToSign);
      });

      let selectedStorage = "PKCS12";
      signXmls(selectedStorage, "SIGNATURE", xmlsToSign, "signXmlsBack");
    },
    sendSignXmls() {
      let xmlsList = [];

      this.acts.filter(i => i.checked).forEach(function (item_act, index_act) {
        console.log(index_act);
        let xmlObj = {
          act_id: item_act.id,
          xmlToSign: item_act.xmlToSign,
          signedXml: document.getElementById("signedXml_" + item_act.id).value
        }
        xmlsList.push(xmlObj);
      });

      let formData = {
        xmlsList: xmlsList
      };

      this.$store.dispatch("sendSignXmls", formData)
          .then((response) => {
            console.log('sendSignXmls-middle');
            console.log(response);
            location.reload();
            // router.push({ name: 'actview', params: {id: response.data.id} });
          })
          .catch((error) => {
            this.$store.dispatch('addNotification', {
              type: 'error',
              message: error.response.data.detail,
            })
          });

    },
    showInfo() {
      console.log('showInfo');
      let bla = document.getElementById('home-section');
      bla.classList.toggle('open', true);
      let right_arrow = document.getElementById('bx-chevrons');
      right_arrow.classList.toggle('bx-chevrons-left', false);
      right_arrow.classList.toggle('bx-chevrons-right', true);

      let req_names = document.getElementsByClassName('req-name');

      Array.prototype.forEach.call(req_names, function (el) {
        el.classList.toggle('open', true);
      });

      // let to_hide_elements = document.getElementsByClassName('to-hide');
      //
      // Array.prototype.forEach.call(to_hide_elements, function (el) {
      //   if (el) {
      //     el.classList.toggle('hiden-element', true);
      //   }
      // });

      let another_selected = document.getElementsByClassName('selected_element');
      Array.prototype.forEach.call(another_selected, function (el) {
        el.classList.toggle('selected_element', false);
      });

      this.selected['mode'] = true;

    },
    getHideClass() {
      if (this.selected.mode) {
        return 'hiden-element';
      }
      return '';
    },
    hideInfo() {
      console.log('hideInfo');
      let bla = document.getElementById('home-section');
      bla.classList.toggle('open', false);
      let right_arrow = document.getElementById('bx-chevrons');
      right_arrow.classList.toggle('bx-chevrons-right', false);
      right_arrow.classList.toggle('bx-chevrons-left', true);

      let req_names = document.getElementsByClassName('req-name');

      Array.prototype.forEach.call(req_names, function (el) {
        el.classList.toggle('open', false);
      });

      // let to_hide_elements = document.getElementsByClassName('to-hide');
      //
      // Array.prototype.forEach.call(to_hide_elements, function (el) {
      //   if (el) {
      //     el.classList.toggle('hiden-element', false);
      //   }
      // });

      let another_selected = document.getElementsByClassName('selected_element');
      Array.prototype.forEach.call(another_selected, function (el) {
        el.classList.toggle('selected_element', false);
      });
      this.selected = {};
      this.selected['mode'] = false;
    },
    showInfoFull(req) {
      if (this.selected && this.selected.id === req.id) {
        this.hideInfo();
      } else {
        this.$store.dispatch('getActDetail', req.id).then((response) => {
          this.selected = response.data;
          this.showInfo();
        }).catch((error) => {
          this.$store.dispatch('addNotification', {
            type: 'error',
            message: error.response.data.detail,
          })
        });
      }
    },
    isIMPL() {
      return localStorage.getItem('roles').includes(1);
    },
    isABP() {
      return localStorage.getItem('roles').includes(2);
    },
    isAPPROVER() {
      return localStorage.getItem('roles').includes(3);
    },
    isRATIFICATOR() {
      return localStorage.getItem('roles').includes(4);
    },
    isECONOMIST() {
      return localStorage.getItem('roles').includes(5);
    },
    isBOOKER() {
      return localStorage.getItem('roles').includes(6)
    },
    isDZOADM() {
      return localStorage.getItem('roles').includes(7);
    },
    get_current_class(page_number){
      if (page_number === this.current_page) {
        return "current";
      } else {
        return '';
      }
    },
    next_page(){
      if (this.current_page < this.max_pages) {
        this.current_page += 1;
        this.getAllActs(this.current_page);
      }
    },
    prev_page(){
      if (this.current_page > 1) {
        this.current_page -= 1;
        this.getAllActs(this.current_page);
      }
    },
    prettyPrice(price) {
      if (typeof price == "number") {
        return price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
      } else {
        return price;
      }
    },
    getAllActs(page) {
      this.acts = [];
      this.acts_global = [];
      this.loading = true
      if (page > 0 && page < this.max_pages + 1) {
        this.current_page = page;
        let formData = {
          status: this.fast_filter,
          page: page,
          search_act: this.search_act,
          search_act_ngdu: this.search_act_ngdu,
          search_act_contr: this.search_act_contr,
          search_act_impl: this.search_act_impl,
          filter_year: this.fast_filter_year,
          filter_month: this.fast_filter_month,
        };
        this.$store.dispatch("getAllActs", formData).then((response) => {
          this.acts = response.data.results;
          this.acts_global = response.data.results;
          this.all_acts = response.data;
          this.max_pages = parseInt(this.all_acts.count / 10) + 1;
          this.$store.dispatch("getHasMirrorContracts").then((response_mirror) => {
            this.has_mirror_contracts = response_mirror.data.res;
          }).catch((error) => {
            this.$store.dispatch('addNotification', {
              type: 'error',
              message: error.response.data.detail,
            })
          });
        }).then(() => {
          this.loading = false
        })
        .catch((error) => {
          this.$store.dispatch('addNotification', {
            type: 'error',
            message: error.response.data.detail,
          })
        });
        if (page === 1) {
          this.$store.dispatch("get_acts_total", formData).then((response) => {
            this.acts_total = this.prettyPrice(response.data.sum_total);
            this.acts_total_str = response.data.sum_total_str;
          })
          .catch((error) => {
            this.$store.dispatch('addNotification', {
              type: 'error',
              message: error.response.data.detail,
            })
          });
        }
      }
    },
    fast_active(to_filter) {
      if (to_filter.toString() === this.fast_filter.toString()) {
        return 'active';
      }
      return '';
    },
    fast_active_year(to_filter) {
      if (to_filter.toString() === this.fast_filter_year.toString()) {
        return 'active';
      }
      return '';
    },
    fast_active_month(to_filter) {
      if (to_filter.toString() === this.fast_filter_month.toString()) {
        return 'active';
      }
      return '';
    },
    change_fast_filter(curr_filter) {
      this.fast_filter = curr_filter;
      this.getAllActs(1);
    },
    change_fast_filter_year(curr_filter) {
      this.fast_filter_year = curr_filter;
      this.getAllActs(1);
    },
    change_fast_filter_month(curr_filter) {
      this.fast_filter_month = curr_filter;
      this.getAllActs(1);
    },
    getSelectedClass(req_id) {
      if (this.selected.mode) {
        if (this.selected.id === req_id) {
          return 'selected_element';
        }
      }
      return '';
    },
    checkBxFolderOpen(req) {
      if (req.open) {
        return 'bxs-folder-open'
      } else {
        return 'bxs-folder'
      }
    },
    showCreateActModal() {
      this.$modal.show("create-act-modal");
      this.hideInfo();
    },
    showSignModalList() {
      this.$modal.show("ds-list-modal");
    },
    change_act_type() {
      let act_type_element = document.getElementById('act-type');
      this.act_type = act_type_element.value;
    },
    redirect_to_create_act() {
      if (this.act_type === '') {
        this.$store.dispatch('addNotification', {
              type: 'error',
              message: 'Для создания акта необходимо выбрать "Тип акта"',
          })
      } else {
        if (this.act_type === this.act_types[0]) {
          router.push({name: 'create_act', params: {id: 1}});
        } else if (this.act_type === this.act_types[1]) {
          router.push({name: 'create_act', params: {id: 2}});
        } else {
          router.push({name: 'create_act', params: {id: 3}});
        }
      }
    },
    redirect_to_create_act_mirror() {
      router.push({name: 'create_act_mirror'});
    }
  },
  // computed: {
  //   ...mapGetters(['allReports']),
  // }
};
</script>

<style scoped>
@import "../assets/css/webpixels.css";

.info-card-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px
}

.input-field {
  width: 100%;
  padding: 10px;
  border: none;
  outline: none;
  margin: 1rem 0;
  border-radius: 10px;
}

.req-name {
  max-width: 300px !important;
}

@keyframes progress {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.tr-main {
  cursor: pointer
}

.tr-main td {
  padding: 0.9rem !important;
}

@keyframes wave {
  50% {
    transform: scale(0.9);
  }
}

.item-wrap {
  display: flex;
  justify-content: space-between;
}

.left-item ul {
  display: flex;
  justify-content: left;
  gap: 1rem;
}

@media screen and (max-width: 1100px) {
  .left-item {
    flex-direction: column;
    gap: 0.2rem;
    height: 100px !important;
  }
}

.item {
  height: 50px;
  /*width: 50%;*/
}

.innernavbar-wrap {
  width: 100%;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

svg{
  width: 100px;
  height: 100px;
  margin: 20px;
  display:inline-block;
}

.empty-table {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 1rem;
}

@media (max-width: 1000px) {
  .mainless {
    display: none;
  }
  .mobile-flex {
    display: flex;
    flex-direction: column;
  }
  .mobile-flex li {
    width: 100%;
  }
  .mobile-flex li button{
    width: 100%;
  }
  .innernavbar-wrap {
    height: 420px;
  }
  .item-wrap {
    width: 100% !important;
  }
  .item {
    width: 100%;
  }
  .p-10 {
    padding: 10px !important;
    margin: 0 !important;
  }
}
</style>