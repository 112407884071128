import Vue from 'vue'
import Vuex from 'vuex'
import modules from '../store/modules';
import axios from "axios";
import router from '../router.js'

Vue.use(Vuex)
// Axios globals
axios.defaults.baseURL = process.env.VUE_APP_ROOT_URL

axios.interceptors.request.use(config => {
  let jwt = localStorage.getItem('token');
  if (jwt) {
    config.headers.Authorization = 'JWT ' + jwt;
  }

  let currentTime = new Date();
  let h = currentTime.getHours();
  let m = currentTime.getMinutes();
  let s = currentTime.getSeconds();

  console.log(`${config.method.toUpperCase()} request sent to API: ${config.url} at ${h}:${m}:${s}`)
  return config
}, error => {
  return Promise.reject(error)
})

axios.interceptors.response.use(null, function (error) {
  if (error.response.status === 401) {
    router.push({
      name: 'logout'
    })
  }
  return Promise.reject(error)
})

export default new Vuex.Store({
  modules,
})