<template>
  <div class="home-section-wrap">
    <section class="home-section" id="home-section">
      <div>
        <div class="innernavbar-wrap p-10" style="padding-bottom: 0 !important;">
          <div class="item-wrap">
            <div class="item left-item">
              <ul>
                <li>
                  <button
                      class="filter-btn transition-all ease-in-out duration-150 transform hover:scale-110 bg-indigo-500 hover:bg-indigo-600 px-5 py-1 rounded-lg max-h-12 whitespace-nowrap"
                      :class="fast_active('perms')"
                      @click="change_fast_filter('perms')">
                    Заявки на регистрацию
                  </button>
                </li>
                <li>
                  <button
                      class="filter-btn transition-all ease-in-out duration-150 transform hover:scale-110 bg-indigo-500 hover:bg-indigo-600 px-5 py-1 rounded-lg max-h-12 whitespace-nowrap"
                      :class="fast_active('users')"
                      @click="change_fast_filter('users')">
                    Активные пользователи
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="folder-mode">
        </div>
      </div>

      <div class="p-10" style="padding-top: 1rem !important;">
        <div class="card">
          <div class="card-header" style="display: flex; justify-content: space-between">
            <h4>Администрирование</h4>
          </div>
          <div class="table-responsive" v-if="fast_filter === 'perms'">
            <table class="table table-hover table-nowrap">
              <thead class="thead-light">
              <tr>
                <th scope="col" class="">ID</th>
                <th scope="col" class="">ФИО</th>
                <th scope="col" class="">Почта</th>
                <th scope="col" class="">Должность</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="perm in this.permissibles" :key="perm.id" :id="'req-tr-' + perm.id"
                  :class="'tr-main ' + getSelectedClass(perm.id, 'p')">
                <td data-label="" class="" @click="showInfoFull(perm)">
                  {{ perm.id }}
                </td>
                <td data-label="" class="" @click="showInfoFull(perm)">
                  {{ perm.last_name }} {{ perm.first_name }} {{ perm.middle_name }}
                </td>
                <td data-label="" class="" @click="showInfoFull(perm)">
                  {{ perm.email }}
                </td>
                <td data-label="" class="" @click="showInfoFull(perm)">
                  {{ perm.position }}
                </td>
              </tr>
              </tbody>
            </table>
            <div v-if="isApplicationsLeft" class="flex justify-content-center align-items-center empty-list-container">
              <img class="empty-list-img" src="../assets/no_data.svg" alt="Empty list">
              <p class="empty-list-text">Заявки отсутствуют</p>
            </div>
          </div>
          <div class="table-responsive" v-else>
            <table class="table table-hover table-nowrap">
              <thead class="thead-light">
              <tr>
                <th scope="col" class="">ID</th>
                <th scope="col" class="">ФИО</th>
                <th scope="col" class="to-hide">Почта</th>
                <th scope="col" class="to-hide">Должность</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="user in this.users" :key="user.id" :id="'req-tr-' + user.id"
                  :class="'tr-main ' + getSelectedClass(user.id, 'u')">
                <td data-label="" class="">
                  {{ user.id }}
                </td>
                <td data-label="" class="">
                  {{ user.last_name }} {{ user.first_name }} <span v-if="user.ouser">{{ user.ouser.middle_name }}</span>
                </td>
                <td data-label="" class="">
                  {{ user.email }}
                </td>
                <td data-label="" class="">
                  <span v-if="user.ouser">{{ user.ouser.position }}</span>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="mt-5">
          <pagination v-if="fast_filter === 'perms'" :pageCount="max_pages_p" @pageClicked="loadPage" />
          <pagination v-else :pageCount="max_pages_u" @pageClicked="loadPage" />
        </div>
      </div>
    </section>
    <section class="right-section scrl-y" id="right-section">
      <div class="card-header flex-space-between font-50"
          style="border-bottom: none; padding-bottom: 0; display: flex; justify-content: space-between; align-items: center">
        <i class="bx bx-chevrons-left"
          id="bx-chevrons"
          @click="hideInfo">
        </i>
        <span v-if="selected.mode" class="card-title"
              style="padding-right: 0; font-size: 30px; margin-bottom: 0 !important; text-align: right">
          Пользователь <a class="custom-link">{{ selected.last_name }} {{ selected.first_name }}</a>
        </span>
      </div>
      <div class="p-5" v-if="selected.mode">
        <div class="p-5 info-card big-card scrl-y img-wrap"
            style="padding-bottom: 0 !important; padding-top: 0 !important;">
          <div class="info-card-item" style="display: flex; justify-content: right">
            <span class="span-title" style="text-align: right; font-style: italic">Заявка создана: {{
                selected.created_datetime_parsed
              }}</span>
          </div>
          <hr>
          <div class="div-item">
            <span class="descripcion">ИИН</span>
            <span class="precio">{{ selected.iin }}</span>
          </div>
          <div class="div-item">
            <span class="descripcion">Фамилия</span>
            <span class="precio">{{ selected.last_name }}</span>
          </div>
          <div class="div-item">
            <span class="descripcion">Имя</span>
            <span class="precio">{{ selected.first_name }}</span>
          </div>
          <div class="div-item">
            <span class="descripcion">Отчество</span>
            <span class="precio">{{ selected.middle_name }}</span>
          </div>
          <div class="div-item">
            <span class="descripcion">Почта</span>
            <span class="precio"><a class="custom-link" :href="'mailto:' + selected.email">{{
                selected.email
              }}</a></span>
          </div>
          <div class="div-item">
            <span class="descripcion">Телефон</span>
            <span class="precio"><a class="custom-link" :href="'tel:' + selected.phone">{{ selected.phone }}</a></span>
          </div>
          <hr>
          <div class="div-item">
            <span class="descripcion">Должность</span>
            <span class="precio">{{ selected.position }}</span>
          </div>
          <div class="div-item">
            <span class="descripcion">Наименование организации</span>
            <span class="precio">{{ selected.company_name }}</span>
          </div>
          <div class="div-item">
            <span class="descripcion">БИН | ИИН организации</span>
            <span class="precio">{{ selected.bin }}</span>
          </div>
          <div class="div-item">
            <span class="descripcion" title="Адресс организации">Адресс организации</span>
            <span class="precio">{{ selected.company_address }}</span>
          </div>
          <hr>
          <div class="input-container form_checkbox_btn"
              v-for="role_i in selected.role" :key="role_i.id">
            <input
                class="input-field text-center"
                type="checkbox"
                checked
                disabled
                :id="'cb_' + role_i.id"
                :name="'cb_' + role_i.id"
            />
            <label class="input-field" :for="'cb_' + role_i.id">{{ role_i.type }}</label>
          </div>
          <hr>
          <div class="flex justify-end mb-2" style="gap: 2rem">
            <button class="transition-all ease-in-out duration-150 transform hover:scale-110 bg-indigo-500 hover:bg-indigo-600 px-5 py-1 rounded-lg text-white" v-if="!selected.accepted && !selected.activated" @click="approve_permissible(selected.id)">Подтвердить аккаунт</button>
            <button class="transition-all ease-in-out duration-150 transform hover:scale-110 bg-indigo-500 hover:bg-indigo-600 px-5 py-1 rounded-lg text-white" style="background: #f36 !important;" v-if="!selected.accepted && !selected.activated" @click="showRejectModal">Отклонить аккаунт</button>
          </div>
        </div>
      </div>
    </section>
    <modal name="reject-modal" :height="400">
      <div class="modal-inner"
           style="display: flex; justify-content: center; flex-direction: column; align-items: center; gap: 1rem">
        <h3 style="text-align: center; padding: 20px">Отклонение заявки</h3>
        <textarea v-model="comment"
                  style="width: 80%; margin: 1rem; padding: .5rem; height: 5rem; border: 1px gray solid; border-radius: .5rem"
                  placeholder="Введите причину отклонения"></textarea>

        <button v-if="reject_comment !== ''" class="btn file-selector"
                style="width: 80%; color: white; background: rgb(255, 51, 102) !important;"
                @click="disapprove_permissible(selected.id)">Отклонить
        </button>
      </div>
    </modal>
  </div>
</template>
<script async>
import Pagination from "../components/shared/Pagination.vue"

export default {
  components: {
    Pagination
  },
  data() {
    return {
      selected: {},
      fast_filter: "perms",
      users_global: [],
      users: [],
      comment: "",
      permissibles_global: [],
      permissibles: [],

      is_IMPL: false,
      is_ABP: false,
      is_APPROVER: false,
      is_RATIFICATOR: false,
      is_ECONOMIST: false,
      is_BOOKER: false,
      is_DZOADM: false,

      current_page_u: 1,
      current_page_p: 1,
      pages_u: [],
      max_pages_u: 1,
      pages_p: [],
      max_pages_p: 1,
    };
  },
  created() {
    this.is_IMPL = this.isIMPL();
    this.is_ABP = this.isABP();
    this.is_APPROVER = this.isAPPROVER();
    this.is_RATIFICATOR = this.isRATIFICATOR();
    this.is_ECONOMIST = this.isECONOMIST();
    this.is_BOOKER = this.isBOOKER();
    this.is_DZOADM = this.isDZOADM();
    let a = {
      class: 'current',
      number: 1,
      // search_contract: ""
    }

    this.pages_u.push(a);
    this.pages_p.push(a);
    this.getAllPerms(1);
    this.getAllUsers(1);
  },
  mounted() {
  },
  computed: {
    isApplicationsLeft() {
      if (this.permissibles.length === 0) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    loadPage(pageNum) {
      console.log('This page is clicked ' + pageNum)
      this.getAllUsers(pageNum)
    },
    showCreatePermsModal() {
      this.$modal.show("create-perms-modal");
    },
    showInfo() {
      console.log('showInfo');
      let bla = document.getElementById('home-section');
      bla.classList.toggle('open', true);
      let right_arrow = document.getElementById('bx-chevrons');
      right_arrow.classList.toggle('bx-chevrons-left', false);
      right_arrow.classList.toggle('bx-chevrons-right', true);

      let to_hide_elements = document.getElementsByClassName('to-hide');

      Array.prototype.forEach.call(to_hide_elements, function (el) {
        if (el) {
          el.classList.toggle('hiden-element', true);
        }
      });

      let another_selected = document.getElementsByClassName('selected_element');
      Array.prototype.forEach.call(another_selected, function (el) {
        el.classList.toggle('selected_element', false);
      });

      this.selected['mode'] = true;
      if (this.fast_filter === 'perms') {
        this.selected['p_u'] = 'p';
      } else {
        this.selected['p_u'] = 'u';
      }
    },
    hideInfo() {
      console.log('hideInfo');
      let bla = document.getElementById('home-section');
      bla.classList.toggle('open', false);
      let right_arrow = document.getElementById('bx-chevrons');
      right_arrow.classList.toggle('bx-chevrons-right', false);
      right_arrow.classList.toggle('bx-chevrons-left', true);

      let to_hide_elements = document.getElementsByClassName('to-hide');

      Array.prototype.forEach.call(to_hide_elements, function (el) {
        if (el) {
          el.classList.toggle('hiden-element', false);
        }
      });

      let another_selected = document.getElementsByClassName('selected_element');
      Array.prototype.forEach.call(another_selected, function (el) {
        el.classList.toggle('selected_element', false);
      });
      this.selected = {};
      this.selected['mode'] = false;
    },
    showInfoFull(req) {
      if (this.selected === req) {
        this.hideInfo();
      } else {
        this.selected = req;
        this.showInfo();
      }
    },
    // should be refactored
    isIMPL() {
      return localStorage.getItem('roles').includes(1);
    },
    isABP() {
      return localStorage.getItem('roles').includes(2);
    },
    isAPPROVER() {
      return localStorage.getItem('roles').includes(3);
    },
    isRATIFICATOR() {
      return localStorage.getItem('roles').includes(4);
    },
    isECONOMIST() {
      return localStorage.getItem('roles').includes(5);
    },
    isBOOKER() {
      return localStorage.getItem('roles').includes(6)
    },
    isDZOADM() {
      return localStorage.getItem('roles').includes(7);
    },
    get_current_class_u(page_number) {
      if (page_number === this.current_page_u) {
        return "current";
      } else {
        return '';
      }
    },
    get_current_class_p(page_number) {
      if (page_number === this.current_page_p) {
        return "current";
      } else {
        return '';
      }
    },
    prettyPrice(price) {
      if (typeof price == "number") {
        return price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
      } else {
        return price;
      }
    },
    getAllPerms(page) {
      if (page > 0 && page < this.max_pages_p + 1) {
        this.current_page_p = page;
        let formData = {
          page: page,
        };

        this.$store.dispatch("getAllPerms", formData).then((response) => {
          this.permissibles = response.data.results;
          this.permissibles_global = response.data.results;
          this.all_permissibles = response.data;
          this.max_pages_p = parseInt(this.all_permissibles.count / 10) + 1;
        }).catch((error) => {
          this.$store.dispatch('addNotification', {
            type: 'error',
            message: error.response.data.detail,
          })
        });

        this.pages_p.forEach(function (item_page, index_page) {
          console.log(index_page);
          item_page.class = '';
        });
        this.pages_p[page - 1].class = 'current';
      }
    },
    getAllUsers(page) {
      if (page > 0 && page < this.max_pages_u + 1) {
        this.current_page_u = page;
        let formData = {
          page: page,
        };

        this.$store.dispatch("getAllUsers", formData).then((response) => {
          this.users = response.data.results;
          this.users_global = response.data.results;
          this.all_users = response.data;
          this.max_pages_u = parseInt(this.all_users.count / 10) + 1;
        }).catch((error) => {
          this.$store.dispatch('addNotification', {
            type: 'error',
            message: error.response.data.detail,
          })
        });
      }
    },
    showRejectModal() {
      this.$modal.show("reject-modal");
    },
    approve_permissible(permissible_id) {

      this.$store.dispatch("approve_permissible", permissible_id).then((response) => {
        console.log(response);
        this.$store.dispatch('addNotification', {
            type: 'success',
            message: response.data.detail,
        });

        if (response.data.detail === 'Профиль активирован') {
          this.$router.go();
        }
      }).catch((error) => {
        this.$store.dispatch('addNotification', {
          type: 'error',
          message: error.response.data.detail,
        })
      });
    },
    disapprove_permissible(permissible_id) {
      let formData = {
        perm_id: permissible_id,
        comment: this.comment
      };

      this.$store.dispatch("disapprove_permissible", formData).then((response) => {
        console.log(response);
        this.$store.dispatch('addNotification', {
            type: 'success',
            message: response.data.detail,
        });
        this.$router.go();
      }).catch((error) => {
        this.$store.dispatch('addNotification', {
          type: 'error',
          message: error.response.data.detail,
        })
      });
    },
    fast_active(to_filter) {
      if (to_filter.toString() === this.fast_filter.toString()) {
        return 'active';
      }
      return '';
    },
    change_fast_filter(curr_filter) {
      this.fast_filter = curr_filter;
    },
    getSelectedClass(req_id, p_u) {
      if (this.selected.mode) {
        if (this.selected.id === req_id && p_u === 'p' && this.selected.p_u === 'p') {
          return 'selected_element';
        }
        if (this.selected.id === req_id && p_u === 'u' && this.selected.p_u === 'u') {
          return 'selected_element';
        }
      }
      return '';
    },
    checkBxFolderOpen(req) {
      if (req.open) {
        return 'bxs-folder-open'
      } else {
        return 'bxs-folder'
      }
    },
  },
};
</script>

<style scoped>
@import "../assets/css/webpixels.css";

.form_checkbox_btn {
  display: flex;
  justify-content: center;
  margin-right: 10px;
}

.form_checkbox_btn input[type=checkbox] {
  display: none;
}

.form_checkbox_btn label {
  display: inline-block;
  cursor: pointer;
  width: 100% !important;
  text-align: center;
  user-select: none;
}

/* Checked */
.form_checkbox_btn input[type=checkbox] + label {
  text-decoration: line-through;
}

/* Checked */
.form_checkbox_btn input[type=checkbox]:checked + label {
  background: #ffe0a6;
  text-decoration: none;
}

/* Hover */
.form_checkbox_btn label:hover {
  color: #666;
}

.div-item {
  height: 40px;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}

.descripcion {
  /*background-color: green;*/
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.descripcion:after {
  content: " ........................................................................................................................................................................................................................................................................................."
}

.precio {
  /*background-color: red;*/
  flex-shrink: 0;
}

.info-card-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px
}

.embed_full {
  width: 100%;
  height: 30vh;
}

.input-field {
  width: 100%;
  padding: 10px;
  border: none;
  outline: none;
  margin: 0.5rem 0 1rem;
  border-radius: 10px;
}

.btn-blue {
  background: #23468c;
}

.req-name {
  max-width: 300px !important;
}

@keyframes progress {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.folder-mode {
  width: 100%;
  display: flex;
  justify-content: right;
  padding-right: 40px
}

@keyframes wave {
  50% {
    transform: scale(0.9);
  }
}

.item-wrap {
  display: flex;
  justify-content: space-between;
}

.left-item ul {
  display: flex;
  justify-content: left;
  gap: 1rem;
}

@media screen and (max-width: 1100px) {
  .left-item {
    flex-direction: column;
    gap: 0.2rem;
    height: 100px !important;
  }

  .ul-li {
    height: 30px !important;
  }
}

.item {
  height: 50px;
  width: 50%;
}

.innernavbar-wrap {
  width: 100%;
}

.ul-li {
  list-style-type: none;
  width: 250px;
  height: 30px;
  border-radius: 10px;
  background: #d9e3f7 !important;
  text-align: center;
  cursor: pointer;
  color: #666 !important;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  border: 1px solid #d9e3f7;
}

.ul-li:hover {
  background: #2b6de8 !important;
  color: white !important;
}

.active {
  color: white !important;
  background: #23468c !important;
}



/* New Style */
.empty-list-container {
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
}

.empty-list-img {
  width: 300px;
  height: 300px;
}

.empty-list-text {
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
}

</style>