<template>
    <div>
        <Sidebar />
        <div>
          <!-- <transition name="router-anim"> -->
                <slot />
            <!-- </transition> -->
        </div>
        <NotificationList />
    </div>
</template>

<script>
import Sidebar from "@/components/Sidebar";
import NotificationList from "@/components/NotificationList.vue"
    export default {
        components: {
            Sidebar,
            NotificationList
        }
    }
</script>

<style>
.text-bold {
  font-weight: bold;
}

.btn-blue-success {
  border-radius: .5rem;
  background: #00aacc !important;
}

.doc-upload {
  display: none;
}

.act-status {
  font-size: .8rem !important;
  padding: .5rem !important;
}

.width-300 {
  max-width: 300px;
  min-width: 300px;
}

.custom-table tr td {
  padding: 1.2rem !important;
}
.custom-table tr td:last-child {
  padding: .8rem !important;
}
.custom-table tr th {
  padding: 1.2rem !important;
}

.file-selector {
  cursor: pointer;
  background-color: rgba(2, 34, 70, 0.7) !important;
  width: 100%
}

.font-50 {
  font-size: 50px;
  color: #536181
}

.font-30 {
  font-size: 40px;
  color: #536181
}

.loader {
  position: fixed;
  top: calc(50% - 60px);
  left: calc(50% - 60px);
  z-index: 99;
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/***** Global Slide *****/
.slide-right, .slide-left {
  width: 100%;
}

.blocked-elem {
  pointer-events: none;
  opacity: 0.4;
}

/***** Slide Right *****/
.slide-right {
  animation: 1s slide-right;
}

@keyframes slide-right {
  from {
    margin-left: -10%;
  }
  to {
    margin-left: 0%;
  }
}

/***** Slide Left *****/
.slide-left {
  animation: 1s slide-left;
}

@keyframes slide-left {
  from {
    margin-left: 10%;
  }
  to {
    margin-left: 0;
  }
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.flex-start-column {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.flex-space-between {
  display: flex;
  justify-content: space-between;
}

.div-full {
  width: 100% !important;
}

.div-half {
  width: 60% !important;
}

.gap-1r {
  gap: 1rem;
}

.gap-2r {
  gap: 2rem;
}

.gap-half {
  gap: .5rem;
}

.modal-title {
  text-align: center;
  padding: 1rem;
}

.modal-info {
  padding: 1rem;
  overflow: auto;
}

.custom-link {
  position: relative;
  display: inline-block;
  color: royalblue;
  cursor: pointer;
  background: linear-gradient(to right, midnightblue, midnightblue 50%, royalblue 50%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% 100%;
  background-position: 100%;
  transition: background-position 275ms ease-in-out .1s;
  text-decoration: none;
}

.selected_element {
  background: #536181 !important;
  color: white !important;
}

.selected_element_new {
  background: #536181 !important;
  color: white !important;
}

.selected_element_abp {
  background: rgba(227, 227, 227, 0.54) !important;
}

.custom-link:hover {
  background-position: 0 100%;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.selected_element a {
  color: white !important;
  -webkit-text-fill-color: white;
}

.selected_element a:hover {
  border-bottom: solid 1px white;
}

.selected_element_new a {
  color: white !important;
  -webkit-text-fill-color: white;
}

.selected_element_new a:hover {
  border-bottom: solid 1px white;
}

.selected_element_abp a:hover {
  border-bottom: solid 1px white;
}

.scrl-x {
  overflow-x: auto;
}

.scrl-x::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.scrl-x::-webkit-scrollbar-track {
  border-radius: 15px;
}

.scrl-x::-webkit-scrollbar-thumb {
  border-radius: 100px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.scrl-y {
  overflow-y: auto !important;
}

.scrl-y::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.scrl-y::-webkit-scrollbar-track {
  border-radius: 15px;
}

.scrl-y::-webkit-scrollbar-thumb {
  border-radius: 100px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.scrl-y-big {
  overflow-y: auto;
}

.scrl-y-big::-webkit-scrollbar {
  width: 13px;
  height: 13px;
}

.scrl-y-big::-webkit-scrollbar-track {
  border-radius: 15px;
}

.scrl-y-big::-webkit-scrollbar-thumb {
  border-radius: 100px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.bx-btn {
  transition: all 0.5s ease;
}

.bx-btn:hover {
  transform: scale(1.1);
}

.home-section-landing {
  position: relative;
  background: #e4e9f7;
  min-height: 100vh;
  top: 0;
  width: 100%;
  transition: all 0.5s ease;
  margin-right: 0;
  /* z-index: 2; */
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.home-section {
  position: relative;
  background: #e4e9f7;
  min-height: 100vh;
  top: 0;
  left: 78px;
  width: calc(100% - 178px);
  transition: all 1s ease;
  margin-right: 0;
}

.home-section-wrap {
  background: white;
}

.home-section-wrap-landing {
  position: relative;
  background: white;
  left: 78px;
  width: calc(100% - 78px);
  transition: all 1s ease;
}

.home-section-wrap-landing2 {
  position: relative;
  background: white;
  left: 0;
  width: calc(100%);
  transition: all 1s ease;
}

.right-section {
  position: fixed;
  height: 100vh;
  background: #f8fafb;
  min-height: 100vh;
  top: 0;
  left: calc(100% - 100px);
  overflow: scroll;
  width: 100px;
  transition: all 1s ease;
}

.home-section-wrap.open > .home-section {
  left: 250px;
  width: calc(100% - 350px);
}

.sidebar.open ~ .home-section-wrap-landing {
  position: relative;
  left: 250px;
  width: calc(100% - 250px);
}

.home-section .text {
  display: inline-block;
  color: #11101d;
  font-size: 25px;
  font-weight: 500;
  margin: 18px;
}

.home-section.open ~ .right-section {
  left: calc(100% - 800px);
  width: 800px;
}

.hiden-element {
  display: none;
}

.home-section.open {
  width: calc(100% - 878px);
}

.home-section-wrap.open > .home-section.open {
  width: calc(100% - 1050px);
}

#pagination {
  display: inline-block;
  float: right;
  vertical-align: middle;
  border-radius: 4px;
  padding: 1px 2px 4px 2px;
  background-color: #e4e9f7;
  background-image: -webkit-linear-gradient(top, #DBDBDB, #E2E2E2);
  background-image: -moz-linear-gradient(top, #DBDBDB, #E2E2E2);
  background-image: -ms-linear-gradient(top, #DBDBDB, #E2E2E2);
  background-image: -o-linear-gradient(top, #DBDBDB, #E2E2E2);
}

#pagination a, #pagination i {
  display: inline-block;
  vertical-align: middle;
  width: 22px;
  color: #7D7D7D;
  text-align: center;
  font-size: 10px;
  padding: 3px 0 2px 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

#pagination a {
  margin: 0 2px 0 2px;
  border-radius: 4px;
  border: 1px solid #E3E3E3;
  cursor: pointer;
  box-shadow: inset 0 1px 0 0 #FFF, 0 1px 1px #666;
  text-shadow: 0 1px 1px #FFF;
  background-color: #ffffff;
  background-image: -webkit-linear-gradient(top, #F3F3F3, #D7D7D7);
  background-image: -moz-linear-gradient(top, #F3F3F3, #D7D7D7);
  background-image: -ms-linear-gradient(top, #F3F3F3, #D7D7D7);
  background-image: -o-linear-gradient(top, #F3F3F3, #D7D7D7);
}

#pagination i {
  margin: 0 3px 0 3px;
}

#pagination a.current {
  border: none;
  background-color: #23468c;
  color: white;
  background-image: -webkit-linear-gradient(top, #D0D0D0, #EBEBEB);
  background-image: -moz-linear-gradient(top, #D0D0D0, #EBEBEB);
  background-image: -ms-linear-gradient(top, #D0D0D0, #EBEBEB);
  background-image: -o-linear-gradient(top, #D0D0D0, #EBEBEB);
}

/* Modal Styles */
.modal-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  gap: 1rem;
  padding: 1rem;
  height: 100%;
}

.modal-inner form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

hr {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
  color: lightgray !important;
}

h4 {
  font-size: 20px !important;
}

h3 {
  font-size: 25px !important;
}

.filter-btn {
  background: #d9e3f7 !important;
  color: #666;
}

.active {
  background: #23468c !important;
  color: #fff;
}

.search-btn {
  background: #23468c;
  color: #fff;
}

@media (max-width: 1000px) {
  .home-section {
    left: 0 !important;
    width: 100% !important;
  }
  .right-section {
    display: none;
  }
}

</style>