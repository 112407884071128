<template>
  <div class="home-section-wrap">
    <section class="home-section" id="home-section">
      <div>
        <div class="p-10" style="padding-bottom: 0 !important;">
          <h3>Договор:
            <a :href="'/contracts/' + this.contract.id">
              {{ this.contract.number }} {{ this.contract.name }}
            </a>
          </h3>
        </div>
        <div class="innernavbar-wrap p-10" style="padding-bottom: 0 !important;">
          <div class="item-wrap">
            <div class="item left-item">
              <ul class="mobile-flex">
                <li>
                  <button class="filter-btn transition-all ease-in-out duration-150 transform hover:scale-110 bg-indigo-500 hover:bg-indigo-600 px-5 py-1 rounded-lg max-h-12 whitespace-nowrap"
                  :class="fast_active('1')"
                  @click="change_fast_filter('1')">
                    Информация
                  </button>
                </li>
                <li>
                  <button class="filter-btn transition-all ease-in-out duration-150 transform hover:scale-110 bg-indigo-500 hover:bg-indigo-600 px-5 py-1 rounded-lg max-h-12 whitespace-nowrap"
                  :class="fast_active('2')"
                  @click="change_fast_filter('2')">
                    История
                  </button>
                </li>
                <li>
                  <button class="filter-btn transition-all ease-in-out duration-150 transform hover:scale-110 bg-indigo-500 hover:bg-indigo-600 px-5 py-1 rounded-lg max-h-12 whitespace-nowrap"
                  :class="fast_active('3')"
                  @click="change_fast_filter('3')">
                    Акты
                  </button>
                </li>
                <li>
                  <button class="filter-btn transition-all ease-in-out duration-150 transform hover:scale-110 bg-indigo-500 hover:bg-indigo-600 px-5 py-1 rounded-lg max-h-12 whitespace-nowrap"
                  :class="fast_active('4')"
                  @click="change_fast_filter('4')">
                    Согласующие
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="p-10" v-if="this.fast_filter === '1'">
        <div class="grided">
          <div class="card p-10">
            <div class="event-left-part">
              <div style="display: flex; justify-content: space-between;">
                <h4 style="margin-bottom: 10px">Информация по договору</h4>
                <!--                <button v-if="contract.status === 'В РАБОТЕ' && is_ABP && !contract.is_published || contract.status === 'ВОЗВРАТ' && is_ABP && !contract.is_published"-->
                <!--                        class="btn-success btn-blue" style="border-radius: 5px" @click="save_old_gpz">Сохранить-->
                <!--                </button>-->
                <!--                <button class="badge bg-soft-danger text-danger" v-if="contract.status === 'КМГ' && is_SMC && !contract.is_published"-->
                <!--                        @click="showDeclineModalMain">Отправить на доработку-->
                <!--                </button>-->
              </div>
              <div class="div-item">
                <div class="descripcion" v-if="!contract.is_mirror">Поставщик</div>
                <div class="descripcion" v-else>Заказчик</div>
                <div class="precio" style="font-weight: bold">
                  <span v-if="contract.impl_company">{{ contract.impl_company.name }}</span>
                  <!--                  <select v-if="contract.status === 'В РАБОТЕ' && is_ABP && !contract.is_published || contract.status === 'ВОЗВРАТ' && is_ABP && !contract.is_published"-->
                  <!--                          class="select-item" style="width: 200px; padding-left: 10px" v-model="selected_old_gpz">-->
                  <!--                    <option :value="this.empty_old_gpz">Отсутствует</option>-->
                  <!--                    <option v-for="old_gpz in old_gpz_list" :key="old_gpz.id" :value="old_gpz">-->

                  <!--                    </option>-->
                  <!--                  </select>-->
                </div>
              </div>
              <div class="div-item">
                <div class="descripcion" v-if="!contract.is_mirror">БИН поставщика</div>
                <div class="descripcion" v-else>БИН заказчика</div>
                <div class="precio">
                  <span v-if="contract.impl_company">{{ contract.impl_company.bin }}</span>
                </div>
              </div>
              <div class="div-item">
                <div class="descripcion" v-if="!contract.is_mirror">Адрес поставщика</div>
                <div class="descripcion" v-else>Адрес заказчика</div>
                <div class="precio">
                  <span v-if="contract.impl_company">{{ contract.impl_company.address }}</span>
                </div>
              </div>
              <hr>
              <div class="div-item">
                <div class="descripcion" v-if="!contract.is_mirror">Заказчик</div>
                <div class="descripcion" v-else>Поставщик</div>
                <div class="precio" style="font-weight: bold">
                  <span v-if="contract.abp">{{ contract.abp.ouser.company.name }}</span>
                </div>
              </div>
              <div class="div-item">
                <div class="descripcion" v-if="!contract.is_mirror">БИН заказчика</div>
                <div class="descripcion" v-else>БИН поставщика</div>
                <div class="precio">
                  <span v-if="contract.abp">{{ contract.abp.ouser.company.bin }}</span>
                </div>
              </div>
              <div class="div-item">
                <div class="descripcion" v-if="!contract.is_mirror">Адрес заказчика</div>
                <div class="descripcion" v-else>Адрес поставщика</div>
                <div class="precio">
                  <span v-if="contract.abp">{{ contract.abp.ouser.company.address }}</span>
                </div>
              </div>
              <hr>
              <div class="div-item">
                <div class="descripcion" v-if="!contract.is_mirror">Администратор бюджетной программы</div>
                <div class="descripcion" v-else>Администратор подрядной органазации</div>
                <div class="precio">
                  <span v-if="contract.abp">{{ contract.abp.ouser.full_name }}</span>
                </div>
              </div>
              <div class="div-item" v-if="contract.is_mirror">
                <div class="descripcion">Администратор со стороны заказчика</div>
                <div class="precio">
                  <span v-if="contract.impl_abp">{{ contract.impl_abp.ouser.full_name }}</span>
                </div>
              </div>
              <!--              <div class="div-item">-->
              <!--                <div class="descripcion">Дата создания в системе</div>-->
              <!--                <div class="precio">-->
              <!--                  {{ contract.created_date }}-->
              <!--                </div>-->
              <!--              </div>-->
              <div class="div-item">
                <div class="descripcion">Период действия</div>
                <div class="precio">
                  {{ contract.date_from }} → {{ contract.date_to }}
                </div>
              </div>
              <h5 style="text-align: center">Дополнительные файлы</h5>
              <div v-if="this.contract.additional_files">
                <a :download="additional_file.name" target="_blank" :href="additional_file.file"
                   v-for="(additional_file, index) in this.contract.additional_files"
                   :key="additional_file.id">{{ index + 1 }}. {{ additional_file.name }}, </a>
              </div>
            </div>
          </div>
          <div class="card p-10">
            <div class="event-left-part">
              <div style="display: flex; justify-content: space-between;">
                <h4 style="margin-bottom: 10px">Основной файл</h4>
              </div>
              <embed v-if="contract.main_file" :src="contract.main_file.file" class="embed_full"/>
            </div>
          </div>
        </div>
      </div>
      <div class="p-10" v-if="this.fast_filter === '2'">
        <div class="bg-gradient_solid">
          <div class="container">
            <div class="section-header">
              <h2>История / действия</h2>
              <hr>
            </div>
            <div class="steps">
              <div :class="'steps-container ' + slideClass(index)" v-for="(action, index) in this.contract.actions"
                   :key="action.id" :id="'action-tr-' + action.id">
                <div class="content" v-if="true" style="min-width: 85%">
                  <!--                  is_IMPL && !action.private || !is_IMPL-->
                  <h2>{{ action.user.first_name }} {{ action.user.last_name }} / {{ action.datetime_point_parsed }}</h2>
                  <p v-if="action.name !== 'Договор редактирован'">{{ action.name }}. {{ action.comment }}</p>
                  <p v-if="action.name === 'Договор редактирован'">{{ action.name }}.</p>
                  <div v-if="action.name === 'Договор редактирован'">
                    <p v-for="(action_row, index) in action.comment.split(';')" :key="index">
                      <span v-if="index === action.comment.split(';').length - 1">{{ action_row }}.</span>
                      <span v-else>{{ action_row }}, </span>
                    </p>
                  </div>
                </div>
                <div class="content" v-else style="min-width: 85%">
                  <h2>Информация скрыта настройками приватности</h2>
                  <p>Информация скрыта настройками приватности, для подробной информации обратитесь к администратору
                    системы</p>
                </div>
                <i class="step-line"></i>
                <div class="date">{{ action.short_date }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="p-10" v-if="this.fast_filter === '3'">
        <div class="card">
          <div class="table-responsive">
            <table class="table table-hover table-nowrap custom-table">
              <thead class="thead-light">
              <tr>
                <th scope="col" class="">ID</th>
                <th scope="col" class="mainless">№</th>
                <th scope="col" class="mainless to-hide">Наименование</th>
                <th scope="col" class="">Дата</th>
                <th scope="col" class="mainless">Тип</th>
                <th scope="col" class="">Сумма</th>
                <th scope="col" class="">Статус</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="act in this.contract.acts" :key="act.id" :id="'req-tr-' + act.id">
                <td data-label="" class="">
                  <a class="custom-link" :href="'/acts/' + act.id">
                    {{ act.id }}
                  </a>
                </td>
                <td data-label="" class="mainless">
                  {{ act.number }}
                </td>
                <td data-label="" class="mainless to-hide">
                  {{ act.name }}
                </td>
                <td data-label="" class="">
                  {{ act.act_date }}
                </td>
                <td data-label="" class="mainless">
                  {{ act.type }}
                </td>
                <td data-label="" class="">
                  {{ prettyPrice(act.total_with_nds) }} тг
                </td>
                <td data-label="" class="">
                  <span :class="act.color + ' act-status'">{{ act.status }}</span>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="mt-5">
            <p class="text-center">Сумма согласованных актов: <strong style="font-size: 20px">{{ prettyPrice(this.contract.sum_total) }} тг</strong></p>
            <p class="text-center" style="font-style: italic">{{ this.contract.sum_total_str }}</p>
          </div>
        </div>
      </div>
      <div class="p-10" v-if="this.fast_filter === '4'">
        <div class="card">
          <div class="table-responsive">
            <table class="table table-hover table-nowrap">
              <thead class="thead-light">
              <tr>
                <th scope="col" class="scrl-x">№</th>
                <th scope="col" class="scrl-x">ФИО</th>
                <th scope="col" class="scrl-x">Должность</th>
                <th scope="col" class="scrl-x">Организация</th>
                <th scope="col" class="scrl-x">Контакты</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(approver, index) in this.contract.approvers" :key="approver.id" :id="'req-tr-' + approver.id"
                  :class="'tr-main'">
                <td data-label="" class="scrl-x">
                  {{ index + 1 }}
                </td>
                <td data-label="" class="scrl-x">
                  {{ approver.const_last_name }} {{ approver.const_first_name }} {{ approver.const_middle_name }}
                </td>
                <td data-label="" class="scrl-x">
                  {{ approver.const_position }}
                </td>
                <td data-label="" class="scrl-x">
                  {{ approver.const_company_name }}
                </td>
                <td data-label="" class="scrl-x">
                  Тел: {{ approver.user.ouser.phone || 'Отсутствует' }} <br> Email: {{ approver.user.email || 'Отсутствует' }}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>


    </section>
    <section class="right-section flex-column-animated" id="right-section"
             style="background: #e4e9f7 !important; overflow: hidden">
      <button v-if="curr_user === contract.abp" class="section-btn" title="Редактировать" @click="showEditContractModal"><i
          class="material-icons section-btn-bx"
          style="background: #1da1f2;">edit</i></button>
      <!--      <button class="section-btn" title="Архивировать"><i class="bx bx-archive-in section-btn-bx" style="background: #f5f595;"></i></button>-->
      <!--      <button class="section-btn" title="Проверить наличие в SAP"><i class="bx bx-arrow-to-right section-btn-bx" style="background: #0c8;"></i></button>-->
      <!--      <button class="section-btn" title="Согласовать"><i class="bx bx-check section-btn-bx" style="background: #0c8;"></i></button>-->
      <!--      <button class="section-btn" title="Отклонить"><i class="bx bx-block section-btn-bx" style="background: #f36;"></i></button>-->
      <button v-if="is_ABP && is_OWNER && contract.acts.length === 0" class="section-btn"
              title="Удалить" @click="deleteContract">
        <i class="material-icons section-btn-bx" style="background: #f36;">delete</i>
      </button>
      <!--      <button class="section-btn" title="Скачать историю согласования"><i class="bx bx-history section-btn-bx" style="background: #ff8c00;"></i></button>-->
      <button class="section-btn" title="Скачать оригинал договора" @click="download_contract" v-if="this.contract.main_file">
        <a hidden id="download_contract" :download="this.contract.main_file.name" target="_blank" :href="this.contract.main_file.file"></a>
        <i class="material-icons section-btn-bx" style="background: #23468c;">download</i>
      </button>
      <modal name="edit-contract-modal" :height="800">
        <form method="POST" id="edit-contract-form" @submit.prevent="submitEditForm">
          <div class="modal-inner" style="text-align: left; width: inherit">
            <h3 class="modal-title" style="padding-bottom: 0">Редактирование договора</h3>
            <div class="modal-info" style="width: inherit">
              <div style="width: 100%; padding-right: 1rem; text-align: right">
                В системе SAP
                <i v-if="contract.new_contract_sap" class="material-icons"
                   style="font-size: 20px; color: green">check</i>
                <i v-else class="material-icons" style="font-size: 20px; color: red">block</i>
              </div>
              <input required class="input-field" style="border: 1px solid whitesmoke"
                     placeholder="Введите номер договора" v-model="contract.new_contract_number"
                     @change="checkInSapSelected">
              <input required class="input-field" style="border: 1px solid whitesmoke"
                     placeholder="Введите наименование / предмет договора" v-model="contract.new_contract_name">
              <select class="input-field" style="border: 1px solid whitesmoke" v-model="contract.new_contract_impl"
                      required>
                <option value="">Выберите поставщика работ / услуг</option>
                <option v-for="company in this.companies" :key="company.id" :value="company.id">
                  {{ company.name }}
                </option>
              </select>
              <select class="input-field" style="border: 1px solid whitesmoke" v-model="contract.new_contract_abp" v-if="is_DZOADM"
                      required>
                <option value="">Выберите администратора бюджетной программы</option>
                <option v-for="abp_ouser in this.abp_list" :key="abp_ouser.id" :value="abp_ouser.id">
                  {{ abp_ouser.full_name.toUpperCase() }}
                </option>
              </select>
              <div style="display: grid; grid-template-columns: repeat(2, 1fr)">
                <span class="input-field">Введите дату начала</span>
                <input type="date" class="input-field" style="border: 1px solid whitesmoke"
                       v-model="contract.new_contract_date_from" required>
              </div>
              <div style="display: grid; grid-template-columns: repeat(2, 1fr)">
                <span class="input-field">Введите дату завершения</span>
                <input type="date" class="input-field" style="border: 1px solid whitesmoke"
                       v-model="contract.new_contract_date_to" required>
              </div>
              <div>
                <div v-if="contract.main_file" style="text-align: center">
                  Основной файл:
                  <a :download="contract.main_file.name" target="_blank" :href="contract.main_file.file">
                    {{ contract.main_file.name }}
                  </a>
                </div>
                <label class="btn btn-dark file-selector input-field" for="my-file-selector-edit">
                  <input id="my-file-selector-edit" type="file" name="file" class="doc-upload" ref="file_main_edit"
                         onchange="document.getElementById('upload-file-info-div-edit').removeAttribute('hidden');
                             document.getElementById('upload-file-info-edit').innerText=this.files[0].name">
                  Заменить скан оригинала (PDF)
                </label>
                <div id="upload-file-info-div-edit" hidden style="text-align: right">Выбран файл: <span
                    class='label label-info' id="upload-file-info-edit"></span></div>
              </div>
              <div>
                <label class="btn btn-dark file-selector input-field" for="my-files-selector-edit">
                  <input id="my-files-selector-edit" type="file" name="files" class="doc-upload" multiple
                         ref="files_additional_edit"
                         onchange="document.getElementById('upload-files-info-div-edit').removeAttribute('hidden');
                             document.getElementById('upload-files-info-edit').innerText=this.files.length">
                  Заменить дополнительные файлы
                </label>
                <div id="upload-files-info-div-edit" hidden style="text-align: right">Выбрано файлов: <span
                    class='label label-info' id="upload-files-info-edit"></span></div>
              </div>
            </div>
            <div style="position: absolute; bottom: 0; width: 100%; padding: 1rem">
              <button class="btn-success input-field" style="border-radius: 5px;" type="submit" v-if="is_ABP">
                Сохранить
              </button>
            </div>
          </div>
        </form>
      </modal>
    </section>
  </div>
</template>
<script async>

import router from "../router";

export default {
  components: {},
  data() {
    return {
      fast_filter: "1",
      contract: {},
      curr_user: {},
      is_Impl: false,
      is_SMC: false,
      is_ABP: false,
      is_OWNER: false,
      is_DZOADM: false,
      companies: [],
      abp_list: [],
    };
  },
  created() {
    this.getContractDetail();
    this.is_Impl = this.isImpl();
    this.is_SMC = this.isSMC();
    this.is_ABP = this.isABP();
    this.is_DZOADM = this.isDZOADM();
    this.getAllImplCompanies();
    this.getAllABPList();
    this.current_user();
  },
  methods: {
    current_user() {
      this.curr_user = JSON.parse(localStorage.getItem('user'));
    },
    isOWNER() {
      let user = JSON.parse(localStorage.getItem('user'));
      return user['id'] === this.contract.abp.id;
    },
    download_contract() {
      let a_link = document.getElementById('download_contract');
      a_link.click();
    },
    deleteContract() {
      this.$store.dispatch('deleteContract', this.$route.params.id)
          .then((response) => {
            console.log(response);
            router.push({name: 'contracts'});
          })
          .catch((error) => {
            this.$store.dispatch('addNotification', {
              type: 'error',
              message: error.response.data.detail,
            })
          });
    },
    submitEditForm() {
      let formData = new FormData();
      console.log('submitEditForm');
      console.log(this.$refs['files_additional_edit'].files);

      let file = this.$refs['file_main_edit'].files[0];
      formData.append('file_main_edit', file);

      for (let i = 0; i < this.$refs['files_additional_edit'].files.length; i++) {
        let file = this.$refs['files_additional_edit'].files[i];
        formData.append('files_additional-' + i, file);
      }

      const new_contract = JSON.stringify(this.contract);
      formData.append('new_contract', new_contract);
      formData.append('new_contract_id', this.contract.id);

      this.$store.dispatch("submitEditForm", formData)
          .then((response) => {
            console.log('response');
            console.log(response);
            if (response.data.new_contract_id) {
              this.$store.dispatch('addNotification', {
                  type: 'success',
                  message: 'Договор успешно редактирован',
              })
              this.$router.go()
            } else {
              this.$store.dispatch('addNotification', {
                  type: 'error',
                  message: 'Заполните все поля корректно. ' + response.data.detail,
              })
              this.$router.go()
            }
          })
          .catch((error) => {
            this.$store.dispatch('addNotification', {
              type: 'error',
              message: error.response.data.detail,
            })
          });
    },
    getAllABPList() {
      this.$store.dispatch("getAllABPList").then((response) => {
        this.abp_list = response.data;
      }).catch((error) => {
        this.$store.dispatch('addNotification', {
          type: 'error',
          message: error.response.data.detail,
        })
      });
    },
    getAllImplCompanies() {
      this.$store.dispatch("getAllImplCompanies").then((response) => {
        this.companies = response.data;
      }).catch((error) => {
        this.$store.dispatch('addNotification', {
          type: 'error',
          message: error.response.data.detail,
        })
      });
    },
    checkInSapSelected() {
      let formData = {
        number: this.contract.new_contract_number,
      };
      this.$store.dispatch("checkInSap", formData).then((response) => {
        if (response.data.in_sap) {
          this.contract.new_contract_sap = response.data.in_sap;
          this.$store.dispatch('addNotification', {
            type: 'success',
            message: 'Договор успешно найден в системе SAP',
          })
        } else {
          this.contract.new_contract_sap = false;
          this.$store.dispatch('addNotification', {
            type: 'warning',
            message: 'Договор не найден в системе SAP',
          })
        }
      }).catch((error) => {
        console.log(error);
        this.contract.new_contract_sap = false;
        this.$store.dispatch('addNotification', {
          type: 'warning',
          message: 'Договор не найден в системе SAP',
        })
      });
    },
    showEditContractModal() {
      this.$modal.show("edit-contract-modal");
    },
    prettyPrice(price) {
      if (typeof price == "number") {
        return price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
      } else {
        return price;
      }
    },
    showDeclineModal() {
      this.$modal.show("decline-modal");
    },
    slideClass(index) {
      if (index % 2 !== 0) {
        return 'slide-right'
      }
      return 'slide-left'
    },
    isSMC() {
      return localStorage.getItem('roles').includes(4);
    },
    isImpl() {
      return localStorage.getItem('roles').includes(1);
    },
    isABP() {
      return localStorage.getItem('roles').includes(2);
    },
    isDZOADM() {
      return localStorage.getItem('roles').includes(7);
    },
    fast_active(to_filter) {
      if (to_filter.toString() === this.fast_filter.toString()) {
        return 'active';
      }
      return '';
    },
    change_fast_filter(curr_filter) {
      this.fast_filter = curr_filter;
    },
    getContractDetail() {
      this.$store.dispatch('getContractDetail', this.$route.params.id).then((response) => {
        this.contract = response.data;
        this.is_OWNER = this.isOWNER();
      }).catch((error) => {
        this.$store.dispatch('addNotification', {
          type: 'error',
          message: error.response.data.detail,
        })
      });
    },

  },
  mounted() {
  },
};
</script>

<style scoped>
@import "../assets/css/webpixels.css";

@keyframes example {
  from {
    flex: 1
  }
  to {
    flex: none
  }
}


.input-field {
  width: 100%;
  padding: 10px;
  border: none;
  outline: none;
  margin: 0.5rem 0 1rem;
  border-radius: 10px;
}


.flex-column-animated {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 2rem;
  padding-bottom: 2rem;
}

.section-btn {
  background: inherit;
  animation: example 1000ms ease;

  position: relative;
}

.section-btn-bx:hover {
  transform: scale(1.2) translateX(-0.6rem);
}

.section-btn-bx {
  color: white;
  font-size: 40px;
  border-radius: 50%;
  padding: 0.5rem;
  transition-duration: .5s;
}

.embed_full {
  width: 100%;
  height: 60vh;
}

.table-abp thead tr th {
  border-top-width: 0 !important;
}

.section-header {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.section-header h2 {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 16px;
  color: black;
}

.section-header hr {
  border: 1px solid gray;
  width: 120px;
}

.bg-gradient_solid {
  color: #fff;
}

.steps {
  position: relative;
  margin-top: 32px;
}

.steps::after {
  content: "";
  position: absolute;
  width: 1px;
  background-color: white;
  opacity: 1;
  top: 0;
  bottom: 0;
  left: 50%;
}

.steps .content p {
  color: #676767;
  font-size: 16px;
}

.steps .content h2 {
  font-weight: 600;
  font-size: 16px;
  color: #676767;
}

.steps-container {
  position: relative;
  background-color: inherit;
  width: calc(50% + 32px);
}

.steps-container .content {
  padding: 32px;
  background-color: white;
  position: relative;
  border-radius: 0px 0px 80px 0px;
  transition: all 1.3s linear;
  box-shadow: 0px 16px 27px rgb(0 11 30 / 10%);
}

.steps .steps-container:nth-child(even) {
  left: calc(50% - 32px);
  flex-direction: row-reverse;
}

.steps-container {
  display: flex;
}

.steps .steps-container .date {
  font-weight: 900;
  font-size: 16px;
  color: #ffffff;
  margin-bottom: 10px;
  width: 62px;
  height: 62px;
  background-color: var(--primary);
  border-radius: 50%;
  flex-shrink: 0;
  align-items: center;
  display: flex;
  justify-content: center;
  z-index: 777;
}

.step-line {
  width: 40px;
  background-color: white;
  height: 1px;
  margin-top: 31px;
  opacity: 1;
  flex-shrink: 0;
}

@media (max-width: 767px) {
  .steps::after {
    left: 32px;
  }

  .steps-container {
    left: 0;
    flex-direction: row-reverse;
    width: auto;
    margin-bottom: 16px;
  }

  .steps .steps-container:nth-child(even) {
    left: 0;
  }
}

.table-bordered2 thead tr th {
  border-left: 1px #e7eaf0 solid;
  border-right: 1px #e7eaf0 solid
}

.table-bordered2 tbody tr td {
  border-left: 1px #e7eaf0 solid;
  border-right: 1px #e7eaf0 solid
}

.item-wrap {
  display: flex;
  justify-content: space-between;
}

.left-item ul {
  display: flex;
  justify-content: left;
  gap: 1rem;
}

@media screen and (max-width: 1100px) {
  .left-item {
    flex-direction: column;
    gap: 0.2rem;
    height: 100px !important;
  }

  .ul-li {
    height: 30px !important;
  }
}

.item {
  height: 50px;
  width: 50%;
}

.innernavbar-wrap {
  width: 100%;
}

.ul-li {
  list-style-type: none;
  width: 250px !important;
  min-width: 250px !important;
  height: 30px;
  border-radius: 10px;
  background: #d9e3f7 !important;
  text-align: center;
  cursor: pointer;
  color: #666 !important;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  border: 1px solid #d9e3f7;
}

.ul-li:hover {
  background: #2b6de8 !important;
  color: white !important;
}

@keyframes progress {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

th {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

td {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.tr-main {
  cursor: pointer
}

@keyframes wave {
  50% {
    transform: scale(0.9);
  }
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.div-item {
  height: 40px;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}

.descripcion {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.descripcion:after {
  content: " ........................................................................................................................................................................................................................................................................................."
}

.precio {
  flex-shrink: 0;
}

.event-left-part {
  height: 600px;
  display: flex;
  justify-content: flex-start;
  gap: 0.7rem;
  flex-direction: column;
}

.grided {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  margin-bottom: 1rem
}

@media (max-width: 1000px) {
  .mainless {
    display: none;
  }
  .grided {
    display: flex;
    flex-direction: column;
  }
  .right-section {
    display: none !important;
  }
  .mobile-flex {
    display: flex;
    flex-direction: column;
  }
  .mobile-flex li {
    width: 100%;
  }
  .mobile-flex li button{
    width: 100%;
  }
  .innernavbar-wrap {
    height: 200px;
  }
  .item-wrap {
    width: 100% !important;
  }
  .item {
    width: 100%;
  }

  .p-10 {
    padding: 10px !important;
    margin: 0 !important;
  }
}

</style>
