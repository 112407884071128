<template>
  <div class="notification-list">
    <NotificationItem
      v-for="notification in getNotifications"
      :key="notification.id"
      :notification="notification"
    />
  </div>
</template>

<script>
import NotificationItem from "./NotificationItem";
import { mapGetters } from "vuex";
export default {
  components: {
    NotificationItem,
  },
  computed: mapGetters(["getNotifications"]),
};
</script>

<style scoped>
.notification-list {
  position: fixed;
  bottom: 1rem;
  right: 2rem;
  /* width: 320px; */
}
</style>