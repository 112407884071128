import { render, staticRenderFns } from "./Notifications.vue?vue&type=template&id=8a5ce780&scoped=true&"
import script from "./Notifications.vue?vue&type=script&async=true&lang=js&"
export * from "./Notifications.vue?vue&type=script&async=true&lang=js&"
import style0 from "./Notifications.vue?vue&type=style&index=0&id=8a5ce780&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8a5ce780",
  null
  
)

export default component.exports